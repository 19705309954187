import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  ThemeProvider,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Path25, Path2, Path24, Path26, Path1, FinalLogo1 } from "./assets"
import RequesterLoginController, { Props, configJSON } from "./RequesterLoginController";
import { Toaster } from "react-hot-toast";
// Customizable Area End

// Customizable Area Start

const webStyles = {
  pprBoxWrappers: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: '5%'
  },
  pprLogins: {
    width: 500,
    borderRadius: "var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    padding: '30px'
  },
  footerMainWrapperLogins: {
    display: "flex", justifyContent: "space-between"
  },

  path2StyleLogins: {
    position: "absolute", zIndex: 1, bottom: 0
  },

  path1StyleLogins: {
    position: "absolute", zIndex: 1, bottom: 0, left: 0

  },
  path24StyleLogins: {

    position: "absolute", zIndex: 1, bottom: 0, right: 0
  },
  path25StyleLogins: {
    position: "absolute", zIndex: 1, bottom: 0, right: "45px"
  },

  forgetPasswordStyle: {
    marginBottom: "50px",
    paddingRight: "5px"
  },

  resetPassword: {
    cursor: 'pointer',
    color: "#8760A9"
  },

  fontStyle: {
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700
  },

  fontStyle_1: {
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 500
  },

  fontStyle_2: {
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 500
  },

  cancelBtton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial' as const
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial' as const,
    color: '#FFFFFF'
  },

  errorfontStyle: {
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontWeight: 400,
    color: '#DC2626'
  },
  boxStyle_1: {
    display: "flex",
    flexDirection: 'column' as const,
    gap: '15px'
  },

  logoMargin: {
    marginTop: '5%'
  },

  boxStyle_2: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '30px',
    marginTop: '4%'
  },

  boxStyle_3: {
    display: "flex",
    flexDirection: 'column' as const,
    gap: '5px'
  },

  boxStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px'
  },
  redFont:{
    color: 'red'
  }
};
// Customizable Area End
// Customizable Area Start
const themeRew = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class RequesterLogin extends RequesterLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={themeRew}>
        <Toaster />
        <Container maxWidth="lg">
          <Box style={webStyles.logoMargin}>
            <img src={FinalLogo1} alt="My Image" />
          </Box>

          <Box sx={webStyles.pprBoxWrappers}>
            <Paper style={webStyles.pprLogins}>
              <Box style={webStyles.boxStyle_1}>
                <Typography style={webStyles.fontStyle}>{configJSON.login}</Typography>
                <Typography style={webStyles.fontStyle_1}>{configJSON.provideworkemail}</Typography>
              </Box>

              <Box style={webStyles.boxStyle_2}>
                <Box style={webStyles.boxStyle_3}>
                  <Typography style={webStyles.fontStyle_2}>{configJSON.enterEmail}<span style={webStyles.redFont}> *</span></Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={this.state.webEmail}
                    onChange={this.handleEmailChange}
                    error={Boolean(this.state.emailError)}
                    data-testId={"changeEmail"}
                    helperText={
                      <>
                        <Typography style={webStyles.errorfontStyle}>{this.state.emailError}</Typography>
                      </>
                    }
                    placeholder={configJSON.enterEmail}
                  />
                </Box>
                <Box style={webStyles.boxStyle_3}>
                  <Typography style={webStyles.fontStyle_2}>{configJSON.enterPass}<span style={webStyles.redFont}> *</span></Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type={this.state.enablePasswordFieldReq ? "password" : "text"}
                    value={this.state.webPassword}
                    onChange={this.handlePasswordChange}
                    placeholder={configJSON.enterPass}
                    error={Boolean(this.state.passwordError)}
                    data-testId={"changePassword"}
                    helperText={
                        <Typography style={webStyles.errorfontStyle}>{this.state.passwordError}</Typography>
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            data-testId={"visiblity"}
                            onClick={this.handlePasswordVisibilityToggle}
                          >
                            {this.state.enablePasswordFieldReq ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
                <Box>
                  <Typography style={webStyles.forgetPasswordStyle}>
                    {configJSON.forgotpass}
                    <span
                      data-testId={"linkForgotPassword"}
                      onClick={this.redirectResetPassowrd}
                      style={webStyles.resetPassword}>{configJSON.resetpass}</span>
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyles.boxStyle}>
                <Button style={webStyles.cancelBtton}>{configJSON.btnTextCancelPasswordChange}</Button>
                <Button variant="contained" data-testId={"submit"} style={webStyles.submitButton} onClick={this.doWebEmailLoginReq}>
                  {configJSON.labelTitle}
                </Button>
              </Box>
            </Paper>
          </Box>

          <Box sx={webStyles.footerMainWrapperLogins}  >
            <Box style={{ display: "flex", }} >
              <Box sx={webStyles.path2StyleLogins}  >
                <img src={Path2} alt="Path2" />
              </Box>
              <Box sx={webStyles.path1StyleLogins} >
                <img src={Path1} alt="Path1" />
                {""}
              </Box>
            </Box>
            <Box style={{ display: "flex", }}>
              <Box sx={webStyles.path24StyleLogins} >
                {""}
                <img src={Path24} alt="Path24" />
              </Box >
              <Box sx={webStyles.path25StyleLogins} >
                <img src={Path25} alt="Path23" />
              </Box>

              {""}
              <Box sx={webStyles.path24StyleLogins}>
                <img src={Path26} alt="Path26" />
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}