Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.accountSetting = "Account setting";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.departmentsApiMethod = "GET";
exports.departmentsApiEndPoint = "bx_block_department/departments";
exports.serachDepartmentApiEndPoint = "bx_block_department/departments/search_department";
exports.departmentsApiContentType = "application/json";

exports.saveDepartmentApiEndPoint = "bx_block_department/departments"
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.saveDepartmentApiMethod ="POST"
exports.departmentLabel = 'Departments'
exports.exportBTN = 'Export'
exports.importBTN = 'Import'
exports.allAgentBTN = 'All Agents'
exports.createNewDepartmentBTN = 'Create new department'
exports.editDepartment = "Edit department";
exports.saveBTN = 'Save changes'
exports.cancelBTN = 'Cancel'
exports.submitBTN = 'Submit'
exports.addNewDepartment = " Add new department"
exports.departmentNameLabel = 'Department Name'
exports.departmentHeadLabel ="Department Head"
exports.primeUserLabel ="Prime User"
exports.emptyDataMessage = "No data found."
exports.domainForDepartmentLabel = "Domain for the Department"
exports.description = "Description"
exports.deleteDepartmentApiEndPoint = "bx_block_department/departments";
exports.deleteDepartmentApiMethod = 'DELETE';
exports.updateDepartmentApiEndPoint = "bx_block_department/departments";
exports.updateDepartmentApiMethod = "PATCH";
exports.rolesLabel = 'Roles'
exports.adminRole = 'Admin role'
exports.agentRole = "Agent role";
exports.addNewRole = 'Add new role'
exports.rolesApiContentType = "application/json";
exports.getRolesApiMethod = 'GET'
exports.getRolesApiEndPoint = 'bx_block_roles_permissions/roles'
exports.createRoleApiEndpoint = 'bx_block_roles_permissions/roles';
exports.createRoleApiMethod = 'POST';
exports.deleteRoleApiEndPoint = 'bx_block_roles_permissions/roles';
exports.deleteRoleApiMethod = 'DELETE';
exports.updateRoleApiEndPoint = "bx_block_roles_permissions/roles";
exports.updateRoleApiMethod = "PATCH";
exports.agentGroupLabel = "Agent groups";
exports.createRoleApiEndpoint= 'bx_block_roles_permissions/roles'
exports.getDepartmentInfoEndPoint= 'bx_block_department/departments'
exports.createRoleApiMethod = 'POST'
exports.agentRole = "Agent role"
exports.createNewRole = "Create new role";
exports.roleNameLabel = "Role Name"
exports.editRole = "Edit Role";

exports.userFieldsLabel = 'User Fields'
exports.requesterFieldsLabel = 'Requester Fields'
exports.requesterFieldsLabel2 = 'Requester fields'
exports.agentFieldsLabel = 'Agent Fields'
exports.agentFieldsLabel2 = 'Agent fields'
exports.editLabel = 'Edit'
exports.fieldsLabel = 'fields'
exports.AllBtn = 'All'
exports.AllRequesersBtn = 'All Requesters'
exports.specialCharPattern  = /^[A-Za-z0-9]/;
exports.userFieldsModalTitle = "Customizing your user fields"
exports.userFieldInfoMsg1 = 'The agent and requester forms in Freshservice let you capture important data about your agents and requesters, and thereby help you provide a better support experience. You can help your team get additional context about users quickly by including custom fields based on your type of business. For example, you can collect information with custom fields for team plan, department etc. and prioritize tickets accordingly in your service desk.'
exports.userFieldInfoMsg2 = 'Just like your ticket fields, you can add new fields to the user sign up form and make them mandatory. You can also have private fields that are visible just to your agents.'
exports.fieldsApiEndPoint = 'bx_block_department/dynamic_fields'
exports.fieldsApiContentType = "application/json";
exports.fieldsApiMethod = "GET";
exports.closeBtnLabel = 'Close'
exports.radioQuestion = 'Is this field mandatory ?'

exports.agentsLabel = 'Agents'
exports.createNewAgentstLabel = 'Create new agent'
exports.updateAgentstLabel = 'Update agent'
exports.agentsModalTitle = 'Understanding agents'
exports.agentsInfoMsg1 = 'The list shows all Agents added in your service desk. You can edit an existing agent’s permissions and access rights by hovering over the agent and clicking on edit icon button.'
exports.agentsInfoMsg2 = 'You can add new agents by clicking on the “Add new agent” button.'

exports.agentNameLabel = 'Name'
exports.agentEmailLabel = 'Email'
exports.agentLastActiveLabel = 'Last active'
exports.addNewAgent = 'Add new agent'
exports.getAllAgentsApiMethod = 'GET'
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.getAllAgentsApiContentType = "application/json";

exports.getAgentsFieldsApiEndPoint = 'bx_block_department/dynamic_fields/get_agent_field'
exports.getAgentsFieldsApiContentType = "application/json";
exports.getAgentsFieldsApiMethod = "GET";


exports.removeAgentApiEndPoint = 'bx_block_department/agent_and_requesters/'
exports.removeAgentApiContentType = "application/json";
exports.removeAgentApiMethod =  'DELETE'

exports.agentCreateApiContentType = "application/json";
exports.agentCreateApiEndPoint = "bx_block_department/agent_and_requesters";
exports.agentCreateApiMethod =  'POST'

exports.updateAgentApiContentType ="application/json";
exports.updateAgentApiEndPoint = "bx_block_department/agent_and_requesters/"
exports.updateAgentApiMethod = "PATCH"

exports.updateFieldApiContentType = "application/json";
exports.updateFieldApiEndPoint = "bx_block_department/dynamic_fields/update_user_field"
exports.updateFieldApiMethod = "POST";


exports.agentRemoveMessage = 'Agent Destroyed Successfully.'
exports.agentAddMessage = 'Agent created successfully.'
exports.agentUpdateMessage = 'Agent updated successfully.'
exports.removeAgentResponse = "Agent Or Requester Succesfully Destroyed"
exports.emailLabel = 'Email'
exports.nameLabel = 'Name'
exports.requestersLabel = 'Requesters' 

exports.requestersModalTitle = "Understanding requesters";
exports.addNewRequesterLabel = "Add new requester";
exports.createRequesterLabel = 'Create requester';
exports.updateRequesterLabel = 'Update requester';
exports.sendMainLabel = "Send activation email";
exports.deactivateLabel = "Deactivate";
exports.requestersInfoMsg1 = "This page lets you handpick a set of requesters and add them to your service desk. These requesters will have selective privileges to submit requests to your service desk. You can restrict access such that only people who have been added here are allowed to login to your self-service portal and access your knowledge base."
exports.requestersInfoMsg2 = "You can fill in the details of each of your new requesters manually or import a list of users from a CSV file. Once you have populated your list, your agents can open up each of your requesters and view their ticket history and contact information."

exports.getRequestersApiContentType = "application/json";
exports.getRequestersApiEndPoint = 'bx_block_department/agent_and_requesters/get_all_requester'
exports.getRequestersApiMethod = "GET";


exports.getRequesterFieldsApiContentType = "application/json";
exports.getRequesterFieldsApiEndPoint = "bx_block_department/dynamic_fields/get_requester_field"
exports.getRequesterFieldsApiMethod = "GET";

exports.removeRequesterApiContentType = "application/json";
exports.removeRequesterApiEndPoint = "bx_block_department/agent_and_requesters/"
exports.removeRequesterApiMethod  = 'DELETE'

exports.createRequesterApiContentType = "application/json";
exports.createRequesterApiEndPoint = "bx_block_department/agent_and_requesters"
exports.createRequesterApiMethod  = 'POST'

exports.requesterRemoveMessage = 'Agent Or Requester Succesfully Destroyed';
exports.requesterRemoveMessage2 = 'Requester Succesfully Destroyed';
exports.requesterUpdateMessage = 'Requester Updated Succesfully.';
exports.requesterAddMessage = 'Requester Created Succesfully';

exports.updateRequesterApiContentType = "application/json";
exports.updateRequesterApiEndPoint = "bx_block_department/agent_and_requesters/"
exports.updateRequesterApiMethod  = 'PATCH'


exports.getAgentsListApiContentType = "application/json";
exports.getAgentsListApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.getAgentsListApiMethod = "GET";
exports.departmentRemoveMessage = "Department Destroyed Successfully.";
exports.departmentUpdateMessage = "Department Updated Successfully.";
exports.departmentAddMessage = "Department Created Successfully.";
exports.departmentsModalTitle = "Understanding departments";
exports.departmentsInfoMsg1 = "You can configure departments inside Freshservice and group your requesters based on the nature of their job or position in your company. Departments can also help you classify tickets, problems, changes and releases from different types of employees differently, and define different workflows based on the impact and the urgency of requests.";
exports.departmentsInfoMsg2 = "Every department in Freshservice has a nominated head employee, who will take care of approving service requests from members in their team. In addition, you can also create separate knowledge base and forum categories for each of your departments that can be accessed only by the members of that team.";
exports.roleAddMessage = "Role Created Succesfully";
exports.roleRemoveMessage = "Role Destroyed Successfully.";
exports.roleUpdateMessage = "Role Updated Succesfully";
exports.selectLabel = "Role Type";
exports.rolesModalTitle = "Understanding roles";
exports.rolesModalSubTitle = "Role Type";
exports.roleInfoMsg1 = "Roles allow you to create and edit access permissions for agents. You can create new roles, specify what actions agents with these roles can perform within your service desk, and assign the role to agents.";
exports.roleInfoMsg2 = "For example, you can create a role for your Support Co-ordinators, allowing them to update fields and assign tickets, and even add notes internally, but not reply to employees.";
exports.roleInfoMsg3 = "Once you create and save a new role, you will be able to assign it to agents when you create or edit their profile by clicking on the Agents icon under the admin tab.";
exports.roleInfoMsg4 = "Admin roles contain permissions that grant agents the ability to modify service desk configurations.";
exports.roleInfoMsg5 = "Agent roles contain permissions that grant agents the ability to perform everyday activities like responding to tickets, managing changes, and working on projects.";
exports.roleInfoMsg6 = "Learn more about roles and privileges";
exports.noMemberMsg = "No members available";
exports.DepartmentFieldModalTitle = "Customizing your department fields"
exports.DepartmentFieldModal1 = "The department form in Freshservice lets you capture important data about the departments you support. You can help your team get additional context about the departments quickly by adding custom fields. For example, you can add a custom field to specify the physical location of departments to help agents provide better support.";
exports.DepartmentFieldModal2 = "Just like requester fields, you can add new fields to the department form and choose to make them mandatory."
exports.addMembersInDepartmentEndPoint = "add_members_in_department";
exports.nomembersadded = "There are no members added.";
// Customizable Area End