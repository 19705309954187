import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    isModalOpen: boolean;
    passwordResetModal: boolean;
    passwordIconReset: boolean;
    resetNewPassowrdModal: boolean;
    confirmNewPasswordIcon: boolean;
    deleteModal1: boolean;
    deleteModal2: boolean;
    profileData : any;
    editProfile : any;
    PasswordDeleteInput : string;
    errors: any;
    passerrors : any;
    currentPassword : string;
    currentPassErr : string;
    confirmpassword: string;
    newPassword : string;
    languages :any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
 

    // Customizable Area End
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiProfileReqCallIdWeb : string = "";
    apiProfileEditIdWeb : string = "";
    apiDeleteReqIdWeb : string = "";
    currentPasswordReqId : string = "";
    verifyPasswordReqId : string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),

            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            isModalOpen: false
            ,passwordResetModal: false,
            passwordIconReset: false,
            resetNewPassowrdModal: false,
            confirmNewPasswordIcon: false,
            deleteModal1: false,
            deleteModal2: false ,
            profileData : null,
            editProfile : {
              full_name: '',
              email: '',
              work_phone: '',
              time_zone: '',
            },
            errors: {},
            PasswordDeleteInput : '',
            passerrors : {

            },
            currentPassword : "",
            currentPassErr : "",
            confirmpassword : "",
            newPassword : "",
            languages : [
              { code: 'en', name: 'English' },
              { code: 'es', name: 'Spanish' },
              { code: 'fr', name: 'French' },
              { code: 'de', name: 'German' },
              { code: 'zh', name: 'Chinese' },
              { code: 'ja', name: 'Japanese' },
              { code: 'ar', name: 'Arabic' },
              { code: 'ru', name: 'Russian' },
              { code: 'pt', name: 'Portuguese' },
              { code: 'it', name: 'Italian' },
              { code: 'tr', name: 'Turkish' },
              { code: 'nl', name: 'Dutch' },
              { code: 'ko', name: 'Korean' },
              { code: 'sv', name: 'Swedish' },
              { code: 'pl', name: 'Polish' },
              { code: 'hi', name: 'Hindi' },
              { code: 'vi', name: 'Vietnamese' },
              { code: 'fa', name: 'Persian' },
              { code: 'th', name: 'Thai' },
              { code: 'cs', name: 'Czech' },
              { code: 'fi', name: 'Finnish' },
              { code: 'el', name: 'Greek' },
              { code: 'ro', name: 'Romanian' },
              { code: 'hu', name: 'Hungarian' }
            ]
        };
     


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        this.handleTokenReqUser()
        const ID = localStorage.getItem('reqID')
        this.handleRequesterProfileApi(ID);
        // Customizable Area End
    }

    // Customizable Area Start

    // Web Event Handling
    handleRequesterProfileApi = (id : any) => {
        
        const webHeader = {};
        const webRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiProfileReqCallIdWeb = webRequestMessage.messageId;
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.reqApiCallEndPoint+`${id}/show_requester_details`
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(webHeader)
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.reqApiType
        );
        runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
        
        return true;
      }
    handleTokenReqUser = () => {
        const tokenReqUser = localStorage.getItem('RequesterToken');
        if(tokenReqUser == null){
          window.location.href = 'RequesterLogin'
        }
      }
    handleOpenModal = () => {
        this.setState({ isModalOpen: true });
      };
    
    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
      };
    handleProfileEditFlow = () => {
        const id = localStorage.getItem('reqID');
        const hasErrors = Object.values(this.state.errors).some(error => !!error);
        if (hasErrors) {
          return false; 
        }
        const webHeader = {
            "Content-Type" : "application/json"
        };
        const httpBody = {requester : this.state.editProfile}
        const webRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiProfileEditIdWeb = webRequestMessage.messageId;
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.editProfileAPI+`${id}/edit_requester`
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(webHeader)
        );

        webRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
    
        webRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.editProfileType
        );
        runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
        
        return true;
 
      };
    
    handleOpenPasswordResetModal = () => {
        this.setState({ passwordResetModal: true });
      };
    
    handleClosePasswordResetModal = () => {
        this.setState({ passwordResetModal: false });
      };
    handlePasswordInput = () => {
        this.setState({ passwordIconReset: !this.state.passwordIconReset });
      
      };
      handleCurrentPassword = (e: any) => {
        this.setState({currentPassword : e.target.value})
        this.setState({currentPassErr : ""})
      }
    handleResetPasswordFlow = () => {
      if(this.state.currentPassword.trim() == "" || !this.state.currentPassword){
        this.setState({currentPassErr : 'Current Password is required.'})
      }else{
        const Reqqid = localStorage.getItem('reqID');
        const webHeader = {
            "Content-Type" : "application/json"
        };
        const httpBody = {current_password : this.state.currentPassword}
        const PasswordCurrentAPI = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.currentPasswordReqId = PasswordCurrentAPI.messageId;
        PasswordCurrentAPI.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_dashboard/requester_logins/${Reqqid}/current_password_verify`
        );
    
        PasswordCurrentAPI.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(webHeader)
        );
  
        PasswordCurrentAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
    
        PasswordCurrentAPI.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.callTypeApiValidateMobileNo
        );
        runEngine.sendMessage(PasswordCurrentAPI.id, PasswordCurrentAPI);
        
        return true;
      }
   
      
      };
    handleDeleteAccount = () => {
  
        const Reqqid = localStorage.getItem('reqID');
        const webHeader = {
            "Content-Type" : "application/json"
        };
        const httpBody = {password : this.state.PasswordDeleteInput}
        const DeleteAccountAPI = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiDeleteReqIdWeb = DeleteAccountAPI.messageId;
        DeleteAccountAPI.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.deleteProfileAPI+`${Reqqid}/delete_account`
        );
    
        DeleteAccountAPI.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(webHeader)
        );

        DeleteAccountAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
    
        DeleteAccountAPI.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteApiMethod
        );
        runEngine.sendMessage(DeleteAccountAPI.id, DeleteAccountAPI);
        
        return true;
    }
    handleConfirmNewPass = (e: any) => {
      this.setState({confirmpassword : e.target.value});
      this.setState({currentPassErr : ""})
    }
    handleNewPass = (e:any)=>{
      this.setState({newPassword : e.target.value})
      this.setState({currentPassErr : ""})

    }
    
    handleLogoutRequester = () => {
        localStorage.clear();
        window.location.href = 'RequesterLogin'
      }

      handleInputChange = (e: any): void => {
        const { name, value } = e.target;
        const errors = { ...this.state.errors };
      
        // Validate full name field
        if (name === 'full_name') {
          errors.full_name = this.validateFullName(value);
        }
      
        // Validate email field
        if (name === 'email') {
          errors.email = this.validateEmail(value);
        }
      
        // Validate work phone field
        if (name === 'work_phone') {
          errors.work_phone = this.validateWorkPhone(value);
        }
      
        this.setState(prevState => ({
          editProfile: {
            ...prevState.editProfile,
            [name]: value
          },
          errors: {
            ...prevState.errors,
            ...errors
          }
        }));
      };
      
      validateFullName = (value: string): string => {
        return value.trim() ? '' : 'Full name is required';
      };
      
      validateEmail = (value: string): string => {
        if (!value.trim()) {
          return 'Email is required';
        } else if (!/^[\w.-]+@[\w.-]+\.\w+$/.test(value.trim())) {
          return 'Invalid email address';
        } else {
          return '';
        }
      };
      
      
      validateWorkPhone = (value: string): string => {
        if (!value.trim()) {
          return 'Work phone number is required';
        }
        if (!/^\d+$/.test(value)) {
          return 'Work phone number must contain only digits';
        }
        if (!/^\d{9,15}$/.test(value)) {
          return 'Work phone number must be between 9 and 15 digits';
        }
        return '';
      };
      
  
      

// Customizable Area End
// Customizable Area Start
async receive(from: string, message: Message) {
  const { RestAPIResponceMessage } = MessageEnum;
  const apiRequestCallId = message.id;

  if (apiRequestCallId === getName(RestAPIResponceMessage)) {
      this.handleRestAPIResponse(message);
  }
}

handleRestAPIResponse(message: Message) {
  const { RestAPIResponceDataMessage, RestAPIResponceSuccessMessage } = MessageEnum;
  const apiRequestDataId = message.getData(getName(RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(RestAPIResponceSuccessMessage));

  if (apiRequestDataId === this.apiProfileReqCallIdWeb) {
      this.handleProfileRequest(responseJson);
  } else if (apiRequestDataId === this.apiProfileEditIdWeb) {
      this.handleProfileEdit(responseJson);
  } else if (apiRequestDataId === this.apiDeleteReqIdWeb) {
      this.handleProfileDelete(responseJson);
  }else if (apiRequestDataId === this.currentPasswordReqId){
    this.handleCurrentPasswordResp(responseJson)
  }else if(apiRequestDataId === this.verifyPasswordReqId){
    this.handleVerifyPassword(responseJson)
  }
}
handleVerifyPassword = (responseJson : any) => {
if(responseJson.error){
this.setState({currentPassErr : responseJson.error})
}else{
  this.setState({resetNewPassowrdModal : false});
  toast.success('Password Updated Succesfully!')
}
}
handleProfileRequest(responseJson: any) {
  if (responseJson.data?.attributes) {
      this.setState(prevState => ({
          profileData: responseJson.data,
          editProfile: responseJson.data.attributes
      }));
  } else {
      toast.error('Something went wrong!');
  }
}
handleCurrentPasswordResp(responseJson : any){
  if(responseJson.error){
    this.setState({currentPassErr : responseJson.error})
  }else{
      this.setState({ passwordResetModal: false, resetNewPassowrdModal: true });
  }
}

handleProfileEdit(responseJson: any) {
  if (responseJson.data?.id) {
      this.setState({ isModalOpen: false });
      this.handleRequesterProfileApi(responseJson.data.id);
      toast.success("Details updated.");
  } else {
      toast.error('Something went wrong!');
      this.setState({ isModalOpen: false });
  }
}

handleProfileDelete(responseJson: any) {
  if (responseJson.message) {
      localStorage.clear();
      toast.success(responseJson.message);
      window.location.href = 'requesterlogin';
  } else {
      toast.error('Something went wrong!');
  }
}
handlePasswordChange = () => {
  if(this.state.newPassword.trim() == '' || this.state.confirmpassword.trim() == ""){
    this.setState({currentPassErr : "All fields are mandatory!"})
  }else{
    const Reqqid = localStorage.getItem('reqID');
    const webHeader = {
        "Content-Type" : "application/json"
    };
    const httpBody = {create_new_password : this.state.newPassword, repeat_new_password : this.state.confirmpassword}
    const ChangePasswordAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyPasswordReqId = ChangePasswordAPI.messageId;
    ChangePasswordAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_dashboard/requester_logins/${Reqqid}/change_password`
    );
  
    ChangePasswordAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );
  
    ChangePasswordAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );
  
    ChangePasswordAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(ChangePasswordAPI.id, ChangePasswordAPI);
    
    return true;
  }
 
}



// Customizable Area End



}

// Customizable Area Start
// Customizable area End
