Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.settingLabel = "Settings";
exports.accountSettingLabel = "Account Setting";
exports.accountLabel = "Account";
exports.searchPlacholder = "Search";
exports.generalLabel = "General";
exports.currentPlan = "Current Plan";
exports.accountName = "Account Name";
exports.activeSince = "Active since";
exports.assets = "Assets";
exports.agents = "Agents";
exports.dataCenter = "Data Center";
exports.version = "Version";
exports.contactDetailsLabel = "Contact details";
exports.primaryContactDetailsLabel = "Primary contact details";
exports.fullName = "Full name";
exports.email = "Email";
exports.phoneNumber = "Phone number";
exports.invoiceLabel = "Send invoices to";
exports.editBtnText = "Edit";
exports.otherDetailsLabel = "Other details";
exports.support = "Support";
exports.haveInIssue = "Have an issue?";
exports.talkToSupport = "Talk to support";
exports.exportData = "Export Data";
exports.exportDataDetailText = "Generate an XML of all available service desk data and send it via email. Note:- Recent 500 notes alone will be exported for tickets.";
exports.exportNowBtn = "Export now";
exports.rightToRestrictProcessing = "Right To Restrict Processing";
exports.rightToRestrictProcessingTxt = "Choose to stop sharing product usage data with sub-processors. Your product behaviour will no longer be analysed";
exports.optOut = "Opt out";
exports.cancelBtn = "Cancel my account";
exports.editContactDetails = "Edit contact details";
exports.cancelIconAltText = "Cancel";
exports.accountDetailApiContentType = "application/json";
exports.getAccountDetailApiEndpoint = "account_block/accounts";
exports.getAccountDetailApiMethod = "GET";
exports.updateAccountDetailApiEndPoint = "account_block/accounts";
exports.updateAccountDetailApiMethod = "PATCH";
exports.primaryDetailsLabel = "Primary contact details";
exports.sendInvoiceLabel = "Send invoice to";
exports.nameInputId = "name";
exports.fullNameLabel = "Full name";
exports.emailInputId = "email"
exports.emailLabel = "Email";
exports.phoneInputId = "phone";
exports.phoneLabel = "Phone";
exports.invoiceEmailInputId = "invoiceEmail";
exports.modalCancel = "Cancel";
exports.saveChanges = "Save changes";
exports.assetsCount = "100";
exports.dataCenterCount = "10";
exports.versionNumber = "2023.R07.06";
exports.detailsUpdateMessage = "Contact details updated.";
exports.alphaRegex = "[^A-Za-z ]";
// Customizable Area End
