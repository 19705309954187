Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchangemanagementticket";
exports.labelBodyText = "cfchangemanagementticket Body";

exports.btnExampleTitle = "CLICK ME";
exports.textCreateNewChanges = 'Create new changes';
exports.textChanges = "Changes";
exports.textChangeRequestName = 'Change Request Name';
// Customizable Area End