import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import React from "react";
import moment from "moment";
import toast from "react-hot-toast";

interface AllTaskList {
  id: string;
  type: string;
  attributes: {
    title: string;
    status: string;
    note: string
    due_date: string;
    notify_before: string;
    created_at: string;
    planned_startdate: string;
    planned_enddate: string;
    ticket_id: string;
    group: {
      id: string;
      name: string;
    },
    agent: {
      id: string,
      name: string
    }
  }
}

interface ParticularTask {
  attributes: {
    title: string;
    status: string;
    planned_startdate: string;
    planned_enddate: string;
    agent: {
      id: string;
      name: string
    }
  }
}
interface AllGroupsList {
  id: string,
  type: string,
  attributes: {
    name: string
  }
}

interface FilterGroupList {
  id: string,
  name: string
}

interface FilterAgentList {
  id: string,
  full_name: string
}
interface AllAgentsList {
  id: string,
  type: string,
  attributes: {
    full_name: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isLoading: boolean;
  allTaskList: AllTaskList[];
  rowsPerPage: number;
  page: number;
  isDrawerOpen: boolean;
  anchorEl: HTMLElement | null;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  selectedStatus: string | null;
  isFilterDrawerOpen: boolean;
  openEditpopover: HTMLElement | null;
  isEditDialog: boolean;
  particularTaskResponse: AllTaskList | null;
  titleError: string;
  allGroupList: AllGroupsList[];
  allAgentsList: AllAgentsList[];
  changedGroup: string;
  changedAgent: string;
  notifyBefore: string;
  dueDate: string | undefined;
  dueTime: string;
  totalPageCount: number;
  filterGroupList: FilterGroupList[];
  changedFilterGroup: string;
  filterAgentsList: FilterAgentList[];
  changedFilterAgents: string;
  taskTicketId: string
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TasksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllTasksApiCallId: string = "";
  tableHeader = [
    { label: 'Title', key: 'title' },
    { label: 'Assigned To', key: 'agent' },
    { label: 'Status', key: 'status' },
    { label: 'Due Date', key: 'due_date' },
    { label: 'Created Date', key: 'created_at' },
  ];
  statusOptions = ["Open", "Pending", "Resolved", "Closed"];
  getParticularTasksApiCallId: string = "";
  updateStatusAPICallId: string = "";
  selectOptions = ["All Parent Tickets", "Incident Ticket", "Change Ticket", "Release Ticket", "Problem Ticket"];
  dueByOptions = ["Select a time period", "Never", "15 Minutes", "30 Minutes", "1 Hours", "4 Hours"]
  getAllGroupsListAPICallId: string = "";
  getAllAgentsListAPICallId: string = "";
  updateTaskDataAPICallId: string = "";
  taskGroupForFilterAPICallId: string = "";
  taskAgentsForFilterAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      allTaskList: [],
      rowsPerPage: 10,
      page: 0,
      isDrawerOpen: false,
      anchorEl: null,
      sortBy: 'title',
      sortDirection: 'asc',
      particularTaskResponse: null,
      selectedStatus: null,
      isFilterDrawerOpen: false,
      openEditpopover: null,
      isEditDialog: false,
      titleError: '',
      allAgentsList: [],
      allGroupList: [],
      changedGroup: '',
      changedAgent: '',
      notifyBefore: '',
      dueDate: '',
      dueTime: '',
      totalPageCount: 0,
      filterGroupList: [],
      changedFilterGroup: '',
      filterAgentsList: [],
      changedFilterAgents: '',
      taskTicketId: ''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAllTasks();
    this.sortTaskList();
    this.getAllAgentsList();
    this.getAllGroupsList();
    this.getTaskGroupForFilter();
    this.getTaskAgentsForFilter()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })
    switch (apiRequestCallId) {
      case this.getAllTasksApiCallId:
        this.handleAllTaskAPIresponse(responseJson);
        break;
      case this.getParticularTasksApiCallId:
        this.handleParticularTaskAPIResponse(responseJson);
        break;
      case this.updateStatusAPICallId:
        this.handleUpdateStatusAPIResponse(responseJson);
      case this.getAllGroupsListAPICallId:
        this.handleGroupApiDataResponse(responseJson);
        break;
      case this.getAllAgentsListAPICallId:
        this.handleAgentsListResponse(responseJson);
        break;
      case this.updateTaskDataAPICallId:
        this.handlUpdateTaskDataResponse(responseJson);
        break;
      case this.taskGroupForFilterAPICallId:
        this.handleFilterGroupListAPIResponse(responseJson);
        break;
      case this.taskAgentsForFilterAPICallId:
        this.handleFilterAgentsListAPIResponse(responseJson);
        break;
      default:
        break;
    }
  }

  // Event Handlers
  getInitials = (name: string) => {
    if (!name) return '';
    const parts = name.split(' ');
    if (parts.length > 1) {
      return `${parts[0][0].toUpperCase()}${parts[1][0].toUpperCase()}`;
    }
    return parts[0][0].toUpperCase();
  }

  onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event?.target?.value, 10) });
  };

  onPageChange = (unused: unknown, page: number) => {
    this.setState({ page }, () => this.getAllTasks());
  };

  handleRowClick = async (task: AllTaskList) => {
    if (task && task.id) {
      this.setState({
        isDrawerOpen: true,
        taskTicketId: task.attributes.ticket_id && task.attributes.ticket_id.toString()
      });
      await setStorageData('selectedTaskId', task.id);
      await this.getParticularTasks();
    }
  }

  handleModalClose = () => {
    this.setState({ isDrawerOpen: false })
  }

  formatDate = (dateString?: string): string => {
    return dateString ? moment(dateString).format('DD MMM YYYY, hh:mm A') : '--';
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleEditMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openEditpopover: event?.currentTarget })
  }

  handleEditMenuClose = () => {
    this.setState({ openEditpopover: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenEditDailog = () => {
    this.setState({
      isEditDialog: true,
      openEditpopover: null,
      isDrawerOpen: false
    })
  }

  handleCloseEditDialog = () => {
    this.setState({ isEditDialog: false })
  }

  handleSort = (key: string) => {
    const isAsc = this.state.sortBy === key && this.state.sortDirection === 'asc';
    this.setState({
      sortBy: key,
      sortDirection: isAsc ? 'desc' : 'asc',
    }, this.sortTaskList);
  };

  sortTaskList = () => {
    const { allTaskList, sortBy, sortDirection } = this.state;
    if (!sortBy) return;

    const sortedList = [...allTaskList].sort((ascending, decending) => {
      const aValue = this.getAttributeValue(ascending, sortBy);
      const bValue = this.getAttributeValue(decending, sortBy);

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    this.setState({ allTaskList: sortedList });
  };

  getAttributeValue = (taskItem: AllTaskList, key: string) => {
    switch (key) {
      case 'title':
        return taskItem.attributes.title;
      case 'agent':
        return taskItem.attributes.agent.name;
      case 'status':
        return taskItem.attributes.status;
      case 'due_date':
        return new Date(taskItem.attributes[key]);
      case 'created_at':
        return new Date(taskItem.attributes[key]);
      default:
        return '';
    }
  };

  handleStatusMenuChange = async (option: string) => {
    this.setState({ selectedStatus: option }, async () => {
      this.updateTaskStatus();
      this.handleMenuClose();
    });
  };
  handleOpenFilterDrawer = () => {
    this.setState({ isFilterDrawerOpen: true })
  }

  handleCloseFilterDrawer = () => {
    this.setState({ isFilterDrawerOpen: false })
  }

  handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { particularTaskResponse } = this.state;
    const newTitle = event.target.value;
    let titleError = '';

    if (!newTitle) {
      titleError = configJSON.subjectRequire;
    }
    if (particularTaskResponse?.attributes) {
      particularTaskResponse.attributes.title = newTitle;
      this.setState({ particularTaskResponse, titleError });
    }
  }

  handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { particularTaskResponse } = this.state;
    const newNote = event.target.value;
    if (particularTaskResponse?.attributes) {
      particularTaskResponse.attributes.note = newNote;
      this.setState({ particularTaskResponse })
    }
  }

  handleStatusChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedStatus = event.target.value as string;
    this.setState({ selectedStatus });
  }

  handleChangeNotifyBefore = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const notifyBefore = event.target.value as string;
    this.setState({ notifyBefore });
  }

  handleChangeDueDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dueDate = event.target.value;
    this.setState({ dueDate })
  };

  handleChangeDueTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ dueTime: event.target.value });
  };

  getGroupValue = () => {
    let selectedGroup = this.state.allGroupList.filter((data: AllGroupsList) => {
      return parseInt(data.id) === parseInt(this.state.changedGroup);
    });
    return selectedGroup[0] || null;
  }

  getAgentsValue = () => {
    let selectedAgent = this.state.allAgentsList.filter((data: AllAgentsList) => {
      return parseInt(data.id) === parseInt(this.state.changedAgent);
    });
    return selectedAgent[0] || null;
  }

  getFilterGroupValue = () => {
    let selectedGroup = this.state.filterGroupList.filter((data: FilterGroupList) => {
      return parseInt(data.id) === parseInt(this.state.changedFilterGroup);
    });
    return selectedGroup[0] || null;
  }

  getFilterAgentValue = () => {
    let selectedAgent = this.state.filterAgentsList.filter((data: FilterAgentList) => {
      return parseInt(data.id) === parseInt(this.state.changedFilterAgents);
    });
    return selectedAgent[0] || null;
  }

  handleChangeAgents = (value: AllAgentsList | null) => {
    this.setState({ changedAgent: value ? value.id : '' })
  }

  handleChangeGroup = (value: AllGroupsList | null) => {
    this.setState({ changedGroup: value ? value.id : '' })
  }

  handleChangeFilterGroup = (value: FilterGroupList | null) => {
    this.setState({ changedFilterGroup: value ? value.id : '' })
  }

  handleChangeFilterAgents = (value: FilterAgentList | null) => {
    this.setState({ changedFilterAgents: value ? value.id : '' })
  }

  validateFields = () => {
    const { particularTaskResponse } = this.state;
    let isValid = true;
    let titleError = '';

    if (!particularTaskResponse || !particularTaskResponse.attributes || !particularTaskResponse.attributes.title) {
      titleError = configJSON.subjectRequire;
      isValid = false;
    }

    this.setState({ titleError });
    if (isValid) {
      this.updateParticularTaskData()
    }
    return isValid;
  };

  // API Integration
  getAllTasks = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allTaskListAPIEndPoint}` + `?page=${this.state.page}&per_page=${this.state.rowsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getParticularTasks = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };
    const selectedTAskId = await getStorageData("selectedTaskId")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getParticularTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}` + this.state.taskTicketId + '/tasks/' + selectedTAskId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllGroupsList = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllGroupsListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllAgentsList = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAgentsListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAgentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateParticularTaskData = async () => {

    this.setState({ isLoading: true })

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const selectedTAskId = await getStorageData("selectedTaskId")
    const dueDateTime = `${this.state.dueDate}T${this.state.dueTime}:00.000Z`;
    const httpBody = {
      task: {
        title: this.state.particularTaskResponse?.attributes.title,
        status: this.state.selectedStatus,
        group_id: this.state.changedGroup,
        agents_and_requester_id: this.state.changedAgent,
        notify_before: this.state.notifyBefore,
        due_date: dueDateTime,
        note: this.state.particularTaskResponse?.attributes?.note,

      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}` + this.state.taskTicketId + '/tasks/' + selectedTAskId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAgentGroupsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.updateTaskDataAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateTaskStatus = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const selectedTAskId = await getStorageData("selectedTaskId")

    const httpBody = {
      status: this.state.selectedStatus
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}` + this.state.taskTicketId + '/tasks/' + selectedTAskId + "/update_task_status"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.updateStatusAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTaskGroupForFilter = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskGroupForFilterAPICallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskGroupFilterAPIEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTaskAgentsForFilter = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskAgentsForFilterAPICallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskAgentsFilterAPIEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // API responses
  handleAllTaskAPIresponse = async (responseJson: { tasks: { data: AllTaskList[] }, total_count: number }) => {
    if (responseJson && responseJson.tasks && responseJson.tasks.data) {
      this.setState({
        isLoading: false,
        allTaskList: responseJson.tasks.data,
        totalPageCount: responseJson.total_count,
      });
    }
  }



  handleParticularTaskAPIResponse = (responseJson: { data: AllTaskList }) => {
    if (responseJson && responseJson.data) {
      this.setState({
        particularTaskResponse: responseJson.data,
        changedGroup: responseJson.data.attributes?.group?.id || "",
        changedAgent: responseJson.data.attributes?.agent?.id || "",
        selectedStatus: responseJson.data.attributes?.status || "",
        notifyBefore: responseJson.data.attributes?.notify_before || "",
        dueDate: responseJson.data.attributes?.due_date && (new Date(responseJson.data.attributes?.due_date).toISOString().split('T')[0]),
        dueTime: responseJson.data.attributes?.due_date && (new Date(responseJson.data.attributes?.due_date).toISOString().split('T')[1].split('.')[0]),
      })
    }
  }

  handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
    if (responseJson.data) {
      const groupData = responseJson.data.map((group) => ({
        id: group.id,
        type: 'someType',
        attributes: {
          name: group.attributes?.name || ''
        }
      }));
      this.setState({
        allGroupList: groupData
      })
    }
  }

  handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
    if (responseJson.data) {
      const agentData = responseJson.data.map((agents) => ({
        id: agents.id,
        type: 'someType',
        attributes: {
          full_name: agents.attributes?.full_name || ''
        }
      }));
      this.setState({
        allAgentsList: agentData
      })
    }
  }

  handleUpdateStatusAPIResponse = async (responseJson: { message: string }) => {
    if (responseJson) {
      await this.getParticularTasks()
      await this.getAllTasks()
    }
  }

  handlUpdateTaskDataResponse = (responseJson: { data: AllTaskList }) => {
    if (responseJson && responseJson.data) {
      this.setState({ isLoading: false, isEditDialog: false, isDrawerOpen: false })
      toast.success(configJSON.updateTaskSucessResponse)
      this.getAllTasks()
    }
  }

  handleFilterGroupListAPIResponse = (responseJson: { data: FilterGroupList[] }) => {
    if (responseJson && responseJson.data) {
      const filterGroupData = responseJson.data.map((group) => ({
        id: group.id,
        name: group?.name || ''
      }));
      this.setState({
        filterGroupList: filterGroupData
      })
    }
  }

  handleFilterAgentsListAPIResponse = (responseJson: { data: FilterAgentList[] }) => {
    if (responseJson && responseJson.data) {
      const filterAgentData = responseJson.data.map((agent) => ({
        id: agent.id,
        full_name: agent?.full_name || ''
      }));
      this.setState({ filterAgentsList: filterAgentData })
    }
  }
  // Customizable Area End
}
