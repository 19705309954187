import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Button,
    Input,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Typography,
    Dialog,
    Card,
    Chip,
    styled,
    Box,
    Grid,
    TextField,
    InputLabel,
} from "@material-ui/core";
import {
    MoreVert,
    Search,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    InfoOutlined,
    Close,
} from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { cancelIcon, successIcon } from "./assets";
import CabController, { Props, configJSON } from "./CabController";
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const CardTitle = styled(Typography)({
    width: '100%',
    fontFamily: 'Manrope',
    color: '#33354D',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    fontStyle: 'normal',
})
const MainCard = styled(Card)({
    marginBottom: '8px',
    padding: '12px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
})

const CardTextBox = styled(Box)({
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
})

const CustomChip = styled(Chip)({
    background: '#11A64A',
    fontFamily: 'Manrope',
    color: '#FFF',
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '4px !important',
    lineHeight: 'normal',
    border: '0'
})
const Scrollable = styled(Box)({
    marginBottom: '32px',
    "&::-webkit-scrollbar": {
        scrollPadding: '10px',
        width: '10px',
    },
    "&::-webkit-scrollbar-thumb": {
        background: 'gray',
    }
})

const CardParagraph = styled(Typography)({
    color: "#A3A6C2",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontstyle: 'normal',
    fontweight: 600,
    lineheight: 'normal',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
})



// Customizable Area End

export default class Cab extends CabController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    returnNoDataText = () => {
        if (this.state.cabsList.length === 0) {
            return (<Typography>{configJSON.textNoData}</Typography>)
        } else {
            return null
        }

    }

    renderToaster = () => {
        return (
            <Toaster >
                {(t) => (
                    <ToastBar style={{
                        width: '450px',
                        maxWidth: '450px'
                    }} toast={t}>
                        {({ icon, message }) => (
                            <Box style={webStyles.toaster_wrapper}  data-test-id="toast-message">
                                <Box style={webStyles.toaster_container}>
                                    <Box style={webStyles.toaster_box}>
                                        <span style={webStyles.toastIcon}>
                                            <img src={successIcon}/>
                                        </span>
                                        <p style={{...webStyles.toaster_msg, fontWeight: 'bold'}}>
                                            {configJSON.cabCreatedMessage}
                                        </p>
                                    </Box>
                                    <p style={webStyles.toaster_group_name}>{message}</p>
                                </Box>
                                <Box style={webStyles.toaster_box}>
                                    {t.type !== "loading" && (
                                        <>
                                            <span style={webStyles.toaster_span}></span>
                                            <span style={webStyles.toastCancelIcon} onClick={() => toast.dismiss(t.id)}>
                                                <Close />
                                            </span>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                {this.renderToaster()}
                <Loader loading={this.state.isLoading} />
                <Box style={webStyles.container}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={webStyles.navigation}>
                            Settings {">"} User management {">"} Change Advisory Board (CAB)
                        </span>
                        <Box style={{ borderRadius: '5px', background: '#e6deed', }}>
                            <span style={{ height: '36px', width: '36px', }}>
                                <MoreVert style={webStyles.moreIconStyle} />
                            </span>
                        </Box>
                    </Box>

                    <Box style={{ marginBottom: "50px", marginTop: "60px", }}>
                        <Box style={{ display: "flex", gap: "5px" }}>
                            <button style={webStyles.arrow}>
                                <KeyboardArrowLeft style={webStyles.arrow_icon} />
                            </button>
                            <button style={webStyles.arrow}>
                                <KeyboardArrowRight style={webStyles.arrow_icon} />
                            </button>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <h1 style={webStyles.heading} >{configJSON.cabLabel}</h1>
                            <InfoOutlined style={{ color: "#8760A9" }} />
                        </Box>
                    </Box>

                    <Box style={{ position: "relative", marginBottom: "32px" }}>
                        <Search style={webStyles.search_icon} />
                        <Input
                            style={webStyles.input}
                            disableUnderline={true}
                            onChange={(e) => this.setInputValue(e.target.value)}
                            placeholder={configJSON.textSearch}
                        />
                    </Box>
                    <Box style={webStyles.cabListMain}>
                        <Box style={webStyles.cabList}>
                            <Box
                                style={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                    marginBottom: "37px",
                                    alignItems: "center",
                                }}>
                                <h2
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}>
                                    <span style={webStyles.card_heading}>
                                        {configJSON.cabGroupLabel}
                                    </span>
                                    <span style={webStyles.cab_count}>
                                        ({this.state.cabsList.length})
                                    </span>
                                </h2>
                                <Box>
                                    <Button style={webStyles.export_button}>{configJSON.exportBTN}</Button>
                                </Box>
                            </Box>
                            <Scrollable
                                style={
                                    this.state.cabsList.length > 5 ? { overflowY: 'scroll', maxHeight: '455px' } : {}}
                            >
                                {this.state.cabsList.length > 0 &&
                                    this.state.cabsList.map((cab, index: number) => (
                                        <MainCard key={`key-${cab.id}-${index}`} variant="outlined" data-test-id={`navigate-user-card-${index}`} onClick={() => this.navigateUser(cab.id)}>
                                            <CardTextBox>
                                                <CardTitle >{cab.attributes.name}</CardTitle>
                                                <CardParagraph>{cab.attributes.description}</CardParagraph>
                                            </CardTextBox>
                                            <CustomChip
                                                label={`${cab.attributes?.agents?.length || 0} members`}
                                                size="medium"
                                                variant="outlined"
                                            />
                                        </MainCard>
                                    ))
                                }
                                {this.returnNoDataText()}
                            </Scrollable>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "42px",
                                }}
                            >
                                <Button style={webStyles.create_button} onClick={() => this.handleModalOpen(true)} data-test-id='open_add_modal'>
                                    {configJSON.addNewCabLabel}
                                </Button>
                            </Box>
                        </Box>
                        <Box style={webStyles.accordians}>
                            <List component="nav">
                                {this.navigationList.map((item, index: number) => {
                                    if (item?.list?.length) {
                                        return (
                                            <React.Fragment key={`key-${item.title}-${index}`}>
                                                <ListItem
                                                    button style={{
                                                        ...webStyles.navigation_item,
                                                        ...(this.isPathExist(item.list) &&
                                                            webStyles.navigation_menu_bg),
                                                    }}
                                                    onClick={() =>
                                                        this.openAccordian(index)
                                                    }
                                                    data-test-id='openAccordian'
                                                >
                                                    <ListItemText disableTypography={true} primary={item.title}
                                                        style={{
                                                            ...webStyles.navigation_link,
                                                            ...(this.isPathExist(item?.list) &&
                                                                webStyles.navigation_menu_color)
                                                        }}

                                                    />
                                                </ListItem>
                                                <Collapse timeout="auto" unmountOnExit in={this.state.openListIndex === index}>
                                                    {item?.list?.map((link, index: number) => (
                                                        <List key={`key-${link.title}-${index}`} disablePadding style={{ marginTop: '10px' }} >
                                                            <ListItem
                                                                style={{
                                                                    ...webStyles.navigation_item_nested,
                                                                    ...(this.pathname ===
                                                                        link.href &&
                                                                        webStyles.navigation_item_active),
                                                                }}
                                                                href={link.href}
                                                                component="a" button
                                                            >
                                                                <ListItemText
                                                                    style={{
                                                                        ...webStyles.navigation_link,
                                                                        ...(this.pathname ===
                                                                            link.href &&
                                                                            webStyles.navigation_link_active_color),
                                                                    }}
                                                                    disableTypography={true}
                                                                    primary={link.title}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    ))}
                                                </Collapse>
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <ListItem
                                            style={webStyles.navigation_item}
                                            key={`key-${item.title}-${index}`}
                                            button
                                        >
                                            <ListItemText
                                                style={webStyles.navigation_link}
                                                primary={item.title}
                                                disableTypography={true}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Box>
                </Box>
                <Dialog
                    open={this.state.showModal}
                    onClose={() => this.handleModalOpen(false)}
                    maxWidth='sm'
                    data-test-id="close-dialog"
                >
                    <Box p={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Typography style={webStyles.table_heading}>{configJSON.addNewCabLabel}</Typography>
                            <span style={webStyles.modal_cancel_icon} data-test-id='cancelIcon' onClick={() => this.handleModalOpen(false)}>
                                <img src={cancelIcon} alt="" />
                            </span>
                        </Box>
                        <Formik
                            onSubmit={this.handleSubmit}
                            initialValues={this.state.initialValues}
                            validationSchema={this.validationSchema}
                        >
                            {({ errors, touched }) => (
                                <Form translate={undefined} style={webStyles.form_main_Box}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel style={webStyles.input_label} htmlFor="name">
                                                {configJSON.cabNameLabel}<span style={webStyles.cab_span}>*</span>
                                            </InputLabel>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                id="name"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                placeholder={configJSON.cabNamePlaceholder}
                                                helperText={touched.name && errors.name}
                                                error={touched.name && !!errors.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel style={webStyles.input_label} htmlFor="description">
                                                {configJSON.descriptionLabel}
                                            </InputLabel>
                                            <Field
                                                as={TextField}
                                                id="description"
                                                name='description'
                                                multiline
                                                variant="outlined"
                                                minRows={6}
                                                fullWidth
                                                placeholde={configJSON.cabDescriptionPlaceholder}
                                                helperText={touched.description && errors.description}
                                                error={touched.description && !!errors.description}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box mt={'30px'} style={webStyles.modal_button_main_Box}>
                                        <Button type="reset" style={webStyles.cancel_button}>{configJSON.textCancel}</Button>
                                        <Button type="submit" style={webStyles.add_button} >{configJSON.addNewCabLabel}</Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Dialog >
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    container: {
        height: "100%",
        paddingBottom: "25px",
        margin: "15px 15px 15px 300px",
        fontFamily: "Manrope",
    } as const,
    heading: {
        fontSize: "30px",
        color: "#33354D",
        lineHeight: "40px",
        marginTop: "10px",
        marginBottom: '0',
        fontWeight: "bold",
    } as const,
    arrow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100px",
        border: "1px solid #E6DEED",
        width: "28px",
        height: "28px",
        backgroundColor: "white",
        cursor: "pointer",
    } as const,
    arrow_icon: {
        color: "#33354D",
        width: "20px",
        height: "20px",
    } as const,
    table_heading: {
        color: "#33354D",
        fontSize: "20px",
        fontFamily: 'Manrope',
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        marginBottom: '30px'
    } as const,
    card_heading: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: 'Manrope',
        color: "#33354D",
        fontStyle: "normal",
        lineHeight: "normal",
    } as const,
    cabList: {
        flex: "1",
        padding: "16px",
        alignSelf: "flex-start",
        borderRadius: "8px",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    } as const,
    cancel_button: {
        backgroundColor: "transparent",
        color: "#8760A9",
        padding: "10px 16px",
        lineHeight: "16px",
        fontFamily: "Manrope",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "4px",
        letterSpacing: "0em",
        textTransform: "initial",
    } as const,
    add_button: {
        padding: "10px 16px",
        color: "#FFF",
        backgroundColor: "#8760A9",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        letterSpacing: "0em",
        borderRadius: "4px",
        lineHeight: "16px",
        textTransform: "initial",
    } as const,
    accordians: {
        flexBasis: "30%",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        padding: "4px",
        borderRadius: "8px",
    } as const,
    navigation: {
        fontSize: "14px",
        fontFamily: "Manrope",
        lineHeight: "19px",
    } as const,
    input: {
        width: "100%",
        fontFamily: "Manrope",
        height: "40px",
        border: "1px solid #e6deed",
        padding: "10px 10px 10px 40px",
        fontSize: "16px",
        borderRadius: "10px",
    } as const,
    search_icon: {
        color: "#33354D",
        width: "24px",
        position: "absolute",
        height: "24px",
        transform: "translateY(-50%)",
        top: "50%",
        left: "10px",
    } as const,
    navigation_item: {
        padding: "9px, 20px, 9px, 16px",
        borderRadius: "4px",
    } as const,
    navigation_item_nested: {
        padding: "9px 20px 9px 30px",
        borderRadius: "4px",
    } as const,
    cabListMain: {
        display: "flex",
        gap: "20px"
    } as const,
    navigation_item_active: {
        color: "white",
        backgroundColor: "#8760A9",
    } as const,
    navigation_link: {
        fontSize: "14px",
        letterSpacing: "0em",
        fontWeight: "bold",
        fontFamily: "Manrope",
        lineHeight: "22px",
        color: "#33354D",
    } as const,
    create_button: {
        color: "#33354D",
        fontFamily: "Manrope",
        padding: "10px 16px",
        letterSpacing: "0em",
        backgroundColor: "#E6DEED",
        fontWeight: "bolder",
        fontSize: "16px",
        textTransform: "initial",
        lineHeight: "16px",
        borderRadius: "4px",
    } as const,
    modal_button_main_Box: {
        gap: '1rem',
        display: 'flex',
        float: 'right',
    } as const,
    form_main_Box: {
        padding: '1rem',
    } as const,
    cancel_icon: {
        height: "30px",
        width: "30px"
    },
    input_label: {
        marginBottom: "5px",
    },
    export_button: {
        fontWeight: "bolder",
        fontFamily: "Manrope",
        fontSize: "16px",
        color: "#8760A9",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "capitalize",
    } as const,
    cab_count: {
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "19px",
    } as const,
    cab_span: {
        color: "red"
    } as const,
    modal_cancel_icon: {
        height: '30px',
        width: '30px',
        cursor: 'pointer'
    } as const,
    navigation_menu_bg: {
        backgroundColor: "#8760A9",
    } as const,
    navigation_menu_color: {
        color: "white",
    } as const,
    navigation_link_active_color: {
        color: "white",
    } as const,
    active_link: {
        color: "white",
    } as const,
    moreIconStyle: {
        width: '24px',
        height: '24px',
        color: 'black',
        padding: '5px'
    } as const,

    toaster_wrapper: {
        display: "inline-flex",
        padding: "8px 8px 8px 8px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "900px",
        height: "44px"
    },

    toaster_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    },

    toaster_box: {
        display: "flex",
        alignItems: "center"
    },

    toaster_msg: {
        marginLeft: "10px",
        fontSize: "12px",
        fontFamily: 'Manrope',
        lineHeight: 'normal',
        color: '#33354D'
    },
    toastIcon: {
        height: '26px',
        width: '26px',
    },
    toaster_group_name: {
        fontSize: "14px",
        color: '#8760A9',
        fontWeight: 700,
        fontStyle: 'normal',
        fontFamily: 'Manrope',
    },

    toaster_span: {
        borderRight: "1px solid #ccc",
        margin: "0px 8px",
        height: "28px",
        width: "2px",
    },
    toastCancelIcon: {
        cursor: "pointer",
    }
};
// Customizable Area End
