import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  ButtonGroup,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Select,
  OutlinedInput,
  TextField,
  Dialog
} from "@material-ui/core";
import {
  ArrowDropDown,
  MoreVert,
  Search,
  ArrowDropUp
} from "@material-ui/icons";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";
import { Toaster } from "react-hot-toast";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import CloseIcon from '@material-ui/icons/Close';
import { radioButton } from "./assets";
import Autocomplete from '@material-ui/lab/Autocomplete';

interface AllGroupsList {
  id: string,
  type: string,
  attributes: {
    name: string
  }
}

interface AllAgentsList {
  id: string,
  type: string,
  attributes: {
    full_name: string
  }
}
interface FilterGroupList {
  id: string,
  name: string
}

interface FilterAgentList {
  id: string,
  full_name: string
}
// Customizable Area End

import TasksController, { Props, configJSON } from "./TasksController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Tasks extends TasksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <Box style={webStyles.headerBox}>
        <Typography style={webStyles.taskFontStyle}>{configJSON.tasks}</Typography>
        <Box style={webStyles.buttonBoxStyle}>
          <ButtonGroup
            orientation='horizontal'
            color="primary"
            variant="contained"
            style={{ textTransform: 'none' }}
          >
            <Button
              data-testId={"openModal"}
              style={webStyles.buttonTextStyle}>
              {configJSON.textCreateATicket}
            </Button>
            <Button>
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <span style={webStyles.spanWidth}>
            <MoreVert style={webStyles.morevert} />
          </span>
        </Box>
      </Box>
    )
  }

  renderSearchBox = () => {
    return (
      <>
        <Box style={webStyles.arrow_wrapper}>
          <Typography style={webStyles.heading}>{configJSON.tasks}</Typography>
        </Box>
        <Box style={webStyles.search_wrapper}>
          <Search style={webStyles.search_icon} />
          <Input
            disableUnderline={true}
            style={webStyles.input}
            placeholder={configJSON.searchPlacholder}
            data-test-id="searchInput"
          />
        </Box>
      </>
    )
  }

  renderTaskListTable = () => {
    return (
      <Box style={webStyles.main_content_card}>
        <Box style={webStyles.boxStyle}>
          <Typography style={webStyles.heading_1}>{configJSON.alltask} <span style={webStyles.heading_2}>({this.state.allTaskList.length})</span></Typography>
          <Typography style={webStyles.filterFont} onClick={this.handleOpenFilterDrawer}>{configJSON.textFilter}</Typography>
        </Box>
        <TableContainer style={webStyles.tableContainer}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                {this.tableHeader.map((header, index) => (
                  <TableCell
                    key={index}
                    data-testId={"headerSort"}
                    onClick={() => this.handleSort(header.key)}
                  >
                    <Box style={webStyles.tableHeaderStyle}>
                      <Typography style={{ ...webStyles.tableCellHeading, cursor: 'pointer' }}>
                        {header.label}
                        <span>
                          <Box style={webStyles.sortingArrow}>
                            <ArrowDropUp style={this.state.sortDirection === 'asc' ? arrowStyles.asc : {}} />
                            <ArrowDropDown style={this.state.sortDirection === 'desc' ? arrowStyles.desc : {}} />
                          </Box>
                        </span>
                      </Typography>
                    </Box>

                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.allTaskList?.map((taskItem, index) => (
                  <TableRow
                    hover
                    key={`key-${taskItem.id}-${index}`}
                    data-testId={'row_click'}
                    onClick={() => this.handleRowClick(taskItem)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell style={webStyles.heading_2}>{taskItem.attributes.title}</TableCell>
                    <TableCell>
                      <Box style={webStyles.cellBox}>
                        <ImageSection>
                          {this.getInitials(taskItem.attributes.agent.name)}
                        </ImageSection>
                        <Box>
                          <Typography style={webStyles.groupNameFont}>{taskItem.attributes?.group?.name}</Typography>
                          <Typography style={webStyles.heading_2}>{taskItem.attributes?.agent.name}</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography style={webStyles.statusFont}>
                        {taskItem.attributes.status ? taskItem.attributes.status : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell style={webStyles.heading_2}>{taskItem.attributes.due_date ? this.formatDate(taskItem.attributes.due_date) : "--"}</TableCell>
                    <TableCell style={webStyles.heading_2}>{taskItem.attributes.created_at ? this.formatDate(taskItem.attributes.created_at) : "--"}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={this.state.totalPageCount}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.onPageChange}
            component="div"
            onRowsPerPageChange={this.onRowsPerPageChange}
            data-testId={"pagination"}
          />
        </TableContainer>
      </Box>
    )
  }

  renderDetailsDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.isDrawerOpen}
        onClose={this.handleModalClose}
        data-testId={"open_drawer"}
        PaperProps={{
          style: webStyles.drawerPaper,
        }}
      >
        <Box style={webStyles.drawerWidth}>
          <Box style={webStyles.headerBox}>
            <Box style={webStyles.buttonBoxStyle}>
              <IconButton data-testId={"close_drawer"} style={webStyles.IconBox} onClick={this.handleModalClose}>
                <CloseIcon />
              </IconButton>
              <Typography style={webStyles.detailsFontStyle}>{configJSON.textDetails}</Typography>
            </Box>
            <Box style={webStyles.buttonBoxStyle}>
              <ButtonGroup
                orientation='horizontal'
                color="primary"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                <Button
                  data-testId={"openModal"}
                  style={webStyles.buttonTextStyle}>
                  {configJSON.open}
                </Button>
                <Button data-testId={"open_popover"} onClick={this.handleMenuOpen}>
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <span style={webStyles.spanWidth} data-testId={"openEdit_popOver"} onClick={this.handleEditMenuOpen}>
                <MoreVert style={webStyles.morevert} />
              </span>
              <Menu
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                data-testId={"open_menu"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
                PaperProps={{
                  style: {
                    width: '100px',
                    borderRadius: '4px',
                    padding: '9px 15px 9px 15px',
                  },
                }}
              >
                {
                  this.statusOptions.map((options) => (
                    <MenuItem
                      data-testId={"close_status_menu"}
                      style={webStyles.taskFontStyle}
                      onClick={() => this.handleStatusMenuChange(options)}
                      onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                      onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                    >
                      {options}</MenuItem>
                  ))
                }
              </Menu>
            </Box>
          </Box>

          <Box style={webStyles.errorBoxStyle}>
            <img src={radioButton} />
            <Typography style={webStyles.heading_2}>{configJSON.errorMessage}</Typography>
          </Box>

          <Box style={webStyles.detailsListBox}>
            <Typography style={webStyles.generalFontStyle}>{configJSON.general}</Typography>
            <Box style={webStyles.listItemBox}>
              <Typography style={webStyles.generalFontStyle_1}>{configJSON.textStatus}</Typography>
              <Typography style={webStyles.generalFontStyle_2}>{this.state.selectedStatus}</Typography>
            </Box>

            <Box style={webStyles.listItemBox}>
              <Typography style={webStyles.generalFontStyle_1}>{configJSON.assignedTo}</Typography>
              <Typography style={webStyles.generalFontStyle_2}>{configJSON.agentsName}</Typography>
            </Box>

            <Box style={webStyles.listItemBox}>
              <Typography style={webStyles.generalFontStyle_1}>{configJSON.textResolutionDate}</Typography>
              <Typography style={webStyles.generalFontStyle_2}>--</Typography>
            </Box>

            <Box style={webStyles.listItemBox}>
              <Typography style={webStyles.generalFontStyle_1}>{configJSON.textResolutionTime}</Typography>
              <Typography style={webStyles.generalFontStyle_2}>--</Typography>
            </Box>

          </Box>
        </Box>
      </Drawer>
    )
  }

  renderFilterDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.isFilterDrawerOpen}
        onClose={this.handleCloseFilterDrawer}
        data-testId={"open_drawer"}
        PaperProps={{
          style: webStyles.drawerPaper,
        }}
      >
        <Box style={webStyles.drawerWidth_1}>
          <Box style={webStyles.headerBox}>
            <Typography style={webStyles.filterHeading_2}>{configJSON.textFilter}</Typography>
            <IconButton
              data-testId={"close_drawer"}
              style={webStyles.IconBox}
              onClick={this.handleCloseFilterDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box style={webStyles.detailsListBox}>
            <Typography style={webStyles.parentTypeFOnt}>{configJSON.parenetType}</Typography>
            <Select
              input={<OutlinedInput />}
              fullWidth
              style={webStyles.selectBoxStyle}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            >
              {this.selectOptions.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  style={webStyles.menuItemStyle}
                  onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                  onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                >
                  {option}
                </MenuItem>
              ))}

            </Select>
          </Box>

          <Box style={webStyles.detailsListBox}>
            <Typography style={webStyles.heading_2}>{configJSON.taskProperties}</Typography>
            <Box style={webStyles.propertiesBoxStyle}>
              <Typography style={webStyles.parentTypeFOnt}>{configJSON.agentsLabel}</Typography>
              <Autocomplete
                fullWidth
                options={this.state.filterAgentsList}
                style={webStyles.autocompleteContainer}
                getOptionLabel={(option) => option?.full_name || ""}
                value={this.getFilterAgentValue() || ""}
                data-testId={"filter_agentslist"}
                onChange={(event: React.ChangeEvent<{}>, value: FilterAgentList | null) => this.handleChangeFilterAgents(value)}
                renderInput={(params) => (
                  <StyledTextFeild
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={"Search"}
                  />
                )}
              />
            </Box>

            <Box style={webStyles.propertiesBoxStyle}>
              <Typography style={webStyles.parentTypeFOnt}>{configJSON.textGroups}</Typography>
              <Autocomplete
                fullWidth
                options={this.state.filterGroupList}
                style={webStyles.autocompleteContainer}
                getOptionLabel={(option) => option?.name || ""}
                value={this.getFilterGroupValue() || ""}
                data-testId={"filter_groupList"}
                onChange={(event: React.ChangeEvent<{}>, value: FilterGroupList | null) => this.handleChangeFilterGroup(value)}
                renderInput={(params) => (
                  <StyledTextFeild
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={"Search"}
                  />
                )}
              />
            </Box>

            <Box style={webStyles.propertiesBoxStyle}>
              <Typography style={webStyles.parentTypeFOnt}>{configJSON.textStatus}</Typography>
              <Select
                input={<OutlinedInput />}
                fullWidth
                style={webStyles.selectBoxStyle}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {this.statusOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={webStyles.menuItemStyle}
                    onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                    onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                  >
                    {option}
                  </MenuItem>
                ))}

              </Select>
            </Box>

            <Box style={webStyles.propertiesBoxStyle}>
              <Typography style={webStyles.parentTypeFOnt}>{configJSON.textCreatedAt}</Typography>
              <Select
                input={<OutlinedInput />}
                fullWidth
                style={webStyles.selectBoxStyle}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
              </Select>
            </Box>

            <Box style={webStyles.propertiesBoxStyle}>
              <Typography style={webStyles.parentTypeFOnt}>{configJSON.dueby}</Typography>
              <Select
                input={<OutlinedInput />}
                fullWidth
                style={webStyles.selectBoxStyle}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                {this.dueByOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={webStyles.menuItemStyle}
                    disabled={option === 'Select a time period'}
                    onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                    onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>

          </Box>

          <Box style={webStyles.buttonBoxStyle_1}>
            <CustomSubmitButton>{configJSON.applyFilters}</CustomSubmitButton>
            <Typography style={webStyles.filterFont} onClick={this.handleCloseFilterDrawer}>{configJSON.cancelBTN}</Typography>
          </Box>

        </Box>
      </Drawer>
    )
  }

  renderEditDeletePopover = () => {
    return (
      <Menu
        anchorEl={this.state.openEditpopover}
        open={Boolean(this.state.openEditpopover)}
        onClose={this.handleEditMenuClose}
        data-testId={"close__edit_menu"}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            borderRadius: '4px',
            padding: '9px 15px 9px 15px',
          },
        }}
      >
        <MenuItem
          data-testId={"open_edit_modal"}
          onClick={this.handleOpenEditDailog}
          style={webStyles.taskFontStyle}
          onMouseOver={(event) => { event.currentTarget.style.backgroundColor = '#B49CC9' }}
          onMouseOut={(event) => { event.currentTarget.style.backgroundColor = 'initial' }}
        >
          {configJSON.textEdit}
        </MenuItem>
        <MenuItem
          data-testId={"close_menu"}
          onClick={this.handleEditMenuClose}
          style={webStyles.taskFontStyle}
          onMouseOver={(event) => { event.currentTarget.style.backgroundColor = '#B49CC9'; }}
          onMouseOut={(event) => { event.currentTarget.style.backgroundColor = 'initial'; }}
        >
          {configJSON.textDelete}
        </MenuItem>
      </Menu>
    )
  }

  renderEditModal = () => {
    return (
      <Dialog
        open={this.state.isEditDialog}
        onClose={this.handleCloseEditDialog}
        PaperProps={{ style: webStyles.dialogStyle }}
        maxWidth="md"
        data-testId={"openEditDialog"}
      >
        <Box style={webStyles.headerBox}>
          <Typography style={webStyles.generalFontStyle_4}>{configJSON.editTask}</Typography>
          <IconButton
            data-testId={"close_Edit_dialog"}
            style={webStyles.IconBox}
            onClick={this.handleCloseEditDialog}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box style={webStyles.editBoxStyle} >
          <Box style={webStyles.editBox_1}>
            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.textTitle}<span style={webStyles.spanColor}> *</span></Typography>
              <InputField
                variant="outlined"
                fullWidth
                placeholder="Title"
                value={this.state.particularTaskResponse?.attributes.title}
                onChange={this.handleChangeTitle}
                data-testId={"edit_title"}
              />
              {this.state.titleError && <Typography data-testId={"title_Error"} style={webStyles.spanColor}>{this.state.titleError}</Typography>}
            </Box>

            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.textGroups}</Typography>
              <Autocomplete
                fullWidth
                options={this.state.allGroupList}
                style={webStyles.autocompleteContainer}
                getOptionLabel={(option) => option.attributes?.name || ""}
                value={this.getGroupValue()}
                data-testId={"change_group"}
                onChange={(event: React.ChangeEvent<{}>, value: AllGroupsList | null) => this.handleChangeGroup(value)}
                renderInput={(params) => (
                  <StyledTextFeild
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={"Select a value"}
                  />
                )}
              />

            </Box>

            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.assignedTo}</Typography>
              <Autocomplete
                fullWidth
                options={this.state.allAgentsList}
                getOptionLabel={(option) => option.attributes?.full_name}
                value={this.getAgentsValue()}
                onChange={(event: React.ChangeEvent<{}>, value: AllAgentsList | null) => this.handleChangeAgents(value)}
                data-testId={"change_agent"}
                style={webStyles.autocompleteContainer}
                renderInput={(params) => (
                  <StyledTextFeild
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={"Select a value"}
                  />
                )}
              />
            </Box>

            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.textStatus}</Typography>
              <Select
                input={<OutlinedInput />}
                fullWidth
                style={webStyles.selectBoxStyle}
                value={this.state.selectedStatus}
                onChange={this.handleStatusChange}
                data-testId={"change_Status"}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                }}
              >
                {this.statusOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    style={webStyles.menuItemStyle}
                    onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                    onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                  >
                    {option}
                  </MenuItem>
                ))}

              </Select>
            </Box>

            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.notifyBefore}</Typography>
              <Select
                input={<OutlinedInput />}
                fullWidth
                style={webStyles.selectBoxStyle}
                value={this.state.notifyBefore}
                onChange={this.handleChangeNotifyBefore}
                data-testId={"notify_before"}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.dueByOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    disabled={option === 'Select a time period'}
                    style={webStyles.menuItemStyle}
                    onMouseOver={(event) => event.currentTarget.style.backgroundColor = '#E6DEED'}
                    onMouseOut={(event) => event.currentTarget.style.backgroundColor = 'initial'}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.dueDate}</Typography>
              <Box style={webStyles.headerBox}>
                <Box style={webStyles.boxWidth}>
                  <InputField
                    fullWidth
                    id="date"
                    type="date"
                    variant="outlined"
                    value={this.state.dueDate}
                    onChange={this.handleChangeDueDate}
                    data-testId={"start_date"}
                  />
                </Box>

                <Box style={webStyles.boxWidth}>
                  <InputField
                    fullWidth
                    id="time"
                    type="time"
                    variant="outlined"
                    data-testId={"start_time"}
                    value={this.state.dueTime}
                    onChange={this.handleChangeDueTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                  />
                </Box>
              </Box>
            </Box>

          </Box>
          <Box style={webStyles.editBox_1}>
            <Box style={webStyles.editBox_2}>
              <Typography style={webStyles.editDetailsFont}>{configJSON.note}</Typography>
              <TextField
                minRows={12}
                multiline
                id="outlined-multiline-static"
                fullWidth
                variant="outlined"
                data-testId={"change_note"}
                value={this.state.particularTaskResponse?.attributes?.note}
                onChange={this.handleChangeNote}
              />
            </Box>
          </Box>
        </Box>

        <Box style={webStyles.editButtonBox_1}>
          <Box style={webStyles.updateButton}>
            <Typography
              style={webStyles.filterFont}
              onClick={this.handleCloseEditDialog}>
              {configJSON.cancelBTN}
            </Typography>
            <CustomUpdateButton
              variant="contained"
              onClick={this.validateFields}
              data-testId={"update_task"}
            >
              {configJSON.textUpdate}
            </CustomUpdateButton>
          </Box>
        </Box>
      </Dialog>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Toaster toastOptions={{
          duration: 1000
        }} />

        <Box style={webStyles.container}>
          {this.renderHeader()}
          {this.renderSearchBox()}
          {this.renderTaskListTable()}
          {this.renderDetailsDrawer()}
          {this.renderFilterDrawer()}
          {this.renderEditDeletePopover()}
          {this.renderEditModal()}
        </Box>
      </ThemeProvider>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
const ImageSection = styled(Box)({
  height: '32px',
  width: '32px',
  backgroundColor: "#E6DEED",
  borderRadius: '50%',
  color: "#33354D",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

const AutocompleteStyled = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    borderRadius: '8px',
    border: "1px solid #E6DEED",
    backgroundColor: "#FFFFFF",
  },
});

const StyledTextFeild = styled(TextField)({
  root: {
    height: '50px',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 400,
    color: '#33354D'
  },
  '& .MuiOutlinedInput-root': {
    height: '50px',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 400,
    color: '#33354D'
  },
})

const CustomSubmitButton = styled(Button)({
  background: '#8760A9',
  borderRadius: '4px',
  border: 0,
  width: '320px',
  height: "36px",
  padding: '16px',
  textTransform: 'initial',
  color: '#FFFFFF',
  fontFamily: "Manrope",
  fontSize: '16px',
  fontWeight: 600,
  '&:hover': {
    background: '#8760A9',
  },
});

const CustomUpdateButton = styled(Button)({
  background: '#8760A9',
  borderRadius: '4px',
  border: 0,
  padding: '10px',
  textTransform: 'initial',
  color: '#FFFFFF',
  fontFamily: "Manrope",
  fontSize: '16px',
  fontWeight: 600,
  '&:hover': {
    background: '#8760A9',
  },
});

const InputField = styled(TextField)({
  "& div": {
    borderRadius: "8px",
    border: "1px solid #E6DEED",
    height: '50px',
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& input::placeholder": {
      fontFamily: 'Manrope',
      fontSize: '16px',
      fontWeight: 400,
      color: '#33354D',
    },
  },
})

const arrowStyles = {
  asc: {
    color: '#A3A6C2',
  },
  desc: {
    color: '#33354D',
  },
};

const webStyles = {
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonBoxStyle_1: {
    marginTop: '15%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  },
  propertiesBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    marginTop: '10px'
  } as const,

  menuItemStyle: {
    borderRadius: '4px',
    padding: "9px 20px 9px 16px",
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontWeight: 400,
    color: '#33354D'
  },

  boxWidth: {
    width: '45%'
  },

  tableHeaderStyle: {
    display: 'flex',
    alignItems: 'center'
  },

  autocompleteContainer: {
    borderRadius: '8px',
    border: "1px solid #E6DEED",
    backgroundColor: "#FFFFFF",
    height: '50px'
  },

  updateButton: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center'
  },

  editBoxStyle: {
    display: 'flex',
    marginTop: '30px',
    gap: '5%'
  },

  editBox_1: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '20px'
  },

  editBox_2: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '5px'
  },

  editButtonBox: {
    marginTop: '15%',
    display: 'flex',
    justifyContent: 'flex-end'
  },

  editButtonBox_1: {
    marginTop: '5%',
    display: 'flex',
    justifyContent: 'flex-end'
  },

  dialogStyle: {
    padding: '25px 40px 25px 40px',
    borderRadius: '8px',
    width: '100%'
  },

  detailsListBox: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  } as const,

  selectBoxStyle: {
    borderRadius: '8px',
    border: "1px solid #E6DEED",
    height: '50px'
  },

  sortingArrow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  } as const,

  listItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as const,

  listItemBox_1: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  } as const,

  errorBoxStyle: {
    borderRadius: '6px',
    border: '1px solid #B49CC9',
    padding: '14px 20px 14px 20px',
    marginTop: '30px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  drawerWidth: {
    width: '397px',
    padding: '20px',
    backgroundColor: "#FBFAFF"
  },
  drawerWidth_1: {
    width: '500px',
    padding: '20px',
  },
  drawerPaper: {
    backgroundColor: "#FBFAFF"
  },
  IconBox: {
    cursor: 'pointer',
    height: '28px',
    width: '28px',
  },

  cellBox: {
    display: 'flex',
    gap: '8px'
  },

  taskFontStyle: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '4px'
  },

  generalFontStyle: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '11px',
    fontWeight: 700
  },

  generalFontStyle_1: {
    fontFamily: "Manrope",
    color: "#A3A6C2",
    fontSize: '14px',
    fontWeight: 600
  },

  generalFontStyle_2: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '14px',
    fontWeight: 700
  },

  generalFontStyle_4: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '20px',
    fontWeight: 700
  },


  generalFontStyle_3: {
    fontFamily: "Manrope",
    color: "#8760A9",
    fontSize: '12px',
    fontWeight: 700
  },

  heading: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '30px',
    fontWeight: 600
  },
  heading_1: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '20px',
    fontWeight: 700
  },
  statusFont: {
    textAlign: 'center' as const,
    color: '#FFFFFF',
    backgroundColor: '#11A64A',
    padding: "2px 6px 3px 6px",
    borderRadius: '4px',
    fontFamily: "Manrope",
    fontSize: '14px',
    fontWeight: 600,
    width: "60%"
  },
  heading_2: {
    fontFamily: "Manrope",
    color: "#33354D",
    fontSize: '14px',
    fontWeight: 600
  },
  filterHeading_2: {
    fontFamily: "Manrope",
    color: "#000000",
    fontSize: '24px',
    fontWeight: 600
  },
  groupNameFont: {
    fontFamily: "Manrope",
    color: "#A3A6C2",
    fontSize: '11px',
    fontWeight: 600,
    letter: '1%'
  },
  filterFont: {
    fontFamily: "Manrope",
    color: "#8760A9",
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer'
  },
  buttonTextStyle: {
    textTransform: 'none' as const,
    fontFamily: "Manrope",
    color: "#FFFFFF",
    fontSize: '16px',
    fontWeight: 700
  },
  detailsFontStyle: {
    fontFamily: "Manrope",
    color: "#000000",
    fontSize: '24px',
    fontWeight: 600
  },
  parentTypeFOnt: {
    fontFamily: "Manrope",
    color: "#3D405C",
    fontSize: '16px',
    fontWeight: 600
  },
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  },
  buttonBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  spanWidth: {
    width: '36px',
    height: '36px',
    background: '#e6deed',
    borderRadius: '5px'
  },
  morevert: {
    width: '24px',
    height: '24px',
    color: 'black',
    padding: '5px'
  },
  search_wrapper: {
    position: "relative" as const,
    marginBottom: "32px",
  },
  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute" as const,
    transform: "translateY(-50%)",
    top: "50%",
    left: "10px"
  },
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px"
  },
  arrow_wrapper: {
    marginBottom: "40px",
    marginTop: "50px",
  },
  main_content_card: {
    borderRadius: "8px",
    padding: "16px",
    flex: "1",
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    marginBottom: '1rem'
  } as const,

  boxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableContainer: {
    marginTop: '2%',
    padding: '10px'
  },
  tableCellHeading: {
    fontFamily: "Manrope",
    color: "#3D405C",
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },

  editDetailsFont: {
    fontFamily: "Manrope",
    color: "#3D405C",
    fontSize: '12px',
    fontWeight: 500,
  },

  spanColor: {
    color: 'red'
  }
};
// Customizable Area Start
