// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const cancelIcon = require('../assets/Cancel.svg')
export const successIcon = require('../assets/success.svg');
export const avatarIcon = require("../assets/group_avatar (1).png")
export const searchIcon = require('../assets/PikPng.com_awesome-png_2019062.png');
export const radioButton = require('../assets/image_radiobutton_.png');
export const sortUpArrow = require("../assets/image_checkbox_.png");
export const sortDownArrow = require("../assets/image_checkbox_(1).png");

// Customizable Area End