import React from 'react';

//Customizable Area Start
import {
    Box,
    Button,
    Dialog,
    FormControlLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    AddCircle,
    ExpandMoreRounded,
    RemoveCircleOutline,
} from "@material-ui/icons";

import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import EditIncidantTickerController, {
    AllAgentsList,
    AllGroupsList,
    DepartmentList,
    Props,
    configJSON,
}
    from './EditIncidantTickerController';

//Customizable Area End

export default class EditIncidantTicket extends EditIncidantTickerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        //Customizable Area End
    }

    render() {
        // Customizable Area Start
        //Customizable Area End

        return (
            // Customizable Area Start
            <>
                {/* Edit Details Dialog */}
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={this.props.openDescModal}
                    data-testId={"open_modal"}
                    PaperProps={{ style: webStyles.dialogStyle }}
                >
                    <Box style={webStyles.boxStyle}>
                        <Typography style={webStyles.fontStyle}>{"Edit Description"}</Typography>
                        <CloseIcon
                            data-testId={"closeIcon"}
                            onClick={this.props.handleModalState}
                        />
                    </Box>

                    <Grid container spacing={4} style={webStyles.constainerSpacing}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box style={webStyles.gridBoxStyle}>
                                <Box style={webStyles.subjectBoxStyle}>
                                    <Typography style={webStyles.fontsStyle_1}>{configJSON.textSubject}<span style={webStyles.spanColor}> *</span></Typography>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.ticketData.attributes?.subject}
                                        onChange={this.handleSubjectChange}
                                        data-testId={"change_subject"}
                                    />
                                    {this.state.subjectError && <Typography data-testId={"subjectError"} style={webStyles.spanColor}>{this.state.subjectError}</Typography>}
                                </Box>

                                <Box style={webStyles.subjectBoxStyle}>
                                    <Typography style={webStyles.fontsStyle_1}>{configJSON.textRequester}<span style={webStyles.spanColor}> *</span></Typography>
                                    <Select
                                        IconComponent={ExpandMoreRounded}
                                        fullWidth
                                        input={<OutlinedInput />}
                                        data-testId={"change_requester"}
                                        value={this.state.changedRequester}
                                        onChange={this.handleRequesterChange}
                                    >
                                        {this.state.requestersList.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.attributes.email}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography style={webStyles.textButtonStyle}
                                        data-testId={"navigate"}
                                        onClick={() => this.props.navigateUser()}> <AddCircle />{configJSON.textAddNewRequester}
                                    </Typography>
                                    {this.state.requesterError && <Typography data-testId={"requesterError"} style={webStyles.spanColor}>{this.state.requesterError}</Typography>}
                                </Box>

                                <Box style={webStyles.subjectBoxStyle}>
                                    <Typography style={webStyles.fontsStyle_1}>{configJSON.textDepartment}</Typography>
                                    <Autocomplete
                                        fullWidth
                                        data-testId={"select_department"}
                                        value={this.getDepartmentValue()}
                                        options={this.state.allDepartmentList}
                                        getOptionLabel={(option) => option.attributes?.department_name || ""}
                                        onChange={(event: React.ChangeEvent<{}>, value: DepartmentList | null) => this.handleChangeDepartment(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                data-testId={"change_group"}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box style={webStyles.subjectBoxStyle}>
                                <Typography style={webStyles.fontsStyle_1}>{configJSON.textDescription}<span style={webStyles.spanColor}> *</span></Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    minRows={10}
                                    multiline
                                    data-testId={"description"}
                                    value={this.state.ticketData?.attributes?.description}
                                    onChange={this.handleChangeDescription}
                                />
                                {this.state.descriptionError && <Typography style={webStyles.spanColor}>{this.state.descriptionError}</Typography>}
                                <Typography style={webStyles.textButtonStyle} >
                                    <AttachFileIcon />{configJSON.attchedFile}</Typography>
                            </Box>
                        </Grid>

                        <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
                            <Box style={webStyles.buttonBox}>
                                <Button onClick={this.props.handleModalState} style={webStyles.cancelButton}>{configJSON.cancelBTN}</Button>
                                <Button onClick={this.validateFields} variant="contained" style={webStyles.submitButton}>{configJSON.savechanges}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>

                {/* Form Modal */}
                <Dialog
                    open={this.props.openFormModal}
                    onClose={this.props.handleCloseFormModal}
                    fullWidth={true}
                    maxWidth='lg'
                    PaperProps={{ style: webStyles.dialogStyle }}
                    data-testId={"form_modal"}
                >
                    <Box style={webStyles.boxStyle}>
                        <Typography style={webStyles.fontStyle}>{"Edit Details"}</Typography>
                        <CloseIcon
                            data-testId={"closeIcon"}
                            onClick={this.props.handleCloseFormModal}
                        />
                    </Box>

                    <Grid container spacing={4} style={webStyles.constainerSpacing}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box style={webStyles.box_Style}>
                                <Typography style={webStyles.input_Label}>{configJSON.textRequester}<span style={webStyles.spanColor}> *</span></Typography>
                                {this.state.ccDropdowns.map((recipient, index) => (
                                    <Box style={webStyles.selectBoxStyle}>
                                        <Select
                                            IconComponent={ExpandMoreRounded}
                                            fullWidth
                                            input={<OutlinedInput />}
                                            value={recipient?.id}
                                            data-testId={`addcc${index}`}
                                            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => this.handleCcChange(index, event.target.value as string)}
                                        >
                                            {this.state.requestersList.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                    data-testId={"template_menu_item"}
                                                >
                                                    {option.attributes.email}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {index > 0 && (
                                            <RemoveCircleOutline
                                                data-test-id="remove_icon_click"
                                                style={webStyles.removeIconStyle}
                                                onClick={() => this.removeCc(index)}
                                                data-testId={"removeCC"}
                                            />
                                        )}
                                    </Box>
                                ))}
                                <Box style={webStyles.boxSpacing}>
                                    <Typography style={webStyles.text_ButtonStyle} data-testId={"addCC_btn"} onClick={this.addCcDropdown}> <AddCircle />{configJSON.textAddCC}</Typography>
                                    <Typography style={webStyles.text_ButtonStyle} data-testId={"navigate"} onClick={() => this.props.navigateUser()}> <AddCircle />{configJSON.textAddNewRequester}</Typography>
                                </Box>
                            </Box>

                            <Box style={webStyles.box_Style}>
                                <Typography style={webStyles.input_Label}>{configJSON.textSubject}<span style={webStyles.spanColor}> *</span></Typography>
                                <TextField
                                    variant="outlined"
                                    value={this.state.ticketData.attributes?.subject}
                                    onChange={this.handleSubjectChange}
                                    data-testId={"changeSubject"}
                                />
                                {this.state.subjectError && <Typography style={webStyles.spanColor}>{this.state.subjectError}</Typography>}
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textSource}<span style={webStyles.spanColor}> *</span></Typography>
                                <Select
                                    input={<OutlinedInput />}
                                    IconComponent={ExpandMoreRounded}
                                    fullWidth
                                    value={this.state.selectedSource}
                                    onChange={this.handleSourceChange}
                                    data-testId={"change_source"}
                                >
                                    {this.source.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textStatus}<span style={webStyles.spanColor}> *</span></Typography>
                                <Select
                                    input={<OutlinedInput />}
                                    IconComponent={ExpandMoreRounded}
                                    fullWidth
                                    value={this.state.selectedStatus}
                                    onChange={this.handleStatusChange}
                                    data-testId={"change_status"}
                                >
                                    {this.status.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {this.state.statusError && <Typography style={webStyles.spanColor}>{this.state.statusError}</Typography>}
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textPriority}</Typography>
                                <RadioGroup
                                    aria-label="priority"
                                    name="priority"
                                    value={this.state.selectedPriority}
                                    onChange={this.handlePriorityChange}
                                    data-testId={"change_priority"}
                                >
                                    <FormControlLabel value="Low" control={<Radio />} label="Low" />
                                    <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                                    <FormControlLabel value="High" control={<Radio />} label="High" />
                                    <FormControlLabel value="Urgent" control={<Radio />} label="Urgent" />
                                </RadioGroup>
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textGroup}</Typography>
                                <Autocomplete
                                    fullWidth
                                    value={this.getGroupValue()}
                                    options={this.state.allGroupList}
                                    getOptionLabel={(option) => option.attributes?.name}
                                    onChange={(event: React.ChangeEvent<{}>, value: AllGroupsList | null) => this.handleChangeGroup(value)}
                                    data-testId={"change_group"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textAgent}</Typography>
                                <Autocomplete
                                    fullWidth
                                    value={this.getAgentsValue()}
                                    options={this.state.allAgentsList}
                                    getOptionLabel={(option) => option.attributes?.full_name}
                                    onChange={(event: React.ChangeEvent<{}>, value: AllAgentsList | null) => this.handleChangeAgents(value)}
                                    data-testId={"change_agent"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.fontsStyle_1}>{configJSON.textDepartment}<span> *</span></Typography>
                                <Autocomplete
                                    fullWidth
                                    value={this.getDepartmentValue()}
                                    options={this.state.allDepartmentList}
                                    getOptionLabel={(option) => option.attributes?.department_name}
                                    onChange={(event: React.ChangeEvent<{}>, value: DepartmentList | null) => this.handleChangeDepartment(value)}
                                    data-testId={"change_department"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box style={webStyles.box_Style}>
                                <Typography style={{ ...webStyles.input_Label, marginTop: '10px' }}>{configJSON.textDescription}<span style={webStyles.spanColor}> *</span></Typography>
                                <TextField
                                    variant="outlined"
                                    minRows={12}
                                    multiline
                                    id="outlined-multiline-static"
                                    fullWidth
                                    placeholder={configJSON.enternote}
                                    value={this.state.ticketData?.attributes?.description}
                                    onChange={this.handleChangeDescription}
                                    data-testId={"description_change"}
                                />
                                {this.state.descriptionError && <Typography style={webStyles.spanColor}>{this.state.descriptionError}</Typography>}
                            </Box>
                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textCategory}</Typography>
                                <Select
                                    input={<OutlinedInput />}
                                    IconComponent={ExpandMoreRounded}
                                    name={'template'}
                                    fullWidth
                                >
                                </Select>
                            </Box>

                            <Box style={{ marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textPlannedSdate}</Typography>
                                <Box style={webStyles.startDateEndBox}>
                                    <Box style={{ ...webStyles.box_Style, width: '45%' }}>
                                        <TextField
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            onChange={this.handleStartDateChange}
                                            value={this.state.startDate}
                                            data-testId={"start_date"}
                                            InputProps={{ inputProps: { max: this.state.endDate } }}
                                        />
                                        {this.state.startDateError && <Typography style={webStyles.spanColor}>{this.state.startDateError}</Typography>}
                                    </Box>

                                    <Box style={{ ...webStyles.box_Style, width: '45%' }}>
                                        <TextField
                                            id="time"
                                            type="time"
                                            variant="outlined"
                                            onChange={this.handleStartTimeChange}
                                            value={this.state.startTime}
                                            data-testId={"start_time"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={{ marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textPlannedEdate}</Typography>
                                <Box style={webStyles.startDateEndBox}>
                                    <Box style={{ ...webStyles.box_Style, width: '45%' }}>
                                        <TextField
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            onChange={this.handleEndDateChange}
                                            value={this.state.endDate}
                                            data-testId={"end_date"}
                                            InputProps={{ inputProps: { min: this.state.startDate } }}
                                        />
                                        {this.state.endDateError && <Typography style={webStyles.spanColor}>{this.state.endDateError}</Typography>}
                                    </Box>

                                    <Box style={{ ...webStyles.box_Style, width: '45%' }}>
                                        <TextField
                                            id="time"
                                            type="time"
                                            variant="outlined"
                                            onChange={this.handleEndTimeChange}
                                            value={this.state.endTime}
                                            data-testId={"end_time"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textPlannedEffort}</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder={configJSON.textPlannedEffortPlaceholder}
                                    value={this.state.plannedEfforts}
                                    data-testId={"efforts"}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box>

                            <Box style={{ ...webStyles.box_Style, marginTop: '15px' }}>
                                <Typography style={webStyles.input_Label}>{configJSON.textTags}</Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder={configJSON.textSearch}
                                    value={this.state.inputValue}
                                />
                            </Box>

                            <Box style={webStyles.buttonBoxSyle}>
                                <Typography
                                    style={webStyles.cancelButton}
                                    onClick={this.props.handleCloseFormModal}
                                >
                                    {configJSON.cancelBTN}
                                </Typography>
                                <Button
                                    style={webStyles.submitButton}
                                    variant="contained"
                                    onClick={this.validateFields}
                                    data-testId={"submit_form"}
                                >
                                    {configJSON.textSaveChanges}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>

            </>

        );
        //Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    dialogStyle: {
        padding: '25px 40px 25px 40px',
        borderRadius: '8px'
    },

    subjectBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '5px'
    },

    fontsStyle_1: {
        fontFamily: "Manrope",
        fontSize: '12px',
        color: '#3D405C',
        fontWeight: 500
    },

    boxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },

    fontStyle: {
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: 700,
        color: '#33354D'
    },

    submitButton: {
        backgroundColor: '#8760A9',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        textTransform: 'initial' as const,
        padding: '5px 35px 5px 35px',
    },

    boxSpacing: {
        display: 'flex',
        gap: '12px',
        justifyContent: 'end'
    },
    spanColor: {
        color: 'red'
    },

    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    },

    text_ButtonStyle: {
        fontSize: '16px',
        color: '#8760A9',
        fontWeight: 700,
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'flex-end' as const,
        alignItems: 'center',
        gap: '6px',
        cursor: "pointer"
    },

    gridBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '15px'
    },

    constainerSpacing: {
        marginTop: '20px'
    },

    buttonBoxSyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '20px',
        marginTop: '25px'
    },
    textButtonStyle: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#8760A9',
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '6px',
        cursor: "pointer"
    },

    startDateEndBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px'
    },

    removeIconStyle: {
        cursor: 'pointer',
        fontSize: '24px',
        marginLeft: '5px'
    },

    selectBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        alignItems: 'center',
    },

    input_Label: {
        color: '#3D405C',
        fontSize: '12px',
        fontWeight: 500,
        marginBottom: '4px'
    },
    box_Style: {
        display: 'flex',
        flexDirection: 'column' as const,
    },

    cancelButton: {
        color: '#8760A9',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'initial' as const,
        cursor: 'pointer'
    },
};
//Customizable Area End
