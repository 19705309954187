import React, { Component } from "react";
// Customizable Area Start
import RequesterNavBar from "./Navbar.web";
import RequestorCommonNavbar from "../../../components/src/RequestorCommonNavbar";

// Customizable Area End
import {Props} from "./DashboardController.web";
import { Box, Grid, Typography } from "@material-ui/core";
import { dotsBG, serviceItemLogo, breadCrumbIcon } from "./assets";
 // Customizable Area Start
const ServiceItem: React.FC<{ name: string; description: string }> = ({ name, description }) => (
    <Box style={webStyles.serviceCard}>
      <img src={serviceItemLogo} style={{ height: '32px', width: '32px', marginBottom: '10px' }} alt={name} />
      <Typography style={webStyles.itemName}>{name}</Typography>
      <Typography style={webStyles.description}>{description}</Typography>
    </Box>
  );
     // Customizable Area End
export default class ServiceCatalog extends Component {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
   async componentDidMount(){
    this.handleTokenReqService()
  }
  handleTokenReqService = () => {
    const tokenReq = localStorage.getItem('RequesterToken');
    if(tokenReq == null){
      window.location.href = 'RequesterLogin'
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <RequestorCommonNavbar isShowSearchAndReport={false}/>
        <Box style={webStyles.container}>
          <Typography style={webStyles.normalText}>Home <span> <img src={breadCrumbIcon} alt="breadcrumb"/> </span> Service Catalog</Typography>
        <Typography style={webStyles.title}>Service Catalog</Typography>
        <Box style={webStyles.serviceCatalog}>
          <Box style={webStyles.serviceItems}>
            <Typography style={webStyles.subtext}>All service items</Typography>
            <Grid container spacing={2}>
              {[...Array(9)].map((_, index) => (
                <Grid item md={4} lg={4} xl={4} key={index}>
                  <ServiceItem
                    name="Item Name"
                    description="Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur ma"
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box style={webStyles.serviceCategories}>
            <Typography style={webStyles.SelectedCate}>All Service Items</Typography>
            {['Hardware Provisioning', 'Software installation', 'Data Services', 'Application Access'].map(category => (
              <Typography style={webStyles.notSelectedCat} key={category}>
                {category}
              </Typography>
            ))}
          </Box>
        </Box>
        <img src={dotsBG} style={{ position: 'absolute', zIndex: -1, maxWidth: '85%', width: '100%', bottom: 0 }} alt="Background" />
      </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: Record<string, React.CSSProperties> = {
    container: {
        margin: '0 5%',
        position: 'relative',
      },
      title: {
        fontSize: '30px',
        color: '#33354D',
        fontWeight: 600,
        lineHeight: '40px',
        fontFamily: 'Manrope',
        margin: '50px 0',
      },
      serviceCatalog: {
        display: 'flex',
        width: '100%',
        gap: '30px',
      },
      serviceItems: {
        padding: '20px',
        maxWidth: '70%',
        width: '100%',
        boxShadow: '0px 8px 32px 0px #8760A929',
        borderRadius: '8px',
        background: '#fff'
      },
      serviceCategories: {
        maxWidth: '20%',
        width: '100%',
        boxShadow: '0px 8px 32px 0px #8760A929',
        borderRadius: '8px',
        height: 'fit-content',
        padding: '8px',
        background : '#fff'
      },
      subtext: {
        fontFamily: 'Manrope',
        lineHeight: '25px',
        fontSize: '20px',
        fontWeight: 700,
        color: '#33354D',
        marginBottom: '20px',
      },
      serviceCard: {
        border: '1px solid #E6DEED',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 20px',
      },
      itemName: {
        fontFamily: 'Manrope',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '22px',
      },
      description: {
        color: '#A3A6C2',
        fontFamily: 'Manrope',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
      },
      SelectedCate: {
        background: '#8760A9',
        width: '100%',
        padding: '9px',
        color: '#fff',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        borderRadius: '8px',
        boxSizing: 'border-box'
      },
      notSelectedCat: {
        width: '100%',
        padding: '9px',
        color: '#33354D',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        borderRadius: '8px',
      },
      normalText : {
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Manrope',
        lineHeight: '18px',
        wordSpacing: '5px',
        display: 'flex',
      alignItems: 'center',
      padding : '5px'
      }
};
// Customizable Area End
