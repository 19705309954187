import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    // Customizable Area Start
    isOpen: boolean
    onClose:()=>void
    title: string
    data?: string[]
    id: string
    anchorEl:  HTMLElement | null | undefined | any 
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CommonModalController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    token: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
          // Customizable Area Start
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }



    // Customizable Area Start
    // Customizable Area End
}
