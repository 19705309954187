import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";
// Customizable Area Start

interface GroupMemberResponse {
    id: string
    type: string,
    attributes: {
        name: string,
        agents: [
            {
                id: number,
                full_name: string,
                email: string,
                agent_type: string
            }
        ],
        user_type: string,
        description: string,
        business_hours: string,
        business_function: string,
        automation_time: string,
        escalation_email: string
    }
}
interface AgentList {
    id: string,
    attributes: {
        full_name: string;
        email: string;
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedData: null;
    token: string;
    isLoading: boolean;
    groupMemberData: GroupMemberResponse;
    agentsList: AgentList[];
    searchAgentList: AgentList[];
    members: string[];
    observers: string[];
    isOpenDialog: boolean;
    groupId: number | null;
    filterOption: string;
    isEditModal: boolean;
    groupNameError: string;
    buisnessFunctionError: string;
    selectedBusinessFunction: string | null;
    selectedBuisnessHours: string | null;
    selectedAgentEmail: AgentList | null;
    searchAgent: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AgentGroupMemberController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllGroupApiCallId: string = "";
    getAllAgentsApiCallId: string = "";
    addAgentsMemberAPICallId: string = "";
    businessFunction: Array<string> = ["IT", "Infosec", "HR", "Accounting & Finance", "Facility/Office Administration", "Sales & Marketing", "Legal", "Supply Chain/Operations", "Others"];
    escalationTime: Array<string> = ["15 Minutes", "30 Minutes", "1 Hour", "2 Hours", "4 Hours", "8 Hours", "12 Hours", "1 Day", "2 Days", "3 Days"];
    updateGroupDataAPICallId: string = "";
    getSearchAgentsAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedData: null,
            token: "",
            isLoading: false,
            groupMemberData: {} as GroupMemberResponse,
            agentsList: [],
            searchAgentList: [],
            members: [''],
            observers: [''],
            isOpenDialog: false,
            groupId: null,
            filterOption: 'All',
            isEditModal: false,
            groupNameError: '',
            buisnessFunctionError: '',
            selectedBusinessFunction: null,
            selectedBuisnessHours: null,
            selectedAgentEmail: null,
            searchAgent: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const { navigation } = this.props
        const ticketID = navigation.getParam('id', 0);
        this.setState({ groupId: ticketID })
        this.getParticularGroup()
        this.getAllAgents()
        this.initializeMembersAndObservers();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        this.setState({
            isLoading: false
        })
        switch (apiRequestCallId) {
            case this.getAllGroupApiCallId:
                this.handleGroupMemberResponse(responseJson);
                break;
            case this.getAllAgentsApiCallId:
                this.handleAllAgentsApiDataResponse(responseJson);
                break;
            case this.addAgentsMemberAPICallId:
                this.handleaddAgentsMemberToGroupResponse(responseJson);
                break;
            case this.updateGroupDataAPICallId:
                this.handleUpdateGroupDataAPIResponse(responseJson);
                break;
            case this.getSearchAgentsAPICallId:
                this.handleSearchAgentAPIResponse(responseJson);
                break;
            default:
                break;
        }

        // Customizable Area End
    }

    // Customizable Area Start

    // Web Events
    handleOpenDialog = () => {
        this.setState({ isOpenDialog: true })
    }

    handleCloseDialog = () => {
        this.setState({ isOpenDialog: false })
    }

    handleAddMember = () => {
        this.setState((prevState) => ({
            members: [...prevState.members, ''],
        }));
    };

    handleAddObserver = () => {
        this.setState((prevState) => ({
            observers: [...prevState.observers, ''],
        }));
    };

    handleRemoveMember = (index: number) => {
        this.setState((prevState) => {
            const members = [...prevState.members];
            members.splice(index, 1);
            return { members };
        });
    };

    handleRemoveObserver = (index: number) => {
        this.setState((prevState) => {
            const observers = [...prevState.observers];
            observers.splice(index, 1);
            return { observers };
        });
    };

    handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>, index: number, type: 'members' | 'observers') => {
        const value = event.target.value as string;
        this.setState((prevState) => {
            const updatedList = [...prevState[type]];
            updatedList[index] = value;
            return { ...prevState, [type]: updatedList };
        });
    };

    initializeMembersAndObservers = () => {
        const members = this.state.groupMemberData.attributes?.agents
            .filter(agent => agent.agent_type === 'member')
            .map(agent => agent.id.toString()) || [];
        const observers = this.state.groupMemberData.attributes?.agents
            .filter(agent => agent.agent_type === 'observer')
            .map(agent => agent.id.toString()) || [];

        this.setState({ members, observers });
    }

    isAllAgentsAdded = () => {
        const totalAgents = this.state.agentsList.length;
        const totalSelected = this.state.members.length + this.state.observers.length;
        return totalSelected >= totalAgents;
    }

    filterAgents = () => {
        const { filterOption, groupMemberData, members, observers } = this.state;
        const selectedMembers = new Set(members);
        const selectedObservers = new Set(observers);
        return groupMemberData.attributes?.agents.filter(agent => {
            const agentId = String(agent.id);
            if (filterOption === 'All') {
                return true;
            } else if (filterOption === 'Member') {
                return selectedMembers.has(agentId);
            } else if (filterOption === 'Observer') {
                return selectedObservers.has(agentId);
            }
            return false;
        }) || [];
    }

    handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ filterOption: event.target.value as string });
    };

    openEditModal = () => {
        this.setState({ isEditModal: true })
    }

    closeEditModal = () => {
        this.setState({ isEditModal: false })
    }

    handleSearchAgent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchAgent = event.target.value;
        this.setState({ searchAgent }, () => {
            if (searchAgent.trim() === '') {
                this.getParticularGroup();
            } else {
                this.getSearchAgentsAPI();
            }
        });
    };

    // Update group data events
    handleChangeGroupName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { groupMemberData } = this.state;
        const newName = event.target.value
        let groupNameError = '';

        if (!newName) {
            groupNameError = configJSON.groupNameRequire
        }
        if (groupMemberData.attributes) {
            groupMemberData.attributes.name = newName
            this.setState({ groupMemberData, groupNameError })
        }
    }

    handleChangeBusinessFunction = (event: React.ChangeEvent<{}>, value: string | null) => {
        if (!value) {
            this.setState({ buisnessFunctionError: configJSON.requiredFeild, selectedBusinessFunction: null })
        } else {
            this.setState({ selectedBusinessFunction: value, buisnessFunctionError: '' });
        }
    };

    handleChangeBuisnessHours = (event: React.ChangeEvent<{}>, value: string | null) => {
        this.setState({ selectedBuisnessHours: value })
    }

    handleChangeAgentsEmail = (event: React.ChangeEvent<{}>, value: AgentList | null) => {
        this.setState({ selectedAgentEmail: value });
    }

    handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { groupMemberData } = this.state
        const newVlaue = event.target.value
        if (groupMemberData.attributes) {
            groupMemberData.attributes.description = newVlaue
            this.setState({ groupMemberData })
        }
    }

    validateForm = () => {
        if (this.state.groupMemberData.attributes?.name.trim() === '') {
            this.setState({ groupNameError: configJSON.groupNameRequire });
            return false;
        }

        if (!this.state.selectedBusinessFunction) {
            this.setState({ buisnessFunctionError: configJSON.requiredFeild });
            return false;
        }
        return true;
    }

    // API Integration
    getParticularGroup = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllGroupApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getParticularGroupAPIEndPoint + this.state.groupId
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllAgents = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAgentsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAgentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    addAgentsMemberToGroup = async () => {
        this.setState({ isLoading: true });
        const groupid = await getStorageData("groupId")
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const httpBody = {
            agent_and_requester_params: [
                ...this.state.members.filter((member) => member).map((memberId) => ({
                    agent_id: memberId,
                    agent_type: "member"
                })),
                ...this.state.observers.filter((observer) => observer).map((observerId) => ({
                    agent_id: observerId,
                    agent_type: "observer"
                }))
            ]
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getParticularGroupAPIEndPoint}${groupid}/add_members_and_observers`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        this.addAgentsMemberAPICallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateGroupdata = async () => {
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const httpBody = {
            group: {
                automation_time: this.state.selectedBuisnessHours,
                business_function: this.state.selectedBusinessFunction,
                business_hours: "",
                description: this.state.groupMemberData.attributes?.description,
                escalation_email: this.state.selectedAgentEmail?.attributes?.email,
                name: this.state.groupMemberData.attributes?.name,
                user_type: "agent"
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getParticularGroupAPIEndPoint + this.state.groupId
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateAgentGroupsApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        this.updateGroupDataAPICallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchAgentsAPI = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchAgentsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getParticularGroupAPIEndPoint + this.state.groupId}/search_agent?search=${this.state.searchAgent}&user_type=agent`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleGroupMemberResponse = (responseJson: { data: GroupMemberResponse }) => {
        if (responseJson) {
            const escalationEmail = responseJson.data.attributes?.escalation_email;
            const selectedAgentEmail = escalationEmail
                ? {
                    id: '',
                    attributes: {
                        full_name: '',
                        email: escalationEmail
                    }
                } : null;
            this.setState({
                groupMemberData: responseJson.data,
                selectedBusinessFunction: responseJson.data.attributes?.business_function,
                selectedBuisnessHours: responseJson.data.attributes?.automation_time,
                selectedAgentEmail: selectedAgentEmail
            }, () => {
                this.initializeMembersAndObservers()
            })
        }
    }

    handleAllAgentsApiDataResponse = (responseJson: { data: AgentList[] }) => {
        if (responseJson?.data) {
            const agentSData = responseJson.data.map((agents) => ({
                id: agents.id,
                attributes: {
                    full_name: agents.attributes.full_name || '',
                    email: agents.attributes.email || ''
                }
            }));
            this.setState({
                agentsList: agentSData
            })
        }
    };

    handleaddAgentsMemberToGroupResponse = (responseJson: { data: { message: string } }) => {
        if (responseJson?.data) {
            this.setState({ isOpenDialog: false, isLoading: false })
            toast.success(responseJson.data?.message)
            this.getParticularGroup()
        }
    }

    handleUpdateGroupDataAPIResponse = (responseJson: { data: {} }) => {
        if (responseJson.data) {
            this.closeEditModal()
            this.setState({ isLoading: false })
            toast.success(configJSON.sucessmessage)
            this.getParticularGroup()
        }
    }

    handleSearchAgentAPIResponse = (responseJson: { data: GroupMemberResponse }) => {
        if (responseJson) {
            this.setState({
                groupMemberData: responseJson.data
            })
        }
    }
    // Customizable Area End
}