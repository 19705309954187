import React from 'react';

//Customizable Area Start
import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
}
    from '@material-ui/core';
import RequesterTaskListController,
{ Props, columns, configJSON, }
    from './RequesterTaskListController';
import RequestorCommonNavbar from '../../../components/src/RequestorCommonNavbar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import { dotsBG } from '../../dashboard/src/assets';
import RequesterTaskCustomForm from '../../customform3/src/RequesterTaskCustomForm.web';
//Customizable Area End

export default class RequesterTaskList extends RequesterTaskListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        //Customizable Area End
    }

    render() {
        // Customizable Area Start
        //Customizable Area End

        return (
            // Customizable Area Start
            <>
                <RequestorCommonNavbar
                    isShowSearchAndReport={false}
                    handleModalOpen={this.handleModalOpen}
                    data-testId={"openModal"}
                />
                <Box style={webStyle.mainContainer}>
                    <Typography style={webStyle.ticketHeader}>{configJSON.textTickets}</Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <TableContainer component={Paper} elevation={3} style={webStyle.tableContainer}>
                            <Box style={webStyle.filterBox}>
                                <Typography style={webStyle.ticketsText}>{configJSON.textTickets}
                                    <span style={webStyle.ticketCount}>{` (${this.state.ticketsData.length})`}</span>
                                </Typography>
                                <Typography style={webStyle.textAlign}>{configJSON.textFilter} | {configJSON.textSort}
                                    <IconButton style={webStyle.iconButton} size="small">
                                        <ArrowDropDownIcon />
                                    </IconButton> | {configJSON.textExport}
                                </Typography>
                            </Box>
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                disableRipple
                                                color="default"
                                                checkedIcon={<span style={{
                                                    borderRadius: '6px',
                                                    width: 20,
                                                    height: 20,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid #33354D',
                                                }}>
                                                    <CheckIcon style={{ color: '#8760A9' }} />
                                                </span>
                                                }
                                                icon={<span style={webStyle.icon} />}
                                            />
                                        </TableCell>
                                        {columns.map((column, index) => (
                                            <TableCell style={webStyle.tableCellHeader} key={index}>{column}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.ticketsData.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<span style={{
                                                        borderRadius: '6px',
                                                        width: 20,
                                                        height: 20,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: '1px solid #33354D',
                                                    }}>
                                                        <CheckIcon style={{ color: '#8760A9' }} />
                                                    </span>
                                                    }
                                                    icon={<span style={webStyle.icon} />}
                                                />
                                            </TableCell>
                                            <TableCell>{row.attributes.subject}</TableCell>
                                            <TableCell style={webStyle.tableCellBody}>
                                                {row.attributes.requester.full_name}
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{
                                                    ...webStyle.tableCellBody,
                                                    textAlign: 'center',
                                                    color: '#FFFFFF',
                                                    backgroundColor: '#11A64A',
                                                    padding: "3px 2px 3px 2px",
                                                    borderRadius: '4px'
                                                }}>
                                                    {row.attributes.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={webStyle.tableCellBody}>{"--"}</TableCell>
                                            <TableCell style={webStyle.tableCellBody}>{"--"}</TableCell>
                                            <TableCell style={webStyle.tableCellBody}>{row.attributes.source == null ? "--" : row.attributes.source}</TableCell>
                                            <TableCell style={webStyle.tableCellBody}>{"--"}</TableCell>
                                        </TableRow>
                                    ))}
                                    {this.state.ticketsData.length === 0 && <Typography style={{ fontWeight: 700, minWidth: '200px' }}>{configJSON.emptyDataMessage}</Typography>}
                                </TableBody>
                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                count={this.state.ticketsData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.onPageChange}
                                component="div"
                                onRowsPerPageChange={this.onRowsPerPageChange}
                                data-testId={"rowChange"}
                            />
                        </TableContainer>
                    </Box>

                    <RequesterTaskCustomForm navigation={this.props.navigation} openModal={this.state.isModalopen} data-testId={"closeModal"} closeModal={this.handleModalClose} />
                    <img src={dotsBG} style={webStyle.bgImage} />
                </Box>
            </>

        );
        //Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        width: '80%',
        margin: '3% 7% 3% 7%',
    },

    ticketHeader: {
        fontFamily: "Manrope",
        fontWeight: 600,
        color: "#33354D",
        fontSize: '30px'
    },

    tableContainer: {
        marginTop: '2%',
        padding: '20px'
    },

    paperContainer: {
        marginTop: '4%',
        width: '100%'
    },

    dropdown: {
        marginLeft: '2px',
        borderBottom: 'none !important',
        outline: 'none !important',
        backgroundColor: 'transparent',
        borderBottomColor: 'transparent',
        boxShadow: 'none'
    },

    filterBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        padding: '10px'
    },

    textAlign: {
        textAlign: 'right' as const,
        color: '#8760A9',
        fontSize: '16px',
        fontFamily: "Manrope",
        fontWeight: 700
    },

    iconButton: {
        padding: '0',
        marginLeft: '4px'
    },

    ticketsText: {
        fontFamily: "Manrope",
        fontWeight: 700,
        color: "#33354D",
        fontSize: '20px'
    },

    ticketCount: {
        fontFamily: "Manrope",
        fontWeight: 600,
        color: "#33354D",
        fontSize: '14px'
    },

    icon: {
        borderRadius: '6px',
        width: 20,
        height: 20,
        backgroundColor: 'transparent',
        border: '1px solid #33354D',
        '&:focus-visible': {
            outlineOffset: 2,
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },

    tableCellHeader: {
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: "Manrope",
        color: "#3D405C",
        verticalAlign: 'middle'
    },

    tableCellBody: {
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: "Manrope",
        color: "#33354D",
        verticalAlign: 'middle'
    },

    bgImage: {
        objectFit: 'cover',
        height: '320px',
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        top: '-7px'
    } as const
};
//Customizable Area End
