import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
import { FormikValues } from "formik";
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

export interface Problems {
  id: string;
  type: string;
  attributes: {
      subject: string;
      description: string;
      priority: string;
      planned_startdate: string;
      planned_enddate: string;
      planned_effort: string;
      status: string;
      category: string | null;
      due_by: string;
      account_id: number;
      group: {
          id: number;
          name: string;
      };
      requester: {
          id: string | null;
          name: string | null;
          email: string | null;
      };
      department: {
          id: string | null;
          name: string | null;
      };
      agent: {
          id: string | null;
          name: string | null;
          email: string | null;
      };
  };
}

interface ProblemsResponse {
  data: Problems[];
}

interface GroupedByStatus {
  [key: string]: Problems[];
}

interface Departments {
  id?: string;
  type?: string;
  attributes?: {
      department_name: string;
      description: string;
      domains_for_department: string;
      department_head: string;
      prime_user: string;
  };
}
interface DropdownTypes {
  title: string;
  id: string
}

export interface FieldTypes {
  name: string,
  column_type: string,
  list?: { title: string, id: string }[]
  values? : InitialValue
}

export interface InitialValue {
  [key: string]: string | { [key: string]: string | null } | null
}
interface ApiCallIds {
  [ky: string]: string
}

export interface AgentAndRequester {
  id?: string;
  type?: string;
  attributes: AgentAndRequesterAttributes
}

interface AgentAndRequesterAttributes {
  [key: string]: string | boolean | null;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  page: number;
  problemList: ProblemsResponse['data'];
  rowsPerPage: number;
  selected: string[];
  selectBtn: 'table' | 'board'
  showModal: boolean;
  initialValues: InitialValue
  requestersList: DropdownTypes[];
  agentList: DropdownTypes[];
  departmentList: DropdownTypes[];
  groupList: DropdownTypes[];
  templateList: DropdownTypes[];
  inputValue: string;
  categoryList: {title: string, id: string}[]
  isLoading: boolean
  filterBy: 'All' | 'Status'
  anchorEl: HTMLElement | null | undefined,
  open: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfproblemmanagementticketController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllProblemsApiCallId: string = ''
  createProblemTicketApiCallId: string = ''
  apiCallIds: ApiCallIds = {
    getAgentsList: '',
    getRequestersList: '',
    getDepartmentsList: '',
    getGroupsList: '',
  };

  token: string = ''
  status: { title: string, id: string }[] = [
    {
      id: '1',
      title: 'Open',
    },
    {
      id: '2',
      title: 'Pending',
    },
    {
      id: '3',
      title: 'Resolved',
    },
    {
      id: '4',
      title: 'Closed',
    },
  ]
  priority: { title: string, id: string }[] = [
    {
      id: '1',
      title: 'Low',
    },
    {
      id: '2',
      title: 'Medium',
    },
    {
      id: '3',
      title: 'High',
    },
    {
      id: '4',
      title: 'Urgent',
    },
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      page: 0,
      problemList: [],
      rowsPerPage: 10,
      selected: [],
      selectBtn: 'table',
      isLoading: false,
      filterBy: 'All',
      anchorEl: null,
      open: false,
      showModal: false,
      initialValues: this.createEmptyValue(),
      requestersList: [],
      departmentList: [],
      agentList: [],
      groupList: [],
      inputValue: '',
      categoryList: [],
      templateList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    this.setState({ isLoading: false })
    
    // This will rerdirect user to login page if token is not valid 
    if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
      (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
      this.goToLogin()}

    switch (apiRequestId) {
      case this.apiCallIds.getRequestersList:
        this.handleRequestersApiDataResponse(responseJson);
        break;
      case this.apiCallIds.getAgentsList:
        this.handleAgentsApiDataResponse(responseJson);
        break;
      case this.apiCallIds.getDepartmentsList:
        this.handleDepartmentsDataResponse(responseJson);
        break;
      case this.apiCallIds.getGroupsList:
        this.handleGroupApiDataResponse(responseJson);
        break;
      case this.createProblemTicketApiCallId:
        this.handleCreateProblemApiDataResponse(responseJson);
        break;
      case this.getAllProblemsApiCallId:
        this.handleProblemsApiDataResponse(responseJson);
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
    super.componentDidMount();
    this.token = await getStorageData('authToken')

    this.getAllProblems()
    await this.fetchDataLists()
  }

  fetchDataLists = async () => {
    this.setState({ isLoading: true });
    const listTypes = ['Groups', 'Agents', 'Requesters', 'Departments']
    for (const listType of listTypes) {
      this.fetchListData(listType);
      await new Promise(resolve => setTimeout(resolve, 500));
    }
  }

  fetchListData = (listType: string) => {
    const apiEndPoint = configJSON[`getAll${listType}ApiEndPoint`];
    const header = { "Content-Type": configJSON.apiContentType, token: this.token };
    const apiCallId: string = `get${listType}List`;

    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);

    this.apiCallIds[apiCallId] = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getAllProblems = () =>{
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    let getRolesMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllProblemsApiEndpoint
    );
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllProblemsApiCallId = getRolesMessage.messageId;
    runEngine.sendMessage(getRolesMessage.id, getRolesMessage);    
  }
  handleSelection = (value: 'table' | 'board') => {
    this.setState({ selectBtn: value });
  };
  
  onPageChange = (_: unknown, page: number) => {
    this.setState({ page });
  };

  returnButtonState = (state: 'table' | 'board') =>{
    if(state === this.state.selectBtn){
      return 'selected'
    } else {
      return ''
    }
  }

  onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.problemList.map((data) => data?.id || '');
      this.setState({ selected: newSelecteds || [] });
      return;
    }
    this.setState({ selected: [] });
  };

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateUser = (id: string | undefined) => {}

  getStateColor = (state: string | undefined) => {
    if (state) {
      const stateColorMapping: { [key: string]: string } = {
        'open': '#11A64A',
        'pending': '#F59E0B',
        'resolved': '#11A64A',
        'closed': '#DC2626',

        'medium': '#11A64A',
        'low': '#F59E0B',
        'high': '#DC2626',
        'urgent': '#DC2626'
      };

      return stateColorMapping[state]
    } else {
      return '#11A64A'
    }
  };

  handleSelect = (id: string) => {
    const { selected } = this.state;
    let newSelected: string[] = [];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )}
    this.setState({ selected: newSelected });
  };
  
  returnMenuColor = (value: string)=>  value === '' ? configJSON.colorGray : configJSON.colorBlack
  handleModalState = (value: boolean) => this.setState({ showModal: value })

  getTableData() {
    return this.state.problemList.slice(
      this.state.page * this.state.rowsPerPage,
      Math.min(
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
        this.state.problemList.length
      )
    )
  }

  combineDateTime = (newDate: string | undefined, newTime: string | undefined) => {
    const dateTimeString = newDate && newTime
        ? `${newDate}T${newTime}:00.000Z`
        : '';
    return dateTimeString
};

  changeStatus = (value: 'All' | 'Status') => {
    this.setState({filterBy: value, open: false})
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  }

  groupByStatus(problems: Problems[]): GroupedByStatus {
    return problems.reduce((acc, problem) => {
      const { status } = problem.attributes;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(problem);
      return acc;
    }, {} as GroupedByStatus);
  }

  createEmptyValue = () => {
    return {
        requester_id: '',
        subject: '',
        status: 'Open',
        priority: 'Low',
        group_id: '',
        agent_id: '',
        department_id: '',
        description: '',
        category: '',
        planned_effort: '',
        due_by: {date: '', time: ''},
        planned_startdate: {date: '', time: ''},
        planned_enddate: {date: '', time: ''},
    }
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      requester_id: Yup.string().required(`Please select requester`),
      description: this.stringWithSpecialCharValidation('Description', configJSON.specialCharPattern).min(10),
      planned_effort: this.stringWithSpecialCharValidation('Planned Effort', configJSON.specialCharPattern).notRequired(),
      status: Yup.string().required(`Please select status`),
      subject: this.stringWithSpecialCharValidation('Subject', configJSON.specialCharPattern),
      priority : Yup.string().required(`Please select priority`),
      due_by : Yup.string().required(`Please select due date`)
    });
  };
  

  notOnlyWhitespace = (errorMessage: string) =>Yup.string().test('not-only-whitespace',errorMessage,value => !value || value.trim().length > 0);

  stringWithSpecialCharValidation = (fieldName: string, configPattern: RegExp) =>
    Yup.string().required(`${fieldName} is required`)
      .matches(configPattern, `${fieldName} ${configJSON.inputFieldSpecialCharMessage}`)
      .concat(this.notOnlyWhitespace(`${fieldName} ${configJSON.inputFieldEmptyErrorMessage}`));

  onSubmitForm = (value: FormikValues) => {
    
    if (value) {
      this.setState({ isLoading: true });
      value = {
        ...value,
        planned_startdate: this.combineDateTime(value.planned_startdate.date, value.planned_startdate.time),
        planned_enddate: this.combineDateTime(value.planned_enddate.date, value.planned_enddate.time),
        due_by: this.combineDateTime(value.due_by.date, value.due_by.time),
      }
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.token
      };

      const body = {
        problem_management: value
      };

      const createTicketMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createProblemTicketApiCallId = createTicketMessage.messageId;
      createTicketMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createProblemTicketApiEndPoint
      );

      createTicketMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      createTicketMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      createTicketMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(createTicketMessage.id, createTicketMessage);
    }

  }

  // It will handle get group list
  handleGroupApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
    if (responseJson?.data) {
      this.setState({
        groupList: responseJson?.data?.map((data) => ({ id: data.id as string, title: data?.attributes?.name as string || '-' })),
      })
    }
  }
  // It will handle get agent list
  handleAgentsApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
    if (responseJson?.data) {
      this.setState({
        agentList: responseJson?.data?.map((data) => ({ id: data.id as string, title: data?.attributes?.full_name as string || '-' })),
      })}
  }


    // It will handle get requesters list
    handleRequestersApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
      if (responseJson?.data) {
        this.setState({
          requestersList: responseJson?.data?.map((data) => ({ id: data.id as string, title: data?.attributes?.email as string || '-' })),
        })
      }
    }
  

  handleCreateProblemApiDataResponse = (responseJson: { data: Problems }) => {
    if (responseJson?.data?.id) {
      toast.success('Problem ticket created successfully.')
      this.getAllProblems()
      this.setState({showModal: false})
    }
  }
  // It will handle get department list
  handleDepartmentsDataResponse = (responseJson: { data: Departments[] }) => {
    if (responseJson?.data) {
      this.setState({
        departmentList: responseJson?.data?.map((data) => ({ id: data.id as string, title: data?.attributes?.department_name || '-' })),
      })
    }
  }

  handleProblemsApiDataResponse = (responseJson: ProblemsResponse) => {
    if(responseJson?.data){
      this.setState({
        problemList: responseJson.data
      })
    }
  }
  // Customizable Area End
}
