export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Path1 = require("../assets/path1.png");
export const Path2 = require("../assets/path2.png");
export const Path24 = require("../assets/path24.png");
export const FinalLogo1 = require("../assets/finallogo1.png");

export const Path25 = require("../assets/path25.png");

export const Path26 = require("../assets/path26.png");
