import React from "react";

// Customizable Area Start
import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Dialog,
  Box,
  Grid,
  TextField,
  InputLabel,
  Card,
  Chip,
  styled,
  Menu,
  MenuItem,
  CardActions,
  Select,
  DialogTitle,
  Divider,
  ListItemAvatar,
  Avatar,
  Popover,
} from "@material-ui/core";
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { Toaster } from "react-hot-toast";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { infoIcon, infoIconOutlined, cancelIcon, downArrow } from './assets'

import RolesController, {  Props, configJSON } from "./RolesController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { Field, Form, Formik } from "formik";
import Loader from "../../../components/src/Loader";
import * as Yup from 'yup';

const MainCard = styled(Card)({
  marginBottom: '8px',
  padding: '12px'
})

const CardTitle = styled(Typography)({
  width: '100%',
  color: '#33354D',
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px'
})
const CardBody = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between'
})
const CustomChip = styled(Chip)({
  background: '#11A64A',
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  "&.MuiChip-clickable.MuiChip-outlined:focus":{
    backgroundColor:'#11A64A'
  }
})
const CustomMenuItem = styled(MenuItem)({
  padding: '9px 16px'
})
const CardParagraph = styled(Typography)({
  color: "#A3A6C2",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontstyle: 'normal',
  fontweight: 600,
  lineheight: 'normal',
  maxWidth: '400px'
})

const Scrollable = styled(Box)({
  marginBottom :'32px',
  "&::-webkit-scrollbar": {
    width: '10px',
    scrollPadding: '10px'
  },
  "&::-webkit-scrollbar-thumb": {
    background: 'gray',  
  }
})

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('This field is required')
    .min(4, 'Please enter at least 4 characters'),
  description: Yup.string().test(
    'not-only-whitespace',
    'This field cannot be only whitespace', // This is the message that should be displayed
    value => !value || value.trim().length > 0
  ),
  role_type: Yup.string().trim().required("This field is required")
});
// Customizable Area End

export default class Roles extends RolesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  editModalCell = () =>{
    return(
      <Dialog           
          open={this.state.showEditModal}
          onClose={this.handleEditModalClose}
          fullWidth={true}
          maxWidth='lg'
          data-test-id="editDialog"
        >
          <Box p={'1rem'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography style={styles.table_heading}>{configJSON.editRole}</Typography>
              <span style={styles.cancel_icon} onClick={this.handleEditModalClose} data-test-id="cancelEditIcon">
                <img src={cancelIcon} alt="Cancel..." />
              </span>
            </Box>
            <Formik
              initialValues={this.state.editData.attributes}
              validationSchema={validationSchema}
              onSubmit={this.handleEditSubmit}
              data-test-id="formikEditClickEvent"
            >
              {({ errors, touched }) => (
                <Form translate={undefined} style={styles.form_main_Box}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel style={styles.input_label} htmlFor="name"> {configJSON.roleNameLabel + " "}<span style={styles.role_span}>*</span> </InputLabel>
                      <Field type="text" name="name" id="name"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel style={styles.input_label} htmlFor="description"> {configJSON.description} </InputLabel>
                      <Field type="text" name="description"
                        id="description"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        error={touched.description && !!errors.description}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={styles.input_label} htmlFor="role_type"> {configJSON.selectLabel + " "}<span style={styles.role_span}>*</span> </InputLabel>
                      <Field type="text" name="role_type"
                        id="role_type"
                        as={Select}
                        fullWidth
                        variant="outlined"
                        error={touched.role_type && !!errors.role_type}
                        helperText={touched.role_type && errors.role_type}  
                      > 
                        <MenuItem value="admin_roles">Admin Role</MenuItem>
                        <MenuItem value="agent_roles">Agent Role</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                  <Box mt={'30px'} style={styles.modal_button_main_Box}>
                    <Button type="reset" style={styles.cancel_button} onClick={this.handleEditModalClose}>{configJSON.cancelBTN}</Button>
                    <Button type="submit" style={styles.add_button} >{configJSON.saveBTN}</Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Dialog >
    )
  }

  renderMemberList = () => {
    const { memberList } = this.state;
    return (
      <Box>
        <Dialog
          open={this.state.showMemberList}
          aria-labelledby="simple-dialog-title"
          onClose={this.handleMemberListModalClose}
          style={styles.customDialogStyle}
          data-test-id="memberListDialog"
        >
          <DialogTitle id="simple-dialog-title">{configJSON.agentsLabel}</DialogTitle>
          <Divider />
          {memberList.length > 0 ? <List style={styles.customLinkStyle}>
            {memberList.map((member) => (
              <ListItem
                button
                key={member.id}
                style={styles.memberListItem}
              >
                <ListItemAvatar>
                  <Avatar>
                    {member.full_name[0].toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={member.full_name} />
              </ListItem>
            ))}
          </List> :
            <Typography variant="body1" style={styles.customLinkStyle}>
              {configJSON.noMemberMsg}
            </Typography>
          }
        </Dialog>
      </Box>
    )
  }

  renderInfoIcon() {
    const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
    return (
      <img
        src={icon}
        aria-describedby="infoIcon"
        style={styles.infoIconsStyle}
        onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
        data-test-id='openInfoModal'
      />
    );
  }

  renderInfoModal = () => {
    return (
      <Popover
        data-test-id="infoModal"
        id="infoIcon"
        open={this.state.isInfoModalOpen}
        anchorEl={this.state.anchorEl}
        onClose={() => this.handelInfoModal(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { padding: '15px' },
        }}
      >
        <Box style={styles.modal_main_div}>
          <Box style={styles.modal_head}>
            <Typography style={styles.head_title}>{configJSON.rolesModalTitle}</Typography>
            <img src={cancelIcon} alt="cancel" style={styles.head_icon} data-test-id="infoCancelIcon" onClick={() => this.handelInfoModal(false)} />
          </Box>
          <Box style={styles.modal_body}>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg1}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg2}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg3}</Typography>
          </Box>

          <Typography style={styles.head_title}>{configJSON.rolesModalSubTitle}</Typography>
          <Box style={styles.modal_body}>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg4}</Typography>
              <Typography style={styles.body_text}>{configJSON.roleInfoMsg5}</Typography>
              <Typography style={styles.body_link}>{configJSON.roleInfoMsg6}</Typography>
          </Box>
          <Box >
            <Button style={styles.footer_btn} data-test-id="cancelInfoModal" onClick={() => this.handelInfoModal(false)}>{configJSON.closeBtnLabel}</Button>
          </Box>
        </Box>
      </Popover>
    )
  }

  renderContextMenuSetting = () => {
    return (
      <Box
        style={styles.accordians}
      >
        <List
          component="nav"
        >
          {this.navigationList.map((item, index: number) => {
            if (item.list && item.list.length > 0) {
              return (
                <React.Fragment key={`fragment-${item.title}-${index}`}>
                  <ListItem
                    button
                    data-test-id='openAccordian'
                    style={styles.navigation_item}
                    onClick={() =>
                      this.openAccordian(index)
                    }
                    key={`key-${item.title}-${index}`}
                  >
                    <ListItemText style={styles.navigation_link} primary={item.title} disableTypography={true} />
                  </ListItem>
                  <Collapse
                    timeout="auto" unmountOnExit
                    in={
                      this.state.openListIndex ===
                      index
                    }
                  >
                    {item.list.map((link, index: number) => (
                      <List key={`key-${link.title}-${index}`} disablePadding>
                        <ListItem
                          button
                          style={{
                            ...styles.navigation_item_nested,
                            ...(this.pathname ===
                              link.href &&
                              styles.navigation_item_active)
                          }}
                          href={link.href}
                          component="a"
                        >
                          <ListItemText
                            disableTypography={true}
                            primary={link.title}
                            style={{
                              ...styles.navigation_link,
                              ...(this.pathname ===
                                link.href &&
                                styles.active_link)                  
                            }}
                          />
                        </ListItem>
                      </List>
                    ))}
                  </Collapse>
                </React.Fragment>
              );
            }

            return (
              <ListItem button key={item.title} style={styles.navigation_item}>
                <ListItemText style={styles.navigation_link} disableTypography={true} primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
        <Toaster/>
        {this.renderMemberList()}
        <Loader loading={this.state.isLoading} />
        <Box style={styles.container}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={styles.navigation}>
              Settings {">"} User management {">"} Roles
            </span>
            <Box style={{ background: '#e6deed', borderRadius: '5px' }}>
              <span style={{ width: '36px', height: '36px', }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </Box>
          </Box>

          <Box
            style={{
              marginTop: "60px",
              marginBottom: "50px",
            }}
          >
            <Box style={{ display: "flex", gap: "5px" }}>
              <button style={styles.arrow}>
                <KeyboardArrowLeft style={styles.arrow_icon} />
              </button>
              <button style={styles.arrow}>
                <KeyboardArrowRight style={styles.arrow_icon} />
              </button>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <h1 style={styles.heading} data-test-id="roleTestID">{configJSON.rolesLabel}</h1>
              {this.renderInfoIcon()}
            </Box>
          </Box>

          <Box style={{ position: "relative", marginBottom: "32px" }}>
            <Search style={styles.search_icon} />
            <Input
              disableUnderline={true}
              onChange={(e) => this.setInputValue(e.target.value)}
              data-test-id="searchInput"
              style={styles.input}
              placeholder={"Search..."}
            />
          </Box>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={styles.rolesList}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "37px",
                }}
              >
                <h2
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span style={styles.card_heading}>
                    {configJSON.rolesLabel}
                  </span>
                  {this.state.RolesList.length !== 0 && (
                    <span style={styles.role_count}>
                      ({this.state.RolesList.length})
                    </span>
                  )}
                </h2>
                <Box>
                  <Button
                    id="basic-button"
                    data-test-id="basicButton"
                    aria-controls={this.state.open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}
                    endIcon={<img src={downArrow} height='20px' width='20px'/>}
                    style={styles.menu_buttton}
                  >
                    {this.state.selectedRoleType === "admin_roles" ? configJSON.adminRole : configJSON.agentRole}
                  </Button>
                  <Menu
                    id="basic-menu"
                    data-test-id="basicMenu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}  
                  >
                    <CustomMenuItem data-test-id="filterRole" onClick={() => this.handleRoleTypeChange(this.state.selectedRoleType === "admin_roles" ? "agent_roles" : "admin_roles" )}>
                      {this.state.selectedRoleType === "admin_roles" ? configJSON.agentRole : configJSON.adminRole }
                    </CustomMenuItem>
                  </Menu>
                </Box>
              </Box>
              <Scrollable style={
                this.state.RolesList.length > 5 ? {
                  overflowY: 'scroll', maxHeight: '455px'
                } : {}
              }>
                {
                  this.state.RolesList.filter(role => role.attributes.role_type === this.state.selectedRoleType).map((role, i) => (
                    <MainCard key={`${role.id}-${i}`} variant="outlined">
                      <CardTitle data-test-id={`roleTitle${i}`}>{this.handleInvalidText(role.attributes.name)}</CardTitle>
                      <CardBody>
                        <CardParagraph>{this.handleDescription(role.attributes.description)}</CardParagraph>
                        <Box style={styles.listIcon}>
                          <CustomChip
                            data-test-id="showList"
                            label={role.attributes.agent_count + " agent" || " 0 agent"}
                            size="medium"
                            variant="outlined"
                            onClick={()=> this.handleMemberListModalOpen(role.attributes.agent_details)}
                          />
                          <CardActions >
                            <Button size="small" style={styles.edit_button}>
                              <EditIcon fontSize="small" data-test-id="openEditModal" onClick={() => this.handleRoleEdit(true, role)} />
                            </Button>
                            <Button size="small" style={styles.edit_button}>
                              <DeleteIcon fontSize="small" data-test-id="deleteButton" onClick={() => this.handleRoleDelete(role.id)} />
                            </Button>
                          </CardActions>
                        </Box>
                      </CardBody>
                    </MainCard>
                  ))
                }
              </Scrollable>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "42px",
                }}
              >
                <Button style={styles.create_button} onClick={() => this.handleModalOpen(true)}
                  data-test-id="openModal">
                  {configJSON.addNewRole}
                </Button>
              </Box>
            </Box>
            {this.renderContextMenuSetting()}
          </Box>
        </Box>
        <Dialog
          open={this.state.showModal}
          onClose={() => this.handleModalOpen(false)}
          fullWidth={true}
          maxWidth='lg'
          data-test-id="close-dialog"
        >
          <Box p={'1rem'}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Typography style={styles.table_heading}>{configJSON.createNewRole}</Typography>
              <span style={{ height: '30px', width: '30px' }} onClick={() => this.handleModalOpen(false)} data-test-id="cancelIcon">
                <img src={cancelIcon} alt="Cancel..." />
              </span>
            </Box>
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              data-test-id="formikClickEvent"
            >
              {({ errors, touched }) => (
                <Form translate={undefined} style={styles.form_main_Box}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel style={{ marginBottom: "5px" }} htmlFor="name">
                        {configJSON.roleNameLabel + " "}<span style={styles.role_span}>*</span>
                      </InputLabel>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={{ marginBottom: "5px" }} htmlFor="description">
                        {configJSON.description}
                      </InputLabel>
                      <Field
                        type="text"
                        name="description"
                        id="description"
                        as={TextField}
                        fullWidth
                        variant="outlined"
                        error={touched.description && !!errors.description}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={styles.input_label} htmlFor="role_type">
                        {configJSON.selectLabel + " "}<span style={styles.role_span}>*</span>
                      </InputLabel>
                      <Field
                        type="text"
                        name="role_type"
                        id="role_type"
                        as={Select}
                        fullWidth
                        variant="outlined"    
                        error={touched.role_type && !!errors.role_type}
                        helperText={touched.role_type && errors.role_type}
                        >
                        <MenuItem value="admin_roles">Admin Role</MenuItem>
                        <MenuItem value="agent_roles">Agent Role</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                  <Box mt={'30px'} style={styles.modal_button_main_Box}>
                    <Button data-test-id="modal_Cancel_Btn" type="reset" style={styles.cancel_button} onClick={() => this.handleModalOpen(false)}>{configJSON.cancelBTN}</Button>
                    <Button type="submit" style={styles.add_button} >{configJSON.saveBTN}</Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Dialog >
        {this.editModalCell()}
        {this.renderInfoModal()}
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold",
  } as const,
  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white",
  } as const,
  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px",
  } as const,
  setting: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: "36px",
    backgroundColor: "#E6DEED",
    width: "36px",
    borderRadius: "4px",
  } as const,

  table_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  card_heading:{
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal",
  } as const,
  role_count: {
    lineHeight: "19px",
    fontWeight: "bold",
    fontSize: "14px",
  } as const,

  rolesList: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)',
    alignSelf: "flex-start",
  } as const,

  create_button: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
  } as const,
  cancel_button: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial",
  } as const,
  add_button: {
    padding: "10px 16px",
    color: "#FFF",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    letterSpacing: "0em",
    lineHeight: "16px",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,
  accordians: {
    boxShadow: "0px 4px 8px 0px #8760A921",
    flexBasis: "30%",
    padding: "4px",
    borderRadius: "8px",
  } as const,
  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px",
  } as const,
  navigation_item: {
    borderRadius: "4px",
    padding: "9px, 20px, 9px, 16px",
  } as const,

  navigation_item_nested: {
    borderRadius: "4px",
    padding: "9px 20px 9px 30px",
  } as const,

  navigation_item_active: {
    color: "white",
    backgroundColor: "#8760A9",
  } as const,

  navigation_link: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Manrope",
    letterSpacing: "0em",
    lineHeight: "22px",
    color: "#33354D",
  } as const,

  active_link: {
    color: "white",
  } as const,
  form_main_Box: {
    padding: '1rem',
  } as const,
  modal_button_main_Box: {
    gap: '1rem',
    display: 'flex',
    float: 'right',
  } as const,
  menu_buttton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    textTransform: 'none',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    fontWeight: 700,
  } as const,

  listIcon: {
    display: "flex",
    alignItems: "center"
  },

  cancel_icon: {
     height: "30px",
      width: "30px" 
  },

  input_label: {
    marginBottom: "5px",
   },

   edit_button: {
    padding: "0px",
    minWidth: "0px" 
   },

   role_span: {
    color: "red"
   },

  modal_main_div: {
    maxWidth: "460px"
  },

  modal_head: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px"
  },

  head_title: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal"
  },

  head_icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },

  modal_body: {
    marginBottom: "30px"
  },

  body_text: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    marginBottom: "2px"
  },

  body_link: {
    color: "#8760A9",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    marginTop: "5px"
  },

  footer_btn: {
    display: "flex",
    height: "48px",
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    background: "#8760A9",
    float: "right",
    color: "white",
    textTransform: "none"
  },

  infoIconsStyle: {
    cursor: "pointer",
    color: "#8760A9",
    marginLeft: "10px"
   },

   customDialogStyle: {
    marginTop: "-250px"
   },

   customLinkStyle: {
    padding: "15px"
   },

   memberListItem: {
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    marginBottom: "8px"
   }

};
// Customizable Area End
