Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";

exports.textMainHeading = 'How can we help you?'
exports.textCard1Heading = 'Report an issue'
exports.textCard1Desc = 'Having trouble? Contact the support team'
exports.textReport = 'Report'
exports.textApprove = 'Approve'
exports.textRequest = 'Request'
exports.textCard2Heading = 'Request a service'
exports.textCard2Desc = 'Browse the list of services offered and raise a request'
exports.textCard3Heading = 'Approve requests'
exports.textCard3Desc = 'View all requests awaiting your approval'
exports.reqApiCallEndPoint = '/bx_block_dashboard/requester_logins/'
exports.reqApiType = 'GET'
// Customizable Area End