import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Checkbox,
  Typography,
  TablePagination,
  ButtonGroup,
  styled,
  Chip,
  Menu,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Search, ArrowDropDown, AddCircleOutline } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import {Ticket} from './TaskListController';
import IncidentTicketCustomForm from "../../customform3/src/IncidentTicketCustomForm.web";

// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#8760A9'
      },
      input: {
        borderRadius: '5px'
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      },
    },
  }
});


const CustomChip = styled(Chip)({
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  "& .MuiChip-clickable.MuiChip-outlined:focus": {
    backgroundColor: '#11A64A'
  }
})

const StyledButtonGroup = styled(ButtonGroup)({
  backgroundColor: '#E6DEED',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '50px',
  border: 'none',
  outline: 'none',
  padding: '5px',
  '& button': {
    border: 'none',
    borderRadius: '50px !important',
    color: '#33354D',
    textTransform: 'inherit',
  },
  '& .selected': {
    color: '#fff',
    backgroundColor: '#8760A9',
    '&:hover': {
      backgrounColor: '#3700b3'
    }
  }
})

const CustomMenuItem = styled(MenuItem)({
  padding: '9px 16px'
})

const ImageSection = styled(Box)({
  height:'32px',
  width:'32px',
  backgroundColor:"#E6DEED",
  borderRadius:'50%',
  marginRight:'10px',
  color:"#33354D",
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
})

// Customizable Area End


export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  returnChip = (status: string) => {
    return <CustomChip
      label={status}
      size="medium"
      variant="outlined"
      style={{ background: this.getStateColor(status) }}
    />
  }

  renderTables() {
    const { filterBy, ticketListNew } = this.state;
    if (filterBy === 'status') {
      if (ticketListNew["status"]?.tickets.length === 0) return <Typography  data-test-id='notFoundTypography'>{configJSON.textNoTickets}</Typography>;
      return Object.keys(ticketListNew).map(status =>{
        const count = ticketListNew[status].count;
        return  (
          <React.Fragment key={status}>
            {this.renderTable(ticketListNew[status]?.tickets, status, count)}
          </React.Fragment>
        )
      });
    }
    if(filterBy === 'priority') {
      if (ticketListNew["priority"]?.tickets.length === 0) return <Typography data-test-id='notFoundTypography'>{configJSON.textNoTickets}</Typography>;
      return Object.keys(ticketListNew).map(priority =>{
        const count = ticketListNew[priority].count;
        return  (
          <React.Fragment key={priority}>
            {this.renderTable(ticketListNew[priority]?.tickets, priority, count)}
          </React.Fragment>
        )
      });
    }

    if(filterBy == "all"){
      if (ticketListNew && ticketListNew["all"]?.tickets?.length === 0) return <Typography data-test-id='notFoundTypography'>{configJSON.textNoTickets}</Typography>;
      const count = ticketListNew && ticketListNew["all"]?.count;
      return (
        <React.Fragment key={"all"}>
          {this.renderTable(ticketListNew["all"]?.tickets, configJSON.textAllTickets, count)}
        </React.Fragment>
      )
    }
  }

  renderTable(ticketList: Ticket[], title: string, count: number) {
    return (
      <Box style={webStyles.table_card}>
        <Box display={'flex'} justifyContent={'space-between'} mb={'35px'}>
          <Box style={webStyles.cardHead} >
            <span style={webStyles.card_heading}>
              {title}
            </span>
            <span style={webStyles.ticket_count}>
              ({count})
            </span>
          </Box>
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              <Button style={webStyles.export_button_border}>{configJSON.textFilter}</Button>
            </Box>
          </Box>
        </Box>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={this.state.selected?.length === this.state.ticketListNew?.[title]?.tickets?.length}
                    indeterminate={this.state.selected?.length > 0 && this.state.selected?.length < this.state.ticketListNew?.[title]?.tickets?.length}
                    onChange={this.handleSelectAllClick}
                  />
                </TableCell>
                <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textSubject}
                </TableCell>
                <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textRequester}
                </TableCell>
                {(this.state.filterBy === "status" || this.state.filterBy === "all") && <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textStatus}
                </TableCell>}
                {(this.state.filterBy === "priority" || this.state.filterBy === "all") && <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textPriority}
                </TableCell>}
                <TableCell style={webStyles.table_label}>
                  {configJSON.textAssignedTo}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketList?.length > 0 &&
                ticketList?.map((ticket, i: number) => {
                                    return (
                    <TableRow
                      hover
                      key={`key-${ticket.id}-${i}`}
                      data-testId={'row_click'}
                      onClick={() => this.handleRowClick(ticket)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={this.state.selected.indexOf(ticket?.id as string) !== -1}
                          onClick={(e) => { e?.stopPropagation() }}
                          onChange={() => { this.handleSelect(ticket.id as string) }}
                        />
                      </TableCell>
                      <TableCell style={webStyles.truncatedText}>
                        {ticket?.attributes?.subject || "--"}
                      </TableCell>
                      <TableCell style={webStyles.truncatedText}>
                        <Box style={webStyles.DetailsboxSupport}>
                          <ImageSection>
                            {ticket?.attributes?.requester?.full_name && this.getShortUserName(ticket?.attributes?.requester?.full_name)}
                          </ImageSection>
                          <Box>
                            <Typography style={webStyles.fieldNameSupport}>
                              {ticket?.attributes?.group?.name?.toUpperCase() ?? "-"}
                            </Typography>
                            <Typography style={webStyles.fieldValue}>
                              {ticket?.attributes?.requester?.full_name ?? "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      {(this.state.filterBy === "status" || this.state.filterBy === "all") && <TableCell style={webStyles.truncatedText}>
                        {ticket.attributes?.status ?
                          this.returnChip(ticket?.attributes?.status) : '-'}
                      </TableCell>}
                      {(this.state.filterBy === "priority" || this.state.filterBy === "all") && <TableCell style={webStyles.truncatedText}>
                        {ticket.attributes?.priority ?
                          this.returnChip(ticket.attributes?.priority) : '-'}
                      </TableCell>}
                      <TableCell style={webStyles.truncatedText}>
                        {ticket.attributes?.agent?.name || configJSON.textNone}
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!this.state.ticketListNew && <Typography data-test-id={"lengthNotFoundTypography"} style={{ fontWeight: 700, minWidth: '200px' }}>{configJSON.emptyDataMessage}</Typography>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.onPageChange}
          component="div"
          onRowsPerPageChange={this.onRowsPerPageChange}
        />
      </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <NavigationSideBar active="tickets" navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <ThemeProvider theme={theme}>
          <Box style={webStyles.task_container}>
            <Box style={webStyles.topheader}>
              <span style={webStyles.navigation}>
                {configJSON.textTickets} {">"} {configJSON.textAllTickets}
              </span>
              <Box style={webStyles.inputMainBox}>
                <Input
                  disableUnderline={true}
                  style={webStyles.input}
                  placeholder={"Search"}
                  startAdornment={<Search />}
                />

                <ButtonGroup
                  orientation='horizontal'
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'none' }}
                >
                  <Button style={{ textTransform: 'none' }}
                  onClick={() => this.handleModalState(true)}
                  >{configJSON.textCreateNewTicket}</Button>
                  <Button>
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            <Typography style={webStyles.mainHeading}>{configJSON.textAllTickets}</Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mt={'25px'} mb={'30px'}>
              <Box>
                <StyledButtonGroup>
                  <Button
                    className={this.state.selectBtn === 'table' ? 'selected' : ''}
                    onClick={() => this.handleSelection('table')}
                    data-test-id="click-table"
                  >
                    {configJSON.textTable}
                  </Button>
                  <Button
                    className={this.state.selectBtn === 'board' ? 'selected' : ''}
                    onClick={() => this.handleSelection('board')}
                  >
                    {configJSON.textBorad}
                  </Button>
                </StyledButtonGroup>

              </Box>
              <ButtonGroup
                orientation='horizontal'
                variant="contained"
                style={webStyles.filterByStyle}
              >
                <Button style={{ textTransform: 'none' }}
                >
                  {configJSON.textGroupBy} <span style={{ fontWeight: 'bold', marginLeft:"2px" }}> {this.getCapitalizeName(this.state.filterBy)}</span>
                </Button>
                <Button
                  id="basic-button"
                  data-test-id="basicButton"
                  aria-controls={this.state.open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={this.state.open ? 'true' : undefined}
                  onClick={this.handleClick}>
                  <ArrowDropDown />
                </Button>
                <Menu
                    id="basic-menu"
                    data-test-id="basicMenu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                  >
                    <CustomMenuItem data-test-id="filterAll" onClick={() => this.changeStatus('all')}>
                      {configJSON.textAll}
                    </CustomMenuItem>
                    <CustomMenuItem data-test-id="filterStatus" onClick={() => this.changeStatus('status')}>
                      {configJSON.textStatus}
                    </CustomMenuItem>
                    <CustomMenuItem data-test-id="filterStatus" onClick={() => this.changeStatus('priority')}>
                      {configJSON.textPriority}
                    </CustomMenuItem>
                  </Menu>
              </ButtonGroup>
            </Box>
            <Box style={webStyles.main_content_card}>
              {this.renderTables()}
            </Box>
          </Box>

          <IncidentTicketCustomForm closeModal = {this.handleModalState} showModal={this.state.showModal} navigation={this.props.navigation} id={""}/>        
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  modalRowViewStyle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainerStyle: {
    maxHeight: 440,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
  truncatedText: {
    overflow: 'hidden',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  } as const,
  navigation: {
    color: '#3D405C',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
  } as const,
  inputMainBox: {
    fontSize: '14px',
    color: '#33354D',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 'bolder',
    fontFamily: 'Manrope',
    cursor: 'pointer',
    alignItems: "center",
    display: 'flex',
    gap: '10px'
  } as const,
  input: {
    width: "100%",
    border: "1px solid #e6deed",
    fontFamily: "Manrope",
    maxWidth: '200px',
    fontSize: "16px",
    borderRadius: "10px",
    height: "40px",
    padding: '10px 8px'
  } as const,
  table_label: {
    color: "#3D405C",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 700,
    opacity: '.5'
  } as const,
  mainHeading: {
    fontFamily: 'Manrope',
    color: '#33354',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontSize: '30px',
    padding: '40px 0'
  } as const,
  main_content_card: {
    borderRadius: "8px",
    alignSelf: "flex-start",
    padding: "16px",
    flex: "1",
    marginBottom: '1rem'
  } as const,
  export_button: {
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Manrope",
    color: "#8760A9",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "capitalize",
  } as const,
  rightBorder: {
    borderRadius: '1px',
    height: '20px',
    width: '2px',
    background: '#E6DEED',
    margin: '0 3px'
  } as const,
  export_button_border: {
    fontWeight: "bold",
    fontFamily: "Manrope",
    fontSize: "16px",
    color: "#8760A9",
    lineHeight: "16px",
    textTransform: "capitalize",
    letterSpacing: "0em",
  } as const,
  cardHead: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  } as const,
  card_heading: {
    fontFamily: 'Manrope',
    color: "#33354D",
    lineHeight: "normal",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: 700,
  } as const,
  filterByStyle: {
    color: '#33354D',
    textTransform: 'none',
    fontStyle: 'normal',
    fontFamily: 'Manrope',
    fontSize: '16px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  } as const,
  ticket_count: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "bold",
  } as const,
  plus_style: {
    height: '24px',
    width: '24px',
    background: '#E6DEED',
    borderRadius: '50%',
    color: 'black',
    padding: '3px',
    cursor: 'pointer',
  } as const,
  task_container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  table_card: {
    borderRadius: "8px",
    padding: "16px",
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
  } as const,
  DetailsboxSupport: {
    display: "flex",
    justifyContent: "start",
    marginTop: "20px",
    alignItems: "center"
  } as const,
  fieldNameSupport: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#A3A6C2",
    display: "flex",
    justifyContent: "start",
    letter : "1%"
  } as const,
  fieldValue: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#33354D"
  } as const,
};
// Customizable Area End
