import React from "react";

// Customizable Area Start
import {
  Button,
  Input,
  Typography,
  Box,
  ButtonGroup,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDropDown,
  Clear,
} from "@material-ui/icons";
import toast, { Toaster, ToastBar } from "react-hot-toast";


import DashboardsController, { Props, configJSON } from "./DashboardsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader";
import IncidentTicketCustomForm from "./IncidentTicketCustomForm.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  }
});

// Customizable Area End

export default class Dashboards extends DashboardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderToaster = () => {
    return (
      <Toaster toastOptions={{ duration: 50000 }}>
        {(toasterData) => (
          <ToastBar style={{
            width: '468px',
            maxWidth: '468px'
          }} toast={toasterData}>
            {({ icon, message }) => (
              <Box style={styles.toaster_wrapper}>
                <Box style={styles.toaster_container}>
                  <Box style={styles.toaster_box}>
                    <span style={styles.toastIcon}>{icon}</span>
                    <Typography style={styles.toaster_msg}>
                      {message}
                    </Typography>
                  </Box>
                  <p style={styles.toaster_group_name}>{configJSON.textTickets}</p>
                </Box>
                <Box style={styles.toaster_box}>
                  {toasterData.type !== "loading" && (
                    <>
                      <span style={styles.toaster_span}></span>
                      <span style={styles.toastCancelIcon} onClick={() => toast.dismiss(toasterData.id)}>
                        <Clear />
                      </span>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </ToastBar>
        )}
      </Toaster>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationSideBar active={'Dashboards'} navigation={this.props.navigation} />
        {this.renderToaster()}
        <Loader loading={this.state.isLoading} />
        <Box style={styles.container}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={styles.navigation}>
              {configJSON.textDashboards} {">"} {configJSON.textDashboardName}
            </span>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <ButtonGroup
                orientation='horizontal'
                color="primary"
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                <Button data-testId={"openModal"} style={{ textTransform: 'none' }}
                  onClick={() => this.handleModalState(true)}
                >{configJSON.textCreateNewTicket}</Button>
                <Button>
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
              <span style={{ width: '36px', height: '36px', background: '#e6deed', borderRadius: '5px' }}>
                <MoreVert style={{ width: '24px', height: '24px', color: 'black', padding: '5px' }} />
              </span>
            </Box>
          </Box>

          <Box
            style={{
              marginTop: "60px",
              marginBottom: "50px",
            }}
          >
            <Typography style={{ fontSize: '30px', color: '#33354D' }}>
              {configJSON.textDashboardName}
            </Typography>
          </Box>

          <Box style={{ position: "relative", marginBottom: "32px" }}>
            <Search style={styles.search_icon} />
            <Input
              disableUnderline={true}
              onChange={(event) => this.setInputValue(event.target.value)}
              data-test-id="searchInput"
              style={styles.input}
              placeholder={configJSON.search}
            />
          </Box>
          <Box style={{ display: "flex", gap: "20px" }}>
            <Box style={styles.leftBox}>
              <Box style={styles.cardStyle}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "37px",
                  }}
                >
                  <h2
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography style={styles.card_heading}>
                      {configJSON.textTickets}
                    </Typography>

                  </h2>
                  <Box>
                    <Button
                      id="basic-button"
                      data-test-id="basicButton"
                      aria-haspopup="true"
                    >
                      WIP
                    </Button>

                  </Box>
                </Box>
                <Box >
                  WIP
                </Box>

              </Box>
              <Box style={styles.cardStyle}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "37px",
                  }}
                >
                  <h2
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography style={styles.card_heading}>
                      {configJSON.textLeaderboard}
                    </Typography>
                  </h2>
                  <Box>
                    <Button
                      id="basic-button"
                      data-test-id="basicButton"
                      aria-haspopup="true"
                    >
                      WIP
                    </Button>

                  </Box>
                </Box>
                <Box >
                  WIP
                </Box>
              </Box>
            </Box>
            <Box style={styles.rightBox}>

              <Box style={styles.cardStyle}>
                <Typography style={styles.card_heading}>
                  {configJSON.textAnnouncements}
                </Typography>
                <Box style={styles.announcementsMainWrapper}>
                  <Box style={styles.announcementsCard} >
                    <Typography style={styles.announcementHeading}>Welcome to Ganga</Typography>
                    <Typography style={styles.announcementDesc}>Your Ganga account has been activated and is now ready to use. During your free 21-day trial period, your account will be on the highest trial plan - Enterprise (Trial) and you will have access to all of the features and capabilities of Ganga.</Typography>

                    <Box style={styles.announcementsCardsUserInfo}>
                      <Box mr={'12px'}>
                        <Typography style={styles.userInfoDate}>6th July 2023</Typography>
                        <Typography style={styles.userInfouserName}>Name Surname</Typography>
                      </Box>
                      <img style={styles.imageStyle} src="https://www.electricallicenserenewal.com/app-assets/images/user/12.jpg" height='32px' width='32px' />
                    </Box>
                  </Box>
                  <Box style={styles.announcementArrowWrapper}>
                    <button style={styles.arrow}>
                      <KeyboardArrowLeft style={styles.arrow_icon} />
                    </button>
                    <button style={styles.arrow}>
                      <KeyboardArrowRight style={styles.arrow_icon} />
                    </button>
                  </Box>
                </Box>
                <Box mt={'30px'}>
                  <Button style={styles.announcementTransBtn}>{configJSON.textViewAllAnnouncement}</Button>
                  <Button style={styles.announcementFilledBtn}>{configJSON.textCreateNewAnnouncement}</Button>
                </Box>
              </Box>

              <Box style={styles.cardStyle}>
                <Typography style={styles.card_heading}>
                  {configJSON.textAnnouncements}
                </Typography>
                <Box style={styles.announcementsMainWrapper}>
                  <Box style={styles.announcementsCard} >
                    WIP
                  </Box>
                  <Box style={{ display: "flex", gap: "5px" }}>
                    <button style={styles.arrow}>
                      <KeyboardArrowLeft style={styles.arrow_icon} />
                    </button>
                    <button style={styles.arrow}>
                      <KeyboardArrowRight style={styles.arrow_icon} />
                    </button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

      <IncidentTicketCustomForm closeModal = {this.handleModalState} showModal={this.state.showModal} navigation={this.props.navigation} id={""}/>        
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope",
  } as const,
  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white",
  } as const,
  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px",
  } as const,

  leftBox: {
    flex: "1",
    gap: '16px',
    display: 'flex',
    flexDirection: 'column'
  } as const,
  rightBox: {
    flexBasis: "30%",
    gap: '16px',
    display: 'flex',
    flexDirection: 'column'
  } as const,
  card_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal",
  } as const,
  cardStyle: {
    padding: "16px",
    borderRadius: "8px",
    boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px'
  } as const,
  announcementsMainWrapper: {
    marginTop: '30px'
  } as const,
  announcementsCard: {
    borderRadius: '8px',
    border: '1px solid #E6DEED',
    padding: '12px'
  } as const,
  announcementFilledBtn: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px",
    width: '100%'
  } as const,
  announcementTransBtn: {
    padding: "10px 16px",
    color: "#8760A9",
    fontFamily: "Manrope",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    width: '100%',
    fontWeight: 700,
    textTransform: 'initial'
  } as const,
  announcementsCardsUserInfo: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'end'
  } as const,
  imageStyle: {
    height: '32px',
    width: '32px',
    borderRadius: '50%'
  } as const,
  announcementHeading: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#33354D',
    fontFamily: 'Manrope,'
  } as const,
  announcementDesc: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#33354D',
    marginTop: '16px',
    fontFamily: 'Manrope'
  } as const,
  announcementArrowWrapper: {
    display: "flex",
    gap: "5px",
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'end'
  } as const,

  userInfoDate: {
    color: '#A3A6C2',
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'end'
  } as const,
  userInfouserName: {
    color: '#33354D',
    fontSize: '14px',
    fontWeight: 600
  } as const,

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px",
  } as const,

  toaster_wrapper: {
    display: "inline-flex",
    padding: "8px 8px 8px 8px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "900px",
    height: "44px"
  } as const,
  toaster_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  } as const,
  toaster_box: {
    display: "flex",
    alignItems: "center"
  } as const,
  toaster_msg: {
    marginLeft: "10px",
    fontSize: "12px",
    fontWeight: 'bold',
    fontFamily: 'Manrope',
    lineHeight: 'normal',
    color: '#33354D',
    maxWidth: '260px'
  } as const,
  toastIcon: {
    height: '26px',
    width: '26px',
  } as const,
  toaster_group_name: {
    fontSize: "14px",
    color: '#8760A9',
    fontWeight: 'bolder',
    fontStyle: 'normal',
    fontFamily: 'Manrope',
  } as const,
  toaster_span: {
    borderRight: "1px solid #ccc",
    margin: "0px 8px",
    height: "28px",
    width: "2px",
  } as const,
  toastCancelIcon: {
    cursor: "pointer",
  } as const,
};
// Customizable Area End
