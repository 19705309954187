import React from 'react';

//Customizable Area Start
import {
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MobileStepper,
    Paper,
    TextField,
    Typography
}
    from '@material-ui/core';
import RequesterForgotPasswordController, { Props, configJSON, steps } from './RequesterForgotPasswordController';
import { Toaster } from 'react-hot-toast';
import { FinalLogo1 } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

//Customizable Area End

export default class RequesterForgotPassword extends RequesterForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        //Customizable Area End
    }

    render() {
        // Customizable Area Start
        //Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Toaster />
                <Container style={useStyles.containerStyle}>
                    <Box style={useStyles.logoWrapper}>
                        <img src={FinalLogo1} alt="My Image" />
                    </Box>
                    <Box style={useStyles.forgotPasswordWrapperEmailStepper}>
                        <MobileStepper
                            data-testId={"mobileStepper"}
                            variant="progress"
                            steps={steps.length}
                            activeStep={this.state.activeStep}
                            backButton={undefined}
                            nextButton={undefined}
                        />
                    </Box>
                    {
                        this.state.activeStep == 0 && (
                            <Box style={useStyles.forgotPasswordWrapperEmail}>
                                <Paper elevation={3} style={useStyles.forgotPasswordEmail}>
                                    <Box style={useStyles.boxPadding}>
                                        <Typography variant="h4" style={useStyles.typographyMargin} component="h2">
                                            {configJSON.passReset}
                                        </Typography>
                                        <Typography style={useStyles.typographyMargin} variant="subtitle1" component="div">
                                            {configJSON.workEmail}
                                        </Typography>

                                        <Box style={useStyles.boxMargin}>
                                            <InputLabel style={useStyles.inputLableMargin} htmlFor="email">
                                                {configJSON.workEmails}<span style={useStyles.fontColor}>*</span>
                                            </InputLabel>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.email}
                                                onChange={this.handleForgetEmailChange}
                                                error={Boolean(this.state.emailError)}
                                                helperText={this.state.emailError}
                                                data-testId={"change_email"}
                                            />
                                        </Box>

                                        <Box style={useStyles.buttonContainerStyle}>
                                            <Button onClick={this.goToRequesterLogin} style={{ textTransform: 'initial' }} color="primary">{configJSON.cancel}</Button>
                                            <Button
                                                data-test-id={"btnEmailLogIn"}
                                                onClick={this.fogotPasswordOTP}
                                                style={useStyles.buttonBackground}
                                                data-testId={"submit_email"}
                                            >
                                                <span style={useStyles.buttonTextStyle}>{configJSON.submit}</span>
                                            </Button>
                                        </Box>

                                    </Box>
                                </Paper>
                            </Box>
                        )
                    }

                    {
                        this.state.activeStep == 1 && (
                            <Box style={useStyles.forgotPasswordWrapperOTP}>
                                <Paper elevation={3} style={useStyles.forgotPasswordEmail}>
                                    <Box style={useStyles.boxPadding}>
                                        <Typography variant="h4" style={useStyles.typographyMargin} component="h2">
                                            {configJSON.passReset}
                                        </Typography>
                                        <Typography style={useStyles.typographyMargin} variant="subtitle1" component="div">
                                            {configJSON.verificationCode}
                                        </Typography>

                                        <Box style={useStyles.boxMargin}>
                                            <InputLabel style={useStyles.inputLableMargin} htmlFor="email">
                                                {configJSON.codeText}<span style={useStyles.fontColor}>*</span>
                                            </InputLabel>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.forgotPasswordOTP}
                                                onChange={this.handleOTPChange}
                                                data-testId={"verifyOTP"}
                                            />
                                        </Box>

                                        <Typography style={useStyles.typographyMargin1}>
                                            {configJSON.didntreceiveCode}
                                            <Link
                                                data-testId={"resend_otp"}
                                                onClick={this.resendFogotPasswordOTP}
                                            >
                                                {configJSON.resendCode}
                                            </Link>
                                        </Typography>

                                        <Box style={useStyles.buttonContainerStyle}>
                                            <Button onClick={this.goToRequesterLogin} style={{ textTransform: 'initial' }} color="primary">{configJSON.cancel}</Button>
                                            <Button
                                                data-test-id={"btnEmailLogIn"}
                                                onClick={this.verifyFogotPasswordOTP}
                                                style={useStyles.buttonBackground}
                                                data-testId={"submitOTP"}
                                            >
                                                <span style={useStyles.buttonTextStyle}>{configJSON.submit}</span>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        )
                    }

                    {this.state.activeStep === 2 && (
                        <Box style={useStyles.forgotPasswordWrapperEmail}>
                            <Paper elevation={3} style={useStyles.forgotPasswordEmail} >
                                <Box style={useStyles.boxPadding}>
                                    <Typography variant="h4" style={useStyles.typographyMargin} component="h2">
                                        {configJSON.passReset}
                                    </Typography>
                                    <Typography style={useStyles.typographyMargin} variant="subtitle1" component="div">
                                        {configJSON.workEmail}
                                    </Typography>

                                    <Box>
                                        <InputLabel style={useStyles.inputLableMargin} htmlFor="password">
                                            {configJSON.pass}<span style={useStyles.fontColor}>*</span>
                                        </InputLabel>
                                        <TextField
                                            type={this.state.passwordVisible ? 'text' : 'password'}
                                            fullWidth
                                            variant="outlined"
                                            name="password"
                                            value={this.state.formData.password}
                                            onChange={this.handleChangePassword}
                                            error={Boolean(this.state.passwordError)}
                                            helperText={this.state.passwordError}
                                            data-testId={"password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" data-testId={"toggle_password"}>
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            onClick={this.togglePasswordVisibility}
                                                        >
                                                            {this.state.passwordVisible ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>

                                    <Box style={useStyles.retypePasswordBox}>
                                        <InputLabel style={useStyles.inputLableMargin} htmlFor="confirmPassword">
                                            {configJSON.placeholderIsReTypePassword}<span style={useStyles.fontColor}>*</span>
                                        </InputLabel>

                                        <TextField
                                            type={this.state.confirmPasswordVisible ? 'text' : 'password'}
                                            fullWidth
                                            variant="outlined"
                                            name="confirmPassword"
                                            value={this.state.formData.confirmPassword}
                                            onChange={this.handleChangePassword}
                                            error={Boolean(this.state.passwordError)}
                                            helperText={this.state.passwordError}
                                            data-testId={"confirmpassword"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            onClick={this.toggleConfirmPasswordVisibility}
                                                        >
                                                            {this.state.confirmPasswordVisible ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>

                                    <Box style={useStyles.buttonContainerStyle1}>
                                        <Button data-testId={"navigateToLogin"} onClick={this.goToRequesterLogin} style={{ textTransform: 'initial' }} color="primary">{configJSON.cancel}</Button>
                                        <Button
                                            data-test-id={"btnEmailLogIn"}
                                            style={useStyles.buttonBackground}
                                            onClick={this.resetPasswordIfValid}
                                            data-testId={"submit_password"}
                                        >
                                            <span style={useStyles.buttonTextStyle}>{configJSON.submit}</span>
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    )}


                </Container>
            </>
        );
        //Customizable Area End
    }
}

// Customizable Area Start
export const useStyles = {
    containerStyle: {
        maxWidth: 1440,
        height: 1024,
    },

    logoWrapper: {
        position: 'absolute' as const,
        left: '120px',
        right: '1193px',
        bottom: '920px',
        top: '52px',
    },

    forgotPasswordWrapperEmailStepper: {
        left: "14px",
        right: "182px",
        bottom: "-26px",
        position: "absolute" as const
    },

    forgotPasswordWrapperEmail: {
        height: 440,
        flexShrink: 0,
        position: "relative" as const,
        top: "252px",
        left: "470px",
        bottom: "400px",
        padding: "20px"
    },

    boxDisplay: {
        display: "flex"
    },

    forgotPasswordEmail: {
        width: 500,
        borderRadius: "var(--1, 8px)",
        background: "var(--basic-white, #FFF)",
        boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    },

    boxPadding: {
        padding: "24px"
    },

    typographyMargin: {
        marginBottom: "18.5px"
    },

    boxMargin: {
        marginTop: "60px",
        marginBottom: "60px"
    },

    inputLableMargin: {
        marginBottom: "5px"
    },

    fontColor: {
        color: "red"
    },

    buttonContainerStyle: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 12,
        marginBottom: "20px",
        borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
        background: "var(--basic-white, #FFF)",
    },

    buttonContainerStyle1: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 12,
        marginBottom: "20px",
        borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
        background: "var(--basic-white, #FFF)",
        marginTop: '20px'
    },

    buttonBackground: {
        backgroundColor: '#8760A9',
        '&:hover': {
            backgroundColor: '#8760A9',
        }
    },

    retypePasswordBox: {
        marginTop: '20px'
    },

    buttonTextStyle: {
        color: 'white',
        textTransform: 'none' as const,
    },

    forgotPasswordWrapperOTP: {
        height: 440,
        flexShrink: 0,
        position: "relative" as const,
        top: "252px",
        left: "470px",
        bottom: "400px",
        padding: "20px"
    },

    labelMargin: {
        marginBottom: "5px",
        marginTop: "60px"
    },

    typographyMargin1: {
        marginBottom: "50px", marginTop: "10px"
    },

    footerMainWrapperForgot: {
        display: "flex",
        justifyContent: "space-between"
    },

    path2StyleForgot: {
        position: "absolute" as const, zIndex: 1, bottom: 0
    },
    path1StyleForgot: {
        position: "absolute" as const, zIndex: 1, bottom: 0, left: 0
    },
    path24StyleForgot: {
        position: "absolute" as const, zIndex: 1, bottom: 0, right: 0
    },
    path25StyleForgot: {
        position: "absolute" as const, zIndex: 1, bottom: 0, right: "45px"
    }
};
//Customizable Area End
