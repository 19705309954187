import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const steps = ['Enter Email', 'Verification Code', 'Set Password'];

export interface ForgotEmailResponse {
  message: string,
  token: string,
  error: string
}

export interface VerifyOTPResponse {
  errors: [
    {
      otp: string
    }
  ],
  message: string,
  token: string
}

export interface ResetPasswordResponse {
  message: string,
  errors: [
    string
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  email: string;
  forgotPasswordOTP: string;
  pinError: string;
  otpVerifiedSuccessfully: boolean;
  password: string;
  passwordVisible: boolean;
  confirmPasswordVisible: boolean;
  emailError: string;
  isValidEmail: boolean;
  formData: {
    password: string,
    confirmPassword: string
  },
  passwordError: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class RequesterForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordOTPCallId: string = "";
  verifyForgotPasswordOTPAPICallId: string = "";
  resendForgotPasswordApiCallId: string = "";
  resetPasswordAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      activeStep: 0,
      email: "",
      forgotPasswordOTP: "",
      pinError: "",
      otpVerifiedSuccessfully: false,
      password: "",
      passwordVisible: false,
      confirmPasswordVisible: false,
      emailError: "",
      isValidEmail: false,
      formData: {
        password: '',
        confirmPassword: ''
      },
      passwordError: ""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      switch (apiRequestCallId) {
        case this.forgotPasswordOTPCallId:
          this.handleForgotPasswordEmailResponse(apiResponse);
          break;
        case this.verifyForgotPasswordOTPAPICallId:
          this.handleVerifyOTPResponse(apiResponse);
          break;
        case this.resendForgotPasswordApiCallId:
          this.handleResendPasswordOTPResponse(apiResponse);
          break;
        case this.resetPasswordAPICallId:
          this.handleResetPasswordResponse(apiResponse);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Web Events

  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      passwordVisible: !prevState.passwordVisible
    }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({
      confirmPasswordVisible: !prevState.confirmPasswordVisible
    }));
  };

  validateEmail = (email: string): string => {
    if (!email.trim()) {
      return configJSON.emailIsRequired;
    } else if (!configJSON.emailRegEx.test(email)) {
      return configJSON.invalidEmailAddress;
    }
    return "";
  };

  validatePassword = (password: string) => {
    if (!password) {
      this.setState({
        passwordError: configJSON.passrequired,
      });
      return false;
    }

    if (password.length < 8) {
      this.setState({
        passwordError: configJSON.passlength,
      });
      return false;
    }

    this.setState({
      passwordError: '',
    });
    return true;
  };

  handleForgetEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    const emailError = this.validateEmail(email);
    this.setState({ email, emailError });
  }

  handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const otp = event.target.value;
    if (/^\d*$/.test(otp)) {
      if (otp.length <= 4) {
        this.setState({ forgotPasswordOTP: otp });
      } else {
        toast.error(configJSON.lengthOfOTP);
      }
    } else {
      toast.error(configJSON.validOTP);
    }
  }

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { password } = this.state.formData;

    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      passwordError: name === 'confirmPassword' && password !== value ? configJSON.passMustMatch : '',
    }));
  };

  handleNextStep = (isValid: boolean) => {
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  resetPasswordIfValid = async () => {
    const { password, confirmPassword } = this.state.formData;
    if (password !== confirmPassword) {
      toast.error(configJSON.passMustMatch);
      return;
    }
    await this.resetPassword();
  };

  goToRequesterLogin = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RequesterLogin");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)

  }

  // API Integration
  fogotPasswordOTP = () => {
    const { email } = this.state;
    const emailError = this.validateEmail(email);
    if (emailError) {
      this.setState({ emailError });
      return;
    }

    const header = {
      'Content-Type': "application/json",
    };
    const attrs = {
      email: this.state.email,
    };

    const forgotPasswodOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const fogotPasswordOTPHttpBody = {
      data: forgotPasswodOTPData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requesterEmailAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(fogotPasswordOTPHttpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyFogotPasswordOTP = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const attrs = {
      email: this.state.email,
      otp: this.state.forgotPasswordOTP,
    };

    const verifyFogotPasswordOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const verifyForgotPasswordOTPHttpBody = {
      data: verifyFogotPasswordOTPData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyForgotPasswordOTPAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requesterVerifyOTPAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(verifyForgotPasswordOTPHttpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendFogotPasswordOTP = () => {
    const header = {
      "Content-Type": 'application/json',
    };
    const attrs = {
      email: this.state.email,
    };

    const resendForgotPassOTPData = {
      type: "email_account",
      attributes: attrs,
    };
    const fogotPasswordOTPHttpBody = {
      data: resendForgotPassOTPData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendForgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(fogotPasswordOTPHttpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resetPassword = async () => {
    const token = await getStorageData("forgetPasswordToken")
    const header = {
      "Content-Type": 'application/json',
      "token": token
    };

    const { password } = this.state.formData;
    const isPasswordValid = this.validatePassword(password);

    if (!isPasswordValid) {
      return;
    }

    const attrs = {
      create_new_password: password,
      repeat_new_password: password,
    };

    const resetPasswordData = {
      type: "email_account",
      attributes: attrs,
    };
    const resetPasswordHttpBody = {
      data: resetPasswordData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requesterResetPassAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(resetPasswordHttpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // API Response
  handleForgotPasswordEmailResponse = (responseJson: ForgotEmailResponse) => {
    if (responseJson.error) {
      toast.error(responseJson.error);
    } else {
      this.handleNextStep(true);
      toast.success(responseJson.message);
    }
  }

  handleVerifyOTPResponse = async (responseJson: VerifyOTPResponse) => {
    if (responseJson.errors) {
      toast.error(responseJson.errors[0].otp);
    } else {
      this.handleNextStep(true);
      toast.success(responseJson.message);
      await setStorageData('forgetPasswordToken', responseJson.token);
    }
  }

  handleResendPasswordOTPResponse = (responseJson: ForgotEmailResponse) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
    }
  }

  handleResetPasswordResponse = (responseJson: ResetPasswordResponse) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
      setTimeout(() => {
        window.location.href = "RequesterLogin"
      }, 2000);
    } else {
      toast.error(responseJson.errors[0]);
    }
  }
  // Customizable Area End
}