Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.textGeneral = 'General'
exports.textEdit = 'Edit'
exports.textGeneralHeadinf = 'General and roles'
exports.noGeneralDataMessage = 'There are no assigned groups and roles'
exports.textAddGroupText = 'Add group and roles'
exports.textConvertBTNN = "Convert to a requester"
exports.saveBTN = 'Save changes'
exports.cancelBTN = 'Cancel'
exports.submitBTN = 'Submit'
exports.textMember = 'Member'
exports.textChooseMember= 'Choose group to add as a member'
exports.textObserver = 'Observer'
exports.textChooseObserver= 'Choose group to add as a observer'
exports.textAddGroup = 'Add group'
exports.textAddRole= 'Add role'
exports.textRole = 'Role'
exports.textRoles = 'Roles'
exports.textChooseRole = 'Choose role'
exports.textCapacity = 'In what capacity'
exports.textGroupsAndRoles = 'Groups and roles'
exports.textMemberOf = 'Member of'
exports.textObserverOf = 'Observer of'
exports.textDetails = "Details"
exports.textGeneralTitle2 = 'GENERAL'
exports.textAdditioonalInformation = "ADDITIONAL INFORMATION"

exports.textEmail= 'Email'
exports.textTitle = 'Title'
exports.textWorkPhone = 'Work phone'
exports.textMobilePhone = "Mobile phone"
exports.textTimeZone = "Time zone"
exports.textLanguage = "Language"

exports.textAgentType = "Agent type"
exports.textReportingManager= "Reporting manager"
exports.textBGInfo = "Background infomation"
exports.textAddress = "Address"
exports.textDepartment = 'Department'
exports.textTimeFormat = 'Time format'
exports.textVipUser = "VIP user"
exports.textWorkSchedule = "Work schedule"
exports.textLocation = "Location"
exports.textSignature = "Signature"


exports.AgentUpdateMessage = 'User information has been updated'
exports.noAgentDataMessage = "There are no assigned groups and roles"
exports.inWhatCapsMessage = "In what capacity"


exports.apiEndPointGetAgentGroupList = "bx_block_department/groups?user_type=0";
exports.apiEndPointGetRoleList = "bx_block_roles_permissions/roles"
exports.apiEndPointAddGroupsRolesSlug = "bx_block_profile/agent_profiles/"
exports.apiEndPointAddGroupsRolesSlug2 = "/add_groups_roles"
exports.apiEndPointGetGroupsRoles = "bx_block_profile/agent_profiles/"
exports.apiEndPointGetAgentDetails = "bx_block_department/agent_and_requesters/"
exports.apiEndPointGetAgentDetailsSlug = "/fetch_particular_agent"
exports.requesterLoginApiEndPoint = 'bx_block_dashboard/requester_logins/requester_login'
exports.errorEmailNotValid = "Invalid email format";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.webLoginApiEndPoint="bx_block_login/logins"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.reqApiCallEndPoint = '/bx_block_dashboard/requester_logins/'
exports.reqApiType = 'GET'
exports.editProfileAPI = "/bx_block_dashboard/requester_logins/"
exports.editProfileType = "PATCH"
exports.deleteProfileAPI = "/bx_block_dashboard/requester_logins/";
exports.forgotpass = "Forgot Password ? ";
exports.resetpass = "Reset Password";
exports.login = "Requester Login";
exports.provideworkemail="Provide email address and password associated with your account.";
exports.enterEmail="Enter Email";
exports.enterPass="Enter Password";
exports.emailRequired="Email is required";
exports.passRequired="Password is required";
exports.passlength="Password must be at least 8 characters long";
exports.requesterLoggedIn="Requester Logged In Succesfully!";
exports.somethingWrong="Something went wrong!";
exports.emailRegEx =/^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i
// Customizable Area End

