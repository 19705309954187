import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { FormikHelpers } from 'formik';
import toast from 'react-hot-toast';
import { getStorageData } from "../../../framework/src/Utilities";
export interface AccountDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    company_name: string;
    email: string;
    password: string;
    time_zone: string;
    full_phone_number: string;
    agent: number;
    current_plan: string;
    created_at: string;
  }
}

export interface AccountDetailsUpdateData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    company_name: string;
    email: string;
    password: string;
    time_zone: string;
    full_phone_number: string;
    agent: number;
    current_plan: string;
  }
}

interface AccountDetailResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      company_name: string;
      email: string;
      password: string;
      time_zone: string;
      full_phone_number: string;
      agent: number;
      current_plan: string;
      created_at: string;
    }
  }
}
 
interface AccountDetailUpdateResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      full_name: string;
      company_name: string;
      email: string;
      password: string;
      time_zone: string;
      full_phone_number: string;
      agent: number;
      current_plan: string;
    }
  }
}

interface ErrorRes {
  errors:[
    {
      token: string
    }
  ]
}

export interface NavigationItem {
  title: string;
  list?: Array<NavigationItem>;  
  href?: string;  
}

interface FormikValues {
  full_name: string;
  full_phone_number: string;
  email: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  openListIndex: number;
  isLoading: boolean;
  accountDetails: AccountDetailsData;
  showDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getAccountDetailsApiCallId: string = "";
  updateAccountDetailsApiCallId: string = "";
  pathname = "/account-settings/account";
  navigationList: Array<NavigationItem> = [
    {
      title: "Account settings", list: [
        {
          title: "Account",
          href: "/account-settings/account"
        },
        {
          title: "Plans & Billing",
          href: "/account-settings/plans-billing"
        },
        {
          title: "Service Desk Rebranding",
          href: "/account-settings/service-desk-rebranding"
        },
        {
          title: "Email Notifications",
          href: "/account-settings/email-notifications"
        },
      ]
    },
    {
      title: "User management",
      list: [
        {
          title: "Agents",
          href: "/user-management/agents"
        },
        {
          title: "Roles",
          href: "/user-management/roles"
        },
        {
          title: "Departments",
          href: "/user-management/departments"
        },
        {
          title: "Department Fields",
          href: "/user-management/department-fields"
        },
        {
          title: "Requesters",
          href: "/user-management/requesters"
        },
        {
          title: "User Fields",
          href: "/user-management/user-fields"
        },
        {
          title: "CAB",
          href: "/user-management/cab"
        },
        {
          title: "Agent groups",
          href: "/user-management/agent-groups"
        },
        {
          title: "Requester groups",
          href: "/user-management/requester-groups"
        },
      ],
    },
    { title: "Channels", list: [] },
    { title: "Service management", list: [] },
    { title: "Automation and productivity", list: [] },
    { title: "Asset management", list: [] },
    { title: "IT operations management", list: [] },
    { title: "Project and workload management", list: [] },
  ];
  accessToken: string = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      openListIndex: -1,
      isLoading: false,
      accountDetails: {
        id: "",
        type: "",
        attributes: {
          full_name: "",
          company_name: "",
          email: "",
          password: "",
          time_zone: "",
          full_phone_number: "",
          agent: 0,
          current_plan: "",
          created_at: ""
        }
      },
      showDialog: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.setState({ isLoading: false });

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data
          });
        }
      }

      if(this.inValidResponse(responseJson)){
        this.responseFailureCallBack(apiRequestCallId,responseJson);
      }
      else if(this.isValidResponse(responseJson)){
        this.responseSuccessCallBack(apiRequestCallId,responseJson.data)
      }   
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.accessToken = await getStorageData("authToken");
    this.getAccountDetails();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start
  getValidationSchema = () => {
    return Yup.object().shape({
      full_name: Yup.string().trim().required("Full Name is required"),
      email: Yup
        .string()
        .required("Email is required")
        .trim()
        .email("Invalid email address"),
      full_phone_number: Yup.string()
        .required('Phone number is required')
        .min(10, 'Must be at least 10 digits')
        .max(15, 'Must not exceed 15 digits'),
    })
  };

  openAccordian = (index: number) => {
    this.setState((prevState) => ({
      openListIndex: prevState.openListIndex === index ? -1 : index,
    }));
  };

  apiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.accessToken
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    body &&  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body));
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAccountDetails = async () => {
    this.setState({ isLoading: false });
    this.getAccountDetailsApiCallId = await this.apiCall({
      contentType: configJSON.accountDetailApiContentType,
      method: configJSON.getAccountDetailApiMethod,
      endPoint: configJSON.getAccountDetailApiEndpoint,
    });
  };
  
  updateAccountDetails = async (value: FormikValues) => {
    this.setState({ isLoading: false });

    const data = {
      account: {
        ...value,
      }
    };
    this.updateAccountDetailsApiCallId = await this.apiCall({
      contentType: configJSON.accountDetailApiContentType,
      method: configJSON.updateAccountDetailApiMethod,
      endPoint: `${configJSON.updateAccountDetailApiEndPoint}/${this.state.accountDetails.id}`,
      body: data,
    });
  };

  handleDialogOpen = () => {
    this.setState({ showDialog: true });
  };

  handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  handleAccountDetails = (responseJson: AccountDetailsData) => {
    this.setState({ accountDetails: responseJson });
  }

  handleText = (text: string) => {
    return text ? text : "-";
  }

  isValidResponse = (responseJson: AccountDetailResponse & AccountDetailUpdateResponse ) => {
    return responseJson && responseJson.data;
  }

  inValidResponse = (responseJson: ErrorRes ) => {
    return responseJson && responseJson.errors;
  }

  responseSuccessCallBack = async (apiRequestCallId: string, responseJson:AccountDetailsData & AccountDetailsUpdateData) => {
    if(apiRequestCallId === this.getAccountDetailsApiCallId ) {
        this.handleAccountDetails(responseJson);
    }

    if(apiRequestCallId === this.updateAccountDetailsApiCallId) {
        toast.success(configJSON.detailsUpdateMessage)
        this.setState({ showDialog:false });
        this.getAccountDetails();
    }
  }

  responseFailureCallBack = async (apiRequestCallId: string, responseJson: ErrorRes & string) => {
    if(apiRequestCallId === this.getAccountDetailsApiCallId ) {
        this.parseApiErrorResponse(responseJson)
    }

    if(apiRequestCallId === this.updateAccountDetailsApiCallId) {
        this.setState({ showDialog: false });
        this.parseApiCatchErrorResponse(responseJson)
    }
  }

  handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue'],
    inputFieldName: string,
    isNameField: boolean
  ) => {
    let inputValue = isNameField
      ? e.target.value.replace(new RegExp(`${configJSON.alphaRegex}`, 'g'), "")
      : e.target.value.replace(/\D/g, "");

    setFieldValue(inputFieldName, inputValue);
  };
  
  // Customizable Area End
}
