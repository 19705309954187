// Customizable Area Start
import React,{useState,useEffect} from "react";
import {
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";
import { Box, styled,List,Collapse,ListItem,ListItemText } from "@material-ui/core";
// Customizable Area End

type ListItems = {
    title: string,
    href: string
  }

type Item = {
    title:string,
    list:ListItems[]
}

// Customizable Area Start
interface Props {
  navigationList:Item[],
    pathName:string,
    handleNavigation:(path:string)=>void
}
// Customizable Area End

// Customizable Area Start
interface S { }

interface SS { }

function RightSidebar(props:Props){

    const [openListIndex,setOpenListIndex] = useState<number | null>()

    const openAccordian = (index: number) => {
        setOpenListIndex(openListIndex === index ? -1 : index)
        // this.setState((prevState) => ({
        //   openListIndex: prevState.openListIndex === index ? -1 : index,
        // }));
      };

      useEffect(()=>{
        props.navigationList.map((item:Item,index:number)=>{
            item.list.map((innerItem:ListItems)=>{
                innerItem.href == props.pathName && setOpenListIndex(index)
            })
        })
      },[])

    return <Box 
    style={styles.accordians}
    >
    <List component="nav">
      {props.navigationList.map((item, index: number) => {
        if (item.list.length) {
          return (
            <React.Fragment key={`fragment-${item.title}-${index}`}>
              <ListItem
                button key={`key-${item.title}-${index}`} 
                style={styles.navigation_item}
                onClick={() =>
                  openAccordian(index)
                }
                data-test-id='openAccordian'
              >
                <ListItemText disableTypography={true} primary={item.title}
                  style={
                    styles.navigation_link
                  }


                />
              </ListItem>
              <Collapse timeout="auto" unmountOnExit
                in={openListIndex === index}
              >
                {item.list.map((link, index: number) => (
                  <List key={`key-${link.title}-${index}`} disablePadding >
                    <ListItem
                      style={{
                        ...styles.navigation_item_nested,
                        ...(props.pathName ===
                          link.href &&
                          styles.navigation_item_active),
                      }}
                      disabled={!Boolean(link.href)}
                    //   href={link.href}
                      onClick={()=>{
                        if(link.href){
                            props.handleNavigation(link.href)

                        }
                    }}
                      component="a" button
                    >
                      <ListItemText
                        style={{
                          ...styles.navigation_link,
                          ...(props.pathName ===
                            link.href &&
                            styles.navigation_link_active),
                        }}
                        disableTypography={true}
                        primary={link.title}
                      />
                    </ListItem>
                  </List>
                ))}
              </Collapse>
            </React.Fragment>
          );
        }

        return (
          <ListItem
            style={styles.navigation_item}
            key={item.title}
            button
          >
            <ListItemText
              style={styles.navigation_link}
              primary={item.title}
              disableTypography={true}
            />
          </ListItem>
        );
      })}
    </List>
  </Box>
}
// Customizable Area End

// Customizable Area Start
const Container = styled(Box)({

})

const styles = {
    container: {
      paddingBottom: "25px",
      height: "100%",
      margin: "15px 15px 15px 300px",
      fontFamily: "Manrope",
    } as const,
    heading: {
      fontSize: "30px",
      margin: "10px 0 0",
      lineHeight: "40px",
      fontWeight: "bold",
      color: "#33354D",
    } as const,
    arrow: {
      display: "flex",
      alignItems: "center",
      height: "28px",
      justifyContent: "center",
      borderRadius: "100px",
      border: "1px solid #E6DEED",
      width: "28px",
      cursor: "pointer",
      backgroundColor: "white",
    } as const,
    arrow_icon: {
      width: "20px",
      height: "20px",
      color: "#33354D",
    } as const,
  
  
    table_heading: {
      color: "#33354D",
      fontStyle: "normal",
      fontFamily: 'Manrope',
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "normal",
      marginBottom: '30px'
    } as const,
    card_heading: {
      color: "#33354D",
      fontFamily: 'Manrope',
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    } as const,
  
    export_button: {
      fontFamily: "Manrope",
      fontWeight: "bolder",
      fontSize: "16px",
      lineHeight: "16px",
      color: "#8760A9",
      letterSpacing: "0em",
      textTransform: "capitalize",
    } as const,
  
    department_count: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "19px",
    } as const,
    departmentsList: {
      borderRadius: "8px",
      flex: "1",
      alignSelf: "flex-start",
      padding: "16px",
      boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)'
    } as const,
  
    department: {
      display: "flex",
      alignItems: "center",
      gap: "26px",
      borderRadius: "8px",
      border: "1px solid #E6DEED",
      padding: "17px 12px",
      height: "58px",
    } as const,
  
    field: {
      width:'40%',
      "& p":{
        color:'red'
      }
    } as const,
    discriptionField: {
      marginTop:'24px'
    } as const,
    inputField: {
      width:'100%',
      borderRadius:'8px !important',
      color:"#A3A6C2"
    } as const,
    department_label: {
      fontFamily: "Manrope",
      fontSize: "14px",
      color: "#3D405C",
      margin:0,
      marginBottom:"4px"
    } as const,
    department_start: {
      color: "#F22A2A",
      marginLeft:'4px'
    } as const,
  
    create_button: {
      padding: "10px 16px",
      backgroundColor: "#E6DEED",
      color: "#33354D",
      fontFamily: "Manrope",
      fontWeight: "bolder",
      fontSize: "16px",
      letterSpacing: "0em",
      lineHeight: "16px",
      textTransform: "initial",
      borderRadius: "4px",
      cursor:'pointer'
    } as const,
  
    add_button: {
      padding: "10px 16px",
      backgroundColor: "#8760A9",
      fontSize: "16px",
      color: "#FFF",
      fontWeight: 600,
      fontFamily: "Manrope",
      lineHeight: "16px",
      letterSpacing: "0em",
      borderRadius: "4px",
      textTransform: "initial",
      height:'48px',
      cursor:'pointer'
    } as const,
  
    cancel_button: {
      padding: "10px 16px",
      fontSize: "16px",
      fontFamily: "Manrope",
      backgroundColor: "transparent",
      fontWeight: 600,
      color: "#8760A9",
      lineHeight: "16px",
      letterSpacing: "0em",
      borderRadius: "4px",
      textTransform: "initial",
      cursor:'pointer'
    } as const,
    accordians: {
      boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
      flexBasis: "30%",
      borderRadius: "8px",
      padding: "4px",
      maxWidth:'300px'
    } as const,
    navigation: {
      fontFamily: "Manrope",
      fontSize: "14px",
      lineHeight: "19px",
    } as const,
  
    search_icon: {
      height: "24px",
      width: "24px",
      position: "absolute",
      color: "#33354D",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
    } as const,
    input: {
      fontFamily: "Manrope",
      width: "100%",
      height: "40px",
      border: "1px solid #e6deed",
      padding: "10px 10px 10px 40px",
      fontSize: "16px",
      borderRadius: "10px",
    } as const,
    navigation_item: {
      borderRadius: "4px",
      padding: "9px, 20px, 9px, 16px",
    } as const,
  
    navigation_item_nested: {
      borderRadius: "4px",
      padding: "9px 20px 9px 30px",
    } as const,
  
    navigation_item_active: {
      color: "white",
      backgroundColor: "#8760A9",
    } as const,
  
    navigation_link: {
      fontFamily: "Manrope",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#33354D",
      lineHeight: "22px",
      letterSpacing: "0em",
    } as const,
    navigation_link_active: {
      color: "white",
    } as const,
    form_main_box: {
      padding: '1rem',
    } as const,
    cardHead: {
      alignItems: "center",
      display: "flex",
      gap: "5px",
    },
    department_main_box: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    } as const,
    modal_button_main_box: {
      // gap: '1rem',
      float: 'right',
      display: 'flex',
    } as const,
    truncatedText: {
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color:"#33354D"
      // width:'50%'
    } as const,
    truncatedTextHead: {
      maxWidth: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width:'50%',
      fontFamily:"Manrope",
      fontWeight:700,
      fontSize:"16px",
      lineHeight:"24px"
    } as const,
  
    cancel_icon: { 
      height: "30px",
      width: "30px"
     },
  
     input_label: {
      marginBottom: "5px",
     },
  
     department_span: {
      color: "red"
     },
  
     infoIconsStyle: {
      cursor: "pointer",
      color: "#8760A9",
      marginLeft: "10px"
     }
  
  };
// Customizable Area End
export default RightSidebar;