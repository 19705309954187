import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Button,
    Input,
    Typography,
    Dialog,
    Box,
    Card,
    styled,
    FormControl,
    Select,
    MenuItem,
    Grid,
    TextField,
    InputLabel,
} from "@material-ui/core";
import {
    MoreVert,
    Search,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    AddCircleOutline,
    RemoveCircleOutline,
    ExpandMoreOutlined,
    Close
} from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader.web";
import { cancelIcon, successIcon } from "./assets";
import CabDetailsController, { Props, configJSON } from "./CabDetailsController";
import toast, { ToastBar, Toaster } from "react-hot-toast";

// Customizable Area End


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const MainCard = styled(Card)({
    marginBottom: '8px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    cursor: 'pointer'
})

const CardTitle = styled(Typography)({
    width: '100%',
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px'
})
const CardTextBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
})

const CardParagraph = styled(Typography)({
    color: "#A3A6C2",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontstyle: 'normal',
    fontweight: 600,
    lineheight: 'normal',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
})

const Scrollable = styled(Box)({
    marginBottom: '32px',
    "&::-webkit-scrollbar": {
        width: '10px',
        scrollPadding: '10px'
    },
    "&::-webkit-scrollbar-thumb": {
        background: 'gray',
    }
})

const NoMembersMessage = styled(Typography)({
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontstyle: 'normal',
    lineheight: 'normal',
    textAlign: 'center',
    'fontWeight' : 'bold'
})

const RightSideBar = styled(Box)({
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    flexBasis: "30%",
    padding: "16px 16px 4rem 16px",
    borderRadius: "8px",
    position: 'relative'
})

// Customizable Area End

export default class CabDetails extends CabDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    returnFormData = (value: boolean) => {
        if (value) {
            return (
                <Formik
                    initialValues={this.state.initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form translate={undefined} style={webStyles.form_main_Box}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InputLabel style={webStyles.input_label} htmlFor="name">
                                        {configJSON.cabNameLabel}<span style={webStyles.cab_span}>*</span>
                                    </InputLabel>
                                    <Field
                                        type="text"
                                        name="name"
                                        id="name"
                                        as={TextField}
                                        fullWidth
                                        variant="outlined"
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        placeholder={configJSON.cabNamePlaceholder}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel style={webStyles.input_label} htmlFor="description">
                                        {configJSON.descriptionLabel}
                                    </InputLabel>
                                    <Field
                                        name='description'
                                        as={TextField}
                                        multiline
                                        variant="outlined"
                                        fullWidth
                                        id="description"
                                        minRows={6}
                                        error={touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        placeholde={configJSON.cabDescriptionPlaceholder}
                                    />
                                </Grid>
                            </Grid>
                            <Box mt={'30px'} style={webStyles.modal_button_main_Box}>
                                <Button type="reset" style={webStyles.cancel_button} >{configJSON.textCancel}</Button>
                                <Button type="submit" style={webStyles.add_button} >{this.returnSubmitBtnText()}</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            )
        } else {
            return (
                <>
                    <Typography>{configJSON.textMember}</Typography>
                    <Box>
                        <Box mb={'30px'}>
                            {this.state.selectedMembers?.map((selectedMember, index: number) => (
                                <Box key={index} style={{ display: 'flex', alignItems: 'center', margin: 8 }}>
                                    <FormControl fullWidth variant="outlined" >
                                        <Select
                                            value={selectedMember.id || ''}
                                            onChange={(event: React.ChangeEvent<any>) => this.handleSelectMember(index, event.target.value)}
                                            displayEmpty
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            IconComponent={ExpandMoreOutlined}
                                            style={{ color: this.returnMenuColor(selectedMember.id || '') }}
                                        >
                                            <MenuItem style={webStyles.menuItemStyle} value="" disabled>
                                                {configJSON.textSelectMember}
                                            </MenuItem>
                                            {this.state.agents.map((member) => (
                                                <MenuItem style={{ color: '#' }} key={member.id} value={member.id} disabled={this.isMemberSelected(member.id)}>
                                                    {member.attributes.full_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <RemoveCircleOutline
                                        style={webStyles.removeIconStyle}
                                        onClick={() => this.handleDeleteMember(index)}
                                        data-test-id='remove_delete'
                                    />
                                </Box>))}
                            {this.state.selectedMembers.length < this.state.agents.length &&
                                <Box style={webStyles.flexEnd} m={'0 5px'}>
                                    <Button data-test-id='add_member' onClick={() => this.handleAddMember()} style={{ ...webStyles.addIconStyle, textTransform: 'inherit' }} endIcon={<AddCircleOutline />}>{configJSON.textAddMember}</Button>
                                </Box>}
                        </Box>
                        <Box style={webStyles.modal_button_main_Box}>
                            <Button type="reset" style={webStyles.cancel_button} onClick={()=>this.handleModalOpen(false)}>{configJSON.textCancel}</Button>
                            <Button type="submit" style={webStyles.add_button} onClick={() => this.handleSave()}>{configJSON.textSaveChanges}</Button>
                        </Box>
                    </Box>
                </>
            )
        }
    }

    returnNoMembersText = () => {
        if (this.state.cabData?.attributes?.agents?.length === 0) {
            return <NoMembersMessage>{configJSON.textNoMember}</NoMembersMessage>
        } else {
            return null
        }
    }

    renderToaster = () => {
        return (
            <Toaster >
                {(t) => (
                    <ToastBar style={{
                        width: '450px',
                        maxWidth: '450px'
                    }} toast={t}>
                        {({message}) => (
                            <Box style={webStyles.toaster_wrapper}>
                                <Box style={webStyles.toaster_container}>
                                    <Box style={webStyles.toaster_box}>
                                        <span style={webStyles.toastIcon}>
                                            <img src={successIcon}/>
                                        </span>
                                        <p style={{...webStyles.toaster_msg, fontWeight: 'bolder'}}>
                                            {message}
                                        </p>
                                    </Box>
                                </Box>
                                <Box style={webStyles.toaster_box}>
                                    {t.type !== "loading" && (
                                        <>
                                            <span style={webStyles.toastCancelIcon} onClick={() => toast.dismiss(t.id)}>
                                                <Close />
                                            </span>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                <Loader loading={this.state.isLoading} />
                {this.renderToaster()}

                <Box style={webStyles.container}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={webStyles.navigation}>
                            Settings {">"} User management {">"} Change Advisory Board (CAB) {">"} {this.handleText(this.state.cabData.attributes.name)}
                        </span>
                        <Box style={{ borderRadius: '5px', background: '#e6deed', }}>
                            <span style={{ width: '36px', height: '36px' }}><MoreVert style={webStyles.moreIconStyle} /></span>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            marginTop: "60px",
                            marginBottom: "50px",
                        }}
                    >
                        <Box style={{ display: "flex", gap: "5px" }}>
                            <button style={webStyles.arrow}>
                                <KeyboardArrowLeft style={webStyles.arrow_icon} />
                            </button>
                            <button style={webStyles.arrow}>
                                <KeyboardArrowRight style={webStyles.arrow_icon} />
                            </button>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <h1 style={webStyles.heading} >{this.handleText(this.state.cabData.attributes.name)}</h1>
                        </Box>
                    </Box>

                    <Box style={{ position: "relative", marginBottom: "32px" }}>
                        <Search style={webStyles.search_icon} />
                        <Input
                            disableUnderline={true}
                            onChange={(e) => this.setInputValue(e.target.value)}
                            style={webStyles.input}
                            placeholder={"Search..."}
                        />
                    </Box>
                    <Box style={{ display: "flex", gap: "20px" }}>
                        <Box style={webStyles.cabList}>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "37px",
                                }}
                            >
                                <Typography style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                }}>
                                    <span style={webStyles.card_heading}>
                                        {configJSON.textMembers}
                                    </span>
                                </Typography>
                            </Box>
                            <Scrollable>
                                {this.state.cabData?.attributes?.agents?.length > 0 && (
                                    this.state.cabData?.attributes?.agents?.map((agent, index: number) => (
                                        <MainCard key={`key-${agent.id}-${index}`} variant="outlined">
                                            <img style={webStyles.users_iamge} src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
                                            <CardTextBox>
                                                <CardTitle >{this.handleText(agent?.full_name)}</CardTitle>
                                                <CardParagraph>{this.handleText(agent?.email)}</CardParagraph>
                                            </CardTextBox>
                                        </MainCard>
                                    ))
                                )
                                }
                                {this.returnNoMembersText()}
                            </Scrollable>
                            <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "42px" }}>
                                <Button style={webStyles.create_button} onClick={() => this.handleModalOpen(true)}>{configJSON.textAddMembers}</Button>
                            </Box>
                        </Box>
                        <RightSideBar>
                            <Box>
                                <Typography style={webStyles.right_nav_title}>{configJSON.textDetails}</Typography>
                                <Box border={'2px solid #E6DEED'} borderRadius={'8px'} p={'12px'}>
                                    <Typography style={webStyles.right_nav_sub_title}>{configJSON.textDescription}</Typography>
                                    <Typography style={webStyles.right_nav_description}>
                                        {this.handleText(this.state.cabData?.attributes?.description)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Button style={webStyles.edit_details_button} onClick={() => this.handleEditDetailsClick()} data-test-id="editDetails">{configJSON.textEditDetails}</Button>
                        </RightSideBar>
                    </Box>
                </Box>
                <Dialog
                    open={this.state.showModal}
                    onClose={() => this.handleModalOpen(false)}
                    maxWidth='sm'
                    fullWidth
                    data-test-id="close-dialog"
                >
                    <Box p={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Typography style={webStyles.table_heading}>{this.returnModalTitle()}</Typography>
                            <span style={webStyles.modal_cancel_icon} data-test-id='cancelIcon' onClick={() => this.handleModalOpen(false)}>
                                <img src={cancelIcon} alt="Cancel..." />
                            </span>
                        </Box>
                        <Box>
                            {this.returnFormData(this.state.isEditMode)}
                        </Box>
                    </Box>
                </Dialog >
            </ThemeProvider >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    container: {
        height: "100%",
        margin: "15px 15px 15px 300px",
        paddingBottom: "25px",
        fontFamily: "Manrope",
    } as const,
    heading: {
        color: "#33354D",
        fontSize: "30px",
        marginTop: "10px",
        lineHeight: "40px",
        fontWeight: "bold",
        marginBottom: '0'
    } as const,
    arrow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "28px",
        borderRadius: "100px",
        height: "28px",
        border: "1px solid #E6DEED",
        cursor: "pointer",
        backgroundColor: "white",
    } as const,
    arrow_icon: {
        width: "20px",
        color: "#33354D",
        height: "20px",
    } as const,
    moreIconStyle: {
        width: '24px',
        height: '24px',
        color: 'black',
        padding: '5px'
    } as const,
    table_heading: {
        color: "#33354D",
        fontSize: "20px",
        fontFamily: 'Manrope',
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "normal",
        marginBottom: '30px'
    } as const,
    card_heading: {
        color: "#33354D",
        fontSize: "20px",
        fontFamily: 'Manrope',
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "normal",
        marginTop: '0'
    } as const,
    cabList: {
        flex: "1",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        alignSelf: "flex-start",
    } as const,
    cancel_button: {
        backgroundColor: "transparent",
        padding: "10px 16px",
        fontFamily: "Manrope",
        color: "#8760A9",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "4px",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "initial",
    } as const,
    add_button: {
        padding: "10px 16px",
        color: "#FFF",
        backgroundColor: "#8760A9",
        fontSize: "16px",
        fontFamily: "Manrope",
        letterSpacing: "0em",
        lineHeight: "16px",
        fontWeight: 600,
        borderRadius: "4px",
        textTransform: "initial",
    } as const,
    navigation: {
        fontFamily: "Manrope",
        fontSize: "14px",
        lineHeight: "19px",
    } as const,
    input: {
        fontFamily: "Manrope",
        width: "100%",
        border: "1px solid #e6deed",
        height: "40px",
        padding: "10px 10px 10px 40px",
        fontSize: "16px",
        borderRadius: "10px",
    } as const,
    create_button: {
        padding: "10px 16px",
        color: "#33354D",
        backgroundColor: "#E6DEED",
        fontFamily: "Manrope",
        fontWeight: "bolder",
        lineHeight: "16px",
        fontSize: "16px",
        letterSpacing: "0em",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    search_icon: {
        width: "24px",
        color: "#33354D",
        height: "24px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: "10px",
    } as const,
    modal_button_main_Box: {
        gap: '1rem',
        display: 'flex',
        float: 'right',
    } as const,
    right_nav_title: {
        color: "#33354D",
        fontSize: "20px",
        fontweight: 700,
        lineheight: 'normal',
    } as const,
    right_nav_sub_title: {
        color: "#33354D",
        fontSize: "18px",
        fontweight: 600,
    } as const,
    right_nav_description: {
        color: "#A3A6C2",
        fontFamily: "Manrope",
        fontSize: "14px",
        fontstyle: 'normal',
        fontweight: 600,
        lineheight: 'normal',
        marginTop: '15px'
    } as const,
    users_iamge: {
        height: '32px',
        width: '32px',
        borderRadius: '50%'
    } as const,
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    } as const,
    addIconStyle: {
        cursor: 'pointer',
        color: '#8760A9',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        marginTop: '7px'
    } as const,
    edit_details_button: {
        padding: "10px 16px",
        color: "#33354D",
        backgroundColor: "#E6DEED",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
        marginTop: '1rem',
        position: 'absolute',
        bottom: '15px',
        right: '15px',
    } as const,
    form_main_Box: {
        padding: '1rem',
    } as const,
    input_label: {
        marginBottom: "5px",
    },
    cab_span: {
        color: "red"
    } as const,
    menuItemStyle: {
        color: '#A3A6C2',
        fontSize: '16px',
        fontWeight: 400,
        lineasHeight: '140%'
    } as const,
    removeIconStyle: {
        marginLeft: '22px',
        color: '#8760A9',
        cursor: 'pointer'
    } as const,
    modal_cancel_icon: {
        height: '30px',
        width: '30px',
        cursor: 'pointer'
    } as const,

    toaster_wrapper: {
        display: "inline-flex",
        padding: "8px 8px 8px 8px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "900px",
        height: "44px"
    },

    toaster_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%"
    } as const,

    toaster_box: {
        display: "flex",
        alignItems: "center"
    } as const,

    toaster_msg: {
        marginLeft: "10px",
        fontSize: "12px",
        fontFamily: 'Manrope',
        lineHeight: 'normal',
        color: '#33354D'
    } as const,
    toastIcon: {
        height: '26px',
        width: '26px',
    }as const,
    toastCancelIcon: {
        cursor: "pointer",
    } as const
};
// Customizable Area End

