import React from "react";
// Customizable Area Start
import {
  Button,
  Input,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Card,
  styled,
  Dialog,
  Grid,
  TextField,
  MenuItem,
  Select,
  Chip,
  OutlinedInput
} from "@material-ui/core";
import {
  MoreVert,
  Search,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InfoOutlined,
  ExpandMoreRounded,
} from "@material-ui/icons";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { cancelIcon } from '../../settings2/src/assets';
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import Loader from "../../../components/src/Loader";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AgentGroupsController, { NavigationItem, Props, configJSON } from "./AgentGroupsController.web";

const CustomChip = styled(Chip)({
  background: '#11A64A',
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0'
})
// Customizable Area End

export default class AgentGroup extends AgentGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  rednderSkipBreadcrumbs = () => {
    return (
      <Box style={styles.breadcrumb_wrapper}>
        <span style={styles.navigation}>
          {configJSON.settingLabel} {<KeyboardArrowRight />} {configJSON.userManagementLabel} {<KeyboardArrowRight />} {configJSON.agentGroupsLabel}
        </span>
        <Box style={styles.morevert_wrapper}>
          <span style={styles.morevert_span}>
            <MoreVert style={styles.morevert_icon} />
          </span>
        </Box>
      </Box>
    )
  }

  renderNextPrevButton = () => {
    return (
      <Box style={styles.arrow_wrapper}>
        <Box style={styles.label_heading}>
          <h1 style={styles.heading}>{configJSON.agentGroupsLabel}</h1>
          <InfoOutlined style={styles.info_color} />
        </Box>
      </Box>
    )
  }

  renderSearchBar = () => {
    return (
      <Box style={styles.search_wrapper}>
        <Search style={styles.search_icon} />
        <Input
          disableUnderline={true}
          value={this.state.searchGroup}
          onChange={this.handleSearchGroup}
          style={styles.input}
          placeholder={configJSON.searchPlacholder}
          data-testId={"search_group"}
        />
      </Box>
    )
  }

  renderAgentGroupLabelAndCount = () => {
    return (
      <Box style={styles.agentGroupListBox}>
        <h2 style={styles.agentGroupListHeading}>
          <span style={styles.card_heading}>
            {configJSON.agentGroupsLabel}
          </span>
          {this.state.AgentGroupsList.length !== 0 && (
            <span style={styles.group_count}>
              ({this.state.AgentGroupsList.length})
            </span>
          )}
        </h2>
      </Box>
    )
  }

  renderAgentGroupList = () => {
    return (
      <Scrollable style={this.state.AgentGroupsList.length > 5 ? styles.scrollable_wrapper : {}}>
        {this.state.AgentGroupsList.length === 0 ? (
          <Typography style={styles.typographyFont}>{configJSON.noDataFound}</Typography>
        ) : (
          this.state.AgentGroupsList.map((agentGroup, index: number) => (
            <MainCard key={`${agentGroup.id}-${index}`} variant="outlined" onClick={() => this.handleClickGroup(agentGroup.id)}>
              <Box>
                <CardTitle>{agentGroup.attributes.name || "-"}</CardTitle>
                <CardParagraph>{agentGroup.attributes.description || "-"}</CardParagraph>
              </Box>
              <CustomChip
                label={`${agentGroup.attributes.agents?.length || 0} ${configJSON.textMembers2}`}
                size="medium"
                variant="outlined"
              />
            </MainCard>
          ))
        )}
      </Scrollable>
    )
  }

  renderAddNewAgentGroupButton = () => {
    return (
      <Box style={styles.addButtonWrapper}>
        <Button style={styles.create_button}
          onClick={this.handleCreateModalOpen}
          data-test-id="openModal">
          {configJSON.addNewAgentGroup}
        </Button>
      </Box>
    )
  }

  renderSettingList = (link: NavigationItem, index: number) => {
    return (
      <List key={`key-${link.settingName}-${index}`} disablePadding>
        <ListItem
          button
          style={{
            ...styles.navigation_item_nested,
            ...(this.pathname ===
              link.settingLink &&
              styles.navigation_item_active),
          }}
          href={link.settingLink}
          component="a"
        >
          <ListItemText
            disableTypography={true}
            primary={link.settingName}
            style={{
              ...styles.navigation_link,
              ...(this.pathname ===
                link.settingLink &&
                styles.active_link),
            }}
          />
        </ListItem></List>
    )
  };

  contextMenuSetting = () => {
    return (
      <Box style={styles.accordians}>
        <List component="nav">
          {this.navigationList.map((item, index: number) => {
            if (item.list && item.list.length > 0) {
              return (
                <>
                  <ListItem
                    button
                    data-test-id="openAccordian"
                    style={styles.navigation_item}
                    onClick={() =>
                      this.openAccordian(index)
                    }
                    key={`key-${item.settingName}-${index}`}
                  >
                    <ListItemText style={styles.navigation_link} primary={item.settingName} disableTypography={true} />
                  </ListItem>
                  <Collapse
                    timeout="auto" unmountOnExit in={this.state.openListIndex === index}>
                    {item.list.map((link, index: number) => (
                      this.renderSettingList(link, index)
                    ))}
                  </Collapse>
                </>
              );
            }
            return (
              <ListItem button key={item.settingName} style={styles.navigation_item}>
                <ListItemText style={styles.navigation_link} disableTypography={true} primary={item.settingName} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    )
  }

  renderCreateAgentGroupsModal = () => {
    return (
      <Dialog
        open={this.state.showCreateModal}
        onClose={this.handleCreateModalClose}
        fullWidth={true}
        maxWidth="lg"
        data-testId={"create-dialog"}
      >
        <Box p={"1rem"}>
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography style={styles.table_heading}>{configJSON.addGroupLabel}</Typography>
            <span style={styles.cancelIconSpan} onClick={this.handleCreateModalClose} data-test-id="cancelIcon">
              <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
            </span>
          </Box>
          <Typography style={styles.generalFontStyle}>{configJSON.generalLabel}</Typography>
          <Grid container spacing={4} style={{ marginTop: '1%' }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.groupNameLabel}<span style={styles.textColorRed}> *</span></Typography>
                <TextField
                  variant="outlined"
                  placeholder={configJSON.placeholderGroupName}
                  value={this.state.groupName}
                  onChange={this.handleChangeGroupName}
                  data-testId={"changeGroupName"}
                />
                {this.state.groupNameError && <Typography style={styles.textColorRed}>{this.state.groupNameError}</Typography>}
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '20px' }}>
                <Typography style={styles.inputLabel}>{configJSON.businessHourLabel}</Typography>
                <Select
                  input={<OutlinedInput />}
                  IconComponent={ExpandMoreRounded}
                  name={'template'}
                  style={styles.menuItemFont_1}
                  fullWidth
                  value={configJSON.selectBusinessHourLabel}
                >
                  <MenuItem disabled value={configJSON.selectBusinessHourLabel} style={styles.menuItemFont}>{configJSON.selectBusinessHourLabel}</MenuItem>
                  <MenuItem value={configJSON.defaultLabel} style={styles.menuItemFont}>{configJSON.defaultLabel}</MenuItem>
                </Select>
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '20px' }}>
                <Typography style={styles.inputLabel}>{configJSON.businessFunctionLable}<span style={styles.textColorRed}> *</span></Typography>
                <Autocomplete
                  fullWidth
                  value={this.state.selectedFunction}
                  onChange={this.handleChangeBuisnessFunction}
                  options={this.businessFunction}
                  getOptionLabel={(option) => option}
                  data-testId={"buisness_function"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder={configJSON.selectBusinessFunctionLabel}
                    />
                  )}
                />
                {this.state.buisnessFunctionError && <Typography data-testId={"function_error"} style={styles.textColorRed}>{this.state.buisnessFunctionError}</Typography>}
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '25px' }}>
                <Typography style={{ ...styles.generalFontStyle }}>{configJSON.groupAutomation}</Typography>
                <Typography style={{ ...styles.inputLabel, marginTop: '20px' }}>{configJSON.automationTimeLabel}</Typography>
                <Autocomplete
                  fullWidth
                  value={this.state.selectedBuisnessHours}
                  onChange={this.handleChangeBuisnessHours}
                  options={this.escalationTime}
                  getOptionLabel={(option) => option}
                  data-testId={"change_hours"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Box>

            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box style={styles.boxStyle_4}>
                <Typography style={styles.inputLabel}>{configJSON.textDescription}</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  minRows={11}
                  multiline
                  data-testId={"description"}
                  value={this.state.description}
                  onChange={this.handleChangeDescription}
                />
              </Box>

              <Box style={{ ...styles.boxStyle_4, marginTop: '14%' }}>
                <Typography style={styles.inputLabel}>{configJSON.escalationEmailLabel}</Typography>
                <Autocomplete
                  fullWidth
                  options={this.state.agentsList}
                  getOptionLabel={(option) => option?.attributes?.full_name}
                  value={this.state.selectedAgentEmail}
                  onChange={this.handleChangeAgentsEmail}
                  data-testId={"change_agents"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder={configJSON.selectAgentLabel}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="flex-end">
              <Box style={styles.buttonBox}>
                <Button onClick={this.handleCreateModalClose} data-testId={'close_modal'} style={styles.cancelButton}>{configJSON.cancelBTN}</Button>
                <Button onClick={this.addAgentsToGroup} data-testId={"add_agents"} variant="contained" style={styles.submitButton}>{configJSON.addBtn}</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  }

  renderToaster = () => {
    return (
      <Toaster>
        {(t) => (
          <ToastBar style={{
            width: '450px',
            maxWidth: '450px'
          }} toast={t}>
            {({ icon, message }) => (
              <Box style={styles.toaster_wrapper}>
                <Box style={styles.toaster_container}>
                  <Box style={styles.toaster_box}>
                    <span style={styles.toastIcon}>{icon}</span>
                    <p style={styles.toaster_msg}>
                      {configJSON.toasterMessage}
                    </p>
                  </Box>
                  <p style={styles.toaster_group_name}>{message}</p>
                </Box>
                <Box style={styles.toaster_box}>
                  {t.type !== "loading" && (
                    <>
                      <span style={styles.toaster_span}></span>
                      <span style={styles.toastCancelIcon} onClick={() => toast.dismiss(t.id)}>
                        <img src={cancelIcon} alt={configJSON.cancelIconAltText} />
                      </span>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </ToastBar>
        )}
      </Toaster>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <React.Fragment>
        <NavigationSideBar active={"Settings"} navigation={this.props.navigation} />
        <Loader loading={this.state.isLoading} />
        <Box style={styles.container}>
          {this.rednderSkipBreadcrumbs()}
          {this.renderNextPrevButton()}
          {this.renderSearchBar()}
          <Box style={styles.agentGroupListWrapper}>
            <Box style={styles.agentGroupList}>
              {this.renderAgentGroupLabelAndCount()}
              {this.renderAgentGroupList()}
              {this.renderAddNewAgentGroupButton()}
            </Box>
            {this.contextMenuSetting()}
            {this.renderCreateAgentGroupsModal()}
          </Box>
        </Box>
        {this.renderToaster()}
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Scrollable = styled(Box)({
  marginBottom: "32px",
  "&::-webkit-scrollbar": {
    width: "10px",
    scrollPadding: "10px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "gray"
  }
});

const MainCard = styled(Card)({
  marginBottom: "8px",
  padding: "12px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginRight: "8px"
});

const CardTitle = styled(Typography)({
  width: "100%",
  color: "#33354D",
  fontFamily: "Manrope",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px"
});

const CardBody = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const CardParagraph = styled(Typography)({
  color: "#A3A6C2",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontstyle: "normal",
  fontweight: 600,
  lineheight: "normal",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: '400px'
});

const styles: Record<string, React.CSSProperties> = {
  container: {
    height: "100%",
    margin: "15px 15px 15px 300px",
    paddingBottom: "25px",
    fontFamily: "Manrope"
  },

  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px'
  },

  typographyFont: {
    color: "#33354D",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: 'center'
  },

  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },

  text_ButtonStyle: {
    fontSize: '16px',
    color: '#8760A9',
    fontWeight: 700,
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end' as const,
    alignItems: 'center',
    gap: '6px',
    cursor: "pointer"
  },
  inputLabel: {
    fontFamily: 'Manrope',
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500
  },

  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'initial' as const,
  },

  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 600,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 25px 5px 25px',
  },

  menuItemFont: {
    fontFamily: 'Manrope',
    color: '#3D405C',
    fontSize: '16px',
    fontWeight: 400
  },

  menuItemFont_1: {
    fontFamily: 'Manrope',
    color: '#A3A6C2',
    fontSize: '16px',
    fontWeight: 400
  },

  generalFontStyle: {
    fontFamily: 'Manrope',
    color: '#33354D',
    fontSize: '18px',
    fontWeight: 700
  },

  breadcrumb_wrapper: {
    display: "flex",
    justifyContent: "space-between"
  },

  morevert_wrapper: {
    background: "#e6deed",
    borderRadius: "5px"
  },

  morevert_span: {
    width: "36px",
    height: "36px"
  },

  textColorRed: {
    color: 'red'
  },

  morevert_icon: {
    width: "24px",
    height: "24px",
    color: "black",
    padding: "5px"
  },

  heading: {
    color: "#33354D",
    fontSize: "30px",
    margin: "10px 0 0",
    lineHeight: "40px",
    fontWeight: "bold"
  },

  table_heading: {
    color: "#33354D",
    fontStyle: "normal",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "normal",
    marginBottom: "30px"
  },

  arrow_wrapper: {
    marginTop: "60px",
    marginBottom: "50px"
  },

  arrow_btn_wrapper: {
    display: "flex",
    gap: "5px"
  },

  arrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    borderRadius: "100px",
    height: "28px",
    border: "1px solid #E6DEED",
    cursor: "pointer",
    backgroundColor: "white"
  },

  arrow_icon: {
    width: "20px",
    color: "#33354D",
    height: "20px"
  },

  label_heading: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  info_color: {
    color: "#8760A9"
  },

  search_wrapper: {
    position: "relative",
    marginBottom: "32px"
  },

  agentGroupListWrapper: {
    display: "flex",
    gap: "20px"
  },

  agentGroupListBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "37px"
  },

  agentGroupListHeading: {
    display: "flex",
    alignItems: "center",
    gap: "5px"
  },

  scrollable_wrapper: {
    overflowY: "scroll",
    maxHeight: "455px"
  },

  card_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "normal"
  },

  group_count: {
    lineHeight: "19px",
    fontWeight: "bold",
    fontSize: "14px"
  },

  agentGroupList: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",
    alignSelf: "flex-start"
  },

  create_button: {
    padding: "10px 16px",
    color: "#33354D",
    backgroundColor: "#E6DEED",
    fontFamily: "Manrope",
    fontWeight: "bolder",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "16px",
    textTransform: "initial",
    borderRadius: "4px"
  },

  accordians: {
    boxShadow: "0px 4px 8px 0px #8760A921",
    flexBasis: "30%",
    padding: "4px",
    borderRadius: "8px"
  },

  navigation: {
    fontFamily: "Manrope",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex"
  },

  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "10px 10px 10px 40px",
    fontSize: "16px",
    borderRadius: "10px"
  },

  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px"
  },

  navigation_item: {
    borderRadius: "4px",
    padding: "9px, 20px, 9px, 16px"
  },

  navigation_item_nested: {
    borderRadius: "4px",
    padding: "9px 20px 9px 30px"
  },

  navigation_item_active: {
    color: "white",
    backgroundColor: "#8760A9"
  },

  navigation_link: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Manrope",
    letterSpacing: "0em",
    lineHeight: "22px",
    color: "#33354D"
  },

  active_link: {
    color: "white"
  },

  addButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "42px"
  },

  modal_button_main_box: {
    gap: "1rem",
    display: "flex",
    float: "right",
  },

  cancel_button: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    lineHeight: "16px",
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial"
  },

  add_button: {
    padding: "10px 16px",
    color: "FFF",
    backgroundColor: "8760A9",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0em",
    borderRadius: "4px",
    lineHeight: "16px",
    textTransform: "initial"
  },

  common_grid_margin: {
    marginTop: "20px"
  },

  cancelIconSpan: {
    height: "30px",
    width: "30px",
    cursor: 'pointer'
  },

  input_label: {
    marginBottom: "5px"
  },

  span_color: {
    color: "red"
  },

  toaster_wrapper: {
    display: "inline-flex",
    padding: "8px 8px 8px 8px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "900px",
    height: "44px"
  },

  toaster_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },

  toaster_box: {
    display: "flex",
    alignItems: "center"
  },

  toaster_msg: {
    marginLeft: "10px",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: 'Manrope',
    lineHeight: 'normal',
    color: '#33354D'
  },
  toastIcon: {
    height: '26px',
    width: '26px',
  },
  toaster_group_name: {
    fontSize: "14px",
    color: '#8760A9',
    fontWeight: 700,
    fontStyle: 'normal',
    fontFamily: 'Manrope',
  },

  toaster_span: {
    borderRight: "1px solid #ccc",
    margin: "0px 8px",
    height: "28px",
    width: "2px",
  },

  warm_blue: {
    background: "#6366F1"
  },

  detail_box: {
    display: "flex",
    justifyContent: "space-between"
  },
  toastCancelIcon: {
    cursor: "pointer",
  }

};

// Customizable Area End