import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import toast from "react-hot-toast";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    email: string;
    enablePasswordFieldReq: boolean;
    checkedRememberMe: boolean;
    placeHolderEmail: string;
    placeHolderPassword: string;
    imgPasswordVisible: any;
    imgPasswordInVisible: any;
    labelHeader: string;
    btnTxtLogin: string;
    labelRememberMe: string;
    btnTxtSocialLogin: string;
    labelOr: string;
    webEmail: string;
    webPassword: string;
    enableConfirmPasswordField: boolean,
    loginSuccussesfullyFail: string;
    reqLoginStatus: boolean,
    handleLogoutStatus: boolean;
    emailError: string;
    passwordError: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RequesterLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    apiEmailLoginCallIdWeb: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            email: "",
            password: "",
            enablePasswordFieldReq: true,

            checkedRememberMe: false,
            placeHolderEmail: configJSON.placeHolderEmail,
            placeHolderPassword: configJSON.placeHolderPassword,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            imgPasswordInVisible: imgPasswordInVisible,
            handleLogoutStatus: false,
            labelHeader: configJSON.labelHeader,
            btnTxtLogin: configJSON.btnTxtLogin,
            labelRememberMe: configJSON.labelRememberMe,
            btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
            labelOr: configJSON.labelOr,
            webEmail: "",
            webPassword: "",
            reqLoginStatus: true,
            loginSuccussesfullyFail: "",
            enableConfirmPasswordField: false,
            emailError: '',
            passwordError: ''
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Web Event Handling

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        this.setState({ webEmail: email });
        if (!configJSON.emailRegEx.test(email)) {
            this.setState({ emailError: configJSON.errorEmailNotValid });
        } else {
            this.setState({ emailError: "" });
        }
        if (!email) {
            this.setState({ emailError: configJSON.emailRequired });
        } else {
            this.setState({ emailError: "" });
        }
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        this.setState({ webPassword: password });

        if (!password) {
            this.setState({ passwordError: configJSON.passRequired });
        } else if (password.length < 8) {
            this.setState({ passwordError: configJSON.passlength });
        } else {
            this.setState({ passwordError: "" });
        }
    };


    doWebEmailLoginReq = () => {
        const { webEmail, webPassword } = this.state;
        if (!webEmail) {
            this.setState({ emailError: configJSON.emailRequired });
        }

        if (!webPassword) {
            this.setState({ passwordError: configJSON.passRequired });
        }

        const header = {
            "Content-Type": configJSON.loginApiContentType,
        };
        const httpBody = {
            email: this.state.webEmail,
            password: this.state.webPassword,
        };
        const loginRequestMessageReq = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.apiEmailLoginCallIdWeb = loginRequestMessageReq.messageId;

        loginRequestMessageReq.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.requesterLoginApiEndPoint
        );

        loginRequestMessageReq.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        loginRequestMessageReq.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        loginRequestMessageReq.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );

        runEngine.sendMessage(loginRequestMessageReq.id, loginRequestMessageReq);
        return true
    }

    handlePasswordVisibilityToggle = () => {
        this.setState(prevState => ({
            enablePasswordFieldReq: !prevState.enablePasswordFieldReq
        }));
    };

    redirectResetPassowrd = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "RequesterForgotPassword");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    goToRequesterDashboard = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "RequesterDashboard");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiEmailLoginCallIdWeb) {
                if (responseJson.error) {
                    toast.error(responseJson.error)
                } else if (responseJson.meta.token) {
                    await setStorageData('RequesterToken', responseJson.meta.token);
                    await setStorageData('reqID', responseJson.meta.id)
                    toast.success(configJSON.requesterLoggedIn);
                    this.goToRequesterDashboard()
                } else {
                    toast.error(configJSON.somethingWrong)
                }
            }
        }
        // Customizable Area End
    }
}
