import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { FormikValues } from "formik";
import toast from 'react-hot-toast';
import { getStorageData } from "../../../framework/src/Utilities";
// Change intrefaces

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type?: string;
    agent_count?: number
    agent_details: Array<AgentDetail>;
  }
}

interface FormInitalValue {
  name: string;
  description: string;
  role_type: string;
}

interface ResponseJson {
  data: RolesListData[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface NavigationItem {
  title: string;
  list?: NavigationItem[];  
  href?: string;  
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  txtInputValue: string;
  RolesList: RolesListData[];
  page: number;
  rowsPerPage: number;
  openListIndex: number;
  showModal: boolean;
  isInfoModalOpen: boolean,
  initialValues: FormInitalValue
  anchorEl: any,
  open: boolean,
  showEditModal:boolean,
  showMemberList: boolean,
  editData: RolesListData,
  selectedRoleType: string,
  memberList: Array<AgentDetail>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllRolesCallID: string = '';
  roleSaveApiCallId: string = '';
  roleDeleteApiCallId: string = '';
  roleUpdateApiCallId: string = '';
  pathname = "/roles";
  navigationList: NavigationItem[] = [
    {  title: 'Account settings',list: []},
    {
      title: 'User management',
      list: [
        { 
          title: 'Agents', 
          href: '/agents'
        },
        { 
          title: 'Roles', 
          href: '/roles' 
        },
        { 
          title: 'Departments', 
          href: '/departments' 
        },
        {
          title: 'Department Fields',
          href: '/department-fields',
        },
        { 
          title: 'Requesters', 
          href: '/requesters' 
        },
        {
           title: 'User Fields', 
           href: '/userFields'
        },
        { 
          title: 'CAB', 
          href: '/cab' 
        },
        { 
          title: 'Agent groups', 
          href: '/agentGroup' 
        },
        {
          title: 'Requester groups',
          href: '/requester-groups',
        },
      ],
    },
    { title: 'Channels', list: [],},
    { title: 'Service management', list: [],},
    { title: 'Automation and productivity', list: []},
    { title: 'Asset management',list: []},
    { title: 'IT operations management',list: [],},
    { title: 'Project and workload management', list: [] },
  ];
  token: string  = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      page: 0,
      isLoading: false,
      txtInputValue: '',
      RolesList: [],
      rowsPerPage: 5,
      openListIndex: -1,
      showModal: false,
      isInfoModalOpen: false,
      initialValues: {
        name: "",
        description: "",
        role_type: ""
      },
      anchorEl: null,
      open: false,
      showEditModal: false,
      showMemberList: false,
      editData: {  
        id: "",
        type: "",
        attributes: {
          name: "",
          description: "",
          role_type: "",
          agent_count: 0,
          agent_details: []
        }
      },
      selectedRoleType: "admin_roles",
      memberList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    if (this.getAllRolesCallID === apiRequestId) {
      this.handleRolesApiDataResponse(responseJson)
    }

    if (this.roleSaveApiCallId === apiRequestId) {
      toast.success(configJSON.roleAddMessage);
      this.getAllRoles()
      this.setState({
        initialValues: { description: "", name: "", role_type:"" },
        showModal: false
      })
    }
    if(this.roleDeleteApiCallId === apiRequestId){
      if(responseJson && responseJson.data){
        toast.success(configJSON.roleRemoveMessage);
        this.getAllRoles();
      }
      else{
        this.parseApiErrorResponse(responseJson);
      }
    }
    if(this.roleUpdateApiCallId === apiRequestId){
      if(responseJson && responseJson.data){
        toast.success(configJSON.roleUpdateMessage);
        this.getAllRoles();
        this.setState({
        editData:{  
          id: "",
          type: "",
          attributes: {
            name: "",
            description: "",
            role_type: "",
            agent_details:[]
          }
         } ,
        showEditModal: false
      })
      }
      else{
        this.setState({showEditModal:false});
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidMount() {
    this.token = await getStorageData('authToken')
    this.getAllRoles();
  }
   
  getAllRoles() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.rolesApiContentType,
      token: this.token
    };
    let getRolesMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRolesApiMethod
    );
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesApiEndPoint
    );
    getRolesMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllRolesCallID = getRolesMessage.messageId;
    runEngine.sendMessage(getRolesMessage.id, getRolesMessage);
  }


  openAccordian = (index: number) => {
    this.setState((prevState) => ({openListIndex: prevState.openListIndex === index ? -1 : index}));
  };

  setInputValue = (text: string) => this.setState({ txtInputValue: text });


  onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

 

  onPageChange = (_: unknown, page: number) => {
    this.setState({ page });
  };

  handleModalOpen(value: boolean) {
    this.setState({
      showModal: value
    })
  }
  
  handleSubmit = (value: FormikValues) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const body = {
      role: value
    };

    const createMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roleSaveApiCallId = createMessage.messageId;
    createMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRoleApiEndpoint
    );

    createMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    createMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createRoleApiMethod
    );

    runEngine.sendMessage(createMessage.id, createMessage);
  }

  handleRoleDelete = (id:string) => {
    const header={
      "Content-Type":configJSON.rolesApiContentType,
      token:this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roleDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRoleApiEndPoint+`/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRoleApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRoleEdit = (value:boolean,role:RolesListData) => {
    this.setState({
      showEditModal:value,
      editData:role
    })
  }

  handleEditModalClose = () => {
    this.setState({
      showEditModal:false
    })
  }

  handleEditSubmit = (value: FormikValues) => { 
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.rolesApiContentType,
      token: this.token
    };
    const body = {
      role: value
    };    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roleUpdateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRoleApiEndPoint+`/${this.state.editData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRoleApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  handleInvalidText = (text: string) => {
    return text ? text : '-'
  }

  handleDescription = (text: string) => {
    if (!text) {
      return '-';
    }
    if (text.length >= 100) {
      return text.slice(0, 100) + '...';
    }
    return text;
  }
  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  handleRolesApiDataResponse = (responseJson: ResponseJson) => {
      if (responseJson && responseJson.data) {
        this.setState({
          RolesList: responseJson.data
        });
      }
    }

    handleMemberListModalOpen = (agent_details: Array<AgentDetail>) => {
      this.setState({ showMemberList: true, memberList: agent_details  });
    };

    handleMemberListModalClose = () => {
      this.setState({ showMemberList: false });
    };

    handleRoleTypeChange = (roleType: string) => {
      this.setState({ selectedRoleType: roleType });
      this.handleClose();
    };

    handelInfoModal(value: boolean){
      this.setState({
        isInfoModalOpen: value
      })
    }

    handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
      this.setState({
          isInfoModalOpen: true,
          anchorEl: event?.currentTarget,
      });
  };

  // Customizable Area End
}
