import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start

interface EmailOption {
    id: string;
    email: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    openModal: boolean;
    closeModal: () => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    emailOptions: EmailOption[];
    selectedEmail: { id: string; email: string; };
    subject: string;
    description: string;
    isEmailValid: boolean,
    isSubjectValid: boolean,
    isDescriptionValid: boolean,
    descriptionError: string,
    subjectError: string,
    emailError: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class RequesterTaskCustomFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRequesterListAPICallId: string = "";
    submitRequesterTicketAPICalledId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.state = {
            emailOptions: [],
            selectedEmail: { id: '', email: '', },
            subject: '',
            description: '',
            isEmailValid: false,
            isSubjectValid: false,
            isDescriptionValid: false,
            descriptionError: "",
            subjectError: "",
            emailError: ""
        };
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getRequesterLists()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getRequesterListAPICallId === apiRequestCallId) {
                if (responseJson) {
                    const emails = responseJson?.data.map((item: any) => ({
                        id: item.id,
                        email: item.attributes.email
                    }));
                    this.setState({ emailOptions: emails });
                }
            }

            if (this.submitRequesterTicketAPICalledId === apiRequestCallId) {
                if (responseJson?.data) {
                    this.props.closeModal()
                    this.setState({ subject: '', description: '', selectedEmail: { id: '', email: '', } })
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    // Events
    handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedEmailId = event.target.value as string;
        const isEmailValid = selectedEmailId !== '';
        const emailError = isEmailValid ? '' : 'Email is required';
        const selectedEmail: any = this.state.emailOptions.find(
            (email) => email.id === selectedEmailId
        );
        this.setState({ selectedEmail, isEmailValid, emailError });
    };

    handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
        const subject = event.target.value;
        const isSubjectValid = subject !== '';
        const subjectError = isSubjectValid ? '' : configJSON.subjectReq;
        this.setState({ subject, isSubjectValid, subjectError });
    }

    handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const description = event.target.value;
        const isDescriptionValid = description !== '';
        const descriptionError = isDescriptionValid ? '' : configJSON.descriptionRequired;
        this.setState({ description, isDescriptionValid, descriptionError });
    }

    // API
    getRequesterLists = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRequesterListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.requesterListAPIEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    submitRequesterTicket = async () => {
        const { isEmailValid, isSubjectValid, isDescriptionValid } = this.state;

        if (!isEmailValid || !isSubjectValid || !isDescriptionValid) {
            alert(configJSON.formValidation);
            return;
        }

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("RequesterToken")
        };

        const httpBody = {
            "ticket": {
                "subject": this.state.subject,
                "description": this.state.description,
                "requester_id": this.state.selectedEmail.id
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.submitRequesterTicketAPICalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createRequesterTickets
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    // Customizable Area End
}