import React from "react";

import {
  Box,
  Typography,
  Grid,
  CardContent,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { Products } from "./InventoryTypes";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import NavigationSideBar from "../../../components/src/NavigationSideBar";
// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController";

export default class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.mainContainer}>
        <NavigationSideBar navigation={this.props.navigation} active={""}></NavigationSideBar>
   <div style={webStyle.topNavContainer}></div>
   </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  mainContainer : { 
    display : "flex",
    flexFlow : "row",
    width : "inherit"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  navBar: {
    justifyContent: "space-between",
    display: "flex",
    height: 50,
    marginTop: "2%",
  },
  topNavContainer : { 
    display : "flex",
    flexFlow : "row",
    height : "40px",
    width : "100%",
    backgroundColor : "red",
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)'
  }
};
// Customizable Area End
