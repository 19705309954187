export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/imagebutton-new-Password-visibility-Off.png");
export const cancelIcon = require("../assets/Cancel.svg")
export const supportUser = require('../assets/support.png');
export const breadCrumbIcon = require('../assets/image-chevron-right.png');
export const dotsBG = require('../assets/dots.svg');
export const Path1 = require("../assets/path1.png")
export const Path2 = require("../assets/path2.png")
export const Path24 = require("../assets/path24.png")
export const Path25 = require("../assets/path25.png")
export const Path26 = require("../assets/path26.png")
export const FinalLogo1 = require("../assets/finallogo1.png")