import React, { Component } from "react";
// Customizable Area Start
import { Box, Input, Popover, Typography } from "@material-ui/core";
import { logo, noti, user, searchIcon, maskedIcon, logoutIcon } from './assets/assets'
// Customizable Area End
interface Props {
  isShowSearchAndReport: boolean;
  // Customizable Area Start
  navigation?: any;
  data?: any;
  logoutFun?: any;
  navigateToTicketList?: () => void
  handleModalOpen?: () => void
  // Customizable Area End
}

interface State {
  popOver: boolean;
  // Customizable Area Start
  // Customizable Area End
}
export default class RequestorCommonNavbar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popOver: false
      // Customizable Area Start
      // Customizable Area End
    };
  }
  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Box style={webStyles.containerNavbar}>
          <Box style={{ display: "flex", gap: "41px" }}>
            <img src={logo} alt="logo" role="button" />
            {!this.props.isShowSearchAndReport && (
              <Box style={webStyles.Navlinks}>
                <Typography style={webStyles.navText} onClick={this.props.navigateToTicketList}>Tickets</Typography>
                <Typography style={webStyles.navText}>
                  Service Catalog
                </Typography>
                <Typography style={webStyles.navText}>Solutions</Typography>
              </Box>
            )}
          </Box>
          {this.props.isShowSearchAndReport && (
            <Box style={webStyles.Navlinks}>
              <Box>
                <Typography style={webStyles.navText}>Tickets</Typography>
              </Box>
              <Box onClick={() => this.props.navigation.navigate('ServiceCatalog')} style={{ cursor: 'pointer' }}>
                <Typography style={webStyles.navText}>Service Catalog</Typography>
              </Box>
              <Box>
                <Typography style={webStyles.navText}>Solutions</Typography>
              </Box>
            </Box>
          )}

          <Box style={webStyles.notiUser}>
            {!this.props.isShowSearchAndReport && (
              <Box style={webStyles.SearchIconMain}>
                <img
                  src={searchIcon}
                  alt="Search_Icon"
                  style={webStyles.searchFieldIcon}
                  role="button"
                />
                <input
                  placeholder="Search"
                  style={webStyles.inputSearchField}
                />
                <Box style={webStyles.buttonReportIssue} onClick={this.props.handleModalOpen}>
                  Report an issue
                  <Box
                    style={{
                      height: "100%",
                      width: "1px",
                      background: "#4E3663",
                      margin: "0 10px",
                    }}
                  ></Box>
                  <img src={maskedIcon} alt="maskedIcon" role="button" />
                </Box>
              </Box>
            )}

            <img src={noti} alt="notification_bell" role="button" />
            <Box style={{ position: 'relative' }}>
              <img src={user} alt="userProfile" role="button" onClick={() => this.setState({ popOver: !this.state.popOver })} style={{ cursor: 'pointer' }} />
              <Popover
                // id={id}
                open={this.state.popOver}
                style={{
                  top: "45px",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={() => this.setState({ popOver: false })}
              >
                <Box style={{ padding: "20px" }}>
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={user} height={32} width={32} />
                    <Box>
                      <Typography
                        style={{
                          fontFamily: "Manrope",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#33354D",
                        }}
                      >
                        {this.props?.data?.full_name ? this.props?.data?.full_name : 'Name Surname'}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Manrope",
                          fontWeight: 600,
                          fontSize: "11px",
                          color: "#A3A6C2",
                        }}
                      >
                        {this.props?.data?.email ? this.props?.data?.email : 'example@gmail.com'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      height: "2px",
                      width: "100%",
                      background: "#E6DEED",
                      margin: "10px 0",
                    }}
                  ></Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: 'pointer'
                    }}
                    onClick={this.props.logoutFun}
                  >
                    <img src={logoutIcon} alt="logout" />
                    <Typography>Log out</Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: 'pointer',
                      margin: '10px 0'
                    }}
                    onClick={() => window.location.href = 'userprofile'}
                  >

                    <Typography>Account Setting</Typography>
                  </Box>
                </Box>
              </Popover>
            </Box>


          </Box>
        </Box>
        <Box style={webStyles.horizonalLine}></Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles: Record<string, React.CSSProperties> = {
  containerNavbar: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    margin: '0 80px'
  },
  Navlinks: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
  },
  navText: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "Manrope",
    cursor: 'pointer'
  },
  notiUser: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  horizonalLine: {
    height: "1px",
    background: "#E6DEED",
  },
  SearchIconMain: {
    position: "relative",
    display: "flex",
    gap: "10px",
  },
  searchFieldIcon: {
    position: "absolute",
    left: "10px",
    top: "10px",
  },
  inputSearchField: {
    width: "200px",
    padding: "0 34px",
    height: "36px",
    border: "1px solid #E6DEED",
    borderRadius: "8px",
  },
  buttonReportIssue: {
    background: "#8760A9",
    color: "#fff",
    height: "36px",
    width: "185px",
    borderRadius: "4px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "5px",
    cursor: "pointer",
  },
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End