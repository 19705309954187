import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-hot-toast'
interface MenuList {
    title: string;
    onClick: () => void,
    hide: boolean,
    line: boolean,
}
interface Group {
    id: string;
    type: string;
    attributes: {
        [key: string]: string
    };
}

interface GroupData {
    data: Group[];
}

interface Role {
    id: string;
    type: string;
    attributes: {
        [key: string]: string
    }
}
interface AgentDetails {
    id: string;
    type: string;
    attributes: {
        [key: string]: string
    };
}

interface AgentGeneralDetails {
    data: {
        id: string;
        type: string;
        attributes: {
            groups: Group['attributes'][]
            role: {
                data: Role[];
            };
            capacity_type: string[]
        };
    };
}
interface SelectedOptions {
    members: string[];
    observers: string[]
    role: string[]
    capacity?: string;
}
type DropdownType = 'members' | 'observers' | 'role';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    openEditModal: boolean;
    txtInputValue: string;
    selectedTab: number;
    menuAnchorEl: HTMLElement | null
    openMenu: boolean;
    options: {
        members: { id: string, title: string }[];
        observers: { id: string, title: string }[]
        role: { id: string, title: string }[];
        capacities?: { id: string, title: string }[];
    };
    dropdowns: {
        members: { value: string }[];
        observers: { value: string }[];
        role: { value: string }[];
    }
    selectedOptions: SelectedOptions
    agentGeneralDetails: AgentGeneralDetails['data'];
    agentDetails: AgentDetails['attributes']
    isEmptyData: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AgentProfileController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    pathname = '/user-management/agent';
    navigationList = [
        { title: 'Account settings', list: [] },
        { title: 'User management',
          list: [
                { title: 'Agents', href: '/user-management/agents' },
                { title: 'Roles', href: '/user-management/roles' },
                { title: 'Departments', href: '/user-management/departments' },
                { title: 'Department Fields', href: '/user-management/department-fields', },
                { title: 'Requesters', href: '/user-management/requesters' },
                { title: 'User Fields', href: '/user-management/user-fields' },
                { title: 'CAB', href: '/user-management/cab' },
                { title: 'Agent groups', href: '/user-management/agent-groups' },
                { title: 'Requester groups', href: '/user-management/requester-groups' }]},
        { title: 'Channels', list: [], },
        { title: 'Service management', list: [] },
        { title: 'Automation and productivity', list: [] },
        { title: 'Asset management', list: [] },
        { title: 'IT operations management', list: [] },
        { title: 'Project and workload management', list: []}];
    menuList: MenuList[] = []
    token: string = ''
    id: string = ''
    getGroupListApiCallId: string = ''
    getRoleListApiCallId: string = ''
    addGroupsRolesApiCallId: string = ''
    getGroupRolesDataApiCallId: string = ''
    getAgentDataApiCallId: string = ''
    counter: number = 0
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            openEditModal: false,
            txtInputValue: '',
            selectedTab: 0,
            menuAnchorEl: null,
            openMenu: false,
            isEmptyData: false,
            options: {
                members: [],
                observers: [],
                role: []
            },
            dropdowns: {
                members: [{ value: '' }],
                observers: [{ value: '' }],
                role: [{ value: '' }]
            },
            selectedOptions: {
                members: [],
                observers: [],
                role: [],
                capacity: undefined,
            },
            agentGeneralDetails: {
                id: '0',
                type: 'group_role',
                attributes: {
                    groups: [],
                    role: {
                        data: []
                    },
                    capacity_type: ['']
                },
            },
            agentDetails: {},
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const apiRequestId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        this.setState({
            isLoading: false
        })

        // This will rerdirect user to login page if token is not valid 
        if (Array.isArray(responseJson?.errors) && responseJson?.errors[0].token == "Token has Expired") {
            this.goToLogin();
        }

        switch (apiRequestId) {
            case this.getGroupListApiCallId:
                this.handleGroupListResponse(responseJson)
                break;
            case this.getRoleListApiCallId:
                this.handleRoleListResponse(responseJson)
                break;
            case this.addGroupsRolesApiCallId:
                this.handleAddGroupsRolesResponse(responseJson)
                break;
            case this.getGroupRolesDataApiCallId:
                this.handleGetGroupsRolesResponse(responseJson)
                break;
            case this.getAgentDataApiCallId:
                this.handleGetAgentResponse(responseJson)
                break;
            default:
                break;
        }

        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        this.token = await getStorageData('authToken')
        this.id = this.props.navigation.getParam("navigationBarTitleText")
        this.menuList = [
            {
                title: 'Assume identity',
                onClick: this.handlMenuClick,
                hide: false,
                line: true
            },
            {
                title: 'Deactive agent',
                onClick: this.handlMenuClick,
                hide: false,
                line: true
            },
            {
                title: 'Privacy options',
                onClick: this.handlMenuClick,
                hide: true,
                line: false
            },
            {
                title: 'Opt out of analytics',
                onClick: this.handlMenuClick,
                hide: false,
                line: false
            },
            {
                title: 'Forget user',
                onClick: this.handlMenuClick,
                hide: false,
                line: false
            },
        ]

        this.getGroupAndRolesData()
        this.getAgentDetails()
        this.getGroupList()
        this.getRoleList()

        const options = {
            capacities: [
                { id: '0', title: 'Across all groups in the service desk' },
                { id: '1', title: 'In their member and observer groups' },
                { id: '2', title: 'In the groups' },
                { id: '3', title: 'For items assigned to them' }
            ],
        };

        this.setState({
            options: { ...this.state.options, ...options },
        })


    }


    getAgentDetails = () => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointGetAgentDetails + this.id + configJSON.apiEndPointGetAgentDetailsSlug
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getAgentDataApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGroupAndRolesData = () => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointGetGroupsRoles + this.id
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getGroupRolesDataApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGroupList = () => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointGetAgentGroupList
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getGroupListApiCallId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRoleList = () => {
        this.setState({ isLoading: true });
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointGetRoleList
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        this.getRoleListApiCallId = requestMessage.messageId;

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    };


    addDropdown = (dropdownType: DropdownType) => {
        this.setState((prevState) => ({
            dropdowns: {
                ...prevState.dropdowns,
                [dropdownType]: [...prevState.dropdowns[dropdownType], { value: '' }]
            }
        }));
    };

    updateDropdownValue = (dropdownType: DropdownType, index: number, value: string) => {
        // Update the value of the specific dropdown
        const newDropdowns = this.state.dropdowns[dropdownType].map((dropdown, i) => {
            return i === index ? { ...dropdown, value: value || '' } : dropdown;
        });

        // Update the selected options for this dropdown type
        const newSelectedOptions = newDropdowns
            .filter(dropdown => dropdown.value)
            .map(dropdown => dropdown.value);

        // Update the state with the new dropdowns and selected options
        this.setState(prevState => ({
            dropdowns: {
                ...prevState.dropdowns,
                [dropdownType]: newDropdowns
            },
            selectedOptions: {
                ...prevState.selectedOptions,
                [dropdownType]: newSelectedOptions
            }
        }));
    };

    goToLogin() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationEmailLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    handleCapacityChange = (option: string) => {

        this.setState({
            selectedOptions: { ...this.state.selectedOptions, capacity: option }
        })
    }

    returnCapacityEnum = (value: string) => {
        const Enums = [
            { id: '0', value: 'across_all_groups_in_the_service_desk' },
            { id: '1', value: 'in_their_member_and_observer_groups' },
            { id: '2', value: 'in_the_groups' },
            { id: '3', value: 'for_items_assigned_to_them' },
        ]
        const enumObject = Enums.find((enumItem) => enumItem.value === value);
        return enumObject ? enumObject.id : '';
    }

    canAddMoreDropdowns = (dropdownType: DropdownType) => {
        const totalOptions = this.state?.options[dropdownType]?.length;
        const selectedOptionsCount = this.state?.selectedOptions[dropdownType]?.length;
        return selectedOptionsCount < totalOptions;
    };

    removeDropdown = (dropdownType: DropdownType, index: number) => {
        this.setState((prevState) => {
            const newDropdowns = prevState.dropdowns[dropdownType].filter((_, i) => i !== index);

            const removedValue = prevState.dropdowns[dropdownType][index].value;

            const newSelectedOptions = prevState.selectedOptions[dropdownType].filter(value => value !== removedValue);


            return {
                dropdowns: {
                    ...prevState.dropdowns,
                    [dropdownType]: newDropdowns
                },
                selectedOptions: {
                    ...prevState.selectedOptions,
                    [dropdownType]: newSelectedOptions
                }
            };
        });
    }

    onResetClick = () => {
        this.setState({
            openEditModal: false
        })
    }

    handleInvalidText = (text: string) => text ? text : '-'

    handleTabChange = (_: null | unknown, newValue: number) => { this.setState({ selectedTab: newValue }) };

    handlMenuClick = () => { }

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { this.setState({ menuAnchorEl: event.currentTarget, openMenu: true }) }

    handleClose = () => this.setState({ openMenu: false })

    handleModal = (value: boolean) => { this.setState({ openEditModal: value }) }

    handleSaveAgentDetails = () => {
        const { members, observers, role, capacity } = this.state.selectedOptions
        this.setState({ isLoading: true });

        const data = {
            "member_group_ids": [...members].map((id: string) => +id),
            "observer_group_ids": [...observers].map((id: string) => +id),
            "role_ids": [...role].map((id: string) => +id),
            "capacity_type": capacity ? +capacity : null
        }

        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.token
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiEndPointAddGroupsRolesSlug + this.id + configJSON.apiEndPointAddGroupsRolesSlug2
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ ...data })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );

        this.addGroupsRolesApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    a11yProps(index: number) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    getRandomColor = () => {
        const colors = ['#11A64A', '#6366F1', '#FFBA08', '#F22A2A'];
        const index = this.counter++ % colors.length
        return colors[index];
    };

    // Function to generate styles for Chip
    getChipStyle = () => {
        const bgColor = this.getRandomColor();
        const textColor = 'white'
        return {
            backgroundColor: bgColor,
            color: textColor,
            margin: '5px',
        };
    };

    areAllFieldsEmpty(obj: Record<string, any>): boolean {
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
                if (obj[key].length > 0) return false;
            } else if (typeof obj[key] === 'object') {
                if (!this.areAllFieldsEmpty(obj[key])) return false;
            }
        }
        return true;
    }

    returnAgentsData = (type: 'member' | 'observer', data: AgentGeneralDetails['data']['attributes']) => {
        return data?.groups?.filter((data) => data.group_type === type)
    }

    returnMenuColor = (text: string) => {
        return text === '' ? 'A3A6C2' : '#33354D'
    }

    navigateUser = () => {
        const navigateMessage: Message = new Message(
            getName(MessageEnum.NavigationAgentsMessage)
        );

        navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        navigateMessage.addData(getName(MessageEnum.UrlPathParamMessage), this.id);
        this.send(navigateMessage);
    }

    //  It will handle GroupList Response
    handleGroupListResponse = (responseJson: GroupData) => {
        if (responseJson?.data) {
            this.setState({
                options: {
                    ...this.state.options,
                    members: responseJson.data?.map((data) => ({ id: data.id, title: data?.attributes?.name || '-' })),
                    observers: responseJson.data?.map((data) => ({ id: data.id, title: data?.attributes?.name || '-' })),
                }
            })
        }
    }

    //  It will handle role Response
    handleRoleListResponse = (responseJson: { data: Role[] }) => {
        if (responseJson?.data) {
            this.setState({
                options: {
                    ...this.state.options,
                    role: responseJson.data.map((data) => ({ id: data.id, title: data.attributes.name || '-' })),
                }
            })
        }
    }

    //  It will handle Add Groups and Roles Response
    handleAddGroupsRolesResponse = (responseJson: { data: { message: string } }) => {
        if (responseJson?.data?.message) {
            this.setState({
                openEditModal: false
            })
            this.getGroupAndRolesData()
            toast.success(responseJson.data.message)
        }
    }

    //  It will handle get Groups and Roles Response
    handleGetGroupsRolesResponse = (responseJson: AgentGeneralDetails) => {
        if (responseJson?.data) {
            const agentDetails = responseJson.data;

            // Extracting the IDs for members, observers, and roles
            const memberIds = agentDetails.attributes.groups
                .filter(group => group.group_type === 'member')
                .map(group => group.id);
            const observerIds = agentDetails.attributes.groups
                .filter(group => group.group_type === 'observer')
                .map(group => group.id);
            const roleIds = agentDetails.attributes.role.data
                .map(role => role.id);

            // Preparing dropdowns and selectedOptions
            const dropdowns = {
                members: memberIds.length > 0 ? memberIds.map(id => ({ value: id })) : [{value: ''}],
                observers: observerIds.length > 0 ? observerIds.map(id => ({ value: id })) : [{value: ''}],
                role: roleIds.length > 0 ? roleIds.map(id => ({ value: id })): [{value: ''}],
            };
            const selectedOptions = {
                members: memberIds.length > 0 ? memberIds : [' '],
                observers: observerIds.length > 0 ? observerIds : [''],
                role: roleIds.length > 0 ? roleIds : [''],
                capacity: this.returnCapacityEnum(agentDetails.attributes.capacity_type[0])
            };

            const isDataOK = this.areAllFieldsEmpty(responseJson.data.attributes)

            // Updating the state
            this.setState({
                agentGeneralDetails: agentDetails,
                dropdowns: dropdowns,
                selectedOptions: selectedOptions,
                isEmptyData: isDataOK
            });
        }
    }

    //  It will handle get agent
    handleGetAgentResponse = (responseJson: { data: AgentDetails }) => {
        if (responseJson?.data) {
            this.setState({
                agentDetails: responseJson.data.attributes
            })
        }
    }

    // Customizable Area End
}
