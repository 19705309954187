import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Card, Input, CardActionArea, CardMedia, CardContent, CardActions, styled } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { card_img_1, card_img_2, card_img_3, dotsBG } from "./assets";


const CustomButton = styled(Button)({
  padding: '6px 20px',
  background: '#8760A9',
  borderRaduis: '4px',
  color: '#FFFFFF',
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: '#8760A9',
    color: '#fff',
  },
})

const CardMain = styled(Card)({
  boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)',
  borderRadius: '8px',
  width: '30%',
})
const CardTitle = styled(Typography)({
  color: '#33354D',
  fontFamily: 'Manrope',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 900,
  lineheight: 'normal',
  marginBottom: '8px'
})

const CardDescription = styled(Typography)({
  color: '#33354D',
  fontFamily: 'Manrope',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginBottom: '9px',
  height: '48px'
})
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";
import RequestorCommonNavbar from "../../../components/src/RequestorCommonNavbar";
import RequesterTaskCustomForm from "../../customform3/src/RequesterTaskCustomForm.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log(this.state.reqProfileData, 'reqPROFILEDATAAA')
    return (
      //Merge Engine DefaultContainer
      <>
        <RequestorCommonNavbar
          isShowSearchAndReport={false}
          navigation={this.props.navigation}
          data={this.state.reqProfileData}
          logoutFun={this.handleLogoutRequester}
          navigateToTicketList={this.handleNavigateToTicketList}
          handleModalOpen={this.handleModalOpen}
        />
        <Box style={webStyles.container} >
          <Box style={webStyles.mainPage}>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography style={webStyles.mainHeading}>{webConfigJSON.textMainHeading}</Typography>
              <Box style={webStyles.inputMainBox}>
                <Search style={webStyles.search_icon} />
                <Input
                  disableUnderline={true}
                  style={webStyles.input}
                  placeholder={"Search..."}
                />
              </Box>
            </Box>
            <Box style={webStyles.cardsMain}>
              <CardMain>
                <CardActionArea disableRipple>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card_img_1}
                    alt={webConfigJSON.textCard1Heading}
                  />
                  <CardContent>
                    <CardTitle>
                      {webConfigJSON.textCard1Heading}
                    </CardTitle>
                    <CardDescription>
                      {webConfigJSON.textCard1Desc}
                    </CardDescription>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ justifyContent: 'end' }}>
                  <CustomButton>
                    {webConfigJSON.textReport}
                  </CustomButton>
                </CardActions>
              </CardMain>
              <CardMain>
                <CardActionArea disableRipple>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card_img_2}
                    alt={webConfigJSON.textCard2Heading}
                  />
                  <CardContent>
                    <CardTitle>
                      {webConfigJSON.textCard2Heading}
                    </CardTitle>
                    <CardDescription>
                      {webConfigJSON.textCard2Desc}
                    </CardDescription>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ justifyContent: 'end' }}>
                  <CustomButton>
                    {webConfigJSON.textRequest}
                  </CustomButton>
                </CardActions>
              </CardMain>
              <CardMain>
                <CardActionArea disableRipple>
                  <CardMedia
                    component="img"
                    height="230"
                    image={card_img_3}
                    alt="green iguana"
                  />
                  <CardContent>
                    <CardTitle>
                      {webConfigJSON.textCard3Heading}
                    </CardTitle>
                    <CardDescription>
                      {webConfigJSON.textCard3Desc}
                    </CardDescription>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ justifyContent: 'end' }}>
                  <CustomButton>
                    {webConfigJSON.textApprove}
                  </CustomButton>
                </CardActions>
              </CardMain>
            </Box>
          </Box>

          <RequesterTaskCustomForm navigation={undefined} openModal={this.state.isModalopen} closeModal={this.handleModalClose} />

          <img src={dotsBG} style={webStyles.bgImage} />
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles: Record<string, React.CSSProperties> = {
  container: {
    padding: '0 170px'
  },
  search_icon: {
    width: "24px",
    color: "#33354D",
    height: "24px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px",
  } as const,
  mainPage: {
    marginTop: '60px'
  } as const,
  mainHeading: {
    color: '#33354D',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontSize: '52px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    maxWidth: '300px',
    margin: 'auto'
  } as const,
  inputMainBox: {
    marginBottom: "40px",
    marginTop: '40px',
    position: 'relative'
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    border: "1px solid #e6deed",
    height: "40px",
    padding: "20px 35px",
    fontSize: "16px",
    borderRadius: "10px",
  } as const,
  cardsMain: {
    display: 'flex',
    justifyContent: 'space-between'
  } as const,
  bgImage: {
    objectFit: 'cover',
    height: '320px',
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    top: '-7px'
  } as const
};
// Customizable Area End
