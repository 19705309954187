import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

interface AgentAndRequester {
  id: string,
  type: string,
  attributes: {
      email: string
  }
}

interface AllGroupsList {
  id: string,
  type: string,
  attributes: {
      name: string
  }
}

interface AllAgentsList {
  id: string,
  type: string,
  attributes: {
      full_name: string
  }
}

interface DepartmentList {
  id: string,
  type: string,
  attributes: {
      department_name: string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal: boolean;
  closeModal: () => void
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  ccDropdowns: { id: string; email: string; }[];
  requestersList: AgentAndRequester[];
  isValidationError: boolean;
  requesterError: string;
  subject: string;
  subjectError: string,
  selectedSource: string,
  selectedStatus: string,
  selectedPriority: string,
  filteredOptions: AllGroupsList[];
  allGroupList: AllGroupsList[],
  filteredAgents: AllAgentsList[],
  allAgentsList: AllAgentsList[],
  filteredDepartmentList: DepartmentList[],
  allDepartmentList: DepartmentList[],
  description: string;
  descriptionError: string;
  categoryList: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  timeDifference: string;
  reasonForChange: string;
  impact: string;
  rollOutPlan: string;
  backOutPlan: string;
  changeType:string;
  maintainceWindow:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangeTicketFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  createTicketApiCallId: string = ''
  getSingalTemplateDataApiCallId: string = ''
  token: string = ''
  status: { title: string, id: string }[] = [
    {
      id: 'Open',
      title: 'Open',
    },
    {
      id: 'Closed',
      title: 'Closed',
    },
    {
      id: 'Pending',
      title: 'Pending',
    },
    {
      id: 'Resolved',
      title: 'Resolved'
    }
  ]
  source = [
    {
      id: '1',
      title: 'Phone'
    },
    {
      id: '2',
      title: 'Web'
    },
  ]
  getRequesterDataAPICallId: string = "";
  getAllGroupsAPICallId: string = "";
  getAllAgentsListAPICallId: string = "";
  getAllDepartmentAPICallId: string = "";
  submitFormAPICallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      ccDropdowns: [{ id: "", email: "" }],
      requestersList: [],
      isValidationError: false,
      requesterError: '',
      subject: '',
      subjectError: '',
      selectedSource: "",
      selectedStatus: this.status[0].id,
      selectedPriority: "Low",
      filteredOptions: [],
      allGroupList: [],
      filteredAgents: [],
      allAgentsList: [],
      filteredDepartmentList: [],
      allDepartmentList: [],
      description: '',
      descriptionError: '',
      categoryList: '',
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      timeDifference: "",
      reasonForChange:'',
      impact : '',
      rollOutPlan:'',
      backOutPlan:'',
      changeType:"Hardware",
      maintainceWindow : "Hardware"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    secureTextEntry: false,
  };

  txtInputProps = this.txtInputWebProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  // web events
  
  // Customizable Area Start

  async componentDidMount() {
    this.token = await getStorageData('authToken')
    // this.getTemplateData()
    this.getAllRequesterList()
    this.getAllGroups()
    this.setState({ filteredOptions: this.state.allGroupList });
    this.getAllAgentsList()
    this.setState({ filteredAgents: this.state.allAgentsList });
    this.getAllDepartment()
    this.setState({ filteredDepartmentList: this.state.allDepartmentList })
  }

  // API integration

  getAllRequesterList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRequesterDataAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRequestersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllGroups = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllGroupsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllAgentsList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAgentsListAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAgentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllDepartment = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllDepartmentAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllDepartmentsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeCc = (index: number) => {
    this.setState({
      ccDropdowns: this.state.ccDropdowns.filter((_, item) => item !== index)
    });
  }

  handleCcChange = (index: number, value: string) => {
    const updatedCcDropdowns = [...this.state.ccDropdowns];
    updatedCcDropdowns[index].id = value;
    this.setState({ ccDropdowns: updatedCcDropdowns });
  };

  navigateUser = () => {
    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationRequestersMessage)
    );
    navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToLoginMessage.addData(getName(MessageEnum.UrlPathParamMessage), 'createRequester');
    this.send(navigateToLoginMessage);
  }
  handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subjectValue = event.target.value;
    const isSubjectEmpty = subjectValue.trim() === '';
    this.setState({
      subject: subjectValue,
      subjectError: isSubjectEmpty ? configJSON.subjectReq : ''
    });
  }

  handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedSource: event.target.value as string });
  };

  handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedStatus: event.target.value as string });
  }

  handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedPriority: event.target.value });
  };

  handleGroupSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredOptions = this.state.allGroupList.filter(group =>
      group.attributes?.name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredOptions });
  };


  handleAgentsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredAgents = this.state.allAgentsList.filter(agents =>
      agents.attributes?.full_name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredAgents });
  }

  handleDepartmentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    const filteredDepartmentList = this.state.allDepartmentList.filter(department =>
      department.attributes?.department_name?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    this.setState({ filteredDepartmentList });
  }

  handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const descriptionValue = event.target.value;
    const isDescriptionValid = descriptionValue.length >= 10;
    const isDescriptionEmpty = descriptionValue.trim() === '';
    let descriptionError = '';

    if (isDescriptionEmpty) {
      descriptionError = configJSON.descriptionRequired;
    } else if (!isDescriptionValid) {
      descriptionError = configJSON.descriptionCharcter;
    }

    this.setState({
      description: descriptionValue,
      descriptionError: descriptionError
    });
  }

  handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startDate: event.target.value });
  };

  handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ startTime: event.target.value });
  };

  handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endDate: event.target.value });
  };

  handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ endTime: event.target.value }, this.calculateTimeDifference);
  };

  calculateTimeDifference = () => {
    const { startDate, startTime, endDate, endTime } = this.state;
    const startDateTime = new Date(startDate + "T" + startTime);
    const endDateTime = new Date(endDate + "T" + endTime);
    const difference = endDateTime.getTime() - startDateTime.getTime();

    const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    const minutesDifference = Math.floor((difference / 1000 / 60) % 60);

    this.setState({
      timeDifference: `${hoursDifference} hours ${minutesDifference} minutes`,
    });
  };

  onFormSubmiit = () => {
    const { subject, description, ccDropdowns } = this.state;
    let isError = false;

    // Validate requester
    if (ccDropdowns.length < 1 || !ccDropdowns[0].id) {
      this.setState({ requesterError: configJSON.requesterReq });
      isError = true;
    } else {
      this.setState({ requesterError: '' });
    }

    // Validate subject
    if (!subject.trim()) {
      this.setState({ subjectError: configJSON.subjectReq });
      isError = true;
    } else {
      this.setState({ subjectError: '' });
    }

    // Validate description
    if (!description.trim()) {
      this.setState({ descriptionError: configJSON.descriptionRequired });
      isError = true;
    } else if (description.length < 10) {
      this.setState({ descriptionError: configJSON.descriptionCharcter });
      isError = true;
    } else {
      this.setState({ descriptionError: '' });
    }

    if (isError) {
      this.setState({ isValidationError: true });
      return;
    }

    this.setState({ isValidationError: false });

    // If no validation errors, proceed with the form submission
    // this.createTicket();
  };

  handleReasonForChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reasonForChangeValue = event.target.value;
    this.setState({
      reasonForChange: reasonForChangeValue,
    });
  }
  
  handleImpactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const impactChangeValue = event.target.value;
    this.setState({
      impact: impactChangeValue,
    });
  }
  
  handleRollOutPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rollOutPlanValue = event.target.value;
    this.setState({
      rollOutPlan: rollOutPlanValue,
    });
  }
  
  handleBackOutPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const backOutPlanValue = event.target.value;
    this.setState({
      backOutPlan: backOutPlanValue,
    });
  }

  handleChangeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ changeType: event.target.value });
  };

  handleMaintainceWindowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ maintainceWindow: event.target.value });
  };

  // Customizable Area End
}
