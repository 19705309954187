import React, { Component } from "react";
// Customizable Area Start
import { Box, Button, Modal, Typography } from "@material-ui/core";
import {
  supportUser,
  dotsBG,
  breadCrumbIcon,
  cancelIcon,
  imgPasswordVisible as passwordVisible,
  imgPasswordInVisible as passworInvisible
} from "./assets";
import RequestorCommonNavbar from '../../../components/src/RequestorCommonNavbar'
import toast, { Toaster } from "react-hot-toast";
import UserProfileController, { Props } from "./UserProfileWebController";
import moment from "moment-timezone";

// Customizable Area End
// Customizable Area Start
export default class UserProfile extends UserProfileController {


  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const timezones = moment.tz.names();

    return (
        //Merge Engine DefaultContainer
      <Box style={webStyles.container}>
        <RequestorCommonNavbar isShowSearchAndReport={false} logoutFun={this.handleLogoutRequester}/>
        <Box style={{
          margin: '0 80px'
        }}>
           <Typography style={webStyles.normalText}>Home <span> <img src={breadCrumbIcon} alt="breadcrumb"/> </span> Account</Typography>
        <Typography style={webStyles.title}>Account</Typography>
        <Box style={webStyles.detailsSupport}>
          <Box style={webStyles.Details}>
            <Typography style={webStyles.detailsText}>Details</Typography>
            <Box style={webStyles.detailsAccount}>
              <Box style={webStyles.detailsBox}>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>Full name</Typography>
                  <Typography style={webStyles.fieldValue}>
                    {this.state.profileData?.attributes.full_name}
                  </Typography>
                </Box>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>Email</Typography>
                  <Typography style={webStyles.fieldValue}>
                  {this.state.profileData?.attributes.email}
                  </Typography>
                </Box>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>
                    Work phone number
                  </Typography>
                  <Typography style={webStyles.fieldValue}>
                  { this.state.profileData?.attributes.work_phone }
                    
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyles.detailsBox}>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>Time zone</Typography>
                  <Typography style={webStyles.fieldValue}>
                  {this.state.profileData?.attributes?.time_zone }
                  </Typography>
                </Box>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>Language</Typography>
                  <Typography style={webStyles.fieldValue}>
                  {this.state.profileData?.attributes?.language }
               </Typography>
                </Box>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>
                    Time format
                  </Typography>
                  <Typography style={webStyles.fieldValue}>
                  {this.state.profileData?.attributes?.time_format }

                  </Typography>
                </Box>
                <Box style={webStyles.rowDetails}>
                  <Typography style={webStyles.fieldName}>Location</Typography>
                  <Typography style={webStyles.fieldValue}>
                  {this.state.profileData?.attributes?.location }

                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={webStyles.editDetailsbox}>
              <Button
                style={webStyles.editDetails}
                onClick={this.handleOpenModal}
                data-test-id="edit-button-modal"
              >
                edit
              </Button>
            </Box>
          </Box>
          <Box style={webStyles.supportbox}>
            <Typography style={webStyles.detailsText}>Support</Typography>
            <Box style={webStyles.detailsBoxs}>
              <Typography style={webStyles.textTop}>Chat with us</Typography>
              <Typography style={webStyles.textSecond}>
                Are you looking for an IT Service desk? Have any product
                questions? Take us for a spin!
              </Typography>
              <Box style={webStyles.DetailsboxSupport}>
                <Box>
                  <Typography style={webStyles.fieldNameSupport}>
                    6th July 2023
                  </Typography>
                  <Typography style={webStyles.fieldValue}>
                    Name Surname
                  </Typography>
                </Box>
                <img
                  src={supportUser}
                  alt="logo"
                  width={25}
                  height={25}
                  style={{
                    borderRadius: "50%",
                    margin: "0 5px"
                  }}
                />
              </Box>
            </Box>
            <Box style={webStyles.editDetailsbox}>
              <Button style={webStyles.supporDetailsButon}>Chat with us</Button>
            </Box>
          </Box>
        </Box>

        <Box style={webStyles.passwordbox}>
          <Typography style={webStyles.textcahngepass}>Password</Typography>
          <Button
            style={webStyles.textcahngepassbutton}
            onClick={this.handleOpenPasswordResetModal}
            data-test-id = 'change-password-button'
          >
            Change password
          </Button>
        </Box>
        <Box style={webStyles.passwordbox}>
          <Typography style={webStyles.textcahngepass}>
            Delete account
          </Typography>
          <Button
            style={webStyles.textcahngepassbutton}
            onClick={() => this.setState({ deleteModal1: true })}
            data-test-id='deleteaccountBtn'
          >
            Delete account
          </Button>
        </Box>
        
        <img src={dotsBG} style={{ position: 'absolute', zIndex: -1, maxHeight: '220px', height: '100%', width: '100%', objectFit: 'cover', maxWidth: '85%'}} alt="Background" />
        <Modal open={this.state.isModalOpen} onClose={this.handleCloseModal} >
          <Box style={webStyles.modalContent}>
            <Box style={webStyles.modalTopRow}>
              <Typography style={webStyles.detailsText}>
                Edit details{" "}
              </Typography>
              <img
                src={cancelIcon}
                alt="cancelicon"
                role="button"
                style={{ cursor: "pointer" }}
                onClick={this.handleCloseModal}
              />
            </Box>
            <Box style={webStyles.detailsSupport}>
              <Box style={webStyles.filedModal}>
                <Box style={{ marginTop: "20px" }}>
                  <Typography style={webStyles.fontModalTop}>
                    Full name <span style={webStyles.inputImponent}>*</span>
                  </Typography>
                  <input
                    type="text"
                    placeholder="Name Surname"
                    style={webStyles.ModalInput}
                    value={this.state.editProfile.full_name }
                    name="full_name"
                    onChange={this.handleInputChange}
                  />
                  {this.state.errors.full_name && <div className="error" style={webStyles.errors}>{this.state.errors.full_name}</div>}

                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Email <span style={webStyles.inputImponent}>*</span>
                  </Typography>
                  <input
                    type="text"
                    placeholder="name.surname@mail.com"
                    style={webStyles.ModalInput}
                    value={this.state.editProfile.email}
                    name="email"
                    onChange={this.handleInputChange}

                  />
                  {this.state.errors.email && <div className="error" style={webStyles.errors}>{this.state.errors.email}</div>}

                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Work phone number{" "}
                    <span style={webStyles.inputImponent}>*</span>
                  </Typography>
                  <input
                    type="text"
                    placeholder="0044732489287"
                    style={webStyles.ModalInput}
                    value={this.state.editProfile.work_phone}
                    name="work_phone"
                    onChange={this.handleInputChange}

                  />
                  {this.state.errors.work_phone && <div className="error" style={webStyles.errors}>{this.state.errors.work_phone}</div>}
                  
                </Box>
              </Box>
              <Box style={webStyles.filedModal}>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Time zone{" "}
                  </Typography>
                  <select style={webStyles.ModalSelect} name="time_zone" 
                    onChange={this.handleInputChange}
                    value={this.state.editProfile.time_zone}
                  >
                  {timezones.map((timezone: string, index: number) => (
                   <option key={index}   >
                    {timezone}
                  </option>
                 ))}
                  </select>
                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Language
                  </Typography>
                  <select style={webStyles.ModalSelect} name="language" onChange={(e)=>this.handleInputChange(e)}
                  value={this.state.editProfile?.language}
                  
                  >
    {
      this.state.languages.map((item: any,index:number)=>{
        return(

          <option value={item.name} key={index}>{item.name}</option>
        )
      })
    }
                  </select>
                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Time format{" "}
                  </Typography>
                  <select style={webStyles.ModalSelect} onChange={this.handleInputChange} name="time_format"
                  value={this.state.editProfile?.time_format}
                  >
                 <option value="24h">24 Hours</option>
                 <option value="12h">12 Hours</option>

                  </select>
                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Location{" "}
                  </Typography>
                  <input
                    type="text"
                    placeholder="Munich"
                    style={webStyles.ModalInput}
                    value={this.state.editProfile.location}
                    name="location"
                    onChange={this.handleInputChange}

                  />
                </Box>
              </Box>
            </Box>
            <Box style={webStyles.BottomButton}>
              <Typography
                style={webStyles.cancelBottom}
                onClick={this.handleCloseModal}
              >
                Cancel
              </Typography>
              <Button
                style={webStyles.BottomSavebutton}
                onClick={this.handleProfileEditFlow}
                data-test-id='save-changes-edit-btn'
              >
                {" "}
                Save changes{" "}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.passwordResetModal}
          onClose={this.handleClosePasswordResetModal}
        >
          <Box style={webStyles.resetmodalContent}>
            <Box
              style={{
                height: "10px",
                backgroundColor: "#8760A9",
                borderRadius: "8px 8px 0 0"
              }}
            />
            <Box style={{ padding: "20px" }}>
              <Box style={webStyles.modalTopRow}>
                <Typography style={webStyles.detailsText}>
                  Password Reset
                </Typography>
                <img
                  src={cancelIcon}
                  alt="cancelicon"
                  role="button"
                  style={{ cursor: "pointer" }}
                  data-test-id = 'cancel-reset-modal-btn'
                  onClick={() => this.setState({ passwordResetModal: false })}
                />
              </Box>
              <Box style={webStyles.passwordResetContent}>
                <Typography style={webStyles.subtextModal}>
                  Provide current password below
                </Typography>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Current Password{" "}
                    <span style={webStyles.inputImponent} className="required">*</span>
                    {""}
                  </Typography>
                  <Box style={{ position: "relative",gap : '0px' }}>
                  {""}


                    <input
                      type={this.state.passwordIconReset ? "password" : "text"}
                      placeholder="Enter current password"
                      style={webStyles.ModalInput}
                      name="password"
                      data-test-id='currentpassInput'
                      onChange={(e)=>this.handleCurrentPassword(e)}
                    />
                    {this.state.passwordIconReset ? (

                      <img
                        src={passwordVisible}
                        alt="password-visible-2"
                        style={webStyles.iconPassword}
                        onClick={this.handlePasswordInput}
                      />

                      ) : (
                        <img
                        src={passworInvisible}
                        alt="password-visible-2"
                        style={webStyles.iconPassword}
                        onClick={this.handlePasswordInput}
                        />
                        )}
                        {""}
                  </Box>
                  {
                    this.state.currentPassErr &&
                  <p style={webStyles.errors}>
                    {this.state.currentPassErr}
                  </p>
                  }
                </Box>
                {""}

              </Box>

              <Box style={webStyles.BottomButton}>
                <Typography
                  style={webStyles.cancelBottom}
                  onClick={this.handleClosePasswordResetModal}
                >
                  Cancel
                </Typography>
                <Button
                  style={webStyles.BottomSavebutton}
                  onClick={this.handleResetPasswordFlow}
                  data-test-id = 'save-button-current-pass'
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.resetNewPassowrdModal}
          onClose={() => this.setState({ resetNewPassowrdModal: false })}
          data-test-id='modalreset'
        >
          <Box style={webStyles.resetmodalContent}>
            <Box
              style={{
                height: "10px",
                backgroundColor: "#8760A9",
                borderRadius: "8px 8px 0 0"
              }}
            />
            <Box style={{ padding: "20px" }}>
              <Box style={webStyles.modalTopRow}>
                <Typography style={webStyles.detailsText}>
                  Password Reset
                </Typography>
                <img
                  src={cancelIcon}
                  alt="cancelicon"
                  role="button"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ resetNewPassowrdModal: false })
                  }
                  data-test-id = 'reset-cancel-btn'
                />
              </Box>
              <Box style={webStyles.passwordResetContent}>
                <Typography style={webStyles.subtextModal}>
                  Create new strong password below.
                </Typography>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Create new password
                    <span style={webStyles.inputImponent} className="required_password">*</span>
                  </Typography>
                  <Box style={{ position: "relative", alignItems: 'center !important' }}>
                    <input
                      type={this.state.passwordIconReset ? "password" : "text"}
                      placeholder="Create new password"
                      style={webStyles.ModalInput}
                      name="password-reset-"
                      onChange={this.handleNewPass}
                    />
                    {this.state.passwordIconReset ? (
                      <img
                        src={passwordVisible}
                        alt="password-visible-resett-"
                        style={webStyles.iconPassword}
                        onClick={this.handlePasswordInput}
                      />
                    ) : (
                      <img
                        src={passworInvisible}
                        alt="password-visible-34"
                        style={webStyles.iconPassword}
                        onClick={this.handlePasswordInput}
                      />
                    )}
                    {""}
                  </Box>
                </Box>
                <Box style={webStyles.filedModalBox}>
                  <Typography style={webStyles.fontModalTop}>
                    Repeat new password
                    <span style={webStyles.inputImponent}>*</span>
                  </Typography>
                  <Box style={{ position: "relative" }}>
                    <input
                      type={
                        this.state.confirmNewPasswordIcon ? "password" : "text"
                      }
                      placeholder="Repeat new password"
                      style={webStyles.ModalInput}
                      onChange={this.handleConfirmNewPass}
                    />
                    {this.state.confirmNewPasswordIcon ? (
                      <img
                        src={passwordVisible}
                        alt="password-visible"
                        style={webStyles.iconPassword}
                        onClick={() =>
                          this.setState({ confirmNewPasswordIcon: false })
                        }
                        data-test-id='reset-modal-passowordVisible'
                      />
                    ) : (
                      <img
                        src={passworInvisible}
                        alt="password-visible"
                        style={webStyles.iconPassword}
                        onClick={() =>
                          this.setState({ confirmNewPasswordIcon: true })
                        }
                        data-test-id='reset-modal-passowordInVisible'

                      />
                    )}
                  </Box>
                  {
                    this.state.currentPassErr &&
                  <p style={webStyles.errors}>{this.state.currentPassErr}</p>
                  }
                </Box>
              </Box>
              <Box style={webStyles.BottomButton}>
                <Typography
                  style={webStyles.cancelBottom}
                  onClick={() =>
                    this.setState({ resetNewPassowrdModal: false })
                  }
                  data-test-id='cancel-text-reset'
                >
                  Cancel
                </Typography>
                <Button
                  style={webStyles.BottomSavebutton}
                  onClick={this.handlePasswordChange}
                  data-test-id = 'save-button-reset-flow'
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={this.state.deleteModal1}
          onClose={() => this.setState({ deleteModal1: false })}
          data-test-id='deletemodal1'
        >
          <Box style={webStyles.resetmodalContent}>
            <Box
              style={{
                height: "10px",
                backgroundColor: "#8760A9",
                borderRadius: "8px 8px 0 0"
              }}
            />
            <Box style={{ padding: "20px" }}>
              <Box style={webStyles.modalTopRow}>
                <Typography style={webStyles.detailsText}>
                  Delete Account
                </Typography>
                <img
                  src={cancelIcon}
                  alt="cancelicon"
                  role="button"
                  style={{ cursor: "pointer" }}
                  data-test-id = 'delete1-modal-btn'
                  onClick={() => this.setState({ deleteModal1: false })}
                />
              </Box>
              <Box style={webStyles.deleteModal}>
                <Typography
                  style={{
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "20px"
                  }}
                >
                  Are you sure you want to delete your account?
                </Typography>
              </Box>
              <Box style={webStyles.BottomButton}>
                <Typography
                  style={webStyles.cancelBottom}
                  data-test-id='delete1-keepacc-btn'
                  onClick={() => this.setState({ deleteModal1: false })}
                >
                  Keep account
                </Typography>
                <Button
                  style={webStyles.BottomSavebutton}
                  onClick={() =>
                    this.setState({ deleteModal1: false, deleteModal2: true })
                  }
                  data-test-id= 'Delete-account-modal1'
                >
                  Delete account
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={this.state.deleteModal2}
          onClose={() => this.setState({ deleteModal2: false })}
          data-test-id='deletemodal2'
        >
          <Box style={webStyles.resetmodalContent}>
            <Box
              style={{
                height: "10px",
                backgroundColor: "#8760A9",
                borderRadius: "8px 8px 0 0"
              }}
            />
            <Box style={{ padding: "20px" }}>
              <Box style={webStyles.modalTopRow}>
                <Typography style={webStyles.detailsText}>
                  Delete Account
                </Typography>
                <img
                  src={cancelIcon}
                  alt="cancelicon"
                  role="button"
                  style={{ cursor: "pointer" }}
                  data-test-id='delete2-modal-btn'
                  onClick={() => this.setState({ deleteModal2: false })}
                />
              </Box>

              <Box style={{ margin: "40px 0" }}>
                <Typography style={webStyles.fontModalTop}>
                  Password <span style={webStyles.inputImponent} className="requireedfield">*</span>
                  {""}
                </Typography>
                <Box style={{ position: "relative", gap:'1px' }}>
                  <input
                    type={this.state.passwordIconReset ? "password" : "text"}
                    placeholder="Enter password"
                    style={webStyles.ModalInput}
                    onChange={(e)=>this.setState({PasswordDeleteInput : e.target.value})}
                    data-test-id = 'passwordresetinput'
                  />
                  {""}
                  {this.state.passwordIconReset ? (
                    <img
                      src={passwordVisible}
                      alt="password-visiblesss"
                      style={webStyles.iconPassword}
                      onClick={this.handlePasswordInput}
                      
                    />
                  ) : (
                    <img
                      src={passworInvisible}
                      alt="password-visiblesss"
                      style={webStyles.iconPassword}
                      onClick={this.handlePasswordInput}
                      data-test-id = 'passwordIcon'

                    />
                  )}
                </Box>
              </Box>

              <Box style={webStyles.BottomButton}>
                <Typography
                  style={webStyles.cancelBottom}
                  data-test-id='delete2-keepacc-btn'
                  onClick={() => this.setState({ deleteModal2: false })}
                >
                  Keep account
                </Typography>
                <Button
                  style={webStyles.BottomSavebutton}
                  data-test-id='delete2-delete-btn'
                  // onClick={() => this.setState({ deleteModal2: false })}
                  onClick={this.handleDeleteAccount}
                >
                  Delete account
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Toaster
          containerStyle={{
            fontFamily: "Manrope",
            zIndex: 999999
          }}
          
        />
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles: Record<string, React.CSSProperties> = {
  container: {
    position: "relative",
  },
  normalText: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Manrope',
    lineHeight: '18px',
    wordSpacing: '5px',
    display: 'flex',
    alignItems: 'center',
    padding : '5px 0'
  },
  title: {
    fontSize: "30px",
    color: "#33354D",
    fontWeight: 600,
    lineHeight: "40px",
    fontFamily: "Manrope",
    margin: "50px 0"
  },
  detailsSupport: {
    width: "100%",
    display: "flex",
    gap: "20px",
    backgroundColor: "#fff"
  },
  supportbox: {
    width: "23%",
    boxShadow: "0px 8px 32px 0px #8760A929",
    padding: "12px",
    borderRadius: "8px"
  },
  textTop: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24.59px",
    fontFamily: "Manrope"
  },
  textSecond: {
    marginTop: "20px"
  },
  Details: {
    width: "70%",
    boxShadow: "0px 8px 32px 0px #8760A929",
    padding: "12px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff"
  },
  passwordbox: {
    marginTop: "30px",
    width: "70%",
    boxShadow: "0px 8px 32px 0px #8760A929",
    padding: "12px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  textcahngepass: {
    fontWeight: 700,
    fontFamily: "Manrope",
    fontSize: "20px",
    lineHeight: "27.32px"
  },
  textcahngepassbutton: {
    backgroundColor: "#E6DEED",
    color: "black",
    padding: "10px, 16px, 10px, 16px",
    borderRadius: "4px",
    width: "172px",
    fontFamily: "Manrope",
    fontWeight: 700
  },
  editDetailsbox: {
    display: "flex",
    justifyContent: "end"
  },
  editDetails: {
    backgroundColor: "#E6DEED",
    color: "black",
    fontFamily: "Manrope",
    fontWeight: 700
  },
  DetailsboxSupport: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
    alignItems: "center"
  },

  supporDetailsButon: {
    backgroundColor: "#E6DEED",
    color: "black",
    display: "flex",
    justifySelf: "end",
    marginTop: "25px",
    fontFamily: "Manrope",
    fontWeight: 700
  },

  detailsText: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px"
  },
  detailsAccount: {
    margin: "25px 0",
    display: "flex",
    gap: "20px"
  },
  detailsBox: {
    border: "1px solid #E6DEED",
    width: "50%",
    borderRadius: "8px",
    padding: "10px"
  },
  detailsBoxs: {
    marginTop: "25px",
    border: "1px solid #E6DEED",
    borderRadius: "8px",
    padding: "10px"
  },
  rowDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "25px"
  },
  fieldName: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#A3A6C2"
  },
  fieldNameSupport: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#A3A6C2",
    display: "flex",
    justifyContent: "end"
  },
  fieldValue: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#33354D"
  },
  BottomSavebutton: {
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "21.86px",
    fontFamily: "Manrope",
    color: "#fff",
    padding: "10px 16px",
    cursor: "pointer"
  },
  cancelBottom: {
    color: "#8760A9",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21.86px",
    fontFamily: "Manrope",
    cursor: "pointer"
  },
  BottomButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "20px",
    alignItems: "center",
    gap: "10px"
  },
  filedModalBox: {
    marginTop: "20px"
  },
  filedModal: {
    width: "50%"
  },
  ".modalInput:focus": {
    outline: "none"
  },

  fontModalTop: {
    fontSize: "13px",
    fontWeight: 500,
    fontFamily: "Manrope",
    margin: "5px 0"
  },
  ModalSelect: {
    width: "100%",
    padding: "10px 7px",
    borderRadius: "8px",
    border: "1px solid #E6DEED",
    background: "white",
    maxHeight: '100px',
    overflowY: 'scroll'
  },
  ModalInput: {
    width: "100%",
    padding: "8px 7px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.4px",
    fontFamily: "Manrope",
    borderRadius: "8px",
    border: "1px solid #E6DEED"
  },

  modalTopRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  modalContent: {
    position: "absolute",
    width: "100%",
    maxWidth: "900px",
    backgroundColor: "white",
    padding: "20px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    borderRadius: "8px"
  },
  subtextModal: {
    marginTop: "30px",
    marginBottom: "50px",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px"
  },
  resetmodalContent: {
    position: "absolute",
    width: "100%",
    maxWidth: "500px",
    backgroundColor: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    borderRadius: "8px"
  },
  inputImponent: {
    color: "red"
  },
  iconPassword: {
    position: "absolute",
    right: "10px",
    top: "10px",
    height: "24px",
    width: "24px",
    cursor: "pointer"
  },
  deleteModal: {
    padding: "55px 0"
  },
  errors : {
    color: 'red',
    marginTop: '5px',
    fontSize: '13px'
  }
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End

