import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import toast from "react-hot-toast";

// Customizable Area Start

export interface DepartmentList {
    id: string,
    type: string,
    attributes: {
        department_name: string
    }
}

export interface AllGroupsList {
    id: string,
    type: string,
    attributes: {
        name: string
    }
}

export interface AllAgentsList {
    id: string,
    type: string,
    attributes: {
        full_name: string
    }
}

interface AgentAndRequester {
    id: string,
    type: string,
    attributes: {
        email: string
    }
}

interface Ticket {
    id?: string;
    type?: string;
    attributes?: {
        subject: string;
        description: string;
        priority: string;
        planned_startdate: string;
        planned_enddate: string;
        planned_effort: string;
        requester: {
            id: string,
            name: string,
            email: string
        };
        status: string;
        source: null | string;
        impact: null | string;
        urgency: null | string;
        group: {
            id: string,
            name: string,
        };
        agent: {
            id: string,
            name: string,
            email: string
        };
        department: {
            id: string,
            name: string
        };
        category: null | string;
        tags: string;
        ticket_template_id: number;
        created_at: null | string;
        cc: { id: string, email: string }[];
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    openDescModal: boolean;
    handleModalState: () => void;
    navigateUser: () => void;
    openFormModal: boolean;
    handleCloseFormModal: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    requestersList: AgentAndRequester[];
    changedRequester: string | undefined;
    changeDescription: string | undefined;
    changedDepartment: string;
    allDepartmentList: DepartmentList[];
    ccDropdowns: { id?: string; email?: string; }[];
    selectedSource: string;
    allGroupList: AllGroupsList[];
    changedGroup: string;
    changedAgent: string;
    selectedStatus: string;
    selectedPriority: string;
    allAgentsList: AllAgentsList[],
    startDate: string | undefined;
    startTime: string | undefined;
    endDate: string | undefined;
    endTime: string | undefined;
    inputValue: string;
    subjectError: string;
    requesterError: string;
    descriptionError: string;
    statusError: string;
    ticketId: number | null;
    isLoading: boolean;
    ticketData: Ticket;
    startDateError: string;
    endDateError: string;
    plannedEfforts: string | undefined;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class EditIncidantTickerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRequesterTaskListsApiCallId: string = "";
    source = [
        {
            id: '1',
            title: 'Email'
        },
        {
            id: '2',
            title: 'Service Desk - If created by Poral'
        },

        {
            id: '2',
            title: 'Phone'
        },
    ];

    status: { title: string, id: string }[] = [
        {
            id: 'Open',
            title: 'Open',
        },
        {
            id: 'Closed',
            title: 'Closed',
        },
        {
            id: 'Pending',
            title: 'Pending',
        },
        {
            id: 'Resolved',
            title: 'Resolved'
        }
    ]
    updateTicketDataAPICallId: string = "";
    getAllAgentsListAPICallId: string = "";
    getAllGroupsAPICallId: string = "";
    getAllDepartmentAPICallId: string = "";
    getTicketsDataApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.state = {
            selectedStatus: '',
            requestersList: [],
            changedRequester: '',
            allDepartmentList: [],
            changedDepartment: '',
            changeDescription: '',
            ccDropdowns: [{ id: "", email: "" }],
            selectedSource: "",
            allGroupList: [],
            changedGroup: '',
            selectedPriority: '',
            allAgentsList: [],
            changedAgent: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            inputValue: '',
            subjectError: '',
            requesterError: '',
            descriptionError: '',
            statusError: '',
            ticketId: null,
            isLoading: false,
            ticketData: {},
            startDateError: '',
            endDateError: '',
            plannedEfforts: '',
        };
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const { navigation } = this.props
        const ticketID = navigation.getParam('id', 0);
        this.setState({ ticketId: ticketID })
        this.getTicketData()
        this.getAllRequesterList()
        this.getAllDepartment()
        this.getAllGroups()
        this.getAllAgentsList()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            switch (apiRequestCallId) {
                case this.getRequesterTaskListsApiCallId:
                    this.handleRequestersApiDataResponse(responseJson);
                    break;
                case this.getAllDepartmentAPICallId:
                    this.handleDepartmentListResponse(responseJson);
                    break;
                case this.getAllGroupsAPICallId:
                    this.handleGroupApiDataResponse(responseJson);
                    break;
                case this.getAllAgentsListAPICallId:
                    this.handleAgentsListResponse(responseJson);
                    break;
                case this.updateTicketDataAPICallId:
                    this.handleUpdateTicketResponse(responseJson);
                    break;
                case this.getTicketsDataApiCallId:
                    this.handleTicketDataResponse(responseJson);
                    break;
                default:
                    break;
            }
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    // Web Events
    handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { ticketData } = this.state;
        const newSubject = event.target.value;
        let subjectError = '';

        if (!newSubject) {
            subjectError = configJSON.subjectRequire;
        }
        if (ticketData.attributes) {
            ticketData.attributes.subject = newSubject;
            this.setState({ ticketData, subjectError });
        }
    };

    addCcDropdown = () => {
        this.setState((prevState: Readonly<S>) => ({
            ccDropdowns: [...prevState.ccDropdowns, { id: "", email: "" }]
        }));
    };

    removeCc = (index: number) => {
        this.setState({
            ccDropdowns: this.state.ccDropdowns.filter((unusedElement, item) => item !== index)
        });
    }

    handleCcChange = (index: number, value: string) => {
        const updatedCcDropdowns = [...this.state.ccDropdowns];
        updatedCcDropdowns[index].id = value;
        index === 0 && this.setState({ changedRequester: value })
        this.setState({ ccDropdowns: updatedCcDropdowns });
    };

    getDepartmentValue = () => {
        let selectedDepartment = this.state.allDepartmentList.filter((data: DepartmentList) => {
            return parseInt(data.id) === parseInt(this.state.changedDepartment);
        });
        return selectedDepartment[0] || null;
    }

    getAgentsValue = () => {
        let selectedAgent = this.state.allAgentsList.filter((data: AllAgentsList) => {
            return parseInt(data.id) === parseInt(this.state.changedAgent);
        });
        return selectedAgent[0] || null;
    }

    getGroupValue = () => {
        let selectedGroup = this.state.allGroupList.filter((data: AllGroupsList) => {
            return parseInt(data.id) === parseInt(this.state.changedGroup);
        });
        return selectedGroup[0] || null;
    }

    handleChangeDepartment = (value: DepartmentList | null) => {
        this.setState({ changedDepartment: value ? value.id : '' });
    }

    handleChangeAgents = (value: AllAgentsList | null) => {
        this.setState({ changedAgent: value ? value.id : '' })
    }

    handleChangeGroup = (value: AllGroupsList | null) => {
        this.setState({ changedGroup: value ? value.id : '' })
    }

    handleRequesterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedRequestId = event.target.value as string;
        let requesterError = '';
        if (!selectedRequestId) {
            requesterError = configJSON.requesterRequire;
        }
        this.setState({ changedRequester: selectedRequestId, requesterError });
    };

    handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { ticketData } = this.state;
        const newDescription = event.target.value;
        let descriptionError = '';
        if (!newDescription) {
            descriptionError = configJSON.descRequire;
        }
        if (ticketData.attributes) {
            ticketData.attributes.description = newDescription;
            this.setState({ ticketData, descriptionError });
        }
    }

    
    handleSourceChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        this.setState({ selectedSource: event.target.value as string })
    };

    handleStatusChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedStatus = event.target.value as string;
        let statusError = '';
        if (!selectedStatus) {
            statusError = configJSON.statusrequire;
        }
        this.setState({ selectedStatus, statusError });
    }



    handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedPriority: event.target.value });
    };

    handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const startDate = event.target.value;
        const { endDate } = this.state;

        if (!endDate || new Date(startDate) <= new Date(endDate)) {
            this.setState({ startDate }, this.calculateEfforts);
        } else {
            this.setState({ startDateError: configJSON.startDateError })
        }
    };

    handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ startTime: event.target.value }, this.calculateEfforts);
    };

    handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const endDate = event.target.value;
        const { startDate } = this.state;

        if (!startDate || new Date(endDate) >= new Date(startDate)) {
            this.setState({ endDate }, this.calculateEfforts);
        } else {
            this.setState({ endDateError: configJSON.endDateError })
        }
    };

    handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ endTime: event.target.value }, this.calculateEfforts);
    };

    calculateEfforts = () => {
        const { startDate, startTime, endDate, endTime } = this.state;

        const startDateTime = new Date(startDate + "T" + startTime);
        const endDateTime = new Date(endDate + "T" + endTime);
        const difference = endDateTime.getTime() - startDateTime.getTime();

        const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        const minutesDifference = Math.floor((difference / 1000 / 60) % 60);

        this.setState({
            plannedEfforts: `${hoursDifference} hours ${minutesDifference} minutes`,
        });
    };

    validateFields = () => {
        const { ticketData } = this.state;
        let isValid = true;
        let subjectError = '';
        let descriptionError = '';

        if (!ticketData || !ticketData.attributes || !ticketData.attributes.subject) {
            subjectError = configJSON.subjectRequire;
            isValid = false;
        }

        if (!ticketData || !ticketData.attributes || !ticketData.attributes.description) {
            descriptionError = configJSON.descRequire;
            isValid = false;
        }

        this.setState({ subjectError, descriptionError });
        if (isValid) {
            this.updateTicketData()
        }
        return isValid;
    };
    // API Integration
    getTicketData = async () => {
        this.setState({ isLoading: true })
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTicketDataApiEndPoint + this.state.ticketId
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        this.getTicketsDataApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateTicketData = async () => {
        this.setState({ isLoading: true })
        const ticketId = await getStorageData("selectedTicketId")
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };
        let ccDropdown: string[] = []
        this.state.ccDropdowns?.length > 1 && this.state.ccDropdowns.forEach((item: { id?: string, email?: string }, index: number) => {
            if (index !== 0) {
                item.id && ccDropdown?.push(item.id)
            }
        })

        const startDateTime = `${this.state.startDate}T${this.state.startTime}:00.000Z`;
        const endDateTime = `${this.state.endDate}T${this.state.endTime}:00.000Z`;

        const httpBody = {
            ticket: {
                subject: this.state.ticketData.attributes?.subject,
                description: this.state.ticketData.attributes?.description,
                requester_id: this.state.changedRequester,
                department_id: this.state.changedDepartment,
                source: this.state.selectedSource,
                status: this.state.selectedStatus,
                priority: this.state.selectedPriority,
                group_id: this.state.changedGroup,
                agent_id: this.state.changedAgent,
                planned_startdate: startDateTime,
                planned_enddate: endDateTime,
                planned_effort: this.state.plannedEfforts,
                cc: ccDropdown
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getTicketDataApiEndPoint + ticketId
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateAgentGroupsApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        this.updateTicketDataAPICallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAllRequesterList = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequesterTaskListsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllRequestersApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllDepartment = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllDepartmentAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllDepartmentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllGroups = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllGroupsAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllGroupsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getAllAgentsList = async () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllAgentsListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAgentsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Responses

    handleTicketDataResponse = (responseJson: { data: Ticket }) => {
        if (responseJson) {
            this.setState({ isLoading: false })
        }
        if (responseJson.data) {
            let ccDropdowns: { id?: string, email?: string }[] = []

            let requesterData = responseJson.data.attributes?.requester

            ccDropdowns.push({ id: requesterData?.id, email: requesterData?.email })
            responseJson.data.attributes?.cc.map((item: { id?: string, email?: string }) => ccDropdowns.push({ id: item?.id, email: item?.email }))

            this.setState({
                ticketData: responseJson.data,
                changedRequester: responseJson.data.attributes?.requester?.id,
                changedDepartment: responseJson.data.attributes?.department?.id || '',
                selectedSource: responseJson.data.attributes?.source || "",
                changedGroup: responseJson.data.attributes?.group?.id || "",
                selectedStatus: responseJson.data.attributes?.status || "",
                selectedPriority: responseJson.data.attributes?.priority || "",
                changedAgent: responseJson.data.attributes?.agent?.id || "",
                ccDropdowns,
                startDate: responseJson.data.attributes?.planned_startdate && (new Date(responseJson.data.attributes?.planned_startdate).toISOString().split('T')[0]),
                endDate: responseJson.data.attributes?.planned_enddate && (new Date(responseJson.data.attributes?.planned_enddate).toISOString().split('T')[0]),
                startTime: responseJson.data.attributes?.planned_startdate && (new Date(responseJson.data.attributes?.planned_startdate).toISOString().split('T')[1].split('.')[0]),
                endTime: responseJson.data.attributes?.planned_enddate && (new Date(responseJson.data.attributes?.planned_enddate).toISOString().split('T')[1].split('.')[0]),
                plannedEfforts: responseJson.data.attributes?.planned_effort

            })
        }
    }

    handleRequestersApiDataResponse = (responseJson: { data: AgentAndRequester[] }) => {
        if (responseJson.data) {
            const requesterData = responseJson.data?.map((requester) => ({
                id: requester.id,
                type: 'someType',
                attributes: {
                    email: requester.attributes.email || ''
                }
            }));
            this.setState({
                requestersList: requesterData
            });
        }
    };

    handleDepartmentListResponse = (responseJson: { data: DepartmentList[] }) => {
        if (responseJson.data) {
            const departmentData = responseJson.data.map((department) => ({
                id: department.id,
                type: 'someType',
                attributes: {
                    department_name: department.attributes.department_name || ''
                },

            }));
            this.setState({
                allDepartmentList: departmentData
            })
        }
    }

    handleGroupApiDataResponse = (responseJson: { data: AllGroupsList[] }) => {
        if (responseJson.data) {
            const groupData = responseJson.data.map((group) => ({
                id: group.id,
                type: 'someType',
                attributes: {
                    name: group.attributes.name || ''
                }
            }));
            this.setState({
                allGroupList: groupData
            })
        }
    }

    handleAgentsListResponse = (responseJson: { data: AllAgentsList[] }) => {
        if (responseJson.data) {
            const agentData = responseJson.data.map((agents) => ({
                id: agents.id,
                type: 'someType',
                attributes: {
                    full_name: agents.attributes?.full_name || ''
                }
            }));
            this.setState({
                allAgentsList: agentData
            })
        }
    }

    handleUpdateTicketResponse = (responseJson: { data: {}, errors: [] }) => {
        if (responseJson && responseJson?.data) {
            toast.success(configJSON.updatedForm)
            this.setState({ isLoading: false })
            this.props.handleCloseFormModal()
            this.props.handleModalState()
            this.getTicketData()
        }
    }
    // Customizable Area End
}
