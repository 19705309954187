import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList } from "./types";
import { ChangeEvent } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface Ticket {
  id?: string;
  type?: string;
  attributes?: {
    subject: string;
    description: string;
    priority: string | null
    planned_startdate: string;
    planned_enddate: string;
    planned_effort: null | string;
    status: string | null
    source: string | null;
    impact: string | null;
    urgency: string | null;
    category: string | null;
    tags: string;
    ticket_template_id: string;
    created_at: string | null;
    cc: {
      id: string;
      full_name: string;
      email: string
    }[];
    group: {
      id: string | null;
      name: string | null;
    };
    requester: {
      id: string | null;
      name: string | null;
      email: string | null;
      full_name: string | null;
    };
    department: {
      id: string | null;
      name: string | null;
    };
    agent: {
      id: string | null;
      name: string | null;
      email: string | null;
    }
  }
}

interface GroupedByStatus {
  [key: string]: Ticket[];
}

interface GroupedByStatusNew {
  [status: string]: {
    tickets: Ticket[];
    count: number;
  };
}

interface ApiCallIds {
  [ky: string]: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  page: number;
  ticketList: Ticket[];
  rowsPerPage: number;
  selected: string[];
  selectBtn: 'table' | 'board'
  isLoading: boolean
  filterBy: 'all' | 'status' | 'priority'
  anchorEl: HTMLElement | null | undefined,
  open: boolean,
  totalTicketCount: number;
  showModal: boolean;
  ticketListNew : GroupedByStatusNew;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  getTicketsApiCallId = "";
  apiCallIds: ApiCallIds = {
    all : "",
    open: '',
    closed: '',
    pending: '',
    resolved: '',
    Low: "",
    Medium :"",
    High : "",
    Urgent : ""
  };
  token: string = '';
  status: { title: string, id: string }[] = [
    {
      id: 'open',
      title: 'Open',
    },
    {
      id: 'closed',
      title: 'Closed',
    },
    {
      id: 'pending',
      title: 'Pending',
    },
    {
      id: 'resolved',
      title: 'Resolved'
    }
  ]
  priority = [
    {
      id: 'Low',
      title: 'Low'
    },
    {
      id: 'Medium',
      title: 'Medium'
    },
    {
      id: 'High',
      title: 'High'
    },
    {
      id: 'Urgent',
      title: 'Urgent'
    },
  ]

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      isVisibleModal: false,
      dropdownTasks: false,
      page: 0,
      ticketList: [],
      rowsPerPage: 5,
      selected: [],
      selectBtn: 'table',
      isLoading: false,
      filterBy: 'all',
      anchorEl: null,
      open: false,
      totalTicketCount:0,
      showModal: false,
      ticketListNew : {}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.token = await getStorageData('authToken')
    this.getToken();
    this.getAllTickets()
  }

  receive = async (from: string, message: Message) => {
    this.handleMessageReception(message);

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    this.handleTokenExpiryAndInvalidToken(responseJson);
    this.handleFetchTaskListResponse(message, responseJson, apiRequestId);
    this.handleCreateNewTaskListResponse(message, responseJson, apiRequestId);
    this.handleSessionResponse(message);
    this.handleEditTaskListResponse(message, responseJson, apiRequestId);
    this.handleDeleteTaskListResponse(message, responseJson, apiRequestId);
    this.handleFetchTasksResponse(message, responseJson, apiRequestId);
    this.handleTicketsApiCallId(apiRequestId, responseJson);
  };

  handleMessageReception(message: Message) {
    runEngine.debugLog("Message Received", message);
  }

  handleTokenExpiryAndInvalidToken(responseJson: any) {
    if (responseJson && Array.isArray(responseJson.errors) && responseJson.errors.length > 0 &&
      (responseJson.errors[0].token === "Token has Expired" || responseJson.errors[0].token === 'Invalid token')) {
      this.goToLogin();
    }
  }

  handleFetchTaskListResponse(message: Message, responseJson: any, apiRequestId: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTaskListsApiCallId != null &&
      this.getTaskListsApiCallId === apiRequestId) {
      if (!responseJson.errors && responseJson.data) {
        this.setState({
          taskLists: responseJson.data.sort((a: ITaskList, b: ITaskList) => a.id - b.id),
        });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ taskLists: [] });
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleCreateNewTaskListResponse(message: Message, responseJson: any, apiRequestId: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postTaskListApiCallId != null &&
      this.postTaskListApiCallId === apiRequestId) {
      if (!responseJson.errors && responseJson.data) {
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleSessionResponse(message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
  }

  handleEditTaskListResponse(message: Message, responseJson: any, apiRequestId: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putTaskListApiCallId != null &&
      this.putTaskListApiCallId === apiRequestId) {
      if (!responseJson.errors && responseJson.data) {
        this.getTaskLists(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeleteTaskListResponse(message: Message, responseJson: any, apiRequestId: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteTaskListApiCallId != null &&
      this.deleteTaskListApiCallId === apiRequestId) {
      if (!responseJson.errors && responseJson.message) {
        this.getTaskLists(this.state.token);
      } else {
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleFetchTasksResponse(message: Message, responseJson: any, apiRequestId: any) {
    if (this.isFetchTasksResponse(message, apiRequestId)) {
      if (!responseJson.errors && responseJson.data) {
        const updatedTasks = this.updateTasks(responseJson.data);
        this.updateTasksState(updatedTasks);
      } else {
        this.handleFetchTasksError(message);
      }
    }
  }

  isFetchTasksResponse(message: Message, apiRequestId: any): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTasksApiCallId != null &&
      this.getTasksApiCallId === apiRequestId;
  }

  updateTasks(tasks: ITask[]): ITask[] {
    return tasks.map((task: ITask) => ({
      ...task,
      isSelected: this.isTaskSelected(task)
    }));
  }

  isTaskSelected(task: ITask): boolean {
    return this.state.selectedTasks.some((selectedTask: ITask) => selectedTask.id === task.id);
  }

  updateTasksState(updatedTasks: ITask[]) {
    this.setState({ tasksData: updatedTasks });
  }

  handleFetchTasksError(message: Message) {
    this.showAlert("Alert", "No Data", "");
    this.setState({ tasksData: [] });
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    this.parseApiCatchErrorResponse(errorResponse);
  }


  handleTicketsApiCallId(apiRequestId: any, responseJson: any) {
    const relevantIds = new Set([
      this.getTicketsApiCallId,
      this.apiCallIds.open,
      this.apiCallIds.closed,
      this.apiCallIds.pending,
      this.apiCallIds.resolved,
      this.apiCallIds.Low,
      this.apiCallIds.Medium,
      this.apiCallIds.High,
      this.apiCallIds.Urgent,
    ]);
  
    if (relevantIds.has(apiRequestId)) {
      this.handleGetAllTicketsResponse(responseJson);
    }
  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  showAddModal = () => {
    this.getTasks(this.state.token);
    this.setState({
      name: "",
      dropdownTasks: false,
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.getTasks(this.state.token);
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };

  // Function to fetch task list from the API
  getTaskLists = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to add new task list and send it to API
  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to fetch tasks from the API
  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllTickets = (status="",priority="") => {
    this.setState({ isLoading: true })
    const pageValue = this.state.page + 1;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTicketsApiEndPoint + `?page=${pageValue}&per_page=${this.state.rowsPerPage}&priority=${priority}&status=${status}&group_by=${this.state.filterBy}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    if (status) {
      this.apiCallIds[status] = requestMessage.messageId
    } else if (priority) {
      this.apiCallIds[priority] = requestMessage.messageId
    } else {
      this.getTicketsApiCallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelection = (value: 'table' | 'board') => {
    this.setState({ selectBtn: value });
  };

  onPageChange = (_: unknown, page: number) => {
    this.setState({ page, ticketList:[] },()=>{
      if (this.state.filterBy === "status") {
        this.setState({ page})
        this.getAllTickets("open", "");
      }
      if (this.state.filterBy === "priority") {
        this.setState({ page })
        this.getAllTickets("", "low");
      } 
      if (this.state.filterBy === "all") {
        this.setState({ page })
        this.getAllTickets("", "");
      }
    });
  };


  onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) },()=>{
      this.getAllTickets("","");
    });
  };

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.ticketList.map((data) => data?.id || '');
      this.setState({ selected: newSelecteds || [] });
      return;
    }
    this.setState({ selected: [] });
  };

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateUser = (id: string | undefined) => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationTaskScreenMessage)
    );
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props.navigation.navigate);
    navigationMessage.addData(getName(MessageEnum.UrlPathParamMessage), id);
    this.send(navigationMessage);
  }


  getStateColor = (state: string | undefined) => {
    if (state) {
      const stateColorMapping: { [key: string]: string } = {
        'Open': '#11A64A',
        'Pending': '#F59E0B',
        'Resolved': '#11A64A',
        'Closed': '#DC2626',

        'medium': '#11A64A',
        'low': '#F59E0B',
        'high': '#DC2626',
      };

      return stateColorMapping[state] || '#11A64A'
    } else {
      return '#11A64A'
    }
  };

  handleSelect = (id: string) => {
    const { selected } = this.state;
    let newSelected: string[] = [];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    this.setState({ selected: newSelected });
  };
  getTableData() {
    return this.state.ticketList.slice(
      this.state.page * this.state.rowsPerPage,
      Math.min(
        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
        this.state.ticketList.length
      )
    )
  }

  handleRowClick = async (ticket: Ticket) => {
    if (ticket && ticket.id) {
      await setStorageData('selectedTicketId', ticket.id);
      this.props.navigation.navigate('Task', { id: ticket.id });
    }
  }

  handleGetAllTicketsResponse = (responseJson: { total_ticket_count: number; tickets : { data: Ticket[] } }) => {
    if (responseJson?.tickets?.data) {
      if(this.state.filterBy === "status"){
        const groupedByStatusData = this.groupByStatusNew(responseJson?.tickets?.data, responseJson.total_ticket_count);
        this.setState({ticketListNew : {...this.state.ticketListNew,...groupedByStatusData},isLoading: false,});
      } else if(this.state.filterBy === "priority"){
        const groupedByPriorityData = this.groupByPriorityNew(responseJson?.tickets?.data, responseJson.total_ticket_count);
        this.setState({ticketListNew : {...this.state.ticketListNew,...groupedByPriorityData},isLoading: false});
      }else{
        const groupByAllData = {
          ['all']: {
            tickets: responseJson?.tickets?.data,
            count: responseJson.total_ticket_count
          }
        }
        this.setState({ticketListNew : {...this.state.ticketListNew,...groupByAllData},isLoading: false,});
      }
    }
  }

  getCapitalizeName = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      open: false,
    });
  }

  changeStatus = async(value: 'all' | 'status' | 'priority') => {
    this.setState({filterBy: value, open: false},async () => {
      if (this.state.filterBy === "status") {
        this.setState({ page: 0, ticketListNew : {}})
        for(const statusValue of this.status){
          this.getAllTickets(statusValue.id, "");
          await new Promise(resolve => setTimeout(resolve, 300));
        };
      }
      if (this.state.filterBy === "priority") {
        this.setState({ page: 0, ticketListNew : {} })
        for(const priorityValue of this.priority){
          this.getAllTickets("", priorityValue.id)
          await new Promise(resolve => setTimeout(resolve, 300));
        };
      } 
      if (this.state.filterBy === "all") {
        this.setState({ page: 0, ticketListNew : {}})
        this.getAllTickets("", "");
      }
    })
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  }

  groupByStatusNew(ticketList: Ticket[], count: number): GroupedByStatusNew {
    return ticketList.reduce((acc, ticket) => {
      const statusValue = ticket.attributes?.status as string;
      if (!acc[statusValue]) {
        acc[statusValue] = {
          tickets: [],
          count: 0,
        };
      }
      acc[statusValue].tickets.push(ticket);
      acc[statusValue].count = count;
      return acc;
    }, {} as GroupedByStatusNew);
  }

  groupByPriorityNew(ticketList: Ticket[], count: number): GroupedByStatusNew {
    return ticketList.reduce((acc, ticket) => {
      const priorityValue = ticket.attributes?.priority as string;
      if (!acc[priorityValue]) {
        acc[priorityValue] = {
          tickets: [],
          count: 0,
        };
      }
      acc[priorityValue].tickets.push(ticket);
      acc[priorityValue].count = count;
      return acc;
    }, {} as GroupedByStatusNew);
  }

  groupByStatus(ticketList: Ticket[]): GroupedByStatus {
    return ticketList.reduce((acc, ticket) => {
      const statusValue = ticket.attributes?.status as string;
      if (!acc[statusValue]) {
        acc[statusValue] = [];
      }
      acc[statusValue].push(ticket);
      return acc;
    }, {} as GroupedByStatus);
  }

  groupByPriority(ticketList: Ticket[]): GroupedByStatus {
    return ticketList.reduce((acc, ticket) => {
      const priorityValue = ticket.attributes?.priority as string;
      if (!acc[priorityValue]) {
        acc[priorityValue] = [];
      }
      acc[priorityValue].push(ticket);
      return acc;
    }, {} as GroupedByStatus);
  }

  getShortUserName = (value:string)=>{
    if(value){
      const userNameArr = value.toUpperCase().split(" ")
      if(userNameArr.length>1){
        return `${userNameArr[0][0]}${userNameArr[1][0]}`
      }else{
        return `${userNameArr[0][0]}`
      }
    }
  }
  
  handleModalState = (value: boolean) => this.setState({ showModal: value })

  
  // Customizable Area End
}
