import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import toast from 'react-hot-toast';
interface FieldAttributes {
  name: string;
  title: string | null;
  column_type: 'input' | 'email' | 'number' | 'select' | 'textarea';
  mandatory: boolean;
  form_type: string;
  status: boolean;
  optional: boolean;
}

export interface DynamicField {
  id: string;
  type: 'dynamic_field';
  attributes: FieldAttributes;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  txtInputValue: string;
  openListIndex: number;
  requesterFields: DynamicField[];
  agentFields: DynamicField[];
  isInfoModalOpen: boolean;
  anchorEl: HTMLElement | null | undefined;
  openEditModal: boolean;
  fieldsData: DynamicField[];
  selectedUser: 'agent' | 'requester' | '';
  fields: DynamicField[] | []
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserFieldsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pathname = "/userFields";
  getFieldDataApiCallId: string = '';
  updateFieldDataApiCallId: string = '';
  navigationList = [
    { title: "Account settings", list: [] },
    {
      title: "User management",
      list: [
        { title: "Agents", href: "/agents" },
        { title: "Roles", href: "/roles" },
        { title: "Departments", href: "/departments" },
        { title: "Department Fields", href: "/department-fields", },
        { title: "Requesters", href: "/requesters" },
        { title: "User Fields", href: "/userFields" },
        { title: "CAB", href: "/cab" },
        { title: "Agent groups", href: "/agentGroup" },
        { title: "Requester groups", href: "/requester-groups" }]
    },
    { title: "Channels", list: [], },
    { title: "Service management", list: [] },
    { title: "Automation and productivity", list: [] },
    { title: "Asset management", list: [] },
    { title: "IT operations management", list: [] },
    { title: "Project and workload management", list: [] }];
  token: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      openListIndex: -1,
      isInfoModalOpen: false,
      openEditModal: false,
      isLoading: false,
      anchorEl: null,
      selectedUser: '',
      requesterFields: [],
      agentFields: [],
      fieldsData: [],
      fields: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    if (apiRequestId === this.getFieldDataApiCallId) {
      this.handleFieldsDataResponse(responseJson)
    }

    if (apiRequestId === this.updateFieldDataApiCallId) {
      this.handleFieldUpdateResponse(responseJson)
    }

    // Customizable Area End
  }



  // Customizable Area Start
  async componentDidMount() {
    this.setDefaultOpenIndexBasedOnPath()
    this.token = await getStorageData('authToken')
    this.getFields()
  }

  setDefaultOpenIndexBasedOnPath() {
    const index = this.findMenuIndexFromPath(this.pathname);
    this.setState({ openListIndex: index });
  }

  getFields = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.fieldsApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fieldsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fieldsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getFieldDataApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  findMenuIndexFromPath(path: string) {
    for (let i = 0; i < this.navigationList.length; i++) {
      const sublist = this.navigationList[i].list;
      if (sublist.some((item) => item.href === path)) {
        return i;
      }
    }

    return -1; // Return -1 if no match is found
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  openAccordian = (index: number) => {
    this.setState((prevState) => ({
      openListIndex: prevState.openListIndex === index ? -1 : index,
    }));
  };

  handelInfoModal(value: boolean) {
    this.setState({
      isInfoModalOpen: value
    })
  }

  isPathExist = (item: { href: string }[]) => {
    return item.some((item) => item.href === this.pathname);
  }

  returAgentFieldsLength = () => this.state.agentFields.filter((agent)=> agent.attributes.status === true).length
  returnRequesterFieldsLength = () => this.state.requesterFields.filter((requester)=> requester.attributes.status === true).length

  handleRequiredChange = (index: number, checked: boolean) => {
    const fieldsKey = this.state.selectedUser === 'requester' ? 'requesterFields' : 'agentFields';
    const updatedFields = this.state[fieldsKey].map((field: DynamicField, idx: number) =>
      idx === index ? { ...field, attributes: { ...field.attributes, status: checked } } : field
    );
    this.updateFieldState(updatedFields)
  };

  handleMandatoryChange = (index: number, value: string) => {
    const fieldsKey = this.state.selectedUser === 'requester' ? 'requesterFields' : 'agentFields';
    const updatedFields = this.state[fieldsKey].map((field: DynamicField, idx: number) =>
      idx === index ? { ...field, attributes: { ...field.attributes, optional: value === 'no' ? false : true } } : field
    );
    this.updateFieldState(updatedFields)
  };

  updateFieldState(updatedFields: DynamicField[]) {
    const fieldsKey = this.state.selectedUser === 'requester' ? 'requesterFields' : 'agentFields';

    if (fieldsKey === 'requesterFields') {
      this.setState({ requesterFields: updatedFields, fieldsData: updatedFields })
    } else {
      this.setState({ agentFields: updatedFields, fieldsData: updatedFields });
    }
  }

  handleCloseEditModal = (value: boolean) => {
    this.setState({
      openEditModal: value,
      selectedUser: ''
    })
  }

  handleEditToggle = (fieldType: 'requester' | 'agent') => {
    this.setState({
      selectedUser: fieldType,
      openEditModal: true,
      fieldsData: fieldType === "agent" ? this.state.agentFields : this.state.requesterFields
    })
  };

  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      isInfoModalOpen: true,
      anchorEl: event.currentTarget,
    });
  };

  handleSaveData = () => {
    const fieldsKey = this.state.selectedUser === 'requester' ? 'requesterFields' : 'agentFields';
    const index = fieldsKey === 'requesterFields' ? 'requester' : 'agent'

    const fields = this.state.fields.filter((field: DynamicField) => field.attributes.form_type === index);
    const updatedData = this.findUpdatedElements(fields, this.state[fieldsKey]);

    if (updatedData.ids.length > 0|| updatedData.optional_ids.length > 0) {
      
      const header = {
        "Content-Type": configJSON.updateFieldApiContentType,
        token: this.token
      };

      let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateFieldApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateFieldApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(updatedData)
      );

      this.updateFieldDataApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      toast.error('No data to update.')
    }
  };

  handleCancelButton = () => {
    this.getFields()
    this.setState({
      openEditModal: false,
    })
  }

  findUpdatedElements(mainArray: DynamicField[], updatedArray: DynamicField[]) {
    const statusChangedIds: string[] = [];
    const optionalChangedIds: string[] = [];

    mainArray.forEach(mainItem => {
      const updatedItem = updatedArray.find(item => item.id === mainItem.id);

      if (updatedItem) {
        // Check for changes in 'status'
        if (mainItem.attributes.status !== updatedItem.attributes.status) {
          statusChangedIds.push(mainItem.id);
        }
        // Check for changes in 'optional'
        if (mainItem.attributes.optional !== updatedItem.attributes.optional) {
          optionalChangedIds.push(mainItem.id);
        }
      }
    });

    return {ids :statusChangedIds, optional_ids: optionalChangedIds}
  }

  handleFieldsDataResponse = (responseJson: { data: DynamicField[] }) => {
    if (responseJson && responseJson.data) {

      const agentFields = responseJson.data.filter((field: DynamicField) => field.attributes.form_type === 'agent');
      const requesterFields = responseJson.data.filter((field: DynamicField) => field.attributes.form_type === 'requester');

      this.setState({
        agentFields: agentFields,
        requesterFields: requesterFields,
        fields: responseJson.data,
      })
    }
  }

  handleFieldUpdateResponse = (responseJson: any) => {
    if (responseJson && responseJson.message === "Successfully updated") {
      toast.success(responseJson.message)
      this.setState({
        openEditModal: false,
        fieldsData: [],
      })
      this.getFields()
    }
  }

  // Customizable Area End
}
