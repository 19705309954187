import React from "react";

// Customizable Area Start
import {
    Button,
    Input,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    TableHead,
    Typography,
    Dialog,
    Box,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    styled,
} from "@material-ui/core";
import {
    MoreVert,
    Search,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Delete,
    Edit,
    ArrowDropDown
} from "@material-ui/icons";
import { Toaster } from 'react-hot-toast';

import AgentsController, { AgentAndRequester, DynamicField, Props, configJSON } from "./AgentsController";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { infoIcon, infoIconOutlined, cancelIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import CommonModal from "./CommonModal.web";

const ErrorText = styled(Typography)({
    color: 'red',
})
// Customizable Area End

export default class Agents extends AgentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderField = (field: DynamicField) => {
        const { name, column_type } = field.attributes;

        switch (column_type) {
            case 'input':
            case 'email':
            case 'number':
                return (
                    <Field
                        name={name}
                        type={column_type}
                        id={name}
                        as={TextField}
                        variant="outlined"
                        fullWidth
                    />
                );
            case 'textarea':
                return (
                    <Field
                        name={name}
                        as={TextField}
                        multiline
                        variant="outlined"
                        fullWidth
                        minRows={6}
                        id="outlined-multiline-static"
                    />
                );
            case 'select':
                return (
                    <Field
                        as={Select}
                        name={name}
                        displayEmpty
                        fullWidth
                        input={<OutlinedInput />}
                    >
                        {field.attributes.name === 'department' && this.state?.departmentList.map((x: any, i: number) => (
                            <MenuItem value={x.attributes.department_name} key={`key-${x.id}-${i}`}>{x.attributes.department_name}</MenuItem>
                        ))}
                        {field.attributes.name !== 'department' &&
                        this.getOptions(field).map((x: string | number, i: number) => (<MenuItem value={x} key={`key-${i}-${x}`}>{x}</MenuItem>))}
                    </Field>
                );
            default:
                return null;
        }
    };
    renderInfoIcon() {
        const icon = this.state.isInfoModalOpen ? infoIcon : infoIconOutlined;
        return (
            <img
                src={icon}
                aria-describedby="infoIcon"
                style={styles.infoIconsStyle}
                onClick={(e: React.MouseEvent<HTMLElement>) => this.handleInfoIconClick(e)}
                data-test-id='openInfoModal'
            />
        );
    }
    renderEmptyAgentsListMessage() {
        if (this.state.agentsList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={4} style={styles.truncatedText}>
                        {configJSON.emptyDataMessage}
                    </TableCell>
                </TableRow>
            );
        }
        return null;
    }
    renderTableHeading() {
        const headingText = this.state.editMode ? configJSON.updateAgentstLabel : configJSON.createNewAgentstLabel;
        return (
            <Typography style={styles.table_heading}>
                {headingText}
            </Typography>
        );
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                <Toaster />
                <Loader loading={this.state.isLoading} />
                <Box style={styles.container}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={styles.navigation}>
                            Settings {">"} User management {">"} Agents
                        </span>
                        <Box style={styles.more_icon_main_box}>
                            <span style={{ height: '36px', width: '36px' }}>
                                <MoreVert style={styles.more_icons_style} />
                            </span>
                        </Box>
                    </Box>

                    <Box style={{ marginBottom: "50px", marginTop: "60px" }}>
                        <Box style={{ display: "flex", gap: "5px" }}>
                            <button style={styles.arrow_btn}>
                                <KeyboardArrowLeft style={styles.arrow_icon} />
                            </button>
                            <button style={styles.arrow_btn}>
                                <KeyboardArrowRight style={styles.arrow_icon} />
                            </button>
                        </Box>
                        <Box style={styles.agents_main_box}>
                            <h1 style={styles.heading}>{configJSON.agentsLabel}</h1>
                            {this.renderInfoIcon()}
                        </Box>
                    </Box>

                    <Box style={{ marginBottom: "32px", position: "relative", }}>
                        <Search style={styles.search_icon} />
                        <Input
                            disableUnderline={true}
                            onChange={(e) => this.setInputValue(e.target.value)}
                            style={styles.input}
                            placeholder={"Search..."}
                        />
                    </Box>
                    <Box style={{ display: "flex", gap: "20px" }}>
                        <Box style={styles.agentsList}>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "37px",
                                }}
                            >
                                <Box style={styles.cardHead} >
                                    <span style={styles.card_heading}>
                                        {configJSON.agentsLabel}
                                    </span>
                                    <span style={styles.agent_count}>
                                        ({this.state.agentsList.length})
                                    </span>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Button style={styles.export_button_border}>{configJSON.importBTN}</Button>
                                    <Box style={styles.rightBorder}></Box>
                                    <Button style={styles.export_button_border}>{configJSON.exportBTN}</Button>
                                    <Box style={styles.rightBorder}></Box>
                                    <Button style={styles.export_button} endIcon={<ArrowDropDown />}>{configJSON.allAgentBTN}</Button>
                                </Box>
                            </Box>
                            <Box>
                                <TableContainer>
                                    <Table size="medium">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={styles.agent_label}
                                                >
                                                    {configJSON.agentNameLabel}
                                                </TableCell>
                                                <TableCell
                                                    style={styles.agent_label}
                                                >
                                                    {configJSON.agentEmailLabel}
                                                </TableCell>
                                                <TableCell
                                                    style={styles.agent_label}
                                                >
                                                    {configJSON.agentLastActiveLabel}
                                                </TableCell>
                                                <TableCell
                                                    style={styles.agent_label}
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.agentsList.length > 0 &&
                                                this.getTableData().map((agent: AgentAndRequester, i: number) => (
                                                    <TableRow
                                                        hover
                                                        key={`key-${agent.id}-${i}`}
                                                        onClick={()=> this.navigateUser(agent.id)}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            style={styles.truncatedText}
                                                        >{agent.attributes.full_name}
                                                        </TableCell>
                                                        <TableCell style={styles.truncatedText}>
                                                            {agent.attributes.email}
                                                        </TableCell>
                                                        <TableCell style={styles.truncatedText}>-</TableCell>
                                                        <TableCell style={styles.table_buttons} >
                                                            <Delete data-test-id='remove_agent' style={styles.button_cursor} onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                                                e?.stopPropagation();
                                                                this.handleRemoveAgent(agent.id)
                                                            }} />
                                                            <Edit data-test-id='edit_agent' style={styles.button_cursor} onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                                                                e?.stopPropagation();
                                                                this.handleEditAgent(agent)
                                                            }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            {this.renderEmptyAgentsListMessage()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    count={this.state.agentsList.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onPageChange={this.onPageChange}
                                    onRowsPerPageChange={this.onRowsPerPageChange}
                                    component="div"
                                />
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "42px",
                                }}
                            >
                                <Button style={styles.create_button} onClick={() => this.handleModalOpen(true)}
                                    data-test-id="openModal">
                                    {configJSON.addNewAgent}
                                </Button>
                            </Box>
                        </Box>
                        <Box style={styles.accordians}>
                            <List component="nav">
                                {this.navigationList.map((item, index: number) => {
                                    if (item.list.length) {
                                        return (
                                            <React.Fragment key={`key-${item.title}-${index}`}>
                                                <ListItem
                                                    button style={{
                                                        ...styles.navigation_item,
                                                        ...(this.isPathExist(item.list) &&
                                                            styles.navigation_menu_bg),
                                                    }}
                                                    onClick={() =>
                                                        this.openAccordian(index)
                                                    }
                                                    data-test-id='openAccordian'
                                                >
                                                    <ListItemText disableTypography={true} primary={item.title}
                                                        style={{
                                                            ...styles.navigation_link,
                                                            ...(this.isPathExist(item.list) &&
                                                                styles.navigation_menu_color)
                                                        }}

                                                    />
                                                </ListItem>
                                                <Collapse timeout="auto" unmountOnExit in={this.state.openListIndex === index}>
                                                    {item.list.map((link, index: number) => (
                                                        <List key={`key-${link.title}-${index}`} disablePadding style={{ marginTop: '10px' }} >
                                                            <ListItem
                                                                style={{
                                                                    ...styles.navigation_item_nested,
                                                                    ...(this.pathname ===
                                                                        link.href &&
                                                                        styles.navigation_item_active),
                                                                }}
                                                                href={link.href}
                                                                component="a" button
                                                            >
                                                                <ListItemText
                                                                    style={{
                                                                        ...styles.navigation_link,
                                                                        ...(this.pathname ===
                                                                            link.href &&
                                                                            styles.navigation_link_active_color),
                                                                    }}
                                                                    disableTypography={true}
                                                                    primary={link.title}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    ))}
                                                </Collapse>
                                            </React.Fragment>
                                        );
                                    }

                                    return (
                                        <ListItem
                                            style={styles.navigation_item}
                                            key={`key-${item.title}-${index}`}
                                            button
                                        >
                                            <ListItemText
                                                style={styles.navigation_link}
                                                primary={item.title}
                                                disableTypography={true}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Box>
                </Box>

                <Dialog
                    open={this.state.showModal}
                    onClose={() => this.handleModalOpen(false)}
                    fullWidth={true}
                    maxWidth='md'
                    data-test-id='close-dialog'
                >
                    <Box p={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                            <Typography style={styles.table_heading}>
                                {this.renderTableHeading()}
                            </Typography>
                            <span style={styles.modal_cancel_icon} onClick={() => this.handleModalOpen(false)} data-test-id="cancelIcon">
                                <img src={cancelIcon} alt="Cancel..." />
                            </span>
                        </Box>
                        <Formik
                            onSubmit={this.handleSubmit}
                            initialValues={this.state.editMode ?
                                this.convertAgentDataToFormValues(this.state.editingAgent) :
                                this.createInitialValues()}
                            validationSchema={this.createDynamicValidationSchema()}
                        >
                            {({ errors, touched, isSubmitting }: { errors: any, touched: { [key: string]: string }, isSubmitting: boolean }) => (
                                <Form translate={undefined} autoComplete="false">
                                    <Grid container spacing={2}>
                                        {this.state.fields.map((field: DynamicField) => {
                                            if (!field.attributes.status) return null;
                                            const { name, title, optional } = field.attributes;
                                            return (
                                                <Grid item xs={6} key={`key-${field.id}-${name}`}>
                                                    <InputLabel style={{ marginBottom: "5px" }}>
                                                        {title} {optional === true && <span style={{ color: "red" }}>*</span>}
                                                    </InputLabel>
                                                    {this.renderField(field)}
                                                    {(touched[field.attributes.name] || isSubmitting) &&
                                                        <ErrorMessage name={field.attributes.name} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Box mt={'30px'} style={styles.modal_button_main_box}>
                                        <Button type="reset" style={styles.cancel_button} onClick={() => this.handleModalOpen(false)} data-test-id='modal_cancel_btn' >
                                            {configJSON.cancelBTN}
                                        </Button>
                                        <Button type="submit" style={styles.add_button} >{configJSON.saveBTN}</Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Dialog >

                <CommonModal
                    isOpen={this.state.isInfoModalOpen}
                    onClose={() => this.handelInfoModal(false)}
                    title={configJSON.agentsModalTitle}
                    anchorEl={this.state.anchorEl}
                    id="infoIcon"
                    data={[
                        configJSON.agentsInfoMsg1,
                        configJSON.agentsInfoMsg2
                    ]}
                />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    container: {
        paddingBottom: "25px",
        height: "100%",
        margin: "15px 15px 15px 300px",
        fontFamily: "Manrope",
    } as const,

    arrow_btn: {
        display: "flex",
        alignItems: "center",
        height: "28px",
        justifyContent: "center",
        borderRadius: "100px",
        border: "1px solid #E6DEED",
        width: "28px",
        cursor: "pointer",
        backgroundColor: "white",
    } as const,
    arrow_icon: {
        width: "20px",
        height: "20px",
        color: "#33354D",
    } as const,
    heading: {
        color: "#33354D",
        margin: "10px 0  0",
        fontSize: "30px",
        fontWeight: "bold",
        lineHeight: "40px",
    } as const,
    table_heading: {
        fontSize: "20px",
        marginBottom: '30px',
        fontWeight: 700,
        color: "#33354D",
        fontStyle: "normal",
        lineHeight: "normal",
        fontFamily: 'Manrope',
    } as const,
    card_heading: {
        fontFamily: 'Manrope',
        color: "#33354D",
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "normal",
        fontWeight: 700,
    } as const,
    export_button: {
        fontWeight: "bolder",
        fontFamily: "Manrope",
        fontSize: "16px",
        color: "#8760A9",
        lineHeight: "16px",
        letterSpacing: "0em",
        textTransform: "capitalize",
    } as const,
    export_button_border: {
        fontFamily: "Manrope",
        fontWeight: "bolder",
        fontSize: "16px",
        lineHeight: "16px",
        color: "#8760A9",
        letterSpacing: "0em",
        textTransform: "capitalize",
    } as const,
    agent_count: {
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "19px",
    } as const,
    agentsList: {
        borderRadius: "8px",
        flex: "1",
        alignSelf: "flex-start",
        padding: "16px",
        boxShadow: '0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)'
    } as const,

    agent: {
        display: "flex",
        alignItems: "center",
        gap: "26px",
        borderRadius: "8px",
        border: "1px solid #E6DEED",
        padding: "17px 12px",
        height: "58px",
    } as const,

    agent_label: {
        color: "#A3A6C2",
        fontFamily: "Manrope",
        fontSize: "14px",
    } as const,

    create_button: {
        padding: "10px 16px",
        backgroundColor: "#E6DEED",
        color: "#33354D",
        fontFamily: "Manrope",
        fontWeight: "bolder",
        fontSize: "16px",
        letterSpacing: "0em",
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,

    add_button: {
        color: "#FFF",
        padding: "10px 16px",
        backgroundColor: "#8760A9",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Manrope",
        letterSpacing: "0em",
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,

    cancel_button: {
        fontSize: "16px",
        padding: "10px 16px",
        backgroundColor: "transparent",
        fontFamily: "Manrope",
        color: "#8760A9",
        fontWeight: 600,
        letterSpacing: "0em",
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    accordians: {
        flexBasis: "30%",
        borderRadius: "8px",
        padding: "4px",
        boxShadow: "0px 4px 8px 0px #8760A921",
    } as const,
    navigation: {
        fontSize: "14px",
        lineHeight: "19px",
        fontFamily: "Manrope",
    } as const,
    search_icon: {
        width: "24px",
        position: "absolute",
        top: "50%",
        height: "24px",
        left: "10px",
        color: "#33354D",
        transform: "translateY(-50%)",
    } as const,
    more_icon_main_box: {
        background: '#e6deed',
        borderRadius: '5px',
    } as const,
    more_icons_style: {
        width: '24px',
        height: '24px',
        color: 'black',
        padding: '5px'
    } as const,
    input: {
        width: "100%",
        height: "40px",
        fontSize: "16px",
        fontFamily: "Manrope",
        border: "1px solid #e6deed",
        padding: "10px 10px 10px 40px",
        borderRadius: "10px",
    } as const,
    navigation_item: {
        padding: "9px, 20px, 9px, 16px",
        borderRadius: "4px",
    } as const,
    navigation_item_nested: {
        padding: "9px 20px 9px 30px",
        borderRadius: "4px",
    } as const,
    navigation_item_active: {
        backgroundColor: "#8760A9",
        color: "white",
    } as const,
    navigation_link: {
        fontSize: "14px",
        fontFamily: "Manrope",
        fontWeight: "bold",
        lineHeight: "22px",
        color: "#33354D",
        letterSpacing: "0em",
    } as const,
    navigation_link_active_color: {
        color: "white",
    } as const,
    cardHead: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
    },
    agents_main_box: {
        alignItems: "center",
        gap: "5px",
        display: "flex",
    } as const,
    modal_button_main_box: {
        display: 'flex',
        gap: '1rem',
        float: 'right',
    } as const,
    truncatedText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
        whiteSpace: 'nowrap'
    } as const,
    infoIconsStyle: {
        cursor: 'pointer',
        color: "#8760A9",
        marginLeft: '10px'
    } as const,
    table_buttons: {
        gap: '1rem',
        display: 'flex',
    } as const,
    button_cursor: {
        cursor: 'pointer',
    } as const,
    rightBorder: {
        height: '20px',
        width: '2px',
        background: '#E6DEED',
        borderRadius: '1px',
        margin: '0 3px'
    } as const,
    navigation_menu_bg: {
        backgroundColor: "#8760A9",
    } as const,
    navigation_menu_color: {
        color: "white",
    } as const,
    modal_cancel_icon: {
        width: '30px',
        height: '30px',
        cursor: 'pointer'
    } as const
};
// Customizable Area End
