import React from 'react';

//Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    Dialog,
    TextField,
    Select,
    MenuItem,
    FormHelperText
}
    from '@material-ui/core';
import RequesterTaskCustomFormController,
{ Props, configJSON, }
    from './RequesterTaskCustomFormController';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//Customizable Area End

export default class RequesterTaskCustomForm extends RequesterTaskCustomFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        //Customizable Area End
    }

    render() {
        // Customizable Area Start
        //Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Dialog
                    maxWidth='md'
                    open={this.props.openModal}
                    PaperProps={{ style: webStyle.dialogWrapper }}
                    onClose={this.props.closeModal}
                    data-testId={"modal"}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box style={webStyle.dialogHeader}>
                                <Typography style={webStyle.fontStyle1}>{configJSON.textCreateNewIncident}</Typography>
                                <CloseIcon onClick={this.props.closeModal} style={{ fontSize: '24px', cursor: 'pointer' }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box style={webStyle.boxStyle1}>
                                <Box style={webStyle.boxStyle2}>
                                    <Typography style={webStyle.fontStyle2}>{configJSON.textRequester} <span style={webStyle.starColour}>*</span></Typography>
                                    <Select
                                        fullWidth
                                        variant='outlined'
                                        value={this.state.selectedEmail.id}
                                        onChange={this.handleChange}
                                        data-testId={"select_requester"}
                                    >
                                        {this.state.emailOptions.map((emailOption, index) => (
                                            <MenuItem key={emailOption.id} value={emailOption.id}>{emailOption.email}</MenuItem>
                                        ))}
                                    </Select>
                                    {this.state.isEmailValid && <FormHelperText>{this.state.emailError}</FormHelperText>}
                                </Box>
                                <Box style={webStyle.boxStyle2}>
                                    <Typography style={webStyle.fontStyle2}>{configJSON.textSubject} <span style={webStyle.starColour}>*</span></Typography>
                                    <TextField
                                        placeholder='Enter Subject'
                                        fullWidth
                                        variant='outlined'
                                        value={this.state.subject}
                                        onChange={this.handleChangeSubject}
                                        helperText={this.state.subjectError}
                                        error={!this.state.isSubjectValid && this.state.subjectError !== ''}
                                        data-testId={"change_subject"}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box style={webStyle.boxStyle2}>
                                <Typography style={webStyle.fontStyle2}>{configJSON.textDescription} <span style={webStyle.starColour}>*</span></Typography>
                                <TextField
                                    multiline
                                    fullWidth
                                    placeholder='Enter Note'
                                    variant='outlined'
                                    InputProps={{ rows: 6 }}
                                    value={this.state.description}
                                    onChange={this.handleChangeDescription}
                                    helperText={this.state.descriptionError}
                                    error={!this.state.isDescriptionValid && this.state.descriptionError !== ''}
                                    data-testId={"change_description"}
                                />
                            </Box>
                        </Grid>

                        <Grid container item xs={12} md={12} sm={12} lg={12} justifyContent='flex-end'>
                            <Box style={webStyle.boxStyle3}>
                                <Button onClick={this.props.closeModal} style={{ ...webStyle.cancelButton, textTransform: 'initial' }}>{configJSON.textCancel}</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ ...webStyle.submitButton, textTransform: 'initial' }}
                                    endIcon={<ArrowDropDownIcon />}
                                    onClick={this.submitRequesterTicket}
                                    data-testId={"Submit_form"}
                                >
                                    {configJSON.textSave}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        );
        //Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    dialogWrapper: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '25px 40px 25px 40px',
        margin: '2%'
    },

    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    boxStyle1: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '20px'
    },

    boxStyle2: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '5px'
    },

    starColour: {
        color: 'red'
    },

    boxStyle3: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
    },

    fontStyle1: {
        fontFamily: "Manrope",
        fontSize: '20px',
        fontWeight: 700,
        color: '#33354D'
    },

    fontStyle2: {
        fontFamily: "Manrope",
        fontSize: '16.5px',
        fontWeight: 500,
        color: '#3D405C'
    },

    cancelButton: {
        fontFamily: "Manrope",
        fontSize: '16px',
        fontWeight: 700,
        color: '#8760A9',
    },

    submitButton: {
        fontFamily: "Manrope",
        fontSize: '16px',
        fontWeight: 700,
        color: '#FFFFFF',
        backgroundColor: "#8760A9",
        borderRadius: '4px',
        padding: '6px 20px 6px 20px'
    }
};
//Customizable Area End