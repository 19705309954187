import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    Box,
    styled,
    createTheme,
    ThemeProvider,
    Dialog,
    AppBar,
    Tabs,
    Tab,
    Menu,
    MenuItem,
    Grid,
    Select,
    OutlinedInput,
    InputLabel,
    Card,
    Chip,
} from "@material-ui/core";
import {
    MoreVert,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardArrowDown,
    InfoOutlined,
    AddCircleOutline,
    RemoveCircleOutline,
    ExpandMoreRounded
} from "@material-ui/icons";

import NavigationSideBar from "../../../components/src/NavigationSideBar";
import AgentProfileController, { Props, configJSON } from "./AgentProfileController";
import { Toaster } from 'react-hot-toast';
import { cancelIcon } from "./assets";

const theme = createTheme({
    overrides: {
        MuiTab: {
            root: {
                "&.Mui-selected" : {
                    color: '#8760A9'
                },
                color: '#B49CC9'
            }
        }
    },
})

export function TabPanel(props: { index: number, value: number, children: React.ReactNode }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

const CardHeading = styled(Typography)({
    color: '#33354D',
    fontSize: '14px',
    fontWeight: 500
})
const CustomChip = styled(Chip)({
    fontFamily: 'Manrope',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    borderRadius: '4px !important',
    border: '0'
})
const MainCard = styled(Card)({
    marginBottom: '8px',
    padding: '12px',
    display: 'flex',
    justifyContent: 'space-between'
})

const CardTitle = styled(Typography)({
    width: '100%',
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px'
})

const CardParagraph = styled(Typography)({
    color: "#A3A6C2",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontweight: 600,
    lineheight: 'normal',
})

const NoDataMessage = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#33354D',
    padding: '2rem'
})
// Customizable Area End

export default class AgentProfile extends AgentProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    returnNoDataMessage = (value: boolean) => {
        return value ? <NoDataMessage>{configJSON.noAgentDataMessage}</NoDataMessage> : null
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavigationSideBar active={'Settings'} navigation={this.props.navigation} />
                <Toaster />
                <ThemeProvider theme={theme}>
                    <Box style={styles.container}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={styles.navigation}>
                                Settings {">"} User management {">"} Agents {">"}  {this.handleInvalidText(this.state.agentDetails.full_name)}
                            </span>
                            <Box style={styles.buttons_style}>
                                <Button style={{ ...styles.filled_button, textTransform: 'inherit' }}>{configJSON.textConvertBTNN}</Button>
                                <span onClick={this.handleClick} style={styles.more_icon_style}>
                                    <MoreVert style={styles.moreIconStyle} />
                                </span>

                            <Menu
                                anchorEl={this.state.menuAnchorEl}
                                open={this.state.openMenu}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {this.menuList?.map((list, index: number) => (
                                    <MenuItem style={{ ...styles.top_menu_items, borderBottom: list.line ? '1px solid #E6DEED' : 'none' }} key={`key-${index}`} data-test-id="menu_button"
                                        disabled={list.hide}
                                        onClick={() => {
                                            list.onClick()
                                            this.handleClose()
                                        }}
                                    >{list.title}</MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>

                    <Box style={{ marginTop: "60px", marginBottom: "50px", }}>
                        <Box style={{  display: "flex", gap: "5px",}}>
                            <button style={styles.arrow}><KeyboardArrowLeft style={styles.arrow_icon} /></button>
                            <button style={styles.arrow}><KeyboardArrowRight style={styles.arrow_icon} /></button>
                        </Box>
                        <Box
                            style={{ display: "flex",alignItems: "center", gap: "5px" }}>
                            <h1 style={styles.heading}> {this.handleInvalidText(this.state.agentDetails.full_name)}</h1>
                            <Box style={styles.dot_style}></Box>
                        </Box>
                    </Box>

                    <Box style={{ position: "relative", marginBottom: "32px", borderBottom: 'border-bottom: 1px solid #E6DEED' }}>
                        <AppBar position="static" style={{
                            color: '#fff',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            borderBottom: '2px solid #E6DEED'
                        }}>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.handleTabChange}
                                scrollButtons="on"
                                TabIndicatorProps={{
                                    style: {
                                        background: '#B49CC9'
                                    }
                                }}
                                aria-label="scrollable force tabs example"
                            >
                                <Tab style={styles.tab_style} label="General"   {...this.a11yProps(0)} />
                                <Tab style={styles.tab_style} label="Tickets"   {...this.a11yProps(1)} />
                                <Tab style={styles.tab_style} label="Assets"   {...this.a11yProps(2)} />
                                <Tab style={styles.tab_style} label="Software"   {...this.a11yProps(3)} />
                                <Tab style={styles.tab_style} label="Achievements"   {...this.a11yProps(4)} />
                            </Tabs>
                        </AppBar>
                    </Box>
                    <Box style={{ display: "flex", gap: "20px" }}>
                        <Box flex={1}>
                            <Box style={styles.main_content_card}>
                                <TabPanel value={this.state.selectedTab} index={0}>

                                    <Box style={styles.cardHead} >
                                        <Typography style={styles.cardHeading}>{configJSON.textGroupsAndRoles}</Typography>
                                    </Box>
                                    <Box style={styles.card_body}>
                                        {!this.state.isEmptyData && (
                                            <>
                                                <Box>
                                                    <CardHeading>{configJSON.textRoles}</CardHeading>
                                                    <Box>
                                                        {this.state.agentGeneralDetails.attributes.role.data?.map((role, i: number) => (
                                                            <MainCard key={`${role.id}-${i}`} variant="outlined">
                                                                <CardTitle >{this.handleInvalidText(role.attributes.name)}</CardTitle>
                                                                <CardParagraph>{this.handleInvalidText(this.state.agentGeneralDetails?.attributes.capacity_type[0])}</CardParagraph>
                                                            </MainCard>
                                                        ))}
                                                    </Box>
                                                </Box>
                                                <Box mt={'16px'}>
                                                    <CardHeading>{configJSON.textMemberOf}</CardHeading>
                                                    <Box>
                                                        {this.returnAgentsData('member', this.state.agentGeneralDetails?.attributes)?.map((x, index: number) => (
                                                            <CustomChip key={`key-${x.name}-${index}`} variant="outlined" label={x.name} style={this.getChipStyle()} />
                                                        ))}
                                                    </Box>
                                                </Box>
                                                <Box mt={'16px'}>
                                                    <CardHeading>{configJSON.textObserverOf}</CardHeading>
                                                    <Box>
                                                        {this.returnAgentsData('observer', this.state.agentGeneralDetails.attributes)?.map((x, index: number) => (
                                                            <CustomChip key={`key-${x.name}-${index}`} variant="outlined" label={x.name} style={this.getChipStyle()} />
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                        {this.returnNoDataMessage(this.state.isEmptyData)}
                                    </Box>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "42px",
                                        }}>
                                        <Button data-test-id="modalOpen" onClick={() => this.handleModal(true)} style={{ ...styles.add_button, textTransform: 'inherit' }}>{configJSON.textAddGroupText}</Button>
                                    </Box>


                                </TabPanel>
                            </Box>
                        </Box>
                        <Box style={styles.right_box}>
                            <Typography style={styles.right_menu_heading}>{configJSON.textDetails}</Typography>
                            <Box style={{ padding: '16px' }}>
                                <Box>
                                    <Typography style={styles.list_heading}>{configJSON.textGeneralTitle2}</Typography>
                                    <ul style={styles.user_info_main}>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textEmail}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.email)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textTitle}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.title)}</Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textWorkPhone}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.work_phone)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textMobilePhone}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.mobile_phone)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textTimeZone}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.time_zone)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textLanguage}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.language)} </Typography>
                                        </li>
                                    </ul>
                                </Box>

                                <Box mt={'32px'}>
                                    <Typography style={styles.list_heading}>{configJSON.textAdditioonalInformation}</Typography>
                                    <ul style={styles.user_info_main}>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textAgentType}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.agent_type)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textReportingManager}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.reporting_manager)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textBGInfo}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.background_information)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textAddress}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.address)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textDepartment}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.department)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textTimeFormat}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.time_format)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textVipUser}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.vip)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textWorkSchedule}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.work_schedule)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textLocation}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.location)} </Typography>
                                        </li>
                                        <li style={styles.user_info_list}>
                                            <Typography style={styles.title_1}>{configJSON.textSignature}</Typography>
                                            <Typography style={styles.title_2}>{this.handleInvalidText(this.state.agentDetails.signature)} </Typography>
                                        </li>
                                    </ul>
                                </Box>

                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "30px",
                                }}>
                                <Button data-test-id="navigateUser" onClick={() => this.navigateUser()} style={{ ...styles.add_button, textTransform: 'inherit' }}>{configJSON.textEdit}</Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>

                    <Dialog
                        open={this.state.openEditModal}
                        fullWidth={true}
                        maxWidth='xl'
                        PaperProps={{
                            style: { padding: '20px' }
                        }}
                        onClose={() => this.handleModal(false)}
                        data-test-id="close-dialog"
                    >
                        <Box>
                            <Box style={styles.flexWithAlign} justifyContent={'space-between'}>
                                <Typography style={{ fontWeight: 'bold' }}>{configJSON.textAddGroupText}</Typography>
                                <span style={styles.cancelIoncStyle} onClick={() => this.handleModal(false)} data-test-id="cancelIcon">
                                    <img src={cancelIcon} alt="Cancel..." />
                                </span>
                            </Box>
                            <Box mt={'35px'}>
                                <Grid container spacing={3}>
                                    <Grid item md={3}>
                                        <InputLabel style={styles.labelStyle}>{configJSON.textMember} <InfoOutlined style={styles.labelIcon} /></InputLabel>
                                        {this.state?.dropdowns?.members?.map((dropdown, index: number) => (
                                            <Box style={styles.flexWithAlign} key={index}>
                                            
                                                <Select
                                                    value={dropdown.value || ''}
                                                    onChange={(e: React.ChangeEvent<{ value: any }>) => this.updateDropdownValue('members', index, e.target.value)}
                                                    displayEmpty
                                                    fullWidth
                                                    input={<OutlinedInput />}
                                                    IconComponent={ExpandMoreRounded}
                                                    name={'members'}
                                                    style={{ ...styles.dropdownStyle, color: this.returnMenuColor(dropdown.value  || '') }}

                                                >
                                                    <MenuItem style={styles.defualtSelectedMenu} value='' disabled>{configJSON.textChooseMember}</MenuItem>
                                                    {this.state?.options?.members?.map((option, index: number) => (
                                                        <MenuItem
                                                            value={option.id}
                                                            key={`key-${option.id}-${index}`}
                                                            disabled={this.state.selectedOptions?.members?.includes(option.id) && dropdown.value !== option.id}
                                                        >
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <RemoveCircleOutline data-test-id="remove_icon_click" style={styles.removeIconsStyle} onClick={() => this.removeDropdown('members', index)} />
                                            </Box>
                                    ))}
                                    {this.canAddMoreDropdowns('members') &&
                                        <Box display={'flex'} justifyContent={'end'}  mr={'-1px'}>
                                            <Button data-test-id="addMembersButton" style={{ ...styles.addButtonStyle, textTransform: 'inherit' }} onClick={() => this.addDropdown('members')} endIcon={<AddCircleOutline />}>{configJSON.textAddGroup}</Button>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item md={3}>
                                    <InputLabel style={styles.labelStyle}>{configJSON.textObserver} <InfoOutlined style={styles.labelIcon} /></InputLabel>
                                    {this.state.dropdowns.observers?.map((dropdown, index: number) => (
                                        <Box style={styles.flexWithAlign} key={`key-${index}`}>
                                            <Select
                                                value={dropdown.value || ''}
                                                onChange={(e: React.ChangeEvent<{ value: any }>) => this.updateDropdownValue('observers', index, e.target.value)}
                                                displayEmpty
                                                fullWidth
                                                input={<OutlinedInput />}
                                                IconComponent={ExpandMoreRounded}
                                                name={'observer'}
                                                style={{ ...styles.dropdownStyle, color: this.returnMenuColor(dropdown.value  || '') }}
                                            >
                                                <MenuItem style={styles.defualtSelectedMenu} value='' disabled>{configJSON.textChooseObserver}</MenuItem>
                                                {this.state?.options?.observers?.map((option) => (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                        disabled={this.state?.selectedOptions?.observers?.includes(option.id) && dropdown.value !== option.id}
                                                    >
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <RemoveCircleOutline data-test-id='remove_observers' style={styles.removeIconsStyle} onClick={() => this.removeDropdown('observers', index)} />
                                        </Box>

                                    ))}
                                    {this.canAddMoreDropdowns('observers') &&
                                        <Box display={'flex'} justifyContent={'end'}  mr={'-1px'}>
                                            <Button style={{ ...styles.addButtonStyle, textTransform: 'inherit' }} data-test-id='add_observers' onClick={() => this.addDropdown('observers')} endIcon={<AddCircleOutline />}>{configJSON.textAddGroup}</Button>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item md={3}>
                                    <InputLabel style={styles.labelStyle}>{configJSON.textRole} <InfoOutlined style={styles.labelIcon} /></InputLabel>
                                    {this.state.dropdowns.role?.map((dropdown, index: number) => (
                                        <Box style={styles.flexWithAlign} key={`key-${index}`}>
                                            <Select
                                                value={dropdown?.value || ''}
                                                onChange={(e: React.ChangeEvent<{ value: any }>) => this.updateDropdownValue('role', index, e.target.value)}
                                                displayEmpty
                                                fullWidth
                                                input={<OutlinedInput />}
                                                IconComponent={KeyboardArrowDown}
                                                name={'role'}
                                                style={{ ...styles.dropdownStyle, color: this.returnMenuColor(dropdown.value  || '') }}
                                            >
                                                <MenuItem style={styles.defualtSelectedMenu} value='' disabled>{configJSON.textChooseRole}</MenuItem>
                                                {this.state?.options?.role?.map((option, index: number) => (
                                                    <MenuItem
                                                        value={option.id}
                                                        key={`key-${option.id}-${index}`}
                                                        disabled={this.state?.selectedOptions?.role?.includes(option.id) && dropdown.value !== option.id}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <RemoveCircleOutline data-test-id='remove_role' style={styles.removeIconsStyle} onClick={() => this.removeDropdown('role', index)} />
                                        </Box>
                                    ))}
                                    {this.canAddMoreDropdowns('role') &&
                                        <Box display={'flex'} justifyContent={'end'} mr={'-1px'}>
                                            <Button style={{ ...styles.addButtonStyle, textTransform: 'inherit' }} data-test-id="add_role" onClick={() => this.addDropdown('role')} endIcon={<AddCircleOutline />}>{configJSON.textAddRole}</Button>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item md={3}>
                                    <InputLabel style={styles.hiddenLabelStyle}>{configJSON.textCapacity}</InputLabel>
                                    <Box style={styles.flexWithAlign} >
                                        <Select
                                            value={this.state.selectedOptions?.capacity || ''}
                                            onChange={(e: React.ChangeEvent<{ value: any }>) => this.handleCapacityChange(e.target.value)}
                                            displayEmpty
                                            fullWidth
                                            input={<OutlinedInput />}
                                            IconComponent={ExpandMoreRounded}
                                            name={'capacities'}
                                            style={{ ...styles.dropdownStyle, color: this.returnMenuColor(this.state.selectedOptions?.capacity || '') }}
                                        >
                                            <MenuItem style={styles.defualtSelectedMenu} value='' disabled={true}>{configJSON.inWhatCapsMessage}</MenuItem>
                                            {this.state?.options?.capacities?.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                        <Box mt={'30px'} style={styles.modal_button_main_box}>
                            <Button style={styles.cancel_button} onClick={() => this.onResetClick()} data-test-id="reset-form">
                                {configJSON.cancelBTN}
                            </Button>
                            <Button onClick={() => this.handleSaveAgentDetails()} data-test-id="submitBTN" style={{ ...styles.add_button, backgroundColor: '#8760A9', textTransform: 'inherit', color: '#FFF' }} >{configJSON.saveBTN}</Button>
                        </Box>
                    </Box>
                </Dialog>
            </ThemeProvider >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    container: {
        height: "100%",
        paddingBottom: "25px",
        margin: "15px 15px 15px 300px",
        fontFamily: "Manrope",
    } as const,
    arrow: {
        display: "flex",
        justifyContent: "center",
        height: "28px",
        alignItems: "center",
        border: "1px solid #E6DEED",
        width: "28px",
        cursor: "pointer",
        borderRadius: "100px",
        backgroundColor: "white",
    } as const,
    heading: {
        fontSize: "30px",
        lineHeight: "40px",
        color: "#33354D",
        marginTop: "10px",
        marginBottom: '0',
        fontWeight: "bold",
    } as const,
    arrow_icon: {
        height: "20px",
        width: "20px",
        color: "#33354D",
    } as const,
    settings_icon: {
        color: "black",
        height: "24px",
        width: "24px",
    } as const,
    setting: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        width: "36px",
        height: "36px",
        borderRadius: "4px",
        backgroundColor: "#E6DEED",
    } as const,
    modal_button_main_box: {
        display: 'flex',
        gap: '1rem',
        float: 'right',
    } as const,
    main_content_card: {
        flex: "1",
        borderRadius: "8px",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    } as const,
    edit_button: {
        padding: "10px 16px",
        backgroundColor: "#E6DEED",
        fontSize: "16px",
        color: "#33354D",
        fontFamily: "Manrope",
        fontWeight: "bolder",
        lineHeight: "16px",
        letterSpacing: "0em",
        borderRadius: "4px",
        textTransform: "initial",
    } as const,
    cancel_button: {
        fontSize: "16px",
        padding: "10px 16px",
        backgroundColor: "transparent",
        fontFamily: "Manrope",
        color: "#8760A9",
        fontWeight: 700,
        letterSpacing: "0em",
        lineHeight: "16px",
        textTransform: "initial",
        borderRadius: "4px",
    } as const,
    right_box: {
        flexBasis: "30%",
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        borderRadius: "8px",
        padding: "4px",
    } as const,
    navigation: {
        fontFamily: "Manrope",
        fontSize: "14px",
        lineHeight: "19px",
    } as const,
    cardHead: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    } as const,
    buttons_style: {
        display: 'flex',
        gap: '1rem',
        borderRadius: '5px'
    } as const,
    moreIconStyle: {
        width: '24px',
        height: '24px',
        color: 'black',
        padding: '5px'
    } as const,
    dot_style: {
        height: '16px',
        width: '16px',
        background: '#C1BBEB',
        borderRadius: '50%',
        margin: '0 0 10px 10px'
    } as const,
    tab_style: {
        color: '#B49CC9',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'inherit'
    } as const,
    user_info_main: {
        listStyle: 'mone',
        padding: '0'
    } as const,
    user_info_list: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px'
    } as const,
    title_1: {
        color: '#A3A6C2',
        fontSize: '14px',
        fontWeight: 600
    } as const,
    title_2: {
        color: '#33354D',
        fontSize: '14px',
        fontWeight: 700
    } as const,
    cardHeading: {
        color: '#33354D',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700
    } as const,
    card_body: {
        marginTop: '30px'
    } as const,
    add_button: {
        padding: "10px 16px",
        backgroundColor: "#E6DEED",
        color: "#33354D",
        fontFamily: "Manrope",
        fontWeight: 'bold',
        fontSize: "16px",
        lineHeight: "100%",
        borderRadius: "4px",
    } as const,
    filled_button: {
        color: "#FFF",
        padding: "10px 16px",
        backgroundColor: "#8760A9",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Manrope",
        lineHeight: "16px",
        borderRadius: "4px",
    } as const,
    top_menu_items: {
        padding: '9px 20px 9px 16px',
        color: '#33354D',
        fontSize: '14px',
        fontWeight: 400,
        lineGeight: '22px',
        width: '192px'
    } as const,
    more_icon_style: {
        height: '36px',
        width: '36px',
        background: '#E6DEED',
        cursor: 'pointer',
        borderRadius: '4px'
    } as const,
    right_menu_heading: {
        color: '#33354D',
        fontSize: '20px',
        fontWeight: 700,
        padding: '1rem'
    } as const,
    list_heading: {
        color: '#33354D',
        fontSize: '11px',
        fontWeight: 700,
    } as const,
    labelStyle: {
        fontSize: '12px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4px',
        gap: '4px',
    } as const,
    hiddenLabelStyle: {
        fontSize: '12px',
        fontWeight: 500,
        opacity: 0,
        padding: '4px',
    } as const,
    labelIcon: {
        fontSize: '16px',
        color: '#8760A9'
    } as const,
    addButtonStyle: {
        fontSize: '14px',
        color: '#8760A9',
        fontWeight: 700,
    } as const,
    removeIconsStyle: {
        color: '#8760A9',
        cursor: "pointer",
        marginLeft: '10px'
    } as const,
    dropdownStyle: {
        height: '44px',
        marginBottom: '4px',
        maxWidth: '90%'
    } as const,
    defualtSelectedMenu: {
        color: '#A3A6C2',
        fontSize: '16px',
        fontWeight: 400
    } as const,
    cancelIoncStyle: {
        height: '30px',
        width: '30px',
        cursor: "pointer",
    } as const,
    flexWithAlign: {
        display: 'flex',
        alignItems: 'center'
    }
};
// Customizable Area End
