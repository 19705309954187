import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Checkbox,
  TablePagination,
  ButtonGroup,
  styled,
  Chip,
  Menu,
  TextField,
  Select,
  OutlinedInput,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Search, ArrowDropDown, AddCircleOutline, ExpandMoreRounded, Clear } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import NavigationSideBar from "../../../components/src/NavigationSideBar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Field, Form, Formik, ErrorMessage, FormikProps } from "formik";
import { ChangeEvent } from "react"
import  { FieldTypes, InitialValue} from "./CfproblemmanagementticketController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
  overrides: {
     MuiInput: {
      input: {
        borderRadius: '8px'
      }
     },
    MuiRadio: {
      root: {
        color: '#8760A9',
      },
      colorSecondary: {
        '&$checked': {
          color: '#8760A9',
        },
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    },
    MuiCheckbox: {
      root: {
          color: '#8760A9'
      },
      input: {
          borderRadius: '10px',
      },
      colorSecondary: {
          '&$checked': {
              color: '#8760A9',
          },
      },
  },
  }
});

const ErrorText = styled(Typography)({
  color: 'red',
})
const CustomChip = styled(Chip)({
  color: '#FFF',
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  borderRadius: '4px !important',
  border: '0',
  minWidth: '77px',
  "& .MuiChip-clickable.MuiChip-outlined:focus":{
    backgroundColor:'#11A64A'
  }
})

const CustomMenuItem = styled(MenuItem)({
  padding: '9px 16px'
})

const StyledButtonGroup = styled(ButtonGroup)({
  backgroundColor: '#E6DEED',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '50px',
  border: 'none',
  outline: 'none',
  padding: '5px',
  '& button': {
    border: 'none',
    borderRadius: '50px !important',
    color: '#33354D',
    textTransform: 'inherit',
  },
  '& .selected': {
    color: '#fff',
    backgroundColor: '#8760A9',
    '&:hover': {
      backgrounColor: '#3700b3'
    }
  }
})

const DateTimePicker = ({ value, onChange }: { value:{ [key: string]: string | null } | null | string, onChange: (value: {[key: string]: string }) => void }) => {
  const handleDateChange = (event: ChangeEvent<{ value: string }>) => {
    if(typeof value === 'object' && value != null){
      onChange({ ...value, date: event.target.value });
    }
  };

  const handleTimeChange = (event: ChangeEvent<{ value: string }>) => {
    if(typeof value === 'object' && value != null){
      onChange({ ...value, time: event.target.value });
    }
  };

  const date = typeof value === 'object' && value != null ? value?.date || '' : ''
  const time = typeof value === 'object' && value != null ? value?.time || '' : ''

  return (
    <Box display={'flex'} style={{ gap: '11px' , width: '100%'}}>
      <TextField
        type="date"
        variant="outlined"
        placeholder="DD-MM-YYYY"
        fullWidth
        value={date || ''}
        onChange={handleDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        type="time"
        variant="outlined"
        fullWidth
        value={time}
        onChange={handleTimeChange}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300,
        }}
      />
    </Box>
  );
};
// Customizable Area End

import CfproblemmanagementticketController, {
  Problems,
  Props,
  configJSON,
} from "./CfproblemmanagementticketController";
import { Toaster } from "react-hot-toast";

export default class Cfproblemmanagementticket extends CfproblemmanagementticketController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  returnChip = (status: string) => {
    if(status) {
      return <CustomChip
      label={status}
      size="medium"
      variant="outlined"
      style={{ background: this.getStateColor(status.toLowerCase()), minWidth: '77px' }}
      />
    } else {
      return '-'
    }
  }

  renderTables() {
    const { problemList, filterBy } = this.state;
    if (problemList.length === 0) return <Typography>{configJSON.textNoProblems}</Typography>;

    if (filterBy === 'Status') {
      const groupedByStatus = this.groupByStatus(problemList);
      return Object.keys(groupedByStatus).map(status =>{
        return  (
          <React.Fragment key={status}>
            {this.renderTable(groupedByStatus[status], status)}
          </React.Fragment>
        )
      });
    } else {
      return this.renderTable(problemList, configJSON.textAllChanges);
    }
  }

  renderTable(problems: Problems[], title: string) {
    return (
      <Box style={webStyles.table_card}>
        <Box display={'flex'} justifyContent={'space-between'} mb={'35px'}>
          <Box style={webStyles.cardHead} >
            <span style={webStyles.card_heading}>{title}</span>
            <span style={webStyles.ticket_count}>({problems.length})</span>
          </Box>
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              <Button style={webStyles.export_button_border}>{configJSON.textFilter}</Button>
              <Box style={webStyles.rightBorder}></Box>
              <Button style={webStyles.export_button} endIcon={<ArrowDropDown />}>{configJSON.textSort}</Button>
              <Box style={webStyles.rightBorder}></Box>
              <Button style={webStyles.export_button_border}>{configJSON.textExport}</Button>
            </Box>
          </Box>
        </Box>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={this.state.selected.length === this.state.problemList.length}
                    indeterminate={this.state.selected.length > 0 && this.state.selected.length < this.state.problemList.length}
                    onChange={this.handleSelectAllClick}
                  />
                </TableCell>
                <TableCell style={webStyles.table_label}>
                  {configJSON.textSubject}
                </TableCell>
                <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textRequester}
                </TableCell>
                <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textStatus}
                </TableCell>
                <TableCell
                  style={webStyles.table_label}
                >
                  {configJSON.textPriority}
                </TableCell>
                <TableCell style={webStyles.table_label}>
                  {configJSON.textAssignedTo}
                </TableCell>
                <TableCell style={webStyles.table_label}>
                  {configJSON.textDepartment}
                </TableCell>
                <TableCell>
                  <Box style={webStyles.plus_style}>
                    <AddCircleOutline />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {problems.map((ticket, i: number) => (
                <TableRow
                  key={`key-${ticket.id}-${i}`}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(e) => { e?.stopPropagation() }}
                      checked={this.state.selected.indexOf(ticket?.id) !== -1}
                      onChange={() => { this.handleSelect(ticket.id) }}
                    />
                  </TableCell>
                  <TableCell style={webStyles.truncatedText}>{ticket.attributes?.subject || "--"}</TableCell>
                  <TableCell style={webStyles.truncatedText}>{ticket.attributes?.requester?.name || "--"}</TableCell>
                  <TableCell style={webStyles.truncatedText}>
                      {this.returnChip(ticket.attributes?.status)}
                  </TableCell>
                  <TableCell style={webStyles.truncatedText}>{ticket.attributes?.priority ? this.returnChip(ticket.attributes?.priority) : '-'}</TableCell>
                  <TableCell style={webStyles.truncatedText}>{ticket.attributes?.agent?.name || configJSON.textNone}</TableCell>
                  <TableCell style={webStyles.truncatedText}>{ticket.attributes?.department?.name || configJSON.textNone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={this.state.problemList.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.onPageChange}
          component="div"
          onRowsPerPageChange={this.onRowsPerPageChange}
        />
      </Box>
    )
  }
  
  renderField = (field: FieldTypes, placeholder?: string , setFieldValue? : (val: string, value: string | {} )=> void) => {

    const { name, column_type, list, values } = field;

    switch (column_type) {
      case 'input':
      case 'email':
      case 'number':
        return (
          <Field
            name={name}
            type={column_type}
            id={name}
            as={TextField}
            variant="outlined"
            fullWidth
            placeholder={placeholder}
          />
        );
      case 'textarea':
        return (
          <Field
            name={name}
            as={TextField}
            variant="outlined"
            minRows={20}
            multiline
            id="outlined-multiline-static"
            fullWidth
            placeholder={placeholder}
          />
        );
      case 'select':
        const  initVal= values ? values[name] : ''
        return (
          <Field
            as={Select}
            name={name}
            fullWidth
            input={<OutlinedInput />}
            IconComponent={ExpandMoreRounded}
            displayEmpty
            style={{color: this.returnMenuColor(initVal as string)}}
          >
            {placeholder && <MenuItem value='' disabled hidden>{placeholder}</MenuItem>}
            {list?.map((item) => <MenuItem key={`key-${item.id}`} value={item.id}>{item.title}</MenuItem>)}
          </Field>
        );
      case 'radio':
        return (
          <Field
            name={name}
            as={RadioGroup}
            variant="outlined"
          >
            {list?.map((data, index: number)=> (
              <FormControlLabel key={`key-${data.id}-${index}`} value={data.title} control={<Radio />} label={data.title} />
              ))}
          </Field>);
      case 'dateandtime':
        const  dateInitVal = values ? values[name] : ''
        return (
            <DateTimePicker
              value={dateInitVal ? dateInitVal : { date: '', time: '' }}
              onChange={(newValue) => {
                setFieldValue && setFieldValue(name, newValue);
              }}
            />
        );
      default:
        return null;
    }
  };

  showMsg = () => {
    if (this.state.problemList.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={100} style={{ "textAlign": "center" }}>
            <Typography style={{ fontWeight: 700 }}>{configJSON.emptyDataMessage}</Typography>
          </TableCell>
        </TableRow>
      )
    } else {
      return null
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <NavigationSideBar active="tickets" navigation={this.props.navigation} />
      <Loader  loading={this.state.isLoading}/>
      <Toaster />
      <ThemeProvider theme={theme}>
          <Box style={webStyles.container}>
            <Box pl={'1rem'} borderBottom={'1px solid #E6DEED'}>
              <Box style={webStyles.topheader}>
                <span style={webStyles.navigation}>
                  {configJSON.textProblems} {">"} {configJSON.textAllProblems}
                </span>
                <Box style={webStyles.inputMainBox}>
                  <Input
                    disableUnderline={true}
                    style={webStyles.input}
                    placeholder={"Search"}
                    startAdornment={<Search style={{ marginLeft: '10px' }} />}
                  />

                  <ButtonGroup
                    orientation='horizontal'
                    color="primary"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                  >
                    <Button style={{ textTransform: 'none' }} data-test-id='open-modal' onClick={() => this.handleModalState(true)} >{configJSON.textCreateNewProblem}</Button>
                    <Button>
                      <ArrowDropDown />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              <Typography style={webStyles.mainHeading}>{configJSON.textAllProblems}</Typography>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mt={'25px'} mb={'30px'}>
                <Box>
                  <StyledButtonGroup>
                    <Button
                      className={this.returnButtonState('table')}
                      onClick={() => this.handleSelection('table')}
                      data-test-id="click-table"
                    >
                      {configJSON.textTable}
                    </Button>
                    <Button
                      className={this.returnButtonState('board')}
                      onClick={() => this.handleSelection('board')}
                      data-test-id="click-board"
                    >
                      {configJSON.textBorad}
                    </Button>
                  </StyledButtonGroup>

                </Box>
                <ButtonGroup
                  orientation='horizontal'
                  variant="contained"
                  style={webStyles.filterByStyle}
                >
                  <Button style={{ textTransform: 'none' }}>
                    {configJSON.textGroupBy} <span style={{ fontWeight: 'bold' }}> {this.state.filterBy}</span>
                  </Button>

                  <Button
                    id="basic-button"
                    data-test-id="basicButton"
                    aria-controls={this.state.open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}>
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="basic-menu"
                    data-test-id="basicMenu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    getContentAnchorEl={null}
                  >
                    <CustomMenuItem data-test-id="filterAll" onClick={() => this.changeStatus('All')}>
                      {configJSON.textAll}
                    </CustomMenuItem>
                    <CustomMenuItem data-test-id="filterStatus" onClick={() => this.changeStatus('Status')}>
                      {configJSON.textStatus}
                    </CustomMenuItem>
                  </Menu>
                </ButtonGroup>
              </Box>
            </Box>

            <Box style={webStyles.main_content_card}>
              {this.renderTables()}
            </Box>
          </Box>
        <Dialog
          open={this.state.showModal}
          onClose={() => this.handleModalState(false)}
          fullWidth={true}
          maxWidth='lg'
          data-test-id='close-dialog'
        >
            <Box p={'1rem'}>
              <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography style={webStyles.table_heading}>{configJSON.textCreatNewProblem}</Typography>
                <span style={webStyles.clear_button} onClick={() => this.handleModalState(false)} data-test-id='modal-close-icon'>
                  <Clear />
                </span>
              </Box>

              <Formik
                initialValues={this.state.initialValues}
                validationSchema={this.getValidationSchema()}
                onSubmit={(value) => this.onSubmitForm(value)}
                enableReinitialize
                data-test-id="formikClickEvent"
              >
                {({ touched, isSubmitting, values, setFieldValue }: FormikProps<InitialValue>) => (
                  <Form translate={undefined} style={webStyles.form_main_Box}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>

                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textRequester}</InputLabel>
                          {this.renderField({ name: 'requester_id', column_type: 'select', list: this.state.requestersList, values }, configJSON.textRequesterPlaceholder)}
                          {(touched['requester_id'] || isSubmitting) &&
                            <ErrorMessage name={'requester_id'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>

                        {/*  */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textSubject}</InputLabel>
                          {this.renderField({ name: 'subject', column_type: 'input' }, configJSON.textEnterSubject)}
                          {(touched['subject'] || isSubmitting) &&
                            <ErrorMessage name={'subject'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>


                        {/* Status */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textStatus}</InputLabel>
                          {this.renderField({ name: 'status', column_type: 'radio', list: this.status },)}
                          {(touched['status'] || isSubmitting) &&
                            <ErrorMessage name={'status'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>

                        {/* Priority */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel}>{configJSON.textPriority}</InputLabel>
                          {this.renderField({ name: 'priority', column_type: 'radio', list: this.priority },)}
                          {(touched['priority'] || isSubmitting) &&
                            <ErrorMessage name={'priority'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>

                        {/* Due by */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>Due by</InputLabel>
                          {this.renderField({ name: 'due_by', column_type: 'dateandtime', values }, '', setFieldValue)}
                          {(touched['due_by'] || isSubmitting) &&
                            <ErrorMessage name={'due_by'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>

                        {/* Group */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel}>{configJSON.textGroup}</InputLabel>
                          {this.renderField({ name: 'group_id', column_type: 'select', list: this.state.groupList, values }, configJSON.grouoFieldPlaceholder)}
                        </Box>

                        {/* Agent */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel}>{configJSON.textAgent}</InputLabel>
                          {this.renderField({ name: 'agent_id', column_type: 'select', list: this.state.agentList , values}, configJSON.agentFieldPlaceholder)}
                        </Box>

                        {/* Department */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel}>{configJSON.textDepartment}</InputLabel>
                          {this.renderField({ name: 'department_id', column_type: 'select', list: this.state.departmentList , values}, configJSON.departmentFieldPlaceholder)}
                        </Box>

                      </Grid>
                      <Grid item xs={6}>
                        {/* Description */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textDescription}</InputLabel>
                          {this.renderField({ name: 'description', column_type: 'textarea' }, configJSON.textEnterNote)}
                          {(touched['description'] || isSubmitting) &&
                            <ErrorMessage name={'description'} render={(msg: string) => <ErrorText>{msg}</ErrorText>} />}
                        </Box>

                        {/* Category */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel}>{configJSON.textCategory}</InputLabel>
                          {this.renderField({ name: 'category', column_type: 'select', list: this.state.categoryList, values }, configJSON.textChooseCategory)}
                        </Box>


                        {/* Planned effort */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} >{configJSON.textPlannedEffort}</InputLabel>
                          {this.renderField({ name: 'planned_effort', column_type: 'input' }, configJSON.textPlannedEffortPlaceholder)}
                        </Box>

                        {/* Date and time  */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textPlannedSdate}</InputLabel>
                          {this.renderField({ name: 'planned_startdate', column_type: 'dateandtime', values }, '', setFieldValue)}
                        </Box>

                        {/* Planned end date */}
                        <Box style={webStyles.inputWrapper}>
                          <InputLabel style={webStyles.inputLabel} required>{configJSON.textPlannedEdate} </InputLabel>
                          {this.renderField({ name: 'planned_enddate', column_type: 'dateandtime', values }, '', setFieldValue)}
                        </Box>

                      </Grid>
                    </Grid>
                    <Box mt={'30px'} style={webStyles.modal_button_main_Box}>
                      <Button type="reset" data-test-id="close-btn" style={webStyles.cancel_button} onClick={() => this.handleModalState(false)}> {configJSON.textCancel}</Button>
                      <Button type="submit" style={webStyles.add_button} >{configJSON.textSave}</Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
        </Dialog >
      </ThemeProvider>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles ={
  container: {
    height: "100%",
    paddingBottom: "25px",
    margin: "15px 15px 15px 267px",
    fontFamily: "Manrope",
  } as const,
  topheader: {
    display: "flex",
    justifyContent: "space-between",
    padding: '24px 0'
  } as const,
  truncatedText: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  } as const,
  navigation: {
    color: '#3D405C',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal', 
  } as const,
  inputMainBox: {
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'bolder',
    lineHeight: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: "center",
    gap: '10px'
  } as const,
  input: {
    fontFamily: "Manrope",
    width: "100%",
    maxWidth: '200px',
    border: "1px solid #e6deed",
    height: "40px",
    fontSize: "16px",
    borderRadius: "10px",
    padding: '10px 8px'
  } as const,
  table_label: {
    color: "#3D405C",
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: 700,
    opacity: '.5'
} as const,
  mainHeading: {
    color: '#33354',
    fontFamily: 'Manrope',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    padding: '40px 0'
  } as const,
  main_content_card: {
    flex: "1",
    borderRadius: "8px",
    padding: "16px",
    alignSelf: "flex-start",
    // boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  } as const,
  export_button: {
    fontWeight: "bold",
    fontFamily: "Manrope",
    fontSize: "16px",
    color: "#8760A9",
    lineHeight: "16px",
    letterSpacing: "0em",
    textTransform: "capitalize",
  } as const,
  export_button_border: {
    fontWeight: "bold",
    fontFamily: "Manrope",
    lineHeight: "16px",
    fontSize: "16px",
    letterSpacing: "0em",
    color: "#8760A9",
    textTransform: "capitalize",
  } as const,
  rightBorder: {
    height: '20px',
    width: '2px',
    background: '#E6DEED',
    borderRadius: '1px',
    margin: '0 3px'
  } as const,
  cardHead: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  } as const,
  card_heading: {
    fontFamily: 'Manrope',
    color: "#33354D",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "normal",
    fontWeight: 700,
  } as const,
  ticket_count: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",                                                                     
  } as const,
  filterByStyle: {
    textTransform: 'none',
    color: '#33354D',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 'normal',
  } as const,
  plus_style: {
    height: '24px',
    width: '24px',
    background: '#E6DEED',
    borderRadius: '50%',
    color: 'black',
    padding: '3px',
    cursor: 'pointer',
  } as const,
  table_card: {
    borderRadius: "8px",
    padding: "16px",
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
  } as const,
  table_heading: {
    color: "#33354D",
    fontSize: "20px",
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontStyle: "normal",
    lineHeight: "normal",
    marginBottom: '30px'
  } as const,
  inputWrapper: {
    margin: '24px 0'
  } as const,
  inputLabel: {
    fontSize: '12px',
    color: '#3D405C',
    fontWeight: 500,
    marginBottom: '4px'
  } as const,
  clear_button: {
    height: '30px',
    cursor: 'pointer',
    width: '30px',
  } as const,
  modal_button_main_Box: {
    gap: '1rem',
    display: 'flex',
    float: 'right',
  } as const,
  form_main_Box: {
    padding: '1rem',
  } as const,
  cancel_button: {
    color: "#8760A9",
    backgroundColor: "transparent",
    padding: "10px 16px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    fontFamily: "Manrope",
    letterSpacing: "0em",
    textTransform: "initial",
  } as const,
  add_button: {
    color: "#FFF",
    padding: "10px 16px",
    letterSpacing: "0em",
    fontSize: "16px",
    fontFamily: "Manrope",
    textTransform: "initial",
    fontWeight: 600,
    lineHeight: "16px",
    backgroundColor: "#8760A9",
    borderRadius: "4px",
  } as const,
}
// Customizable Area End
