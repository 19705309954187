import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const columns = ["Subject", "Requester", "Status", "Date", "Time", "Source", "Assign To"];
interface Ticket {
    id: string;
    attributes: {
        subject: string;
        status: string;
        source: string;
        created_at: string;
        requester: {
            full_name: string;
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    ticketsData: Ticket[];
    isModalopen: boolean;
    rowsPerPage: number;
    page: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class RequesterTaskListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRequesterTaskListsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.state = {
            ticketsData: [],
            isModalopen: false,
            rowsPerPage: 10,
            page: 0,
        };
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getRequesterTaskLists()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.getRequesterTaskListsApiCallId === apiRequestCallId) {
                this.handleRequesterTaskListData(responseJson);
            }
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleModalOpen = () => {
        this.setState({ isModalopen: true })
    }

    handleModalClose = () => {
        this.setState({ isModalopen: false })
        this.getRequesterTaskLists()
    }

    onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage: parseInt(event?.target?.value, 10) });
    };

    onPageChange = (_: unknown, page: number) => {
        this.setState({ page });
      };

    getRequesterTaskLists = async () => {
        const header = {
            token: await getStorageData("RequesterToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRequesterTaskListsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.taskRequesterEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // API Response
    handleRequesterTaskListData = (responseJson: { data?: Ticket[] }) => {
        if (responseJson.data) {
            const ticketsData = responseJson.data.map(ticket => ({
                id: ticket.id,
                attributes: {
                    subject: ticket.attributes.subject,
                    status: ticket.attributes.status,
                    source: ticket.attributes.source,
                    created_at: ticket.attributes.created_at,
                    requester: ticket.attributes.requester,
                }
            }));

            this.setState({ ticketsData });
        }
    };
    // Customizable Area End
}
