import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  pin:string;
  pinError: string;
  openModal: boolean;


  //Web Signup
otp:any;
isOTPReceived:boolean,
  fullName: string,
  companyName: string,
  confirmPassword: string,
  workEmail: string,
  timeZone: string;
  signupLabelText: string;
  signUpLabelHeader: string;
  activeStep: number,
  createAccoutSuccessFailMsg: string,
  accountCreationFailText: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createAccountApiCallIdWeb: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  verificationOTPApiCallId:any;

 resendOTPApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      isOTPReceived:false,
      otp:0,
      fullName: "",
      confirmPassword: "",
      companyName: "",
      timeZone: "",
      workEmail: "",
      pin:"",
      activeStep: 0,
      createAccoutSuccessFailMsg: "",
      pinError: "",
      openModal: false,
      accountCreationFailText: "",
      signupLabelText: configJSON.signupLabelText,
      signUpLabelHeader: configJSON.singUpLabelHeader,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.handleRestApiResponse(message);
    this.handleNavigationPayload(message);
  
    // Customizable Area End
  }

  // Customizable Area Start
  handleRestApiResponse(message : Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.validationApiCallId) {
                this.handleValidationApiResponse(responseJson);
            } else if (apiRequestCallId === this.createAccountApiCallIdWeb) {
                this.handleCreateAccountApiResponse(responseJson, errorResponse);
            }else if (apiRequestCallId === this.resendOTPApiCallId) {
              this.handleResendOTPApiResponse(responseJson);
          }  else if (apiRequestCallId === this.verificationOTPApiCallId) {
                this.handleVerificationOTPApiResponse(responseJson);
            }
        }
    }
}
handleResendOTPApiResponse(responseJson : any) {
  if (responseJson.token) {
      toast.success(responseJson.message);
  }
}
handleValidationApiResponse(responseJson : any) {
    if (responseJson.data && responseJson.data.length > 0) {
        const regexData = responseJson.data[0];
        if (regexData.password_validation_regexp) {
            this.passwordReg = new RegExp(regexData.password_validation_regexp);
        }
        if (regexData.password_validation_rules) {
            this.setState({ passwordHelperText: regexData.password_validation_rules });
        }
        if (regexData.email_validation_regexp) {
            this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
    }
}

handleCreateAccountApiResponse(responseJson : any, errorResponse : any) {
    if (!responseJson.errors) {
        console.log(responseJson.meta.pin, "Success");
        this.setState({ pin: responseJson.meta.pin, isOTPReceived: true });
        this.setState({ createAccoutSuccessFailMsg: responseJson.meta.message });
    } else {
        console.log(responseJson, "Error");
        this.setState({ createAccoutSuccessFailMsg: responseJson.meta.message });
        this.accountFail();
        this.parseApiErrorResponse(responseJson);
    }
    console.log(errorResponse, "API fail");
    this.parseApiCatchErrorResponse(errorResponse);
}

handleVerificationOTPApiResponse(responseJson : any) {
    if (!responseJson.errors) {
        setTimeout(() => {
            this.openLoginPage();
        }, 1500);
        toast.success(responseJson.message);
    } else {
        this.setState({ pinError: responseJson.errors[0].pin });
    }
}

handleNavigationPayload(message : Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
        const otpAuthAccount = message.getData(getName(MessageEnum.AuthTokenDataMessage));
        if (otpAuthAccount && otpAuthAccount.length > 0) {
            this.setState({ otpAuthToken: otpAuthAccount });
            runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
            runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
        }
    }
}



  accountFail() {
    this.showAlert(
      "Error",
      "Account Already Registered"
    );
  }
  openLoginPage() {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleNextStep = (isValid: boolean) => {
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };
  redirectLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  webcreateAccount(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const attrs = {
      email: this.state.workEmail,
      full_name: this.state.fullName,
      company_name: this.state.companyName,
      password: this.state.confirmPassword,
      time_zone: this.state.timeZone
    };

    const createAccountData = {
      type: "email_account",
      attributes: attrs
    };
    const httpBody = {
      data: createAccountData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallIdWeb = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webAccountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  resendOTPWeb=()=>{
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const attrs = {
      email: this.state.workEmail,
      
    };

    const otpData = {
      type: "email_account",
      attributes: attrs
    };
    const httpBody = {
      data: otpData,
     
    };
    const requestValidateOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOTPApiCallId = requestValidateOTPMessage.messageId;
    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPApiEndPointWeb
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );


    runEngine.sendMessage(requestValidateOTPMessage.id, requestValidateOTPMessage);
    return true;
  }

  validateOTP():boolean{
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };
    const attrs = {
      email: this.state.workEmail,
      pin: this.state.otp,
  

    };

    const otpData = {
      type: "email_account",
      attributes: attrs
    };
    const httpBody = {
      data: otpData,
      token: this.state.otpAuthToken
    };
    const requestValidateOTPMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verificationOTPApiCallId = requestValidateOTPMessage.messageId;
    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOTPValidateApiEndPoint
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestValidateOTPMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );


    runEngine.sendMessage(requestValidateOTPMessage.id, requestValidateOTPMessage);
    return true;
  }

  redirectOTPInputAuth = () => {
    this.props.navigation.navigate("OTPInputAuth")

  }


  webimgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  webbtnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState(prevState => ({
        enableReTypePasswordField: !prevState.enableReTypePasswordField
      }));

    }
  };

  webimgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  webbtnPasswordShowHideProps = {
    onPress: () => {
      this.setState(prevState => ({
        enablePasswordField: !this.state.enablePasswordField
      }));
  }
  };


  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }


    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }



  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
