import React from "react";
// Customizable Area Start
import { Box, Button, Popover, Typography } from "@material-ui/core";
import CommonModalController, { Props ,configJSON } from "./CommonModalController";
import { cancelIcon } from './assets'
// Customizable Area End

export default class CommonModal extends CommonModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Popover
                    id={this.props.id}
                    open={this.props.isOpen}
                    anchorEl={this.props.anchorEl}
                    onClose={this.props.onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: { padding: '20px'},
                      }}
                >
                    <Box style={styles.modal_main_div}>
                        <Box style={styles.modal_head}>
                            <Typography style={styles.head_title}>{this.props.title}</Typography>
                            <img src={cancelIcon} alt="cancel" style={styles.head_icon} onClick={this.props.onClose}/>
                        </Box>
                        <Box style={styles.modal_body}>
                            {this.props.data?.map((text: string, i: number) => (
                                <Typography style={styles.body_text} key={`key-${i}`}>{text}</Typography>
                            ))}
                        </Box>
                        <Box >
                            <Button style={styles.footer_btn} onClick={this.props.onClose}>{configJSON.closeBtnLabel}</Button>
                        </Box>
                    </Box>
                </Popover>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    modal_main_div: {
        maxWidth: '460px',
    } as const,
    modal_head: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px'
    } as const,
    head_title: {
        color: '#33354D',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal'
    } as const,
    head_icon: {
        height: '24px',
        width: '24px',
        cursor: 'pointer'
    } as const,
    modal_body: {
        marginBottom: '30px'
    } as const,
    body_text: {
        color: '#33354D',
        fontFamily: 'Manrope',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '140%',
        marginBottom: '2px'
    } as const,
    footer_btn: {
        display: 'flex',
        height: '48px',
        padding: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '4px',
        background: '#8760A9',
        float: 'right',
        color: 'white',
        textTransform: 'none'
    } as const
}
// Customizable Area End
