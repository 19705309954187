import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Dialog,
  Grid,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {ExpandMoreRounded,RemoveCircleOutline,AddCircle} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import ChangeTicketFormController, {
   Props,
  configJSON,
} from "./ChangeTicketFormController";

export default class ChangeTicketForm extends ChangeTicketFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Dialog
            open={this.props.openModal}
            onClose={this.props.closeModal}
            fullWidth={true}
            maxWidth='lg'
            PaperProps={{ style: styles.dialogPaperStyle }}
            data-testId={"form_modal"}
          >

            <Box style={styles.boxStyle_1}>
              <Typography style={styles.fontStyle_1}>{configJSON.textCreatNewChanges}</Typography>
              <CloseIcon
                data-testId={"closeIcon"}
                onClick={this.props.closeModal}
                style={styles.close_icon}
              />
            </Box>

            <Box style={{ marginTop: '2%' }}>
                <Typography style={styles.fontStyle_1}>{configJSON.textDetails}</Typography>
            </Box>

            <Grid container spacing={4} style={{ marginTop: '1%' }}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box style={styles.boxStyle_4}>
                  <Typography style={styles.inputLabel}>{configJSON.textRequester}<span style={styles.textColorRed}> *</span></Typography>
                  {this.state.ccDropdowns.map((recipient, index) => (
                    <Box style={styles.selectBoxStyle} key={index}>
                      <Select
                        input={<OutlinedInput />}
                        IconComponent={ExpandMoreRounded}
                        name={'cc'}
                        value={recipient.id || ''}
                        style={styles.dropdownStyle}
                        data-testId={`addcc${index}`}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) =>
                          this.handleCcChange(index, event.target.value as string)}
                        fullWidth
                        placeholder={configJSON.textEnterEmailAddress}
                      >
                        <MenuItem style={styles.defualtSelectedMenu} value='none' disabled={true}>{configJSON.textNone}</MenuItem>
                        {this.state.requestersList.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            data-testId={"template_menu_item"}
                          >
                            {option.attributes.email}
                          </MenuItem>
                        ))}
                      </Select>
                      {index > 0 && (
                        <RemoveCircleOutline
                          data-test-id="remove_icon_click"
                          style={styles.removeIconStyle}
                          onClick={() => this.removeCc(index)}
                          data-testId={"removeCC"}
                        />
                      )}
                    </Box>
                  ))}
                  {this.state.isValidationError && !this.state.ccDropdowns[0]?.id && (
                    <Typography style={styles.textColorRed}>{this.state.requesterError}</Typography>
                  )}
                  <Box display={'flex'} gridGap={'12px'} justifyContent={'end'}>
                    <Typography style={styles.textButtonStyle} data-testId={"navigate"} onClick={() => this.navigateUser()}> <AddCircle />{configJSON.textAddNewRequester}</Typography>
                  </Box>
                </Box>

                <Box style={styles.boxStyle_4}>
                  <Typography style={styles.inputLabel}>{configJSON.textSubject}<span style={styles.textColorRed}> *</span></Typography>
                  <TextField
                    variant="outlined"
                    value={this.state.subject}
                    onChange={this.handleSubjectChange}
                    data-testId={"changeSubject"}
                    placeholder={configJSON.textEnterSubject}
                  />
                  {this.state.subjectError && <Typography style={styles.textColorRed}>{this.state.subjectError}</Typography>}
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textChangeType} <span style={styles.textColorRed}> *</span></Typography>
                  <RadioGroup
                    aria-label="change type"
                    name="change_type"
                    value={this.state.changeType}
                    onChange={this.handleChangeTypeChange}
                    data-testId={"change_type"}
                  >
                    <FormControlLabel value="Hardware" control={<Radio />} label="Hardware" />
                    <FormControlLabel value="Software" control={<Radio />} label="Software" />
                    <FormControlLabel value="Network" control={<Radio />} label="Network" />
                    <FormControlLabel value="Process change" control={<Radio />} label="Process change" />
                  </RadioGroup>
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textPriority} <span style={styles.textColorRed}> *</span></Typography>
                  <RadioGroup
                    aria-label="priority"
                    name="priority"
                    value={this.state.selectedPriority}
                    onChange={this.handlePriorityChange}
                    data-testId={"change_priority"}
                  >
                    <FormControlLabel value="Low" control={<Radio />} label="Low" />
                    <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                    <FormControlLabel value="High" control={<Radio />} label="High" />
                    <FormControlLabel value="Urgent" control={<Radio />} label="Urgent" />
                  </RadioGroup>
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textGroup}</Typography>
                  <Autocomplete
                    options={this.state.filteredOptions}
                    getOptionLabel={(option) => option.attributes.name}
                    data-testId={"change_group"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder={configJSON.grouoFieldPlaceholder}
                        variant="outlined"
                        data-testId={"change_group"}
                        onChange={this.handleGroupSelectionChange}
                      />
                    )}
                  />
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textAgent}</Typography>
                  <Autocomplete
                    options={this.state.filteredAgents}
                    getOptionLabel={(option) => option.attributes.full_name}
                    data-testId={"select_agents"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder={configJSON.agentFieldPlaceholder}
                        variant="outlined"
                        onChange={this.handleAgentsSelectionChange}
                      />
                    )}
                  />
                </Box>

                <Box style={{ marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textPlannedSdate} <span style={styles.textColorRed}> *</span></Typography>
                  <Box style={styles.startDateEndBox}>
                    <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                      <TextField
                        id="date"
                        type="date"
                        variant="outlined"
                        onChange={this.handleStartDateChange}
                        value={this.state.startDate}
                        data-testId={"start_date"}
                        placeholder={configJSON.textDateFormtes}
                      />
                    </Box>

                    <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                      <TextField
                        id="time"
                        type="time"
                        variant="outlined"
                        onChange={this.handleStartTimeChange}
                        value={this.state.startTime}
                        data-testId={"start_time"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        placeholder={configJSON.textTimeFormates}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box style={{ marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textPlannedEdate} <span style={styles.textColorRed}> *</span></Typography>
                  <Box style={styles.startDateEndBox}>
                    <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                      <TextField
                        id="date"
                        type="date"
                        variant="outlined"
                        onChange={this.handleEndDateChange}
                        value={this.state.endDate}
                        data-testId={"end_date"}
                        placeholder={configJSON.textDateFormtes}
                      />
                    </Box>

                    <Box style={{ ...styles.boxStyle_4, width: '45%' }}>
                      <TextField
                        id="time"
                        type="time"
                        variant="outlined"
                        onChange={this.handleEndTimeChange}
                        value={this.state.endTime}
                        data-testId={"end_time"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        placeholder={configJSON.textTimeFormates}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box style={styles.boxStyle_4}>
                  <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textDescription}<span style={styles.textColorRed}> *</span></Typography>
                  <TextField
                    variant="outlined"
                    minRows={20}
                    multiline
                    id="outlined-multiline-static"
                    fullWidth
                    placeholder={configJSON.txtEnterDescription}
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    data-testId={"description_change"}
                  />
                  {this.state.descriptionError && <Typography style={styles.textColorRed}>{this.state.descriptionError}</Typography>}
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textDepartment}</Typography>
                  <Autocomplete
                    options={this.state.filteredDepartmentList}
                    getOptionLabel={(option) => option.attributes.department_name}
                    data-testId={"select_department"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder={configJSON.departmentFieldPlaceholder}
                        variant="outlined"
                        onChange={this.handleDepartmentSelectionChange}
                      />
                    )}
                  />
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textCategory}</Typography>
                  <Select
                    input={<OutlinedInput />}
                    IconComponent={ExpandMoreRounded}
                    name={'template'}
                    style={styles.dropdownStyle}
                    fullWidth
                    value={this.state.categoryList}
                  >
                  </Select>
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textSubCategory}</Typography>
                  <Select
                    input={<OutlinedInput />}
                    IconComponent={ExpandMoreRounded}
                    name={'template'}
                    style={styles.dropdownStyle}
                    fullWidth
                    value={this.state.categoryList}
                  >
                  </Select>
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.textPlannedEffort}</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={configJSON.textPlannedEffortPlaceholder}
                  />
                  <Box display={'flex'} gridGap={'12px'} justifyContent={'end'}>
                    <Typography style={styles.textButtonStyle} data-testId={"navigate"} onClick={() => {}}> <AddCircle />{configJSON.txtAssociateAsset}</Typography>
                  </Box>
                </Box>

                <Box style={{ ...styles.boxStyle_4, marginTop: '15px' }}>
                  <Typography style={styles.inputLabel}>{configJSON.txtMaintanceWindow}</Typography>
                  <Select
                    input={<OutlinedInput />}
                    IconComponent={ExpandMoreRounded}
                    name={'template'}
                    style={styles.dropdownStyle}
                    fullWidth
                    value={this.state.categoryList}
                  >
                  </Select>
                </Box>
              </Grid>
            </Grid>

            <Box style={{ marginTop: '2%' }}>
              <Typography style={styles.fontStyle_1}>{configJSON.textPlanning}</Typography>
            </Box>

            <Grid container spacing={4} style={{ marginTop: '0' }}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box style={{ ...styles.boxStyle_4, marginTop: '0px' }}>
                  <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textReasonForChange}</Typography>
                  <TextField
                    variant="outlined"
                    minRows={12}
                    multiline
                    id="outlined-multiline-static"
                    fullWidth
                    placeholder={configJSON.textEnterNote}
                    value={this.state.reasonForChange}
                    onChange={this.handleReasonForChange}
                    data-testId={"reason_for_change"}
                  />
                </Box>

                <Box style={styles.boxStyle_4}>
                  <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textImpact}</Typography>
                  <TextField
                    variant="outlined"
                    minRows={12}
                    multiline
                    id="outlined-multiline-static"
                    fullWidth
                    placeholder={configJSON.textEnterNote}
                    value={this.state.impact}
                    onChange={this.handleImpactChange}
                    data-testId={"impact_change"}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box style={{ ...styles.boxStyle_4, marginTop: '0px' }}>
                  <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textRolloutPlan}</Typography>
                  <TextField
                    variant="outlined"
                    minRows={12}
                    multiline
                    id="outlined-multiline-static"
                    fullWidth
                    placeholder={configJSON.textEnterNote}
                    value={this.state.rollOutPlan}
                    onChange={this.handleRollOutPlanChange}
                    data-testId={"rollout_plan_change"}
                  />
                </Box>

                <Box style={styles.boxStyle_4}>
                  <Typography style={{ ...styles.inputLabel, marginTop: '10px' }}>{configJSON.textBackoutPlan}</Typography>
                  <TextField
                    variant="outlined"
                    minRows={12}
                    multiline
                    id="outlined-multiline-static"
                    fullWidth
                    placeholder={configJSON.textEnterNote}
                    value={this.state.backOutPlan}
                    onChange={this.handleBackOutPlanChange}
                    data-testId={"backout_plan_change"}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box style={styles.buttonBoxSyle}>
              <Typography
                style={styles.cancelButton}
                onClick={this.props.closeModal}
              >
                {configJSON.textCancel}
              </Typography>
              <Button
                style={styles.submitButton}
                variant="contained"
                onClick={this.onFormSubmiit}
                data-testId={"submit_form"}
              >
                {configJSON.textSubmitRequest}
              </Button>
            </Box>
          </Dialog>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  dialogPaperStyle: {
    padding: '25px 40px 25px 40px',
    borderRadius: '8px'
  },
  boxStyle_1: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  boxStyle_4: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabel: {
    color: '#3D405C',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '4px'
  },
  textColorRed: {
    color: 'red'
  },
  selectBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
  },
  dropdownStyle: {
    color: '#A3A6C2'
  },
  removeIconStyle: {
    cursor: 'pointer',
    fontSize: '24px',
    marginLeft: '5px'
  },
  textButtonStyle: {
    fontSize: '16px',
    color: '#8760A9',
    fontWeight: 700,
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: "pointer"
  },
  startDateEndBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px'
  },
  buttonBoxSyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    marginTop: '25px'
  },
  cancelButton: {
    color: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700
  },
  submitButton: {
    backgroundColor: '#8760A9',
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'initial' as const,
    padding: '5px 35px 5px 35px',
  },
  fontStyle_1: {
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontWeight: 700,
    color: '#33354D'
  },
  close_icon: {
    cursor: 'pointer'
  },
  defualtSelectedMenu: {
    color: '#A3A6C2',
    fontSize: '16px',
    fontWeight: 400
} as const,
};
// Customizable Area End
