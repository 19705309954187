Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfproblemmanagementticket";
exports.labelBodyText = "cfproblemmanagementticket Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.textGroupBy = 'group by: ' 
exports.textNone = 'none' 
exports.textAllProblems = 'All problems' 
exports.textAllChanges = "All changes" 
exports.textProblems = 'Problems' 
exports.textCreateNewProblem = 'Create new problem' 
exports.textTable = 'Table'
exports.textBorad = 'Board'
exports.textExport = 'Export'
exports.textFilter = 'Filter'
exports.textSort = 'Sort'
exports.textSubject = 'Subject'
exports.textAssignedTo = 'Assigned to'
exports.emptyDataMessage = "No data found."
exports.textNoProblems = 'No problems found.'
exports.textAll = 'All'
exports.textState = 'State'

exports.getAllProblmesApiEndpoint = 'bx_block_cfproblemmanagementticket/problem_managements'
exports.createProblemTicketApiEndPoint = 'bx_block_cfproblemmanagementticket/problem_managements'

exports.getAllGroupsApiEndPoint = 'bx_block_department/groups?user_type=0';
exports.getAllAgentsApiEndPoint = "bx_block_department/agent_and_requesters/get_all_agent";
exports.getAllRequestersApiEndPoint = 'bx_block_department/agent_and_requesters/get_all_requester';
exports.getAllDepartmentsApiEndPoint = "bx_block_department/departments";


exports.textSubject = "Subject"
exports.textRequester = "Requester"
exports.textStatus = "Status"
exports.textPriority = "Priority"
exports.textAgent = "Agent"
exports.textGroup = "Group"
exports.textDepartment = "Department"
exports.textCategory = "Category"
exports.textDescription = "Description"
exports.textPlannedSdate= "Planned start date"
exports.textPlannedEdate= "Planned end date"
exports.textTags = "Tags"
exports.textPlannedEffort = "Planned effort"
exports.textPlannedEffortPlaceholder = "Specify effort (eg. 02h 00m)"
exports.textEnter = "Enter"
exports.textCancel = "Cancel"
exports.textSearch = "Search"
exports.textSave = "Save"
exports.textEnterSubject = "Enter subject"
exports.textCreateNewTicket = "Create new ticket"
exports.textTickets =  "Tickets"
exports.textLeaderboard =  "Leaderboard"
exports.textEnterNote = "Enter note"
exports.textChooseCategory = "Choose category"
exports.textAddCC= "Add CC"
exports.textNone = "None"
exports.textAddNewRequester = "Add new requester"
exports.textRequesterPlaceholder = "Enter email address"
exports.inputFieldEmptyErrorMessage = "cannot be empty or only whitespace"
exports.inputFieldSpecialCharMessage = "cannot start with a special character or space"
exports.successTicketCreateMessage = "Your ticket has been submitted. To access it head to tickets tab."
exports.textCreatNewProblem = "Create new problem"

exports.colorBlack = '#33354D'
exports.colorGray = '#A3A6C2'

exports.agentFieldPlaceholder = 'Assign person'
exports.departmentFieldPlaceholder = 'Assign department'
exports.grouoFieldPlaceholder  = 'Assign team'
exports.getAllProblemsApiEndpoint = 'bx_block_cfproblemmanagementticket/problem_managements'
// Customizable Area End
